import { button, container } from "@Layout/Color/color";
import React from "react";
import { Link } from "react-router-dom";
import "../invoicesForm.css";

const Master = () => {
  const sections = [
    { title: "Bank Details", path: "/BankDetails", icon: "mdi-map-marker" },
  ];

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col-md-12 col-sm-12">
          <h6 className="">
            <i class="fa-solid fa-list me-1"></i> Finance / Masters
          </h6>
        </div>
      </div>

      {/* <div className="card bg-gradient-info rounded-top-2 p-2 mb-2">
        <div className="row d-flex align-items-center">
          <div className="col-md-12 col-sm-12">
            <h3 className="page-title m-0  text-light">
              <span className="mx-2 text-primary">
                <i className="mdi mdi-file  text-light"></i>
              </span>
              Invoice Master
            </h3>
          </div>
        </div>
      </div> */}

      {/* <div className="row gy-4">
        {sections.map((section, index) => (
          <div className="col-md-4 col-sm-6" key={index}>
            <div className="card shadow h-100 border-0">
              <div className="card-body text-center">
                <div className="card-header bg-gradient-info text-white ">
                  <h5 className="card-title text-light mb-0">
                    {section.title}
                  </h5>
                </div>
                <div className="card-body text-center">
                  <Link
                    to={section.path}
                    className="btn btn-gradient-info py-2 text-white w-100  border mt-2 rounded-pill emMvBtn"
                  >
                    View
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div> */}



 <div className="row gy-4">
 {sections.map((section, index) => (
            <div
              className="col-md-4 col-sm-6"
              key={index}
            >
              <div className="card shadow h-100 border-0">
                {/* Card Header */}

                {/* Card Body */}
                  <div className="row ">
                    <div className="col-md-12">
                <div className="card-body text-center">
                      <div className="card-header bg-gradient-info text-white ">
                        <h5 className="card-title text-light mb-0 ">
                        {section.title}
                        {/* <i className={`mdi ${section.icon} fs-4 me-3`}></i> */}
                        <Link
                    to={section.path}
                    className="btn btn-gradient-info py-2 text-white w-100  border mt-2 rounded-pill emMvBtn"
                      >
                        View  <i class="fa-solid fa-arrow-right ms-2"></i>
                      </Link>
                      </h5>

                      </div>
                    </div>
                 
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

    </div>
  );
};

export default Master;
