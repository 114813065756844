import Loader from "@Layout/Loader/loader";
import { storeFeatureForm } from "@Networking/APIs/SwitchForm/switchApi";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export const FeatureForm = () => {
  const dispatch = useDispatch();
  const { featureLoading } = useSelector((state) => state.SwitchSlice);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      request_id: "0001",
      title: "",
      request_by: "",
      date: "",
      feature_description: "",
      purpose_objective: "",
      case_scenario: "",
      description: "",
      feature_type: "",
      priority_impact: "",
      affected_module_area: "",
      dependencies: "",
      compatibility_requirement: "",
      suggested_deadline_timeline: "",
      budget: "",
      resources_required: "",
      attachment: null,
      reference: "",
      review_comment: "",
      assign_to: "",
      approval_status: "",
      approved_by: "",
      additional_comment: "",
    },
  });

  const [formData, setFormData] = useState({
    request_id: "0001",
    title: "",
    request_by: "",
    date: "",
    feature_description: "",
    purpose_objective: "",
    case_scenario: "",
    description: "",
    feature_type: "",
    priority_impact: "",
    affected_module_area: "",
    dependencies: "",
    compatibility_requirement: "",
    suggested_deadline_timeline: "",
    budget: "",
    resources_required: "",
    attachment: null,
    reference: "",
    review_comment: "",
    assign_to: "",
    approval_status: "",
    approved_by: "",
    additional_comment: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
    setValue(name, value, { shouldValidate: true });
  };

  const handleCKEditorChange = (event, editor) => {
    setFormData((prevData) => ({ ...prevData, description: editor.getData() }));
    setValue("description", editor.getData(), { shouldValidate: true });
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      attachment: e.target.files[0],
    }));
    setValue("attachment", e.target.files[0]);
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file
            .then((file) => {
              const reader = new FileReader();
              reader.onload = () => {
                resolve({ default: reader.result });
              };
              reader.onerror = reject;
              reader.readAsDataURL(file);
            })
            .catch(reject);
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const onSubmit = async (data) => {
    try {

      const apiFormData = new FormData();

      for (const key in data) {
        if (key === "attachment" && data[key]) {
          apiFormData.append(key, data[key]);
        } else {
          apiFormData.append(key, data[key] || "");
        }
      }

      const response = await dispatch(storeFeatureForm(apiFormData));

      const { status, message, errors: apiErrors } = response?.payload;
      if (status == true) {
        setFormData({
          request_id: "0001",
          title: "",
          request_by: "",
          date: "",
          feature_description: "",
          purpose_objective: "",
          case_scenario: "",
          description: "",
          feature_type: "",
          priority_impact: "",
          affected_module_area: "",
          dependencies: "",
          compatibility_requirement: "",
          suggested_deadline_timeline: "",
          budget: "",
          resources_required: "",
          attachment: null,
          reference: "",
          review_comment: "",
          assign_to: "",
          approval_status: "",
          approved_by: "",
          additional_comment: "",
        });
        Object.keys(data).forEach((key) =>
          setValue(key, "", { shouldValidate: false })
        );
        toast.success(message || "Form submitted successfully!");
      }
    } catch (error) {
      toast.error(`Error submitting form: ${error.message || error}`);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div className="row">
          <div className="col-md-6">
            <label className="text-dark">Request ID</label>
            <input type="text" className="inputText" value={"0001"} readOnly />
          </div>
          <div className="col-md-6">
            <label className="text-dark">Title/Summary</label>
            <input
              name="title"
              type="text"
              className={`inputText ${errors.title ? "border-red" : ""}`}
              onChange={handleChange}
              placeholder="Enter Title/Summary"
              {...register("title", { required: "Title is required" })}
            />
            {errors.title && (
              <span className="text-danger">{errors.title.message}</span>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <label className="text-dark">Requested By</label>
            <input
              name="request_by"
              type="text"
              className={`inputText ${errors.request_by ? "border-red" : ""}`}
              onChange={handleChange}
              placeholder="Enter Requested By"
              {...register("request_by", {
                required: "Requested by is required",
              })}
            />
            {errors.request_by && (
              <span className="text-danger">{errors.request_by.message}</span>
            )}
          </div>
          <div className="col-md-6">
            <label className="text-dark">Date & Time</label>
            <input
              name="date"
              type="date"
              className={`inputText ${errors.date ? "border-red" : ""}`}
              onChange={handleChange}
              {...register("date", { required: "Date is required" })}
            />
            {errors.date && (
              <span className="text-danger">{errors.date.message}</span>
            )}
          </div>
        </div>
        <hr />
        <div className="row">
          
          <div className="col-md-12">
            <label className="text-dark">Feature Description</label>
            <textarea
              name="feature_description"
              className={`inputText ${
                errors.feature_description ? "border-red" : ""
              }`}
              onChange={handleChange}
              placeholder="Enter Feature Description"
              {...register("feature_description", {
                required: "Feature Description is required",
              })}
            />
            {errors.feature_description && (
              <span className="text-danger">
                {errors.feature_description.message}
              </span>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <label className="text-dark">Purpose/Objective</label>
            <textarea
              name="purpose_objective"
              className={`inputText ${
                errors.purpose_objective ? "border-red" : ""
              }`}
              onChange={handleChange}
              placeholder="Enter Purpose/Objective"
              {...register("purpose_objective", {
                required: "Purpose/Objective is required",
              })}
            />
            {errors.purpose_objective && (
              <span className="text-danger">
                {errors.purpose_objective.message}
              </span>
            )}
          </div>
          <div className="col-md-6">
            <label className="text-dark">Case/Scenario</label>
            <textarea
              name="case_scenario"
              className={`inputText ${
                errors.case_scenario ? "border-red" : ""
              }`}
              onChange={handleChange}
              placeholder="Enter Case/Scenario"
              {...register("case_scenario", {
                required: "Case/Scenario is required",
              })}
            />
            {errors.case_scenario && (
              <span className="text-danger">
                {errors.case_scenario.message}
              </span>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <label className="text-dark">Description</label>
            <CKEditor
              editor={ClassicEditor}
              onChange={handleCKEditorChange}
              config={{
                extraPlugins: [uploadPlugin],
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "blockQuote",
                  "|",
                  "insertTable",
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "|",
                  "imageUpload",
                  "mediaEmbed",
                  "|",
                  "undo",
                  "redo",
                  "|",
                  "imageTextAlternative",
                  "imageStyle:full",
                  "imageStyle:side",
                  "imageRemove",
                ],
                image: {
                  toolbar: [
                    "imageStyle:full",
                    "imageStyle:side",
                    "|",
                    "imageTextAlternative",
                    "imageRemove",
                    "imageUpload",
                    "imageDelete",
                  ],
                },
              }}
            />
            {errors.description && (
              <span className="text-danger">Description is required</span>
            )}
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-6">
            <label className="text-dark">Feature Type</label>
            <select
              name="feature_type"
              className={`inputText ${errors.feature_type ? "border-red" : ""}`}
              onChange={handleChange}
              {...register("feature_type", {
                required: "Feature Type is required",
              })}
            >
              <option value="" disabled>
                Select Feature Type
              </option>
              <option value="UI Enhancement">UI Enhancement</option>
              <option value="New Functionality">New Functionality</option>
              <option value="Performance Improvement">
                Performance Improvement
              </option>
            </select>
            {errors.feature_type && (
              <span className="text-danger">{errors.feature_type.message}</span>
            )}
          </div>
          <div className="col-md-6">
            <label className="text-dark">Priority/Impact</label>
            <select
              name="priority_impact"
              className={`inputText ${
                errors.priority_impact ? "border-red" : ""
              }`}
              onChange={handleChange}
              {...register("priority_impact", {
                required: "Priority/Impact is required",
              })}
            >
              <option value="" disabled>
                Select Priority
              </option>
              <option value="High">High</option>
              <option value="Medium">Medium</option>
              <option value="Low">Low</option>
            </select>
            {errors.priority_impact && (
              <span className="text-danger">
                {errors.priority_impact.message}
              </span>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <label className="text-dark">Affected Module/Area</label>
            <input
              name="affected_module_area"
              type="text"
              className={`inputText ${
                errors.affected_module_area ? "border-red" : ""
              }`}
              onChange={handleChange}
              placeholder="Enter Affected Module/Area"
              {...register("affected_module_area", {
                required: "Affected Module/Area is required",
              })}
            />
            {errors.affected_module_area && (
              <span className="text-danger">
                {errors.affected_module_area.message}
              </span>
            )}
          </div>
          <div className="col-md-6">
            <label className="text-dark">Dependencies</label>
            <input
              name="dependencies"
              type="text"
              className={`inputText ${errors.dependencies ? "border-red" : ""}`}
              onChange={handleChange}
              placeholder="Enter Dependencies"
              {...register("dependencies", {
                required: "Dependencies is required",
              })}
            />
            {errors.dependencies && (
              <span className="text-danger">{errors.dependencies.message}</span>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <label className="text-dark">Compatibility Requirements</label>
            <input
              name="compatibility_requirement"
              type="text"
              className={`inputText ${
                errors.compatibility_requirement ? "border-red" : ""
              }`}
              onChange={handleChange}
              placeholder="Enter Compatibility Requirements"
              {...register("compatibility_requirement", {
                required: "Compatibility Requirement is required",
              })}
            />
            {errors.compatibility_requirement && (
              <span className="text-danger">
                {errors.compatibility_requirement.message}
              </span>
            )}
          </div>
        </div>
        <hr />
        <div className="row mt-3">
          <div className="col-md-6">
            <label className="text-dark">Suggested Deadline/Timeline</label>
            <input
              name="suggested_deadline_timeline"
              type="date"
              className={`inputText ${
                errors.suggested_deadline_timeline ? "border-red" : ""
              }`}
              onChange={handleChange}
              {...register("suggested_deadline_timeline", {
                required: "Suggested Deadline/Timeline is required",
              })}
            />
            {errors.suggested_deadline_timeline && (
              <span className="text-danger">
                {errors.suggested_deadline_timeline.message}
              </span>
            )}
          </div>
          <div className="col-md-6">
            <label className="text-dark">Budget</label>
            <input
              name="budget"
              type="text"
              className={`inputText ${errors.budget ? "border-red" : ""}`}
              onChange={handleChange}
              placeholder="Enter Budget"
              {...register("budget", {
                required: "Budget is required",
              })}
            />
            {errors.budget && (
              <span className="text-danger">{errors.budget.message}</span>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <label className="text-dark">Resources Required</label>
            <input
              name="resources_required"
              type="text"
              className={`inputText ${
                errors.resources_required ? "border-red" : ""
              }`}
              onChange={handleChange}
              placeholder="Enter Resources Required"
              {...register("resources_required", {
                required: "Resources Required is required",
              })}
            />
            {errors.resources_required && (
              <span className="text-danger">
                {errors.resources_required.message}
              </span>
            )}
          </div>
        </div>
        <hr />
        <div className="row mt-3">
          <div className="col-md-6">
            <label className="text-dark">Attachments</label>
            <input
              name="attachment"
              type="file"
              className="inputText"
              onChange={handleFileChange}
            />
          </div>
          <div className="col-md-6">
            <label className="text-dark">References</label>
            <input
              name="reference"
              type="text"
              className={`inputText ${errors.reference ? "border-red" : ""}`}
              onChange={handleChange}
              placeholder="Enter Reference"
              {...register("reference", { required: "Reference is required" })}
            />
            {errors.reference && (
              <span className="text-danger">{errors.reference.message}</span>
            )}
          </div>
        </div>
        <hr />
        <div className="row mt-3">
          <div className="col-md-6">
            <label className="text-dark">Review Comments</label>
            <input
              name="review_comment"
              type="text"
              className="inputText"
              onChange={handleChange}
              placeholder="Enter Review Comments"
            />
          </div>
          <div className="col-md-6">
            <label className="text-dark">Assigned To</label>
            <input
              name="assign_to"
              type="text"
              className={`inputText ${errors.assign_to ? "border-red" : ""}`}
              placeholder="Enter Assigned To"
              onChange={handleChange}
              {...register("assign_to", {
                required: "Assigned to is required",
              })}
            />
            {errors.assign_to && (
              <span className="text-danger">{errors.assign_to.message}</span>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <label className="text-dark">Approval Status</label>
            <input
              name="approval_status"
              type="text"
              className={`inputText ${
                errors.approval_status ? "border-red" : ""
              }`}
              onChange={handleChange}
              placeholder="Enter Approval Status"
              {...register("approval_status", {
                required: "Approval Status is required",
              })}
            />
            {errors.approval_status && (
              <span className="text-danger">
                {errors.approval_status.message}
              </span>
            )}
          </div>
          <div className="col-md-6">
            <label className="text-dark">Approval By</label>
            <input
              name="approved_by"
              className={`inputText ${errors.approved_by ? "border-red" : ""}`}
              placeholder="Enter Approval By"
              onChange={handleChange}
              {...register("approved_by", {
                required: "Approval By is required",
              })}
            ></input>
            {errors.approved_by && (
              <span className="text-danger">{errors.approved_by.message}</span>
            )}
          </div>
        </div>
        <hr />
        <div className="row mt-3">
          
          <div className="col-md-12">
            <label className="text-dark">Additional Comments</label>
            <textarea
              name="additional_comment"
              type="text"
              className={`inputText ${
                errors.additional_comment ? "border-red" : ""
              }`}
              onChange={handleChange}
              placeholder="Enter Additional Comments"
              {...register("additional_comment", {
                required: "Additional comments are required",
              })}
            />
            {errors.additional_comment && (
              <span className="text-danger">
                {errors.additional_comment.message}
              </span>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
        {featureLoading ? <Loader /> : null}
      </div>
    </form>
  );
};
