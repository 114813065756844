import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { PartialyView, storePayment,PartialyPaid, PartialyList } from '@Networking/APIs/Invoice/Payment/PartialyPayment';
 
const PartialyPaymentSlice = createSlice({
  name: 'PartialyPaymentSlice',
  initialState: {
    loading: false,
    loading1: false,
    isLoading1:false,
    isLoading:false,
    success: false,
    error: null,
    validateError:[],
    PaymentData:null,
    paymentList:[]
  },

  extraReducers: (builder) => {
    builder.addCase(storePayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(storePayment.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
         if(action.payload.status == true){
          toast.success(action.payload.message)
        }
        else{
          // toast.error(action.payload.message)
          state.validateError = action.payload.response.data.errors;
        }
       }); 
      builder.addCase(storePayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Login failed.';
      });
      builder.addCase(PartialyView.pending, (state) => {
        state.loading1 = true;
        state.error = null;
      });
      builder.addCase(PartialyView.fulfilled, (state, action) => {
        state.loading1 = false;
        state.success = true;
        state.PaymentData = action.payload;
        });
      builder.addCase(PartialyView.rejected, (state, action) => {
        state.loading1 = false;
        state.error = action.error.message || 'Login failed.';
      });
      builder.addCase(PartialyPaid.pending, state => {
        state.isLoading = true;
      }); 
      builder.addCase(PartialyPaid.fulfilled, (state, action) => {
        state.invoicData = action.payload;
        state.isLoading = false;
      });
      builder.addCase(PartialyPaid.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });


      //Partial Payment List
      builder.addCase(PartialyList.pending, state => {
        state.isLoading1 = true;
      }); 
      builder.addCase(PartialyList.fulfilled, (state, action) => {
        state.paymentList = action.payload;
        state.isLoading1 = false;
      });
      builder.addCase(PartialyList.rejected, (state, action) => {
        state.isLoading1 = false;
        state.error = action.error.message;
      });
   },
});

export default PartialyPaymentSlice.reducer;