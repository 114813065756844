import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Modal, Button } from "react-bootstrap"; // Assuming you use react-bootstrap for modals
import {
  IoMdClose,
  IoMdCreate,
  IoMdPersonAdd,
  IoMdTrash,
} from "react-icons/io";
import ClientForm from "component/Invoice/ClientFrom/clientForm";
import EditClientForm from "component/Invoice/ClientFrom/EditClient";
import { getClient } from "@Networking/APIs/Invoice/Client/clientGetApi";
import { postProjectStore } from "@Networking/APIs/PMS/Project/projectApi";

const AddNewProject = () => {
  const dispatch = useDispatch();


  const { ClientGet } = useSelector((state) => state.clientSlice);
  const [projectId, setProjectId] = useState("");
  const [clientId, setClientId] = useState("");
  const [teamId, setTeamId] = useState("");
  const [budget, setBudget] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [projectType, setProjectType] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");

  const [errors, setErrors] = useState({});
  const [projects, setProjects] = useState([
    { id: 1, name: "Project A" },
    { id: 2, name: "Project B" },
  ]);

  const [teams, setTeams] = useState([
    { id: 1, name: "Team Alpha" },
    { id: 2, name: "Team Beta" },
  ]);

  const formData = {
    project_id: projectId,
    client_id: clientId,
    team_id: teamId,
    budget,
    start_date: startDate,
    end_date: endDate,
    project_type: projectType,
    status,
    description,
  };

  const handleProjectChange = (selectedOption) => {
    setProjectId(selectedOption ? selectedOption.value : "");
  };

  const handleClientChange = (selectedOption) => {
    setClientId(selectedOption ? selectedOption.value : "");
  };

  const handleTeamChange = (selectedOption) => {
    setTeamId(selectedOption ? selectedOption.value : "");
  };

  const validate = () => {
    const errors = {};
    if (!formData.project_id) errors.project_id = "Project is required.";
    if (!formData.client_id) errors.client_id = "Client is required.";
    if (!formData.team_id) errors.team_id = "Team is required.";
    if (!formData.budget || isNaN(formData.budget))
      errors.budget = "Budget must be a valid number.";
    if (!formData.start_date) errors.start_date = "Start date is required.";
    if (!formData.end_date) errors.end_date = "End date is required.";
    if (!formData.project_type) errors.project_type = "Project type is required.";
    if (!formData.status) errors.status = "Status is required.";
    if (!formData.description) errors.description = "Description is required.";

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);
  
    if (Object.keys(validationErrors).length === 0) {
      try {
        await dispatch(postProjectStore(formData));
        setProjectId("");
        setClientId("");
        setTeamId("");
        setBudget("");
        setStartDate("");
        setEndDate("");
        setProjectType("");
        setStatus("");
        setDescription("");
        setErrors({});
      } catch (error) {
        alert("An error occurred while submitting the form.");
      }
    }
  };
  

  useEffect(() => {
    dispatch(getClient());
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <h6 className="">
            <i class="fa-solid fa-list me-2"></i>PRM / Projects / Add New
            Project
          </h6>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="card">
              <h5 className="card-header text-center bg-gradient-info text-light p-1">
                Add New Project
              </h5>
              <div className="card-body">
                <div className="row mt-2">
                  <div className="col-md-4 col-sm-6">
                    <label>Select Project</label>
                    <Select
                      name="project_id"
                      className="select-client inputText"
                      options={projects.map((project) => ({
                        value: project.id,
                        label: project.name,
                      }))}
                      isSearchable
                      onChange={handleProjectChange}
                      placeholder={projectId || "Select Project"}
                    />
                    {errors.project_id && (
                      <p className="text-danger">{errors.project_id}</p>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label>Select Client</label>
                    <Select
                      className="select-client inputText"
                      id="countryCode"
                      name="client_id"
                      options={ClientGet?.data?.map((client) => ({
                        value: client.value,
                        label: client.label,
                      }))} 
                      placeholder={clientId || "Select Client"}
                      isSearchable
                      onChange={handleClientChange}
                    />
                    {errors.client_id && (
                      <p className="text-danger">{errors.client_id}</p>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label>Select Team</label>
                    <Select
                      className="select-client inputText"
                      id="countryCode"
                      name="team_id"
                      options={teams.map((team) => ({
                        value: team.id,
                        label: team.name,
                      }))}
                      onChange={handleTeamChange}
                      isSearchable
                    />
                    {errors.team_id && (
                      <p className="text-danger">{errors.team_id}</p>
                    )}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4 col-sm-6">
                    <label>Start Date</label>
                    <input
                      className="inputText"
                      type="date"
                      name="start_date"
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                    {errors.start_date && (
                      <p className="text-danger">{errors.start_date}</p>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label>End Date</label>
                    <input className="inputText" type="date" name="end_date"
                    onChange={(e) => setEndDate(e.target.value)}
                    />
                    {errors.end_date && (
                      <p className="text-danger">{errors.end_date}</p>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label>Budget</label>
                    <input className="inputText" type="text" name="budget" 
                    onChange={(e) => setBudget(e.target.value)}
                    />
                    {errors.budget && (
                      <p className="text-danger">{errors.budget}</p>
                    )}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-6 col-sm-6">
                    <label>Project Type</label>
                    <select
                      class="inputText"
                      name="project_type"
                      value={formData.project_type}
                      onChange={(e) =>
                        setProjectType(e.target.value) // Updates project_type in the state
                      }
                    >
                      <option selected>Select Project Type</option>
                      <option value="web">Web</option>
                      <option value="app">App</option>
                    </select>
                    {errors.project_type && (
                      <p className="text-danger">{errors.project_type}</p>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <label>Status</label>
                    <select
                      class="inputText"
                      name="status"
                      value={formData.status}
                      onChange={(e) =>
                        setStatus(e.target.value) // Updates status in the state
                      } 
                    >
                      <option selected>Select Status</option>
                      <option value="Pending">Pending</option>
                      <option value="WorkInProgress">Work In Progress</option>
                      <option value="Completed">Completed</option>
                    </select>
                    {errors.status && (
                      <p className="text-danger">{errors.status}</p>
                    )}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-12 col-sm-12">
                    <label>Description</label>
                    <textarea
                      id="description"
                      className="inputText"
                      name="description"
                      rows="4"
                      value={formData.description}
                      placeholder="Enter project description here..."
                      onChange={(e) =>
                        setDescription(e.target.value) // Updates description in the state
                      }
                    ></textarea>
                    {errors.description && (
                      <p className="text-danger">{errors.description}</p>
                    )}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-12 col-sm-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary w-50 rounded-pill"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewProject;
