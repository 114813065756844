// src/redux/api/leaveShowApi.js
import axios from 'axios';
import { BASE_URL, leaveShow } from '@Networking/APIs/NWConfig'; 
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchLeaveDetailsApi = createAsyncThunk(
  "post/fetchLeaveDetailsApi",
  async () => {
    let url = BASE_URL + leaveShow;
    const token = localStorage.getItem("authToken");

    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })).data;

      const userId = localStorage.getItem('userId'); 
      const filteredLeaves = response.leave.filter(leave => String(leave.user_id) === String(userId));

      return Promise.resolve({  leaves: filteredLeaves });
    } catch (error) {
      throw error;
    }
  }
);

export default fetchLeaveDetailsApi; // Only default export.
