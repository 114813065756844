import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DeleteWorkLocation, getWorkLocation, postWorkLocation, UpdateWorkLocation } from "@Networking/APIs/Employee/EmployeeApi";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../masterForm.css"; 

const MySwal = withReactContent(Swal);

const WorkLocationForm = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationInput, setLocationInput] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { type } = useParams();

  useEffect(() => {
    dispatch(getWorkLocation());
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const workLocationData = useSelector((state) => state.EmployeesSlice);

  const onSubmit = async (data) => {
    const newLocation = {
      location: data.location,
    };

    try {
     
      await dispatch(postWorkLocation(newLocation));
      await dispatch(getWorkLocation());
      reset();
    } catch (error) {
    }
  };

  const handleEdit = (locationData) => {
    setSelectedLocation(locationData);
    setLocationInput(locationData.location);
    openEditModal(locationData);
  };


  const handleSubmitEdit = async (id, newName) => {
    const updateData = { id, location: newName }; // No need to access newName.name
    try {
      await dispatch(UpdateWorkLocation(updateData));
      await dispatch(getWorkLocation());
      // MySwal.fire("Updated!", "Work Location has been updated.", "success");
    } catch (error) {
      MySwal.fire("Error!", "Something went wrong while updating.", "error");
    }
  };



  const openEditModal = (locationData) => {
    MySwal.fire({
      title:"Edit Work Location",
      input: "text",
      inputValue: locationData.location,
      inputPlaceholder: 'Enter New Location',
      showCancelButton: true,
      confirmButtonText: 'Update',
      cancelButtonText: 'Cancel',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      customClass: {
        title: 'custom-title',
        input: 'custom-input-size',
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button'
      },
      preConfirm: () => {
        const location = Swal.getInput().value.trim();
        return location;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmitEdit(locationData.id, result.value);
      }
    });
  };

                                  
const handleDelete = (locationData) => {
  const isConfirmed = window.confirm("Are you sure? You won't be able to revert this!");

  if (isConfirmed) {
    dispatch(DeleteWorkLocation(locationData.id));
    dispatch(getWorkLocation());
  }
}


  return (
    <>
      <div className="container-fluid">

      <div className="row">
        <div className="col-md-12 col-sm-12">
          <h6 className="">
            <i class="fa-solid fa-list me-2"></i>HRM / Employee Master's / Work Location
          </h6>
        </div>
      </div>

      <div className="m-1">

        <button
          type="button"
          className="btn btn-secondary mb-3"
          onClick={handleBack}
        >
          <i className="fa fa-arrow-left me-2"></i>
          Back
        </button>

        </div>


        <div className="card">
          <div className="card-body">
              <div className="row p-3">
                <div className="col-md-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row d-flex align-items-center">
                      <div className="col-md-3">
                        <h6>Work Location:</h6>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          placeholder="Enter New Location"
                          className="form-control"
                          id="location"
                          {...register("location", {
                            required: "Location is required",
                          })}
                        />
                        {errors.location && (
                          <span className="text-danger">
                            {errors.location.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-3">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>

                  <div className="row mt-3 border rounded">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Work Locations</th>
                              <th className="text-end">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {workLocationData?.workLocationData.length > 0 ? (
                              workLocationData?.workLocationData.map(
                                (locationData) => (
                                  <tr key={locationData.id}>
                                    <td>{locationData.location}</td>
                                    <td>
                                      <div className="d-flex justify-content-end">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-primary  me-2"
                                          onClick={() =>
                                            handleEdit(locationData)
                                          }
                                        >
                                          <i className="fa fa-edit"></i>
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-danger"
                                          onClick={() =>
                                            handleDelete(locationData)
                                          }
                                        >
                                          <i className="fa fa-trash "></i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td colSpan="2" className="text-center">
                                  No work locations available.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkLocationForm;
