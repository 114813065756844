import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {BASE_URL,featureForm} from '@Networking/APIs/NWConfig';

export const storeFeatureForm = createAsyncThunk(
    "post/storeFeatureForm",
    async(post) => {
        let url = BASE_URL + featureForm; 
      
        const token = localStorage.getItem("authToken");

        try {
            const response = (await axios.post(url, post, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
            })).data;

                return Promise.resolve(response);
        } catch(error) {
            return error;
        }
    }
);
