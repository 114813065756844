import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

export default function CreateExpenseTrack() {

    const [selectedOptions, setSelectedOptions] = useState([]);
    
      const ViewLeader = useSelector((state) => state.EmployeesSlice);
    
      console.log("ViewLeader", ViewLeader);
      useEffect(() => {}, [ViewLeader]);
    
      const options = [
        { value: "apple", label: "Apple" },
        { value: "banana", label: "Banana" },
        { value: "cherry", label: "Cherry" },
        { value: "date", label: "Date" },
        { value: "elderberry", label: "Elderberry" },
        { value: "fig", label: "Fig" },
        { value: "grape", label: "Grape" },
        { value: "honeydew", label: "Honeydew" },
        { value: "kiwi", label: "Kiwi" },
        { value: "lemon", label: "Lemon" },
        { value: "mango", label: "Mango" },
        { value: "nectarine", label: "Nectarine" },
        { value: "orange", label: "Orange" },
        { value: "peach", label: "Peach" },
        { value: "pineapple", label: "Pineapple" },
        { value: "quince", label: "Quince" },
        { value: "raspberry", label: "Raspberry" },
        { value: "strawberry", label: "Strawberry" },
        { value: "watermelon", label: "Watermelon" },
      ];
    
      const handleChange = (selected) => {
        setSelectedOptions(selected);
      };
    
      console.log(selectedOptions);

  return (
    <>
        <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="">
              <i class="fa-solid fa-list me-2"></i>PRM / Tasks / Add New Expense Tracking
            </h6>
          </div>
        </div>

        <form onSubmit={[]}>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="card">
                <h5 className="card-header text-center bg-gradient-info text-light p-1">
                  Add New Expense Tracking
                </h5>
                <div className="card-body">
                  <div className="row mt-2">
                    <div className="col-md-6 mb-2">
                      <label>Select Project</label>
                      <Select
                      className="select-client inputText"
                      id="countryCode"
                      // options={ClientGet}
                      // placeholder={selectedCountryCode || "Select Country Code"}
                      // {...register("phoneCode")}
                      isSearchable
                      // onChange={handleCountryCodeChange}
                    />
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <label>Expense Name</label>
                      <input className="inputText" type="text" />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6 col-sm-6">
                      <label>Amount</label>
                      <input className="inputText" type="text" />
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <label>Attachments</label>
                      <input className="inputText p-2" type="file" />
                    </div>
                  </div>
           
                  <div className="row mt-2">
                    <div className="col-md-12 col-sm-12">
                      <label>Description</label>
                      <textarea
                        id="description"
                        className="inputText"
                        name="description"
                        rows="4"
                        placeholder="Enter project description here..."
                      ></textarea>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12 col-sm-12 text-center">
                      <button
                        type="submit"
                        className="btn btn-primary w-50 rounded-pill"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
