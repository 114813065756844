import React, { useEffect, useMemo, useState } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchLeaveList,
  fetchTotalLeavesByEmployee,
  fetchEmployeeList,
} from "@Networking/APIs/Leave/allLeaveApi";



const ExpenseTracking = () => {


   const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const TableComponent = ({ data, columns, title }) => {
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
      } = useTable(
        { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
        useGlobalFilter,
        usePagination
      );
      const { globalFilter, pageIndex, pageSize } = state;
  
      const GlobalFilter = ({ globalFilter, setGlobalFilter }) => (
        <div className="d-flex justify-content-between align-items-center">
        <input
          value={globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value || undefined)}
          placeholder="Search all columns"
          className="form-control"
          style={{
            width: "200px",
            marginLeft: "10px",
          }}
        />
      
        <button
          className="btn btn-primary"
          onClick={() => navigate("/addNewExpenseTrack")}
          style={{ marginLeft: "auto" }}
        >
          Add New Expense Track
        </button>
      </div>
      
      );
  
      useEffect(() => {
        dispatch(fetchLeaveList());
        dispatch(fetchEmployeeList());
      }, [dispatch]);
  
      return (
        <div className="card">
          <div className="card-header bg-gradient-dark p-0 text-light text-center">
            <h5>{title}</h5>
          </div>
          <div className="card-body">
            <div className="mb-3">
              <GlobalFilter
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </div>
            <table {...getTableProps()} className="table table-hover">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex flex-wrap justify-content-between align-items-center p-3 border-top mt-3">
              <div>
                <span className="text-muted">
                  Page <strong>{pageIndex + 1}</strong> of{" "}
                  <strong>{pageOptions.length}</strong>
                </span>
              </div>
              <div className="pagination-controls d-flex align-items-center">
                <button
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                  className="btn btn-light btn-sm mx-1"
                  title="First Page"
                >
                  <i className="bi bi-chevron-double-left"></i>
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="btn btn-light btn-sm mx-1"
                  title="Previous Page"
                >
                  <i className="bi bi-chevron-left"></i>
                </button>
  
                <div className="d-flex">
                  {pageOptions.map((page, index) => (
                    <button
                      key={index}
                      onClick={() => gotoPage(page)}
                      className={`btn btn-sm mx-1 ${
                        pageIndex === page
                          ? "btn-primary text-white"
                          : "btn-light"
                      }`}
                      style={{
                        borderRadius: "50%",
                        width: "35px",
                        height: "35px",
                        padding: "5px",
                      }}
                      title={`Page ${page + 1}`}
                    >
                      {page + 1}
                    </button>
                  ))}
                </div>
  
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="btn btn-light btn-sm mx-1"
                  title="Next Page"
                >
                  <i className="bi bi-chevron-right"></i>
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                  className="btn btn-light btn-sm mx-1"
                  title="Last Page"
                >
                  <i className="bi bi-chevron-double-right"></i>
                </button>
              </div>
              <div className="d-flex align-items-center">
                <label htmlFor="pageSize" className="me-2 mb-0 text-muted">
                  Show:
                </label>
                <select
                  id="pageSize"
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                  className="form-select form-select-sm"
                  style={{ width: "80px" }}
                >
                  {[10, 20, 30, 40, 50].map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      );
    };
  
  
  

  return (
    <div className="container-fluid">
    
    <div className="row">
              <div className="col-md-12 col-sm-12">
                <h6 className="">
                  <i class="fa-solid fa-list me-2"></i>PRM / Expense Tracking
                </h6>
              </div>
            </div>
    
          <div className="row mb-2">
            <div className="col-md-12 text-end"></div>
          </div>
    
          <TableComponent data={[]} columns={[]} title="All Project Expanses" />
        </div>
    
  )
}

export default ExpenseTracking;
