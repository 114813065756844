import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, EditBank, banks } from "@Networking/APIs/NWConfig";
import Cookies from 'js-cookie';

// Bank Store
export const postBankStore = createAsyncThunk(
    "post/postBankStore",
    async(post) => {

        let url = BASE_URL + banks; 

        
        const token = localStorage.getItem("authToken");

        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;

                return Promise.resolve(response);
        } catch(error) {
            return error;
        }
    }
);


//Edit Bank
export const EditBankStore = createAsyncThunk(
    "post/EditBankStore",
    async(post) => {

        let url = `${BASE_URL}${EditBank}/${post?.id}`;


        const token = localStorage.getItem("authToken");

        try {
            const response = (await axios.put(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;

                return Promise.resolve(response);
        } catch(error) {
            return Promise.reject(error);
        }
    }
);
