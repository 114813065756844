import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { getTaskList } from "@Networking/APIs/Task/taskApi";

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <span>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value || undefined)}
        placeholder="Search all columns"
        className="form-control"
        style={{ width: "200px", display: "inline-block", marginLeft: "10px" }}
      />
    </span>
  );
};

const TaskList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const TaskList = useSelector((state) => state.TaskSlice);
  const EmployeesData = useSelector((state) => state.EmployeesSlice);


  const getEmployeeName = (id) => {
    const employee = EmployeesData?.EmployeesData?.employee_list?.find(
      (emp) => emp.id == id
    );
    return employee
      ? `${employee.first_name} ${employee.middle_name || ""} ${employee.last_name}`
      : "Unknown";
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getTaskList());
      } catch (err) {
      }
    };
    fetchData();
  }, [dispatch]);

  const data = useMemo(() => {
    if (TaskList && TaskList?.taskList?.task_list) {
      return TaskList?.taskList?.task_list?.map((task) => ({
        ...task,
        Assign_To: task.assign_to,
        Assigned_By: task.assigned_by,
        Description: task.description,
        Project_Name: task.project_name,
        Deadline_Date: task.deadline_date,
      }));
    }
    return [];
  }, [TaskList]);

  const columns = useMemo(
    () => [
      {
        Header: "Assign To",
        accessor: "assign_to",
        Cell: ({ row }) => getEmployeeName(row.original.assign_to), 
      },
      {
        Header: "Project Name",
        accessor: "Project_Name",
      },
      {
        Header: "Deadline Date",
        accessor: "Deadline_Date",
      },
      {
        Header: "Assigned By",
        accessor: "Assigned_By",
        Cell: ({ row }) => getEmployeeName(row.original.assigned_by), 
      },
      {
        Header: "Actions",
        accessor: "actions", 
        Cell: ({ row }) => (
          <div>
            <button
              className="btn btn-sm btn-danger me-1"
              onClick={() => handleEdit(row.original)}
            >
              <i className="fa fa-pencil"></i>
            </button>
            <button
              className="btn btn-sm btn-primary me-1"
              onClick={() => handleView(row.original)}
            >
              <i className="fa fa-eye"></i>
            </button>
          </div>
        ),
      },
    ],
    []
  );
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
    useGlobalFilter,
    usePagination
  );

  const { globalFilter, pageIndex, pageSize } = state;

  const handleEdit = (task) => {
    const id = task.id
    // dispatch(fetchTaskData(task?.Task_ID));
    navigate(`/EditTask`, { state: { id } });
  };

  const handleView = (task) => {

    setSelectedTask(task); 
    setIsEditModalOpen(true); // Open modal
    document.body.classList.add("modal-blur"); // Add blur class to body
  };

  const closeModal = () => {
    setIsEditModalOpen(false);
    document.body.classList.remove("modal-blur"); // Remove blur class from body
  };
  

  return (
    <div className="container-scroller">
      <div className="card text-white rounded-top-2 p-3">
        <div className="row d-flex align-items-center">
          <div className="col-md-6 col-sm-12">
            <h3 className="page-title m-0">
              <span className="mx-2 text-primary">
                <i className="mdi mdi-file"></i>
              </span>
              TASK LIST
            </h3>
          </div>
          <div className="col-md-6 col-sm-12 text-end">
            <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
          </div>
        </div>
      </div>

      <div className="card mt-3 invoice-card mb-5">
        <div className="card-header btn-gradient-info text-white text-center">
          <h5>Task List</h5>
        </div>
        <div className="card-body" style={{ height: "auto", overflowY: "auto" }}>
          <table {...getTableProps()} className="table table-hover">
            <thead
              style={{
                position: "sticky",
                top: -25,
                backgroundColor: "#f0f0f0",
              }}
            >
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th className="text-center" {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td className="text-center" {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-center" colSpan={columns.length}>No Tasks</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="d-flex flex-wrap justify-content-between align-items-center p-3 border-top mt-3">
            {/* Pagination controls (same as Employees component) */}
            <div>
              <span className="text-muted">
                Page <strong>{pageIndex + 1}</strong> of <strong>{pageOptions.length}</strong>
              </span>
            </div>
            <div className="pagination-controls d-flex align-items-center">
              <button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                className="btn btn-light btn-sm mx-1"
                title="First Page"
              >
                <i className="bi bi-chevron-double-left"></i>
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="btn btn-light btn-sm mx-1"
                title="Previous Page"
              >
                <i className="bi bi-chevron-left"></i>
              </button>

              {/* Pagination Numbers */}
              <div className="d-flex">
                {pageOptions.map((page, index) => (
                  <button
                    key={index}
                    onClick={() => gotoPage(page)}
                    className={`btn btn-sm mx-1 ${
                      pageIndex === page ? "btn-primary text-white" : "btn-light"
                    }`}
                    style={{
                      borderRadius: "50%",
                      width: "35px",
                      height: "35px",
                      padding: "5px",
                    }}
                    title={`Page ${page + 1}`}
                  >
                    {page + 1}
                  </button>
                ))}
              </div>

              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="btn btn-light btn-sm mx-1"
                title="Next Page"
              >
                <i className="bi bi-chevron-right"></i>
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                className="btn btn-light btn-sm mx-1"
                title="Last Page"
              >
                <i className="bi bi-chevron-double-right"></i>
              </button>
            </div>
            <div className="d-flex align-items-center">
              <label htmlFor="pageSize" className="me-2 mb-0 text-muted">
                Show:
              </label>
              <select
                id="pageSize"
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                className="form-select form-select-sm"
                style={{ width: "80px" }}
              >
                {[10, 20, 30, 40, 50].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      {isEditModalOpen && selectedTask && (
        <div className="modal fade show" tabIndex="-1" style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-primary bg-light p-2 border border-2 border-primary rounded">View Task Details</h5>
              
              </div>
              <div className="modal-body">
                
                <div className="row mb-3">
                  <div className="col-md-6 border-end">
                    <strong className="border border-4 border-light p-2 bg-light rounded">
                      Assign To:</strong> {getEmployeeName(selectedTask.assign_to)}
                  </div>
                  <div className="col-md-6">
                    <strong className="border border-4 border-light p-2 bg-light rounded">Assigned By:</strong> {getEmployeeName(selectedTask.assigned_by)}
                  </div>
                </div>
                <hr></hr>
                <div className="row mb-3">
                  <div className="col-md-6 border-end">
                    <strong className="border border-4 border-light p-2 bg-light rounded">Project Name:</strong> {selectedTask.project_name}
                  </div>
                  <div className="col-md-6">
                    <strong className="border border-4 border-light p-2 bg-light rounded">Deadline Date:</strong> {selectedTask.deadline_date}
                  </div>
                  </div>
                  <hr></hr>
                  <div className="row mb-3">

                  <div className="col-md-12">
                    <strong className="border border-4 border-light p-2 bg-light rounded">Description:</strong> {selectedTask.task_description}
                  </div>
                </div>
                <hr></hr>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <strong className="border border-4 border-light p-2 bg-light rounded">Remarks:</strong> {selectedTask.remark}
                  </div>
                </div>
                <hr></hr>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <strong className="border border-4 border-light p-2 bg-light rounded">Files:</strong> 
                    <ul>
  {selectedTask.upload_file ? (
    <li>
      <a
        href={selectedTask.upload_file}
        target="_blank"
        rel="noopener noreferrer"
      >
        {selectedTask.file_name || "View File"}
      </a>
    </li>
  ) : (
    <li>No files attached</li>
  )}
</ul>

                  </div>
                </div>

              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={closeModal}

                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskList;
