export const handleInputChange = (newValue) => {
    if (/^[A-Za-z\s]*$/.test(newValue)) {
      return newValue;
    }
    return ''; 
  };


  
 export const validateInput = (value) => {
    const containsNumbers = /\d/.test(value);
    if (containsNumbers) {
      return false;
    }
    return true;
  };
  