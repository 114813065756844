// leaveEditSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { fetchLeaveApi, updateLeaveApi, updateLeaveStatus } from "@Networking/APIs/Leave/editLeaveApi";
import { toast } from "react-toastify";

const leaveEditSlice = createSlice({
  name: "leaveEditSlice",
  initialState: {
    leave: null,
    loading: false,
    leaveStatus:"",
    error: null,
    apiErrors: {},
  },
  reducers: {
    setLeave(state, action) {
      state.leave = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeaveApi.pending, (state) => {
        state.loading = true;
        state.apiErrors = {};
      })
      .addCase(fetchLeaveApi.fulfilled, (state, action) => {
        state.loading = false;
        state.leave = action.payload;
      })
      .addCase(fetchLeaveApi.rejected, (state, action) => {
        state.loading = false;
        state.apiErrors = action.payload || {};
      })
      .addCase(updateLeaveApi.pending, (state) => {
        state.loading = true;
        state.apiErrors = {};
      })
      .addCase(updateLeaveApi.fulfilled, (state, action) => {
        state.loading = false;
        state.leave = action.payload;
        if (action.payload.status) {
          toast.success(action.payload.message);
        } else {
          toast.error(action.payload.messages);
          state.apiErrors = action.payload.response.data.messages;
        }
      })
      .addCase(updateLeaveApi.rejected, (state, action) => {
        state.loading = false;
        state.apiErrors = action.payload || {};
      })
      .addCase(updateLeaveStatus.pending, (state) => {
        state.loading = true;
        state.apiErrors = {};
      })
      .addCase(updateLeaveStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.leaveStatus = action.payload;
        
        if (action.payload.success == true) {
          toast.success(action.payload.message);
        } else {
          toast.error(action.payload.message);
          state.apiErrors = action.payload.response?.data?.message;
        }
      })
      .addCase(updateLeaveStatus.rejected, (state, action) => {
        state.loading = false;
        state.apiErrors = action.payload || {};
      });
  },
});

export const { setLeave } = leaveEditSlice.actions;
export default leaveEditSlice.reducer;
