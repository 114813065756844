import { fetchEmployeData, postEmployeeComensationStore, postEmployeeCompensationStore, postEmployeeDocumentStore, postEmployeeJobStore, postEmployeePersonalStore, postEmployeeWorkStore} from '@Networking/APIs/Employee/EmployeStoreApi';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const EmployeStoreSlice = createSlice({
  name: 'EmployeStoreSlice',
  initialState: {
    step1Loading: false,
    step2loading : false,
    step3loading : false,
    step4loading : false,
    loading : false,
    success: false,
    error: null,
    validateFields:[],
    employeData:[],
    validateError : [],
  },
  reducers: {
    clearEmployeData: (state) => {
      state.employeData = null; // Clear employeData
    },
    resetValidateFields: (state) => {
      state.validateFields = []; // Reset validateFields
    },
    resetValidateError: (state) => {
      state.validateError = []; // Reset validateFields
    },
  },
  extraReducers: (builder) => {
    // Employee peersonal Store
    builder.addCase(postEmployeePersonalStore.pending, (state) => {
      state.step1Loading = true;
      state.error = null;
    });
    builder.addCase(postEmployeePersonalStore.fulfilled, (state, action) => {
      state.step1Loading = false;
      state.success = true;
      state.data = action.payload;
       if(action.payload.status == true){
        // toast.success(action.payload.message)

      }
      else{
        state.step1Loading = false;
        toast.error(action.payload.response.data.errors)
        state.validateFields = action.payload.response.data.message;

      }
     });
     builder.addCase(postEmployeePersonalStore.rejected, (state, action) => {
      state.step1Loading = false;
      state.error = action.payload ;
    });

    // Employee Job Store
    builder.addCase(postEmployeeJobStore.pending, (state) => {
      state.step2loading = true;
      state.error = null;
    });
    builder.addCase(postEmployeeJobStore.fulfilled, (state, action) => {
      state.step2loading = false;
      state.success = true;
      state.data = action.payload;

       if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.response.data.errors)
        state.validateError = action.payload.response.data.message;
      }
     });
     builder.addCase(postEmployeeJobStore.rejected, (state, action) => {
      state.step2loading = false;
      state.error = action.payload ;
    });

      // Employee Work Store
      builder.addCase(postEmployeeWorkStore.pending, (state) => {
        state.step3loading = true;
        state.error = null;
      });
      builder.addCase(postEmployeeWorkStore.fulfilled, (state, action) => {
        state.step3loading = false;
        state.success = true;
        state.data = action.payload;
         if(action.payload.status == true){
          toast.success(action.payload.message)
        }
        else{
          toast.error(action.payload.response.data.errors)
          state.validateError = action.payload.response.data.message;
        }
       });
       builder.addCase(postEmployeeWorkStore.rejected, (state, action) => {
        state.step3loading = false;
        state.error = action.payload ;
      });


         // Employee Compensation Store
    builder.addCase(postEmployeeCompensationStore.pending, (state) => {
      state.step4loading = true;
      state.error = null;
    });
    builder.addCase(postEmployeeCompensationStore.fulfilled, (state, action) => {
      state.step4loading = false;
      state.success = true;
      state.data = action.payload;

       if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.response.data.errors)
        state.validateError = action.payload.response.data.message;
      }
     });
     builder.addCase(postEmployeeCompensationStore.rejected, (state, action) => {
      state.step4loading = false;
      state.error = action.payload ;
    });


        // Employee Document Store
        builder.addCase(postEmployeeDocumentStore.pending, (state) => {
          state.loading = true;
          state.error = null;
        });
        builder.addCase(postEmployeeDocumentStore.fulfilled, (state, action) => {
          state.loading = false;
          state.success = true;
          state.data = action.payload;
           if(action.payload.status == true){
            toast.success(action.payload.message)
          }
          else{
            toast.error(action.payload.message)
            state.validateError = action.payload.response.data.message;
          }
         });
         builder.addCase(postEmployeeDocumentStore.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload ;
        });


        // Employe Get Slice 
        builder.addCase(fetchEmployeData.pending, (state) => {
          state.loading = true;
          state.error = null;
        });
        builder.addCase(fetchEmployeData.fulfilled, (state, action) => {
          state.loading = false;
          state.success = true;
          state.employeData = action.payload;
          });
         builder.addCase(fetchEmployeData.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload ;
        });
        


   },
});


export const { clearEmployeData, resetValidateFields, resetValidateError } = EmployeStoreSlice.actions;
export default EmployeStoreSlice.reducer;