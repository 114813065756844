import { storeFeatureForm } from "@Networking/APIs/SwitchForm/switchApi";
import { createSlice } from "@reduxjs/toolkit";

const SwitchSlice = createSlice({
  name: "SwitchSlice",
  initialState: {
    FeatureFormData:[],
    featureLoading:false, 
  },

  extraReducers: (builder) => {
    builder.addCase(storeFeatureForm.pending, (state) => {
        state.featureLoading = true;
      })
      builder.addCase(storeFeatureForm.fulfilled, (state, action) => {
        state.FeatureFormData = action.payload;
        state.featureLoading = false;
      })
      builder.addCase(storeFeatureForm.rejected, (state) => {
        state.featureLoading = false;
      })
  },
});

export default SwitchSlice.reducer;
