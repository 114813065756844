import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllMonth } from "@Networking/APIs/Salary/salaryApi";

const AttendanceRecord = () => {
  const monthData = useSelector((state) => state.SalarySlice);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllMonth());
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="">
              <i class="fa-solid fa-list me-2"></i>HRM / Attendance Record
            </h6>
          </div>
        </div>

        <div className="card bg-gradient-info rounded-top-2 p-2 mb-4">
          <div className="row d-flex align-items-center">
            <div className="col-md-12 col-sm-12">
              <h3 className="page-title m-0  text-light">
                <span className="mx-2 text-primary">
                  <i className="mdi mdi-file  text-light"></i>
                </span>
                Attendance Record
              </h3>
            </div>
          </div>
        </div>

        {/* Content Section */}
        <div className="card shadow-sm">
          <div className="card-body">
            <div className="row gy-4">
              {/* Fixed Holidays Card */}
              <div className="col-md-6">
                <div className="card border-0 shadow-sm h-100">
                  <div className="card-header bg-gradient-info">
                    <h5 className="card-title mb-0 text-white">
                      Fixed Holidays
                    </h5>
                  </div>
                  <div className="card-body d-flex justify-content-between align-items-center">
                    <p className="mb-0">View and manage all fixed holidays.</p>
                    <Link
                      to="/fixed-holidays"
                      className="btn btn-gradient-info btn-sm"
                    >
                      View All
                    </Link>
                  </div>
                </div>
              </div>

              {/* Working Hours Card */}
              <div className="col-md-6">
                <div className="card border-0 shadow-sm h-100">
                  <div className="card-header bg-gradient-info text-white">
                    <h5 className="card-title mb-0 text-white">
                      Working Hours
                    </h5>
                  </div>
                  <div className="card-body d-flex justify-content-between align-items-center">
                    <p className="mb-0">Check and update working hours.</p>
                    <Link
                      to="/working-hours"
                      className="btn btn-gradient-info btn-sm"
                    >
                      View All
                    </Link>
                  </div>
                </div>
              </div>

              {/* Attendance Records Card */}
              <div className="col-md-6">
                <div className="card border-0 shadow-sm h-100">
                  <div className="card-header bg-gradient-info text-white">
                    <h5 className="card-title mb-0 text-white">
                      Employee Monthly Attendance
                    </h5>
                  </div>
                  <div className="card-body d-flex justify-content-between align-items-center">
                    <p className="mb-0">Access complete attendance records.</p>
                    <Link
                      to="/attendance-records"
                      className="btn btn-gradient-info btn-sm"
                    >
                      View All
                    </Link>
                  </div>
                </div>
              </div>

              {/* Attendance Card */}
              <div className="col-md-6">
                <div className="card border-0 shadow-sm h-100">
                  <div className="card-header bg-gradient-info text-white">
                    <h5 className="card-title mb-0 text-white">
                      Attendance Record{" "}
                    </h5>
                  </div>
                  <div className="card-body d-flex justify-content-between align-items-center">
                    <p className="mb-0">Access complete attendance record.</p>
                    <Link
                      to="/attendanceFile"
                      className="btn btn-gradient-info btn-sm"
                    >
                      View All
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendanceRecord;
