import { createSlice } from '@reduxjs/toolkit';
 import { toast } from 'react-toastify';
import { getAllMonth, getDayHours, getSalary, getSalaryById, SalaryStoreApi, SalaryUpdateApi, SalaryDetailsById } from '@Networking/APIs/Salary/salaryApi';

const SalarySlice = createSlice({
  name: 'SalarySlice',
  initialState: {
    getDays: [],
    SalaryData:[],
    monthData: [],
    salaryStore: [],
    salaryStoreById:[],
    salaryUpdateById:[],
    SalaryDetailsId:[],
    success: false,
    error: null,
    loading: false,
    apiErrors: {},
  },

  extraReducers: (builder) => {
    builder
    .addCase(getSalary.pending, state => {
      state.loading = true;
    })
    .addCase(getSalary.fulfilled, (state, action) => {
      state.SalaryData = action.payload;
    })
    .addCase(getSalary.rejected, state => {
      state.loading = false;
    })
    .addCase(getAllMonth.pending, state => {
      state.loading = true;
    })
    .addCase(getAllMonth.fulfilled, (state, action) => {
      state.monthData = action.payload;
      
    })
    .addCase(getAllMonth.rejected, state => {
      state.loading = false;
    })
    .addCase(getDayHours.pending, state => {
      state.loading = true;
    })
    .addCase(getDayHours.fulfilled, (state, action) => {
      state.getDays = action.payload;
    })
    .addCase(getDayHours.rejected, state => {
      state.loading = false;
    })
    .addCase(SalaryStoreApi.pending, state => {
      state.loading = true;
      state.apiErrors = {};
    })
    .addCase(SalaryStoreApi.fulfilled, (state, action) => {
      state.salaryStore = action.payload;
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.message)
        state.apiErrors = action.payload.response.data.message;
      }
    })
    .addCase(SalaryStoreApi.rejected, (state, action) => {
        state.loading = false;
        state.apiErrors = action.error.message;
    })
    .addCase(getSalaryById.pending, state => {
      state.loading = true;
    })
    .addCase(getSalaryById.fulfilled, (state, action) => {
      state.salaryStoreById = action.payload;
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.message)
        state.apiErrors = action.payload.response.data.message;
      }
    })
    .addCase(getSalaryById.rejected, state => {
      state.loading = false;
    })

    .addCase(SalaryUpdateApi.pending, state => {
      state.loading = true;
    })
    .addCase(SalaryUpdateApi.fulfilled, (state, action) => {
      state.salaryUpdateById = action.payload;
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.message)
        state.apiErrors = action.payload.response.data.message;
      }
    })
    .addCase(SalaryUpdateApi.rejected, state => {
      state.loading = false;
    })

    .addCase(SalaryDetailsById.pending, state => {
      state.loading = true;
    })
    .addCase(SalaryDetailsById.fulfilled, (state, action) => {
      state.SalaryDetailsId = action.payload;
      
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.response.data.message)
        state.apiErrors = action.payload.response.data.message;
      }
    })
    .addCase(SalaryDetailsById.rejected, state => {
      state.loading = false;
    });
  },
});

export default SalarySlice.reducer;