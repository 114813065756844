import {createSlice} from '@reduxjs/toolkit';
import {getcountries, getstate , getcity, getcountryCode, fetchUserData, getcurrency } from '@Networking/APIs/Background/backgorundApi';

const BackgroundSlice = createSlice({
  name: 'BackgroundSlice',
  initialState: {
    country: [],
    state: [],
    city: [],
    countryCode:[],
    currencyCode:[],
    isLoading: false,
    error: null,
    userData:null
  },
  extraReducers: builder => {
    builder.addCase(fetchUserData.pending, state => {
      state.isLoading = true;
    }); 
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      state.userData = action.payload;
      state.isLoading = false;
      
      localStorage.setItem("name", state.userData.name);
       localStorage.setItem("userId", state.userData.role_id);
     
    });
    builder.addCase(fetchUserData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getcountries.pending, state => {
      state.loading = true;
    });
    builder.addCase(getcountries.fulfilled, (state, action) => {
      state.country = action.payload;
    });
    builder.addCase(getcountries.rejected, state => {
      state.loading = false;
    });

    builder.addCase(getstate.pending, state => {
        state.loading = true;
      });
      builder.addCase(getstate.fulfilled, (state, action) => {
        state.state = action.payload;
      });
      builder.addCase(getstate.rejected, state => {
        state.loading = false;
      });


      builder.addCase(getcity.pending, state => {
      state.loading = true;
    });
    builder.addCase(getcity.fulfilled, (state, action) => {
      state.city = action.payload;
    });
    builder.addCase(getcity.rejected, state => {
      state.loading = false;
    });
    
    builder.addCase(getcountryCode.pending, state => {
        state.loading = true;
      });
      builder.addCase(getcountryCode.fulfilled, (state, action) => {
        state.countryCode = action.payload;
      });
      builder.addCase(getcountryCode.rejected, state => {
        state.loading = false;
      });

      builder.addCase(getcurrency.pending, state => {
        state.loading = true;
      });
      builder.addCase(getcurrency.fulfilled, (state, action) => {
        state.currencyCode = action.payload;
      });
      builder.addCase(getcurrency.rejected, state => {
        state.loading = false;
      });

  },
});

//Reducer
export default BackgroundSlice.reducer;