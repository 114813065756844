import React, { useEffect, useState } from "react";
import { Logout } from "@Networking/APIs/Login/loginApi";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "@Networking/APIs/Profile/profileApi";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

export const Navbar = ({ onToggleSidebar, isSidebarOpen }) => {
  const dispatch = useDispatch();
  const name = localStorage.getItem("name");
  const role = localStorage.getItem("role");

  const handleSignOut = async () => {
    await dispatch(Logout());
    localStorage.removeItem("authToken");
    localStorage.removeItem("name");
    localStorage.removeItem("role");
    localStorage.removeItem("id");
    // Redirect to the login page
  };

  const { ProfileData } = useSelector((state) => state.ProfileSlice);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    setEmpId(ProfileData?.employee?.id);
  }, [ProfileData]);

  const [empId, setEmpId] = useState(null);

  const profilePhoto = ProfileData?.employee?.profile_photo || "N/A";

  const getImageUrl = () => {
    const imageUrl = `https://developbo.techsimba.in/management/public/profile_Photos/${profilePhoto}`;
    return imageUrl;
  };

  return (
    <>
      <nav
        className={`navbar p-0 ${
          isSidebarOpen ? "sidebar-open" : "sidebar-closed"
        }`}
        style={{ position: "fixed", top: 0, width: "100%", zIndex: 100 }}
      >
        <div
          className={`navbar-menu-wrapper d-flex align-items-stretch ${
            isSidebarOpen ? "sidebar-open" : "sidebar-closed"
          }`}
        >
          {/* Move the logo inside the navbar-menu-wrapper */}
          <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start ms-5 my-auto">
            <a className="navbar-brand brand-logo">
              <img src="assets/logo/fulllogo.png" alt="logo" />
            </a>
          </div>

          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
            onClick={onToggleSidebar}
          >
            <span className="mdi mdi-menu"></span>
          </button>
          <div className="search-field d-none d-md-block">
            <form className="d-flex align-items-center h-100" action="#">
              <div className="input-group">
                <div className="input-group-prepend bg-transparent">
                  <i className="input-group-text border-0 mdi mdi-magnify"></i>
                </div>
                <input
                  type="text"
                  className="bg-transparent border-0"
                  placeholder="Search projects"
                />
              </div>
            </form>
          </div>
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-profile dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="profileDropdown"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="nav-profile-img">
                  <img src={getImageUrl()} alt="image" />
                  <span className="availability-status online"></span>
                </div>
                <div className="nav-profile-text">
                  <p className="mb-1 text-black">{name}</p>
                  <p className="mb-1 text-secondary badge bg-light  fs-7">{role}</p>
                </div>
              </a>
              <div
                className="dropdown-menu navbar-dropdown"
                aria-labelledby="profileDropdown"
              >
                <Link to="/Profile" className="dropdown-item" href="#">
                  <i className="mdi mdi-cached me-2 text-success"></i> Profile{" "}
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/" onClick={handleSignOut}>
                  <i className="mdi mdi-logout me-2 text-primary"></i> Signout{" "}
                </Link>
              </div>
            </li>
            {/* <li className="nav-item nav-logout d-none d-lg-block">
              <a className="nav-link" href="#">
                <i className="mdi mdi-power"></i>
              </a>
            </li> */}
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    </>
  );
};
