import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getEditTask,
  getTaskList,
  postTaskUpdate,
  storeTask,
} from "@Networking/APIs/Task/taskApi";

const TaskSlice = createSlice({
  name: "TaskSlice",
  initialState: {
    taskList: [],
    TaskEdit: [],
    salaryStore: [],
    taskStore: [],
    success: false,
    error: null,
    taskStoreloading: false,
    apiErrors: {},
    taskLoader: false,
  },

  extraReducers: (builder) => {
    builder.addCase(getTaskList.pending, (state) => {
        state.loading = true;
      })
      builder.addCase(getTaskList.fulfilled, (state, action) => {
        state.taskList = action.payload;
      })
      builder.addCase(getTaskList.rejected, (state) => {
        state.loading = false;
      })

      builder.addCase(storeTask.pending, (state) => {
        state.taskStoreloading = true;
      })
      builder.addCase(storeTask.fulfilled, (state, action) => {
        state.taskStore = action.payload;
        state.taskStoreloading = false;

        if (action.payload.status == true) {
          toast.success(action.payload.message);
        } else {
          toast.error(action.payload.response.data.message);
          state.apiErrors = action.payload.response.data.message;
        }
      })
      builder.addCase(storeTask.rejected, (state) => {
        state.taskStoreloading = false;
      });

    //Edit Task
    builder.addCase(getEditTask.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEditTask.fulfilled, (state, action) => {
      state.TaskEdit = action.payload;
    });
    builder.addCase(getEditTask.rejected, (state) => {
      state.loading = false;
    });

    // Update Update Task
    builder.addCase(postTaskUpdate.pending, (state) => {
      state.taskLoader = true;
      state.error = null;
    });
    builder.addCase(postTaskUpdate.fulfilled, (state, action) => {
      state.taskLoader = false;
      state.success = true;
      state.message = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(postTaskUpdate.rejected, (state, action) => {
      state.taskLoader = false;
      state.error = action.error.message || "Add Working Hours  failed.";
    });
  },
});

export default TaskSlice.reducer;
