import React, { useState } from "react";

const BugForm = () => {
  const [formData, setFormData] = useState({
    bugId: "0001",
    title: "",
    reportedBy: "",
    date: "",
    operatingSystem: "",
    browser: "",
    device: "",
    stepsToReproduce: "",
    expectedResult: "",
    actualResult: "",
    severity: "",
    frequency: "",
    affectedModule: "",
    errorMessages: "",
    buildVersion: "",
    networkInfo: "",
    bugType: "",
    assignedTo: "",
    status: "",
    comments: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validate = () => {
    let formErrors = {};
    if (!formData.title) formErrors.title = "Title is required";
    if (!formData.reportedBy) formErrors.reportedBy = "Reported By is required";
    // Add more validation as needed
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // Handle form submission
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-12">
          <h5>General Information</h5>
        </div>
        <div className="col-md-6">
          <label className="text-dark">Bug ID</label>
          <input
            type="text"
            className="inputText"
            value={formData.bugId}
            readOnly
          />
        </div>
        <div className="col-md-6">
          <label className="text-dark">Title</label>
          <input
            placeholder="Enter Bug Title"
            type="text"
            className="inputText"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
          {errors.title && <span className="text-danger">{errors.title}</span>}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-6">
          <label className="text-dark">Reported By</label>
          <input
            placeholder="Enter Reported By"
            type="text"
            className="inputText"
            name="reportedBy"
            value={formData.reportedBy}
            onChange={handleChange}
          />
          {errors.reportedBy && <span className="text-danger">{errors.reportedBy}</span>}
        </div>
        <div className="col-md-6">
          <label className="text-dark">Date</label>
          <input
            type="date"
            className="inputText"
            name="date"
            value={formData.date}
            onChange={handleChange}
          />
        </div>
      </div>

      <hr />
      <div className="row mt-3">
        <div className="col-md-12">
          <h5>Environment</h5>
        </div>
        <div className="col-md-4">
          <label className="text-dark">Operating System</label>
          <select
            className="inputText"
            name="operatingSystem"
            value={formData.operatingSystem}
            onChange={handleChange}
          >
            <option value="" disabled>Select Operating System</option>
            <option value="windows">Windows</option>
            <option value="macos">macOS</option>
            <option value="linux">Linux</option>
            <option value="android">Android</option>
            <option value="ios">iOS</option>
          </select>
        </div>
        <div className="col-md-4">
          <label className="text-dark">Browser</label>
          <select
            className="inputText"
            name="browser"
            value={formData.browser}
            onChange={handleChange}
          >
            <option value="" disabled>Select Browser</option>
            <option value="web">Web-based</option>
            <option value="software">Software</option>
          </select>
        </div>
        <div className="col-md-4">
          <label className="text-dark">Device</label>
          <select
            className="inputText"
            name="device"
            value={formData.device}
            onChange={handleChange}
          >
            <option value="" disabled>Select Device Type</option>
            <option value="desktop">Desktop</option>
            <option value="tablet">Tablet</option>
            <option value="mobile">Mobile</option>
          </select>
        </div>
      </div>

      <hr />
      <div className="row mt-3">
        <div className="col-md-12">
          <h5>Bug Description</h5>
        </div>
        <div className="col-md-12">
          <label className="text-dark">Steps to Reproduce</label>
          <textarea
            placeholder="Enter Steps to Reproduce"
            className="inputText"
            name="stepsToReproduce"
            value={formData.stepsToReproduce}
            onChange={handleChange}
          ></textarea>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-6">
          <label className="text-dark">Expected Result</label>
          <input
            type="text"
            placeholder="Enter Expected Result"
            className="inputText"
            name="expectedResult"
            value={formData.expectedResult}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6">
          <label className="text-dark">Actual Result</label>
          <input
            type="text"
            placeholder="Enter Actual Result"
            className="inputText"
            name="actualResult"
            value={formData.actualResult}
            onChange={handleChange}
          />
        </div>
      </div>

      <hr />
      <div className="row mt-3">
        <div className="col-md-12">
          <h5>Additional Information</h5>
        </div>
        <div className="col-md-4">
          <label className="text-dark">Bug Type</label>
          <select
            className="inputText"
            name="bugType"
            value={formData.bugType}
            onChange={handleChange}
          >
            <option value="" disabled>Select Bug Type</option>
            <option value="ui">UI issue</option>
            <option value="performance">Performance Bug</option>
            <option value="functional">Functional Error</option>
          </select>
        </div>
        <div className="col-md-4">
          <label className="text-dark">Assigned To</label>
          <select
            className="inputText"
            name="assignedTo"
            value={formData.assignedTo}
            onChange={handleChange}
          >
            <option value="" disabled>Select Assignee</option>
            <option value="ui">UI Developer</option>
            <option value="backend">Backend Developer</option>
            <option value="qa">QA</option>
          </select>
        </div>
        <div className="col-md-4">
          <label className="text-dark">Status</label>
          <select
            className="inputText"
            name="status"
            value={formData.status}
            onChange={handleChange}
          >
            <option value="" disabled>Select Status</option>
            <option value="new">New</option>
            <option value="inProgress">In Progress</option>
            <option value="fixed">Fixed</option>
            <option value="closed">Closed</option>
          </select>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-12">
          <label className="text-dark">Comments/Notes</label>
          <textarea
            placeholder="Enter Comments/Notes"
            className="inputText"
            name="comments"
            value={formData.comments}
            onChange={handleChange}
          ></textarea>
        </div>
      </div>

      <button type="submit" className="btn btn-primary mt-3">Submit</button>
    </form>
  );
};

export default BugForm;
