import React , { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getEditAnnouncement, postAnnouncementStore, postAnnouncementUpdate } from "@Networking/APIs/Announcement/announcementApi";

const EditAnnouncement = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = location.state || {};


  const AnnouncementEditData = useSelector((state) => state.AnnouncementSlice);

  useEffect(() => {

    if (id) {
      dispatch(getEditAnnouncement({ id }));
    }
  }, [dispatch, id]);


useEffect(() => {
    if (id && AnnouncementEditData?.AnnouncementEdit?.announcement_data) {
      reset({
        id: AnnouncementEditData?.AnnouncementEdit?.announcement_data?.id,
        title: AnnouncementEditData?.AnnouncementEdit?.announcement_data?.title,
        start_date: AnnouncementEditData?.AnnouncementEdit?.announcement_data?.start_date,
        description: AnnouncementEditData?.AnnouncementEdit?.announcement_data?.description,
      });
    }
  }, [AnnouncementEditData, id, reset]);


  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file
            .then((file) => {
              const reader = new FileReader();
              reader.onload = () => {
                resolve({ default: reader.result }); // Use base64 or replace with actual upload URL
              };
              reader.onerror = reject;
              reader.readAsDataURL(file);
            })
            .catch(reject);
        });
      },
    };
  }

  // Plugin to include the custom upload adapter
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const onSubmit = async (data) => {
    const NewAnnouncement = {
      title: data.title,
      start_date: data.start_date,
      description: data.description,
    };

    try {
      await dispatch(postAnnouncementUpdate({NewAnnouncement, id}));
      navigate("/AnnouncementList");
    } catch (error) {
    }
  };

  return (
    <>
      <div className="container-fluid">
      <div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="">
              <i class="fa-solid fa-list me-2"></i>HRM / Announcement / Edit
              Announcement
            </h6>
          </div>
        </div>

        <div className="card bg-gradient-info rounded-top-2 p-2 mb-3">
          <div className="row d-flex align-items-center">
            <div className="col-md-12 col-sm-12">
              <h3 className="page-title m-0  text-light">
                <span className="mx-2 text-primary">
                  <i className="mdi mdi-file  text-light"></i>
                </span>
                Edit Announcement 
              </h3>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
              <div className="row p-2">
                <div className="col-md-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-md-9">
                        <label>Title:</label>
                        <input
                          id="input"
                          type="text"
                          {...register("title", {
                            required: "Title is required",
                          })}
                        />
                        {errors.title && (
                          <span className="text-danger">
                            {errors.title.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-3">
                        <label>Date:</label>
                        <input
                          id="input"
                          type="date"
                          {...register("start_date", {
                            required: "Start date is required",
                          })}
                        />
                        {errors.start_date && (
                          <span className="text-danger">
                            {errors.start_date.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-12">
                        <label>Description:</label>
                        <Controller
                          name="description"
                          control={control}
                          rules={{ required: "Description is required" }}
                          render={({ field }) => (
                            <CKEditor
                              editor={ClassicEditor}
                              // onChange={(event, editor) => {
                              //   const data = editor.getData();
                              //   field.onChange(data);
                              // }}
                              data={field.value || ""} // Set the initial value here
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                field.onChange(data); // Update the form state
                              }}                        
                              config={{
                                extraPlugins: [uploadPlugin],
                                toolbar: [
                                  "heading",
                                  "|",
                                  "bold",
                                  "italic",
                                  "link",
                                  "bulletedList",
                                  "numberedList",
                                  "blockQuote",
                                  "|",
                                  "insertTable",
                                  "tableColumn",
                                  "tableRow",
                                  "mergeTableCells",
                                  "|",
                                  "imageUpload",
                                  "mediaEmbed",
                                  "|",
                                  "undo",
                                  "redo",
                                  "|",
                                  "imageTextAlternative",
                                  "imageStyle:full",
                                  "imageStyle:side",
                                  "imageRemove", // Add this for image removal
                                ],
                                image: {
                                  toolbar: [
                                    "imageStyle:full",
                                    "imageStyle:side",
                                    "|",
                                    "imageTextAlternative",
                                    "imageRemove",
                                    "imageUpload",
                                    "imageDelete",
                                  ],
                                },
                              }}
                            />
                          )}
                        />
                        {errors.description && (
                          <span className="text-danger">
                            {errors.description.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-12 text-center">
                        <button
                          type="submit"
                          className="btn btn-primary w-50 rounded-pill"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAnnouncement;
