import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {attendanceRecordDelete, attendanceRecordEdit, attendanceRecordList, attendanceRecordStore, attendanceRecordUpdate, BASE_URL, holidayDelete, holidays, holidayStore, holidayUpdate, workingHours, workingHoursDelete, workingHoursStore, workingHoursUpdate, } from '@Networking/APIs/NWConfig';
import Cookies from 'js-cookie';

export const getWorkingHours = createAsyncThunk(
    'get/getWorkingHours',
    async () => {  
  
      // Using template literals to build the URL
      let url = BASE_URL + workingHours;  // Corrected line
  
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );

  export const getHolidays = createAsyncThunk(
    'get/getHolidays',
    async () => {  
  
      // Using template literals to build the URL
      let url = BASE_URL + holidays;  // Corrected line
  
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );


  // Holiday store
  export const postHolidayStore = createAsyncThunk(
    "post/postHolidayStore",
    async(post) => {

        let url = BASE_URL + holidayStore;

        const token = localStorage.getItem("authToken");

        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;

                return Promise.resolve(response);
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

// Update Holiday

export const postHolidayUpdate = createAsyncThunk(
  'put/postHolidayUpdate',
  async ({ id, occasion, date, day }) => {  
    
    // Using template literals to build the URL
    let url = BASE_URL + holidayUpdate  + id; // Construct the URL

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.put(url , { occasion, date, day }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`  
        }
      })).data;
      return Promise.resolve(response);
    } catch (error) {
      return error;
    }
  },
);



// Delete Holidays

export const DeleteHoliday = createAsyncThunk(
  "post/DeleteHoliday",
  async ({id}) => {
    const url = `${BASE_URL}${holidayDelete}${id}`;

    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);


// Working Hours Store
export const postWorkingHours = createAsyncThunk(
  "post/postWorkingHours",
  async(post) => {

      let url = BASE_URL + workingHoursStore;

      const token = localStorage.getItem("authToken");

      try {
          const response = (await axios.post(url, post, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;

              return Promise.resolve(response);
      } catch(error) {
          return Promise.reject(error);
      }
  }
);

export const DeleteWorkingHours = createAsyncThunk(
  "post/DeleteWorkingHours",
  async ({id}) => {
    const url = `${BASE_URL}${workingHoursDelete}${id}`;

    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

// Update Working Hours

export const postWorkingHoursUpdate = createAsyncThunk(
  'put/postWorkingHoursUpdate',
  async ({ id, month, days, hours }) => {  
    
    // Using template literals to build the URL
    let url = BASE_URL + workingHoursUpdate  + id; // Construct the URL

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.put(url , { month, days, hours }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`  
        }
      })).data;
      return Promise.resolve(response);
    } catch (error) {
      return error;
    }
  },
);

// Attendance Record Store
export const postAttendanceRecord = createAsyncThunk(
  "post/postAttendanceRecord",
  async(post) => {

      let url = BASE_URL + attendanceRecordStore;

      const token = localStorage.getItem("authToken");

      try {
          const response = (await axios.post(url, post, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;

              return Promise.resolve(response);
      } catch(error) {
          return Promise.reject(error);
      }
  }
);


// Attendance Record List

export const getAttendanceRecord = createAsyncThunk(
  'get/getAttendanceRecord',
  async () => {  
    
    // Using template literals to build the URL
    let url = BASE_URL + attendanceRecordList;  // Corrected line

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

// Edit Attendance Record

export const getAttendanceRecordEdit = createAsyncThunk(
  'get/getAttendanceRecordEdit',
  async ({ id }) => {  
    
    // Using template literals to build the URL
    let url = BASE_URL + attendanceRecordEdit + id;  // Corrected line

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);



// Update Attendance Record

export const postAttendanceRecordUpdate = createAsyncThunk(
  'post/postAttendanceRecordUpdate',
  async ({ data }) => {  
    // Using template literals to build the URL
    let url = BASE_URL + attendanceRecordUpdate  + data.id; // Construct the URL

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.post(url ,data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`  
        }
      })).data;
      return Promise.resolve(response);
    } catch (error) {
      return error;
    }
  },
);


// Delete Attendance Record

export const DeleteAttendanceRecord = createAsyncThunk(
  "post/DeleteAttendanceRecord",
  async ({id}) => {
    const url = `${BASE_URL}${attendanceRecordDelete}${id}`;

    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
