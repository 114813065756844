import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import {
  DeleteDepartment,
  getDepartmemt,
  getEmployees,
  updateDepartment,
} from "@Networking/APIs/Employee/EmployeeApi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  getAttendanceRecordEdit,
  postAttendanceRecordUpdate,
} from "@Networking/APIs/AttendanceRecord/attendanceRecordApi";

const EditAttendanceRecords = () => {
  const location = useLocation();
  const { id } = location.state || {};

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { type } = useParams();

  useEffect(() => {
    dispatch(getEmployees());

    if (id) {
      dispatch(getAttendanceRecordEdit({ id }));
    }
  }, [dispatch, id]);

  const handleBack = () => {
    navigate(-1);
  };

  const attendaceRecordDataEdit = useSelector(
    (state) => state.AttendanceRecordSlice
  );
  const employeesData = useSelector((state) => state.EmployeesSlice);

  useEffect(() => {
    if (id && attendaceRecordDataEdit?.AttendanceRecordDataEdit?.data) {
      reset({
        id: attendaceRecordDataEdit?.AttendanceRecordDataEdit?.data?.id,
        employee_id:
          attendaceRecordDataEdit?.AttendanceRecordDataEdit?.data?.employee_id,
        working_month:
          attendaceRecordDataEdit?.AttendanceRecordDataEdit?.data?.working_month.split(
            " "
          )[0],
        working_hours:
          attendaceRecordDataEdit?.AttendanceRecordDataEdit?.data
            ?.working_hours,
        leave_type:
          attendaceRecordDataEdit?.AttendanceRecordDataEdit?.data?.leave_type,
        leave_this_month:
          attendaceRecordDataEdit?.AttendanceRecordDataEdit?.data
            ?.leave_this_month,
        total_working_hours:
          attendaceRecordDataEdit?.AttendanceRecordDataEdit?.data
            ?.total_working_hours,
        unpaid_leave:
          attendaceRecordDataEdit?.AttendanceRecordDataEdit?.data?.unpaid_leave,
        paid_leave:
          attendaceRecordDataEdit?.AttendanceRecordDataEdit?.data?.paid_leave,
        leave_balance:
          attendaceRecordDataEdit?.AttendanceRecordDataEdit?.data
            ?.leave_balance,
        employee_advance:
          attendaceRecordDataEdit?.AttendanceRecordDataEdit?.data
            ?.employee_advance,
        remark: attendaceRecordDataEdit?.AttendanceRecordDataEdit?.data?.remark,
      });
    }
  }, [attendaceRecordDataEdit, id, reset]);

  const onSubmit = async (data) => {
    try {
      await dispatch(postAttendanceRecordUpdate({ data })).unwrap();
      navigate("/attendance-records");
    } catch (error) {}
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-secondary mb-3"
        onClick={handleBack}
      >
        <i className="fa fa-arrow-left me-2"></i>
        Back
      </button>

      <div className="container-fluid">
        <div className="card mb-2">
          <div className="card-body">
            <div className="card shadow">
              <div className="row p-3">
                <div className="col-md-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label>Employee Name</label>
                        <select
                          id="input"
                          defaultValue={
                            attendaceRecordDataEdit?.AttendanceRecordDataEdit
                              ?.data?.employee_id || ""
                          }
                          {...register("employee_id", { required: true })}
                        >
                          <option value="" disabled selected>
                            Select Employee
                          </option>
                          {employeesData?.EmployeesData?.employee_list?.length >
                          0 ? (
                            employeesData.EmployeesData.employee_list.map(
                              (employees) => (
                                <option key={employees.id} value={employees.id}>
                                  {employees.first_name}
                                </option>
                              )
                            )
                          ) : (
                            <option disabled>No Employee available</option>
                          )}
                        </select>
                        {errors.employee_id && (
                          <span className="text-danger">
                            *Employee name is required
                          </span>
                        )}
                      </div>
                      <div className="col-md-4">
                        <label>Working Month</label>
                        <select
                          id="input"
                          defaultValue={
                            attendaceRecordDataEdit?.AttendanceRecordDataEdit?.data?.working_month?.split(
                              " "
                            )[0] || ""
                          }
                          {...register("working_month", { required: true })}
                        >
                          <option value="" disabled>
                            Select Month
                          </option>
                          <option value="January">January</option>
                          <option value="February">February</option>
                          <option value="March">March</option>
                          <option value="April">April</option>
                          <option value="May">May</option>
                          <option value="June">June</option>
                          <option value="July">July</option>
                          <option value="August">August</option>
                          <option value="September">September</option>
                          <option value="October">October</option>
                          <option value="November">November</option>
                          <option value="December">December</option>
                        </select>
                        {errors.working_month && (
                          <span className="text-danger">
                            *Working Month is required
                          </span>
                        )}
                      </div>

                      <div className="col-md-4">
                        <label>Working Hours</label>
                        <input
                          type="text"
                          placeholder="Enter Working Hours"
                          id="input"
                          defaultValue={
                            attendaceRecordDataEdit?.AttendanceRecordDataEdit
                              ?.data?.working_hours || ""
                          }
                          {...register("working_hours", {
                            required: "Working Hours is required",
                          })}
                        />
                        {errors.working_hours && (
                          <span className="text-danger">
                            {errors.working_hours.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label>Leave Type</label>
                        <input
                          type="text"
                          placeholder="Enter Leave Type"
                          id="input"
                          defaultValue={
                            attendaceRecordDataEdit?.AttendanceRecordDataEdit
                              ?.data?.leave_type || ""
                          }
                          {...register("leave_type", {
                            required: "Leave Type is required",
                          })}
                        />
                        {errors.leave_type && (
                          <span className="text-danger">
                            {errors.leave_type.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-4">
                        <label>Leave This Month</label>
                        <input
                          type="text"
                          placeholder="Enter Leave This Month"
                          id="input"
                          defaultValue={
                            attendaceRecordDataEdit?.AttendanceRecordDataEdit
                              ?.data?.leave_this_month || ""
                          }
                          {...register("leave_this_month", {
                            required: "Leave This Month is required",
                          })}
                        />
                        {errors.leave_this_month && (
                          <span className="text-danger">
                            {errors.leave_this_month.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-4">
                        <label>Total Working Hours</label>
                        <input
                          type="text"
                          placeholder="Enter Total Working Hours"
                          id="input"
                          defaultValue={
                            attendaceRecordDataEdit?.AttendanceRecordDataEdit
                              ?.data?.total_working_hours || ""
                          }
                          {...register("total_working_hours", {
                            required: "Total Working Hours is required",
                          })}
                        />
                        {errors.total_working_hours && (
                          <span className="text-danger">
                            {errors.total_working_hours.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label>Unpaid Leaves</label>
                        <input
                          type="text"
                          placeholder="Enter Unpaid Leaves"
                          id="input"
                          defaultValue={
                            attendaceRecordDataEdit?.AttendanceRecordDataEdit
                              ?.data?.unpaid_leave || ""
                          }
                          {...register("unpaid_leave", {
                            required: "Unpaid Leaves is required",
                          })}
                        />
                        {errors.unpaid_leave && (
                          <span className="text-danger">
                            {errors.unpaid_leave.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-4">
                        <label>Leave Balance</label>
                        <input
                          type="text"
                          placeholder="Enter Leave Balance"
                          id="input"
                          defaultValue={
                            attendaceRecordDataEdit?.AttendanceRecordDataEdit
                              ?.data?.leave_balance || ""
                          }
                          {...register("leave_balance", {
                            required: "Leave Balance is required",
                          })}
                        />
                        {errors.leave_balance && (
                          <span className="text-danger">
                            {errors.leave_balance.message}
                          </span>
                        )}
                      </div>

                      <div className="col-md-4">
                        <label>Paid Leaves</label>
                        <input
                          type="text"
                          placeholder="Enter Paid Leaves"
                          id="input"
                          defaultValue={
                            attendaceRecordDataEdit?.AttendanceRecordDataEdit
                              ?.data?.paid_leave || ""
                          }
                          {...register("paid_leave", {
                            required: "Paid Leaves is required",
                          })}
                        />
                        {errors.paid_leave && (
                          <span className="text-danger">
                            {errors.paid_leave.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label>Employee Advance</label>
                        <input
                          type="text"
                          placeholder="Enter Employee Advance"
                          id="input"
                          defaultValue={
                            attendaceRecordDataEdit?.AttendanceRecordDataEdit
                              ?.data?.employee_advance || ""
                          }
                          {...register("employee_advance", {
                            required: "Employee Advance is required",
                          })}
                        />
                        {errors.employee_advance && (
                          <span className="text-danger">
                            {errors.employee_advance.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-8">
                        <label>Remarks</label>
                        <textarea
                          type="text"
                          placeholder="Enter Remarks"
                          id="input"
                          defaultValue={
                            attendaceRecordDataEdit?.AttendanceRecordDataEdit
                              ?.data?.remark || ""
                          }
                          {...register("remark", {
                            required: "Remarks is required",
                          })}
                        ></textarea>
                        {errors.department_name && (
                          <span className="text-danger">
                            {errors.department_name.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12 text-center">
                        <button
                          type="submit"
                          className="btn btn-primary w-50 rounded-pill"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                  <hr></hr>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAttendanceRecords;
