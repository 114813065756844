import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { updateLeaveStatus } from "@Networking/APIs/Leave/editLeaveApi";
import Loader from "@Layout/Loader/loader";
import { toast } from "react-toastify";

export default function EditLeave() {
  //Hooks
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { employee } = location.state || {};

  //States
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAction, setSelectedAction] = useState(employee?.status || "");

  const simulateLoading = async () => {
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsLoading(false);
    navigate("/leave");
  };

  // Redirect to leave list page
  const handleRedirect = () => {
    navigate("/leave");
  };

  // Handle action change
  const handleChange = (event) => {
    setSelectedAction(event.target.value);
  };

  // Handle submit
  const handleSubmit = () => {
    if (!selectedAction) {
      alert("Please select an action");
      return;
    }
    simulateLoading();
    try {
      const result = dispatch(
        updateLeaveStatus({ leaveId: employee?.id, status: selectedAction })
      ).unwrap();

      if (result.status == true) {
        toast.success(result.message);
        simulateLoading();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error("Failed to apply leave. Please try again.");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="">
              <i class="fa-solid fa-list me-1"></i>HRM / All Leaves / Action
            </h6>
          </div>
        </div>

        <div className="card bg-gradient-info rounded-top-2 p-2 mb-3">
          <div className="row d-flex align-items-center">
            <div className="col-md-12 col-sm-12">
              <h3 className="page-title m-0  text-light">
                <span className="mx-2 text-primary">
                  <i className="mdi mdi-file  text-light"></i>
                </span>
                View Leave
              </h3>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="row d-flex align-items-center">
              <div className="col-md-5">
                <button
                  className="btn btn-secondary btn-sm"
                  onClick={handleRedirect}
                >
                  <i className="fa fa-arrow-left me-2"></i>
                  Leave List
                </button>
              </div>
              <div className="col-md-5 d-flex justify-content-end">
                <select
                  className="form-select w-50"
                  value={selectedAction}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Select Action
                  </option>
                  <option className="text-danger" value="Cancel">
                    Cancel
                  </option>
                  <option className="text-warning" value="Pending">
                    Pending
                  </option>
                  <option className="text-success" value="Approved">
                    Approve
                  </option>
                </select>
               
                {isLoading && <Loader />}
              </div>
              <div className="col-md-2 d-flex justify-content-end">
              <button className="btn w-75 btn-primary ms-2" onClick={handleSubmit}>
                  Submit
                </button>
              </div>

            </div>
            <hr></hr>
            <div className="row mb-3">
              <div className="col-md-3">
                <h6>Employee Name:</h6>
              </div>
              <div className="col-md-9">
                <h6>{employee?.user?.name || "N/A"}</h6>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-3">
                <h6>Subject:</h6>
              </div>
              <div className="col-md-9">
                <h6>{employee?.subject || "N/A"}</h6>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-3">
                <h6>Date:</h6>
              </div>
              <div className="col-md-9">
                <h6>{employee?.start_date || "N/A"}</h6>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <h6>Description:</h6>
              </div>
              <div className="col-md-9">
                <textarea
                  className="form-control"
                  rows="3"
                  readOnly
                  value={employee?.description || "No description available"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
