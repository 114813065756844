import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, ClientDelete, ClientEdit, client } from "@Networking/APIs/NWConfig";
import Cookies from 'js-cookie';

// GenerateInvoice
export const postClientStore = createAsyncThunk(
    "post/postClientStore",
    async(post) => {

        let url = BASE_URL + client;

        const token = localStorage.getItem("authToken");

        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;

                return Promise.resolve(response);
        } catch(error) {
            return error;
        }
    }
);


export const EditClientStore = createAsyncThunk(
    "post/EditClientStore",
    async ({ id, ...post }) => {  // Destructure id from post parameters
        let url = `${BASE_URL}${ClientEdit}/${id}`; // Append id to the URL

        const token = localStorage.getItem("authToken");

        try {
            const response = (await axios.put(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;

            return Promise.resolve(response);
        } catch (error) {
            return error;
        }
    }
);



