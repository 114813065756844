import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, ClientDelete } from "@Networking/APIs/NWConfig";



export const DeleteClientStore = createAsyncThunk(
    "post/DeleteClientStore",
    async (id) => {  

        let url = `${BASE_URL}${ClientDelete}/${id}`;

        const token = localStorage.getItem("authToken");

        try {
            const response = (await axios.delete(url, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;

            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }
);