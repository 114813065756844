import { React, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import "@fortawesome/fontawesome-free/css/all.min.css";

const steps = [
  {
    id: "employeeDetails",
    title: "Employee Details",
    component: EmployeeDetails,
  },
  {
    id: "jobDetails",
    title: "Job Details",
    component: JobDetails,
  },
  {
    id: "workDetails",
    title: "Work Details",
    component: WorkDetails,
  },
  {
    id: "compensation",
    title: "Compensation",
    component: Compensation,
  },
  {
    id: "documents",
    title: "Documents",
    component: Documents,
  },
];

const EditEmployee = () => {
  const { id } = useParams(); // Get the ID from the URL parameters

  const [currentStep, setCurrentStep] = useState(0); // Initialize to 0 to start from the first step
  const [completedSteps, setCompletedSteps] = useState(
    Array(steps.length).fill(false)
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [formData, setFormData] = useState({});

  const handleNext = (data) => {
    setFormData({ ...formData, ...data });

    const updatedCompletedSteps = [...completedSteps];
    updatedCompletedSteps[currentStep] = true; // Mark the current step as completed
    setCompletedSteps(updatedCompletedSteps);

    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleReset = () => {
    setFormData({});
    setCurrentStep(0);
    setCompletedSteps(Array(steps.length).fill(false)); // Reset completed steps
    reset(); // Reset form
  };

  const CurrentStepComponent = steps[currentStep].component;

  const location = useLocation();
  const employee = location.state?.employee;

  return (
    <>
      <h5 className="text-primary">
        Edit Employee - {employee?.first_name} {employee?.last_name}
      </h5>

      <div className="container-fluid">
        {/* Stepper Headings */}
        <div className="row mb-3 ">
          <div className="col-md-12 col-sm-12">
            <div className="d-flex flex-wrap justify-content-evenly">
              {steps.map((step, index) => (
                <div key={step.id} className="d-flex align-items-center my-2">
                  <div
                    className={`d-flex align-items-center justify-content-center ${
                      currentStep === index
                        ? "bg-primary text-white" // Current step
                        : completedSteps[index]
                        ? "bg-primary text-light" // Completed step
                        : "bg-light text-dark" // Incomplete step
                    } rounded-circle`}
                    style={{
                      width: "45px",
                      height: "40px",
                      cursor: "pointer",
                      border: "2px solid #ddd",
                    }}
                    employee
                    onClick={() => setCurrentStep(index)}
                  >
                    <div
                      className={`d-flex align-items-center justify-content-center rounded-circle ${
                        currentStep === index || completedSteps[index]
                          ? "text-light"
                          : "text-dark"
                      }`}
                      style={{ width: "40px", height: "40px" }}
                    >
                      {index + 1}
                    </div>
                  </div>
                  {/* Arrow icon next to the number */}
                  {index < steps.length - 1 && (
                    <h5 className="m-0 ms-3 d-none d-sm-block">
                      <div className="ms-2" style={{ textAlign: "center" }}>
                        <i
                          className="fa-solid fa-arrow-right-long fs-3"
                          style={{ width: "100px" }}
                        ></i>
                      </div>
                    </h5>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-12 mb-2">
                    <form onSubmit={handleSubmit(handleNext)}>
                      <CurrentStepComponent
                        register={register}
                        errors={errors}
                      />
                      <div className="row mt-3">
                        <div className="col-md-6">
                          {currentStep > 0 && (
                            <button
                              type="button"
                              className="btn btn-secondary w-50 w-md-25"
                              onClick={handlePrevious}
                            >
                              Previous
                            </button>
                          )}
                        </div>
                        <div className="col-md-6 text-end">
                          {currentStep < steps.length - 1 && (
                            <button
                              type="submit"
                              className="btn btn-primary w-50 w-md-25"
                            >
                              Next
                            </button>
                          )}
                          {currentStep === steps.length - 1 && (
                            <button
                              type="submit"
                              className="btn btn-success w-50 w-md-25"
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function EmployeeDetails({ register, errors }) {
  const location = useLocation();
  const employee = location.state?.employee;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="row">
          <div className="col-md-12">
            <h5 className="mb-3 text-primary">Employee Details</h5>
          </div>
        </div>
        <div className="row d-flex justify-content-center align-items-center mb-2">
          <div className="col-md-6 mb-2">
            <div class="form-group">
              <label className="fw-bold" htmlFor="firstName">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                // placeholder="Enter First Name"
                {...register("firstName", { required: true })}
                defaultValue={employee?.first_name || ""}
              />
              {errors.firstName && (
                <span className="text-danger">*This field is required</span>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <div class="form-group">
              <label className="fw-bold" htmlFor="middleName">
                Middle Name(Optional)
              </label>
              <input
                type="text"
                placeholder="Enter Middle Name"
                className="form-control"
                id="middleName"
                defaultValue={employee?.middle_name || ""}
                // {...register("lastName", { required: true })}
              />
              {/* {errors.lastName && (
            <span className="text-danger">*This field is required</span>
          )} */}
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-6 mb-2">
            <div class="form-group">
              <label className="fw-bold" htmlFor="lastName">
                Last Name
              </label>
              <input
                type="text"
                placeholder="Enter Last Name"
                className="form-control"
                id="lastName"
                {...register("lastName", { required: true })}
                defaultValue={employee?.last_name || ""}
              />
              {errors.lastName && (
                <span className="text-danger">*This field is required</span>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <div class="form-group">
              <label className="fw-bold" htmlFor="gender">
                Gender
              </label>
              <input
                type="text"
                placeholder="Enter Gender"
                className="form-control"
                id="gender"
                defaultValue={employee?.gender || ""}
                {...register("gender", { required: true })}
              />
              {errors.gender && (
                <span className="text-danger">*This field is required</span>
              )}
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-6 mb-2">
            <div class="form-group">
              <label className="fw-bold" htmlFor="dateOfBirth">
                Date of Birth
              </label>
              <input
                type="date"
                placeholder="Enter Date of Birth"
                className="form-control"
                id="dateOfBirth"
                defaultValue={employee?.dob || ""}
                {...register("dateOfBirth", { required: true })}
              />
              {errors.dateOfBirth && (
                <span className="text-danger">*This field is required</span>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <div class="form-group">
              <label className="fw-bold" htmlFor="gender">
                Nationality
              </label>
              <input
                type="text"
                placeholder="Enter Nationality"
                className="form-control"
                id="gender"
                defaultValue={employee?.nationality || ""}
                {...register("nationality", { required: true })}
              />
              {errors.nationality && (
                <span className="text-danger">*This field is required</span>
              )}
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-6 mb-2">
            <div class="form-group">
              <label className="fw-bold" htmlFor="contact">
                Contact
              </label>
              <input
                type="text"
                placeholder="Enter Contact"
                className="form-control"
                id="contact"
                defaultValue={employee?.contact || ""}
                {...register("contact", { required: true })}
              />
              {errors.contact && (
                <span className="text-danger">*This field is required</span>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <div class="form-group">
              <label className="fw-bold" htmlFor="alternateNumber">
                Alternate Number
              </label>
              <input
                type="text"
                placeholder="Enter Alternate Number"
                className="form-control"
                id="alternateNumber"
                defaultValue={employee?.alternate_number || ""}
                {...register("alternateNumber", { required: true })}
              />
              {errors.alternateNumber && (
                <span className="text-danger">*This field is required</span>
              )}
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-6 mb-2">
            <div class="form-group">
              <label className="fw-bold" htmlFor="workEmail">
                Personal Email
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter Personal Email"
                id="workEmail"
                defaultValue={employee?.personal_email || ""}
                {...register("personalEmail", { required: true })}
              />
              {errors.personalEmail && (
                <span className="text-danger">*This field is required</span>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <div class="form-group">
              <label for="jobRole">Job Role</label>
              <select
                class="form-select"
                id="jobRole"
                defaultValue={employee?.job_role || ""}
                {...register("jobRole", { required: true })}
              >
                <option value="" disabled selected>
                  Select Job Role
                </option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
              {errors.jobRole && (
                <span className="text-danger">*This field is required</span>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-2"></div>
        </div>

        <div className="row mb-2 border">
          <div className="col-md-6 mt-2 mb-2 border-end">
            <h6>Current Address</h6>
            <div className="row mb-2">
              <div className="col-md-6 mb-2">
                <div class="form-group">
                  <label className="fw-bold" htmlFor="country">
                    Country
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Country"
                    className="form-control"
                    id="country"
                    defaultValue={employee?.country || ""}
                    {...register("country", { required: true })}
                  />
                  {errors.country && (
                    <span className="text-danger">*This field is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-2">
                <div class="form-group">
                  <label className="fw-bold" htmlFor="state">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter State"
                    id="state"
                    defaultValue={employee?.state || ""}
                    {...register("state", { required: true })}
                  />
                  {errors.state && (
                    <span className="text-danger">*This field is required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6 mb-2">
                <div class="form-group">
                  <label className="fw-bold" htmlFor="city">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter City"
                    id="city"
                    defaultValue={employee?.city || ""}
                    {...register("city", { required: true })}
                  />
                  {errors.city && (
                    <span className="text-danger">*This field is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-2">
                <div class="form-group">
                  <label className="fw-bold" htmlFor="pincode">
                    Pincode
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Pincode"
                    id="pincode"
                    defaultValue={employee?.pincode || ""}
                    {...register("pincode", { required: true })}
                  />
                  {errors.pincode && (
                    <span className="text-danger">*This field is required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12 mb-2">
                <div class="form-group">
                  <label className="fw-bold" htmlFor="address">
                    Address
                  </label>
                  <textarea
                    className="form-control"
                    rows="2"
                    placeholder="Enter Address"
                    id="address"
                    defaultValue={employee?.address || ""}
                    {...register("address", { required: true })}
                  ></textarea>
                  {errors.address && (
                    <span className="text-danger">*This field is required</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-2 mb-2 border-start">
            <h6>Permanent Address</h6>
            <div className="row mb-2">
              <div className="col-md-6 mb-2">
                <div class="form-group">
                  <label className="fw-bold" htmlFor="permanentCountry">
                    Country
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Country"
                    id="permanentCountry"
                    defaultValue={employee?.country_1 || ""}
                    {...register("permanentCountry", { required: true })}
                  />
                  {errors.permanentCountry && (
                    <span className="text-danger">*This field is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-2">
                <div class="form-group">
                  <label className="fw-bold" htmlFor="permanentState">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter State"
                    id="permanentState"
                    defaultValue={employee?.state_1 || ""}
                    {...register("permanentState", { required: true })}
                  />
                  {errors.permanentState && (
                    <span className="text-danger">*This field is required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6 mb-2">
                <div class="form-group">
                  <label className="fw-bold" htmlFor="permanentCity">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter City"
                    id="permanentCity"
                    defaultValue={employee?.city_1 || ""}
                    {...register("permanentCity", { required: true })}
                  />
                  {errors.permanentCity && (
                    <span className="text-danger">*This field is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-2">
                <div class="form-group">
                  <label className="fw-bold" htmlFor="permanentPincode">
                    Pincode
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Pincode"
                    id="permanentPincode"
                    defaultValue={employee?.pincode_1 || ""}
                    {...register("permanentPincode", { required: true })}
                  />
                  {errors.permanentPincode && (
                    <span className="text-danger">*This field is required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12 mb-2">
                <div class="form-group">
                  <label className="fw-bold" htmlFor="permanentAddress">
                    Address
                  </label>
                  <textarea
                    className="form-control"
                    rows="2"
                    placeholder="Enter Address"
                    id="permanentAddress"
                    defaultValue={employee?.permanent_address || ""}
                    {...register("permanentAddress", { required: true })}
                  ></textarea>
                  {errors.permanentAddress && (
                    <span className="text-danger">*This field is required</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function JobDetails({ register, errors }) {
  const location = useLocation();
  const employee = location.state?.employee;

  return (
    <div className="container">
      <h5 className="mb-3 text-primary">Job Details</h5>

      <div className="row  mb-2">
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label for="workLocation">Work Location</label>
            <select
              class="form-select"
              id="workLocation"
              {...register("workLocation", { required: true })}
            >
              <option>
              {employee?.location || ""}
              </option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
            {errors.workLocation && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label for="workCountry">Work Country</label>
            <select
              class="form-select"
              id="workCountry"
              {...register("workCountry", { required: true })}
            >
              <option>
              {employee?.work_country || ""}
              </option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
            {errors.workCountry && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
      </div>
      <div className="row  mb-2">
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="employeeNumber">
              Employee Number
            </label>
            <input
              type="text"
              className="form-control"
              id="employeeNumber"
              placeholder="Enter Employee Number"
              defaultValue={employee?.employee_number || ""}
              {...register("employeeNumber", { required: true })}
            />
            {errors.employeeNumber && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label for="reportingTeamleader">Reporting Teamleader</label>
            <select
              class="form-select"
              id="reportingTeamleader"
              {...register("reportingTeamleader", { required: true })}
            >
              <option>
                {employee?.team_id || ""}
              </option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
            {errors.reportingTeamleader && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
      </div>

      <div className="row  mb-2">
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label for="jobTitle">Job Title</label>
            <select
              class="form-select"
              id="jobTitle"
              {...register("jobTitle", { required: true })}
            >
              <option>
                {employee?.job_title || ""}
              </option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
            {errors.jobTitle && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label for="timeType">Time Type</label>
            <select
              class="form-select"
              id="timeType"
              {...register("timeType", { required: true })}
            >
              <option>
                {employee?.time_type || ""}
              </option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
            {errors.timeType && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
      </div>

      <div className="row  mb-2">
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="joiningDate">
              Joining Date
            </label>
            <input
              type="date"
              className="form-control"
              id="joiningDate"
              placeholder="Enter Enter Work Location"
              defaultValue={employee?.joining_date || ""}
              {...register("joiningDate", { required: true })}
            />
            {errors.joiningDate && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label for="workType">Work Type</label>
            <select
              class="form-select"
              id="workType"
              {...register("workType", { required: true })}
            >
              <option>
                {employee?.work_type || ""}
              </option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
            {errors.workType && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
      </div>

      <div className="row  mb-2">
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label for="reportingManager">Reporting Manager</label>
            <select
              class="form-select"
              id="reportingManager"
              {...register("reportingManager", { required: true })}
            >
              <option>
                {employee?.reporting_manager || ""}
              </option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
            {errors.reportingManager && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>

        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="leaveBalance">
              Leave Balance
            </label>
            <input
              type="text"
              className="form-control"
              id="leaveBalance"
              defaultValue={employee?.leave_balance || ""}
              {...register("leaveBalance", { required: true })}
            />
            {errors.leaveBalance && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
      </div>

      <div className="row  mb-2">
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label for="employeeStatus">Employee Status</label>
            <select
              class="form-select"
              id="employeeStatus"
              {...register("employeeStatus", { required: true })}
            >
              <option>
                {employee?.employee_status || ""}
              </option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
            {errors.employeeStatus && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div className="form-group">
            <label htmlFor="probationPolicy">Probation Policy</label>
            <select
              className="form-select"
              id="probationPolicy"
              {...register("probationPolicy", { required: true })}
            >
              <option>
              {employee?.probation_policy || ""} 
              </option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
            {errors.probationPolicy && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
      </div>

      <div className="row  mb-2">
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label for="noticePeriod">Notice Period</label>
            <select
              class="form-select"
              id="noticePeriod"
              {...register("noticePeriod", { required: true })}
            >
              <option>
                {employee?.notice_period || ""}
              </option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
            {errors.noticePeriod && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="leavingDate">
              Leaving Date
            </label>
            <input
              type="date"
              className="form-control"
              id="leavingDate"
              defaultValue={employee?.leaving_date || ""} 
              {...register("leavingDate", { required: true })}
            />
            {errors.leavingDate && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
      </div>

      <div className="row  mb-2">
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="noDuesDate">
              No Dues Date
            </label>
            <input
              type="date"
              className="form-control"
              id="noDuesDate"
             defaultValue={employee?.no_dues_date || ""} 
              {...register("noDuesDate", { required: true })}
            />
            {errors.noDuesDate && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>

        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="fullAndFinal">
              Full And Final Date
            </label>
            <input
              type="date"
              className="form-control"
              id="fullAndFinal"
              defaultValue={employee?.full_n_final_date || ""} 
              {...register("fullAndFinal", { required: true })}
            />
            {errors.fullAndFinal && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
      </div>

      <div className="row  mb-2">
        <div className="col-md-12 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="remarks">
              Remarks
            </label>
            <textarea
              className="form-control"
              id="remarks"
              defaultValue={employee?.salary_remark || ""} 
              {...register("remarks", { required: true })}
            ></textarea>
            {errors.remarks && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function WorkDetails({ register, errors }) {
  const location = useLocation();
  const employee = location.state?.employee;

  return (
    <div className="container">
      <h5 className="mb-3 text-primary">Work Details</h5>

      <div className="row  mb-2">
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="workAnniversary">
              Work Anniversary
            </label>
            <input
              type="date"
              className="form-control"
              id="workAnniversary"
              defaultValue={employee?.work_anniversary || ""}
              {...register("workAnniversary", { required: true })}
            />
            {errors.workAnniversary && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>

        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="workEmail">
              Work Email
            </label>
            <input
              type="email"
              className="form-control"
              id="workEmail"
              defaultValue={employee?.work_email || ""}
              {...register("workEmail", { required: true })}
            />
            {errors.workEmail && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
      </div>

      <div className="row  mb-2">
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label for="onboardSettings">Onboarding Settings</label>
            <select
              class="form-select"
              id="onboardSettings"
              {...register("onboardSettings", { required: true })}
            >
              <option>{employee?.onboarding_setting || ""}              </option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
            {errors.onboardSettings && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label for="shift">Shift</label>
            <select
              class="form-select"
              id="shift"
              {...register("shift", { required: true })}
            >
              <option>
              {employee?.shift || ""}
              </option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
            {errors.shift && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
      </div>

      <div className="row  mb-2">
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="attendanceNumber">
              Atttendance Number
            </label>
            <input
              type="text"
              className="form-control"
              id="attendanceNumber"
              defaultValue={employee?.attendance_number || ""}
              {...register("attendanceNumber", { required: true })}
            />
            {errors.attendanceNumber && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="attendanceCapture">
              Atttendance Capture Method
            </label>
            <input
              type="text"
              className="form-control"
              id="attendanceCapture"
              defaultValue={employee?.attendance_capture || ""}
              {...register("attendanceCapture", { required: true })}
            />
            {errors.attendanceCapture && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
      </div>

      <div className="row  mb-2">
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label for="weeklyOff">Weekly Off</label>
            <select
              class="form-select"
              id="weeklyOff"
              {...register("weeklyOff", { required: true })}
            >
              <option>
              {employee?.weekly_off || ""}
              </option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
            {errors.weeklyOff && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label for="department">Department</label>
            <select
              class="form-select"
              id="department"
              {...register("department", { required: true })}
            >
              <option>
              {employee?.department || ""}
              </option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
            {errors.department && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-md-12 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="departmentDescription">
              Department Description
            </label>
            <textarea
              className="form-control"
              rows="2"
              placeholder="Enter Department Description"
              id="departmentDescription"
              {...register("departmentDescription", { required: true })}
              defaultValue={employee?.department_description || ""}
            ></textarea>
            {errors.departmentDescription && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function Compensation({ register, errors }) {
  const location = useLocation();
  const employee = location.state?.employee;

  return (
    <div className="container">
      <h5 className="mb-3 text-primary">Compensation</h5>
      <div className="row  mb-2">
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="bankName">
              Bank Name
            </label>
            <input
              type="text"
              className="form-control"
              id="bankName"
              defaultValue={employee?.bank || ""}
              {...register("bankName", { required: true })}
            />
            {errors.bankName && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="accountHolderName">
              Account Holder Name
            </label>
            <input
              type="text"
              className="form-control"
              id="accountHolderName"
              defaultValue={employee?.account_name || ""}
              {...register("accountHolderName", { required: true })}
            />
            {errors.accountHolderName && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
      </div>

      <div className="row  mb-2">
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="accountNumber">
              Account Number
            </label>
            <input
              type="text"
              className="form-control"
              id="accountNumber"
              defaultValue={employee?.account_number || ""}
              {...register("accountNumber", { required: true })}
            />
            {errors.accountNumber && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="ifscCode">
              IFSC Code
            </label>
            <input
              type="text"
              className="form-control"
              id="ifscCode"
              defaultValue={employee?.ifsc_code || ""}
              {...register("ifscCode", { required: true })}
            />
            {errors.ifscCode && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
      </div>

      <div className="row  mb-2">
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="bankBranch">
              Bank Branch
            </label>
            <input
              type="text"
              className="form-control"
              id="bankBranch"
              defaultValue={employee?.branch || ""}
              {...register("bankBranch", { required: true })}
            />
            {errors.bankBranch && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="annualSalary">
              Annual Salary
            </label>
            <input
              type="text"
              className="form-control"
              id="annualSalary"
              defaultValue={employee?.annual_salary
                || ""}
              {...register("annualSalary", { required: true })}
            />
            {errors.annualSalary && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
      </div>

      <div className="row  mb-2">
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="salaryRevisionDate">
              Salary Revision Date
            </label>
            <input
              type="date"
              className="form-control"
              id="salaryRevisionDate"
              defaultValue={employee?.salary_revision_date || ""}
              {...register("salaryRevisionDate", { required: true })}
            />
            {errors.salaryRevisionDate && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div class="form-group">
            <label className="fw-bold" htmlFor="salaryRemarks">
              Salary Remarks
            </label>
            <textarea
              type="text"
              className="form-control"
              id="salaryRemarks"
              placeholder="Enter Salary Remarks"
              {...register("salaryRemarks", { required: true })}
              defaultValue={employee?.salary_remark || ""}
            ></textarea>
            {errors.salaryRemarks && (
              <span className="text-danger">*This field is required</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function Documents({ register, errors }) {
  const location = useLocation();
  const employee = location.state?.employee;

  const [documentList, setDocumentList] = useState([
    { documentName: "", documentFile: "" },
  ]);

  const handleAddRow = () => {
    setDocumentList([...documentList, { documentName: "", documentFile: "" }]);
  };

  const handleRemoveRow = (index) => {
    if (index === 0) return; // Ensure the first row cannot be removed

    const updatedDocumentList = documentList.filter((_, i) => i !== index);
    setDocumentList(updatedDocumentList);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedDocumentList = [...documentList];
    updatedDocumentList[index][name] = value;
    setDocumentList(updatedDocumentList);
  };

  const handleFileChange = (index, event) => {
    const file = event.target.files[0];
    const updatedDocumentList = [...documentList];
    updatedDocumentList[index].documentFile = file;
    setDocumentList(updatedDocumentList);
  };

  return (
    <div className="container">
      <h5 className="mb-3 text-primary">Documents</h5>

      {documentList.map((document, index) => (
        <div className="row mb-2" key={index}>
          <div className="col-md-5 mb-2">
            <div className="form-group">
              <label className="fw-bold" htmlFor={`documentName-${index}`}>
                Document Name
              </label>
              <input
                type="text"
                className="form-control"
                id={`documentName-${index}`}
                name="documentName"
                placeholder="Enter Document Name"
                value={document.documentName}
                onChange={(e) => handleInputChange(index, e)}
              />
            </div>
          </div>
          <div className="col-md-5 mb-2">
            <div className="form-group">
              <label className="fw-bold" htmlFor={`documentFile-${index}`}>
                Upload File
              </label>
              <input
                type="file"
                className="form-control"
                id={`documentFile-${index}`}
                name="documentFile"
                onChange={(e) => handleFileChange(index, e)}
              />
            </div>
          </div>

          {/* Conditionally Render Remove Button for rows other than the first */}
          {index > 0 && (
            <div className="col-md-2 d-flex align-items-center">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => handleRemoveRow(index)}
              >
                Remove
              </button>
            </div>
          )}
        </div>
      ))}

      {/* Add New Row Button */}
      <button
        type="button"
        className="btn btn-primary mt-3"
        onClick={handleAddRow}
      >
        Add New
      </button>
    </div>
  );
}
export default EditEmployee;
