import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import Loader from "@Layout/Loader/loader";
import {
  fetchLeaveApi,
  updateLeaveApi,
} from "@Networking/APIs/Leave/editLeaveApi";

export default function EditApplyLeave() {
  //Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  //Redux
  const { loading, apiErrors } = useSelector((state) => state.leaveEditSlice);

  //States
  const [description, setDescription] = useState("");
  const { state: locationState } = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  //UseEffects
  useEffect(() => {
    const leaveData = locationState?.leave;
    if (leaveData) {
      reset({
        id: leaveData.id,
        subject: leaveData.subject,
        fromDate: leaveData.start_date,
        toDate: leaveData.end_date,
      });
      setDescription(leaveData.description || "");
    } else if (locationState?.leaveId) {
      dispatch(fetchLeaveApi(locationState.leaveId));
    }
  }, [locationState, dispatch, reset]);

  const simulateLoading = async () => {
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsLoading(false);
  };

  const handleErrors = (err) => {
    if (err?.messages) {
      Object.entries(err.messages).forEach(([field, messages]) => {
        messages.forEach((message) => toast.error(`${field}: ${message}`));
      });
    } else {
      toast.error("Failed to update leave.");
    }
  };

  const onSubmit = async (data) => {
    const payload = {
      subject: data.subject,
      start_date: data.fromDate,
      end_date: data.toDate,
      description,
      leave_days: 2,
    };

    try {
      const result = await dispatch(
        updateLeaveApi({ leaveId: data.id, payload })
      ).unwrap();
      if (result.status) {
        toast.success(result.message); 
        simulateLoading();
        navigate("/AllLeave");
      } else {
        toast.error(result.messages || "Something went wrong");
      }
    } catch (err) {
      handleErrors(err);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="card text-white rounded-top-2 p-3 mb-4 mx-2">
        <div className="d-flex align-items-center">
          <div className="col-md-6 col-sm-12">
            <h3 className="page-title m-0">
              <span className="mx-2 text-primary">
                <i className="mdi mdi-file"></i>
              </span>
              EDIT LEAVE
            </h3>
          </div>
          <div className="col-md-6 text-end">
            <button
              className="btn btn-gradient-info rounded-pill w-25 btn-sm"
              onClick={() => navigate("/AllLeave")}
            >
              All Leaves
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row p-3">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Subject</label>
                    <input
                      className="form-control"
                      {...register("subject", {
                        required: "Subject is required",
                      })}
                    />
                    {errors.subject && (
                      <span className="text-danger">
                        {errors.subject.message}
                      </span>
                    )}
                    {apiErrors.subject && (
                      <span className="text-danger">
                        {apiErrors.subject[0]}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>From</label>
                    <input
                      type="date"
                      className="form-control"
                      {...register("fromDate", {
                        required: "From date is required",
                      })}
                    />
                    {errors.fromDate && (
                      <span className="text-danger">
                        {errors.fromDate.message}
                      </span>
                    )}
                    {apiErrors.start_date && (
                      <span className="text-danger">
                        {apiErrors.start_date[0]}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>To</label>
                    <input
                      type="date"
                      className="form-control"
                      {...register("toDate", {
                        required: "To date is required",
                      })}
                    />
                    {errors.toDate && (
                      <span className="text-danger">
                        {errors.toDate.message}
                      </span>
                    )}
                    {apiErrors.end_date && (
                      <span className="text-danger">
                        {apiErrors.end_date[0]}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label>Description</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onChange={(event, editor) =>
                        setDescription(editor.getData())
                      }
                    />
                    {!description && (
                      <span className="text-danger">
                        Description is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-12 d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-gradient-info rounded-pill w-75"
                    disabled={loading}
                  >
                    {loading ? "Updating..." : "Update"}
                  </button>
                  {loading && <Loader />}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
