import React, { useEffect, useMemo, useState } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  GenerateSalarySlip,
  getSalary,
  getSalaryById,
} from "@Networking/APIs/Salary/salaryApi";
import "bootstrap/dist/css/bootstrap.min.css";

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => (
  <span>
    <input
      value={globalFilter || ""}
      onChange={(e) => setGlobalFilter(e.target.value || undefined)}
      placeholder="Search all columns"
      className=""
      style={{
        width: "200px",
        marginBottom: "-20px",
        display: "inline-block",
        marginLeft: "10px",
      }}
    />
  </span>
);

const MySalary = () => {
  const dispatch = useDispatch();
  const [employeeData, setEmployeeData] = useState([]);
  const navigate = useNavigate();
  const { SalaryData, loading } = useSelector((state) => state.SalarySlice);

  useEffect(() => {
    dispatch(getSalary());
  }, [dispatch]);

  useEffect(() => {
    if (SalaryData.data && SalaryData.data.length > 0) {
      const loggedInUserId = Number(localStorage.getItem("userId"));
      const filteredData = SalaryData.data
        .filter((emp) => emp?.employee?.user_id === loggedInUserId) // Filter by matching user_id in employee object
        .map((emp) => ({
          id: emp?.id,
          name: emp?.employee?.first_name,
          salary: Number(emp?.employee_salary),
          workingMonth: emp?.working_month,
          workingDays: Number(emp?.working_days),
          workingHours: Number(emp?.working_hours),
          advanceDeduction: Number(emp?.employee_advance),
          otherExpenditure: Number(emp?.other_deduction_amount),
          entryDate: emp?.created_at
            ? new Date(emp?.created_at).toLocaleDateString()
            : "N/A",
          totalHours: Number(emp?.total_hours),
          salaryToBePaid: Number(emp?.total_salary),
          paymentStatus: emp?.payment_status,
        }));
      setEmployeeData(filteredData);
    }
  }, [SalaryData]);

  const [filters, setFilters] = useState({
    empNameOrSalary: "",
    startDate: null,
    endDate: null,
  });

  const filteredData = useMemo(() => {
    if (employeeData.length > 0) {
      return employeeData.filter((employee) => {
        const nameMatch = employee?.name
          ?.toLowerCase()
          .includes(filters?.empNameOrSalary?.toLowerCase());
        const salaryMatch = employee?.salary
          ?.toString()
          .includes(filters?.empNameOrSalary);

        const entryDate = new Date(employee.entryDate); // Parse date string
        const startDateMatch = filters.startDate
          ? entryDate >= filters.startDate
          : true;
        const endDateMatch = filters.endDate
          ? entryDate <= filters.endDate
          : true;

        return (nameMatch || salaryMatch) && startDateMatch && endDateMatch;
      });
    } else {
      return [];
    }
  }, [filters, employeeData]);

  const columns = useMemo(
    () => [
      { Header: "EMP CODE", accessor: "id" },
      { Header: "EMP NAME", accessor: "name" },
      { Header: "EMP SALARY", accessor: "salary" },
      { Header: "WORKING MONTH", accessor: "workingMonth" },
      {
        Header: "WORKING DAYS/HOURS",
        accessor: (row) => `${row.workingDays} / ${row.workingHours}`,
      },
      { Header: "ADVANCE DEDUCTION", accessor: "advanceDeduction" },
      { Header: "OTHER DEDUCTION", accessor: "otherExpenditure" },
      { Header: "ENTRY DATE", accessor: "entryDate" },
      { Header: "TOTAL HOURS", accessor: "totalHours" },
      { Header: "SALARY TO BE PAID", accessor: "salaryToBePaid" },
      { Header: "PAYMENT STATUS", accessor: "paymentStatus" },
      {
        Header: "ACTION",
        Cell: ({ row }) => (
          <>
            <button
              className="btn btn-sm btn-danger me-2"
              onClick={() => handleDownLoadSlip(row.original)}
            >
              <i className="fa-solid fa-file-pdf"></i>
            </button>
            <button
              className="btn btn-sm btn-primary me-2"
              onClick={() => handleEdit(row.original)}
            >
              <i className="fa-solid fa-pencil"></i>
            </button>
          </>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleDateChange = (date, type) => {
    setFilters({ ...filters, [type]: date });
  };

  const handleEdit = async (employee) => {
    const result = await dispatch(getSalaryById(employee.id)).unwrap();
    navigate(`/SalaryEdit/${employee.id}`, {
      state: { employee: result.data, employee_name: employee.name },
    });
  };

  const handleDownLoadSlip = async (employee) => {
    try {
      const response = await dispatch(GenerateSalarySlip(employee.id));
      const pdfURL = URL.createObjectURL(
        new Blob([response.payload], { type: "application/pdf" })
      );
      window.open(pdfURL, "_blank");
    } catch (error) {}
  };

  return (
    <>
          <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <h6 className="">
            <i class="fa-solid fa-list me-2"></i>HRM / Salary / My Salary
          </h6>
        </div>
      </div>

      <div className="card bg-gradient-info rounded-top-2 p-2 mb-2">
        <div className="row d-flex align-items-center">
          <div className="col-md-6 col-sm-6">
            <h3 className="page-title m-0  text-light">
              <span className="mx-2 text-primary">
                <i className="mdi mdi-file  text-light"></i>
              </span>
              MY SALARY
            </h3>
          </div>
          <div className="col-md-6 col-sm-6 text-end">
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </div>
        </div>
      </div>

      <div className="container-scroller m2-4">
        <div className="card mt-3 invoice-card mb-5">
        <div className="card-header bg-gradient-dark p-0 text-light text-center">
            <h5>Salary List</h5>
          </div>
          <div
            className="card-body"
            style={{ height: "auto", overflowY: "auto" }}
          >
            <div className="">
              <div className="">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table {...getTableProps()} className="table table-hover">
                        <thead>
                          {headerGroups.map((headerGroup) => (
                            <tr
                              className="text-center"
                              {...headerGroup.getHeaderGroupProps()}
                            >
                              {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                  {column.render("Header")}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {page.map((row) => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                  <td
                                    className="text-center"
                                    {...cell.getCellProps()}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                ))}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="d-flex flex-wrap justify-content-between align-items-center p-3 border-top mt-3">
                        <div>
                          <span className="text-muted">
                            Page <strong>{pageIndex + 1}</strong> of{" "}
                            <strong>{pageOptions.length}</strong>
                          </span>
                        </div>
                        <div className="pagination-controls d-flex align-items-center">
                          <button
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                            className="btn btn-light btn-sm mx-1"
                            title="First Page"
                          >
                            <i className="bi bi-chevron-double-left"></i>
                          </button>
                          <button
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                            className="btn btn-light btn-sm mx-1"
                            title="Previous Page"
                          >
                            <i className="bi bi-chevron-left"></i>
                          </button>

                          {/* Pagination Numbers */}
                          <div className="d-flex">
                            {pageOptions.map((page, index) => (
                              <button
                                key={index}
                                onClick={() => gotoPage(page)}
                                className={`btn btn-sm mx-1 ${
                                  pageIndex === page
                                    ? "btn-primary text-white"
                                    : "btn-light"
                                }`}
                                style={{
                                  borderRadius: "50%",
                                  width: "35px",
                                  height: "35px",
                                  padding: "5px",
                                }}
                                title={`Page ${page + 1}`}
                              >
                                {page + 1}
                              </button>
                            ))}
                          </div>

                          <button
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                            className="btn btn-light btn-sm mx-1"
                            title="Next Page"
                          >
                            <i className="bi bi-chevron-right"></i>
                          </button>
                          <button
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                            className="btn btn-light btn-sm mx-1"
                            title="Last Page"
                          >
                            <i className="bi bi-chevron-double-right"></i>
                          </button>
                        </div>
                        <div className="d-flex align-items-center">
                          <label
                            htmlFor="pageSize"
                            className="me-2 mb-0 text-muted"
                          >
                            Show:
                          </label>
                          <select
                            id="pageSize"
                            value={pageSize}
                            onChange={(e) =>
                              setPageSize(Number(e.target.value))
                            }
                            className="form-select form-select-sm"
                            style={{ width: "80px" }}
                          >
                            {[10, 20, 30, 40, 50].map((size) => (
                              <option key={size} value={size}>
                                {size}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default MySalary;
