import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Bar, Doughnut } from "react-chartjs-2";
import { Modal, Button, ProgressBar } from "react-bootstrap";
import {
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "chart.js/auto";
import { getInvoiceDashboardData } from "@Networking/APIs/Dashboard/dashboardApi";

const FinanceDashboard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getInvoiceDashboardData());
  }, []);

  const InvoiceDashboardData = useSelector((state) => state.DashboardSlice);

  console.log(InvoiceDashboardData, "InvoiceDashboardData");
  const tasks = [
    {
      name: "Bank Name 1",
      deadline: "2024-12-15",
      status: "Direct",
      statusClass: "alert-info",
      icon: "fa-check-circle", // Font Awesome icon for completed
    },
    {
      name: "Bank Name 2",
      deadline: "2024-12-20",
      status: "Indirect",
      statusClass: "alert-warning",
      icon: "fa-spinner",
    },
    {
      name: "Bank Name 3",
      deadline: "2024-12-18",
      status: "Direct",
      statusClass: "alert-danger",
      icon: "fa-exclamation-circle",
    },
  ];

  const invoices = [
    {
      name: "Invoice 1",
      status: "Paid",
      created: "2024-12-10",
    },
    {
      name: "Invoice 2",
      status: "Partially Paid",
      created: "2024-12-15",
    },
    {
      name: "Invoice 3",
      status: "Unpaid",
      created: "2024-12-20",
    },
  ].map((invoice) => {
    if (invoice.status == "Paid") {
      return {
        ...invoice,
        status: "Paid",
        statusClass: "alert-success",
        icon: "fa-calendar-check",
      };
    } else if (invoice.status == "Partially Paid") {
      return {
        ...invoice,
        status: "Partially Paid",
        statusClass: "alert-warning",
        icon: "fa-sad-tear",
      };
    } else if (invoice.status == "Unpaid") {
      // Changed to else if
      return {
        ...invoice,
        status: "Unpaid",
        statusClass: "alert-danger",
        icon: "fa-sad-tear",
      };
    }
  });

  // Extract labels (days) and data (hours) for the chart
  const labels =
    InvoiceDashboardData?.InvoiceDashboardData?.perMonthCount?.map(
      (data) => data.month_name
    ) || [];
  const data =
    InvoiceDashboardData?.InvoiceDashboardData?.perMonthCount?.map(
      (data) => data.invoice_count
    ) || [];

  // Chart.js configuration
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Invoices",
        data: data,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FF7F50",
          "#FFC0CB",
          "#7FFF00",
          "#00FFFF",
          "#FF00FF",
          "#800080",
        ],
        borderColor: "#ccc",
        borderWidth: 0,
        borderRadius: 0, // Rounded bar corners
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },

      tooltip: {
        enabled: true,
        backgroundColor: "#000",
        titleColor: "#fff",
        bodyColor: "#fff",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          color: "#eaeaea",
        },
        ticks: {
          stepSize: 2,
        },
      },
    },
  };

  // Attendance Card
  const [selectedDate, setSelectedDate] = useState("");
  const [attendanceData, setAttendanceData] = useState({
    "2024-12-01": { checkIn: "9:00 AM", checkOut: "6:00 PM" },
    "2024-12-02": { checkIn: "9:15 AM", checkOut: "5:45 PM" },
  });

  const [monthlySummary, setMonthlySummary] = useState([
    { date: "2024-12-01", status: "Present" },
    { date: "2024-12-02", status: "Present" },
    { date: "2024-12-03", status: "Absent" },
  ]);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const renderAttendanceDetails = () => {
    if (!selectedDate) {
      return;
    }

    const attendance = attendanceData[selectedDate];
    if (attendance) {
      return (
        <div>
          <div className="row alert alert-primary m-auto">
            <div className="col-md-6">
              <p>Check-In Time: {attendance.checkIn}</p>
            </div>
            <div className="col-md-6">
              <p>Check-Out Time: {attendance.checkOut}</p>
            </div>
          </div>
        </div>
      );
    } else {
      return <p>No attendance data available for the selected date.</p>;
    }
  };

  const renderMonthlySummary = () => {
    if (monthlySummary.length === 0) {
      return <p className="text-muted">No summary available for this month.</p>;
    }

    return (
      <ul className="list-group">
        {monthlySummary.map((record, index) => (
          <li key={index} className="list-group-item">
            <strong>{record.date}:</strong> {record.status}
          </li>
        ))}
      </ul>
    );
  };

  ChartJS.register(ArcElement, Tooltip, Legend);

  const leaveData = {
    labels: ["Paid Invoice", "Partially Paid Invoice", "Unpaid Invoice"],
    datasets: [
      {
        label: "Leave Status",
        data: [
          InvoiceDashboardData?.InvoiceDashboardData?.Paid || 0,
          InvoiceDashboardData?.InvoiceDashboardData?.Parcialy_Paid || 0,
          InvoiceDashboardData?.InvoiceDashboardData?.Unpaid || 0,
        ],
        backgroundColor: ["#198754", "#0dcaf0", "#dc3545"],
        hoverBackgroundColor: ["#198754", "#0dcaf0", "#dc3545"],
        borderWidth: 1,
      },
    ],
  };

  const leaveoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          generateLabels: (chart) => {
            const original =
              ChartJS.overrides.doughnut.plugins.legend.labels.generateLabels;
            const labels = original(chart);
            const dataset = chart.data.datasets[0].data || []; // Use the correct dataset
            labels.forEach((label, index) => {
              const count = dataset[index] || 0;
              label.text = `${label.text} (${count})`;
            });
            return labels;
          },
        },
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        display: true,
        formatter: (value) => value,
        color: "#36454F",
        font: {
          weight: "bold",
          size: 16,
        },
      },
    },
  };
  const formatDate = (date) => new Date(date).toISOString().split("T")[0];

  const userName = localStorage.getItem("name");

  return (
    <>
      <div className="page-header">
        <h3 className="page-title">
          Welcome back, {userName}{" "}
          <i className="fa-solid fa-hand fs-5 text-warning"></i>
        </h3>
      </div>
      <div className="row g-6 mt-3">
        <div className="col-md-3 col-sm-4 border-end border-primary border-2">
          <div className="card shadow custom-top-border">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <span className="h6 font-semibold text-muted text-sm d-block mb-2 border-bottom border-secondary pb-1">
                    Total Invoices
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 text-start">
                  <button
                    type="button"
                    className="btn dbMnCrLn"
                    onClick={() => navigate("/InvoicesList")}
                  >
                    View All <i class="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
                <div className="col-md-4 text-end  border-start">
                  <span className="h3 font-bold mb-0">
                    {InvoiceDashboardData?.InvoiceDashboardData?.Invoice ?? (
                      <p className="text-danger">No data available</p>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-4 border-end border-primary border-2">
          <div className="card shadow custom-top-border border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <span className="h6 font-semibold text-muted text-sm d-block mb-2 border-bottom border-secondary pb-1">
                    Paid Invoices
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 text-start">
                  <button
                    type="button"
                    className="btn dbMnCrLn"
                    onClick={() => navigate("/InvoicesList")}
                  >
                    View All <i class="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
                <div className="col-md-4 text-end  border-start">
                  <span className="h3 font-bold mb-0">
                    {InvoiceDashboardData?.InvoiceDashboardData?.Paid ?? (
                      <p className="text-danger">No data available</p>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-4 border-end border-primary border-2">
          <div className="card shadow custom-top-border border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <span className="h6 font-semibold text-muted text-sm d-block mb-2 border-bottom border-secondary pb-1">
                    Partially Paid
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 text-start">
                  <button
                    type="button"
                    className="btn dbMnCrLn"
                    onClick={() => navigate("/InvoicesList")}
                  >
                    View All <i class="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
                <div className="col-md-4 text-end  border-start">
                  <span className="h3 font-bold mb-0">
                    {InvoiceDashboardData?.InvoiceDashboardData
                      ?.Parcialy_Paid ?? (
                      <p className="text-danger">No data available</p>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-4">
          <div className="card shadow custom-top-border border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <span className="h6 font-semibold text-muted text-sm d-block mb-2 border-bottom border-secondary pb-1">
                    Unpaid Invoices
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 text-start">
                  <button
                    type="button"
                    className="btn dbMnCrLn"
                    onClick={() => navigate("/InvoicesList")}
                  >
                    View All <i class="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
                <div className="col-md-4 text-end  border-start">
                  <span className="h3 font-bold mb-0">
                    {InvoiceDashboardData?.InvoiceDashboardData?.Unpaid ?? (
                      <p className="text-danger">No data available</p>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="row g-6 mt-3">
        <div className="col-md-12 border-end border-primary border-2">
          <div className="card ">
            <div className="card-header edAnCh text-white d-flex justify-content-between align-items-center p-2">
              <h5 className="m-0">Latest Invoices</h5>
              <i className="fa-solid fa-receipt"></i>
            </div>
            <div className="card-body">
              {InvoiceDashboardData?.InvoiceDashboardData?.latestInvoices &&
              InvoiceDashboardData?.InvoiceDashboardData?.latestInvoices
                .length > 0 ? (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Business Name</th>
                      <th className="text-center">Create Date</th>
                      <th className="text-center">Due Date</th>
                      <th className="text-center">Total</th>
                      <th className="text-center">Total in INR.</th>
                      <th className="text-end">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {InvoiceDashboardData?.InvoiceDashboardData?.latestInvoices.map(
                      (invoices, index) => (
                        <tr key={index}
                        className={invoices.status === "Paid" ? "table-success text-white" : invoices.status === "Partially Paid" ? "table-info text-white" : invoices.status === "Unpaid" ? "table-danger text-white" : ""}
                        >
                          <td>
                            {invoices.client?.business_name ||
                              "No business name available"}
                          </td>
                          <td className="text-center">
                            {formatDate(invoices.created_at)}
                          </td>
                          <td className="text-center">
                           {invoices.due_date}
                          </td>
                          <td className="text-center">
                            {invoices.total || "No total available"}
                          </td>
                          <td className="text-center">
                            {invoices.total_in_INR || "No total available"}
                          </td>
                          <td className="text-end">
                              {invoices.status}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              ) : (
                <p style={{ color: "red" }}>No data available</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row g-6 mt-3">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header edAnCh text-white d-flex justify-content-between align-items-center p-2">
              <h5 className="m-0">Recently Payments</h5>
              <i class="fa-solid fa-money-bill-1-wave"></i>
            </div>
            <div className="card-body">
              {InvoiceDashboardData?.InvoiceDashboardData?.payment &&
              InvoiceDashboardData?.InvoiceDashboardData?.payment.length > 0 ? (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Invoice Number</th>
                      <th className="text-center">Client Company</th>
                      <th className="text-center">Payment Mode</th>
                      <th className="text-center">Paid Amount</th>
                      <th className="text-center">Paid Amount(INR)</th>
                      <th className="text-center">Payment Date</th>
                      <th className="text-end">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {InvoiceDashboardData?.InvoiceDashboardData?.payment.map(
                      (payments, index) => (
                        <tr key={index} 
                        className={payments.Payment_Status === "Paid" ? "table-success text-white" : payments.Payment_Status === "Partially Paid" ? "table-info text-white" : payments.Payment_Status === "Unpaid" ? "table-danger text-white" : ""}
                        >
                           <td className="text-center">
                            {payments.invoice_number}
                          </td>
                          <td className="text-center">
                            {payments?.invoice?.client.business_name ||
                              "No business name available"}
                          </td>
                         
                          <td className="text-center">
                            {payments.Payment_Mode || "No Mode available"}
                          </td>
                          <td className="text-center">
                            {payments.Paid_Amount || "No amount available"}
                          </td>
                          <td className="text-center">
                            {payments.paid_amount_INR || "No amount available"}
                          </td>
                          <td className="text-center">
                            {payments.Payment_Date || "No date available"}
                          </td>
                          <td className="text-end">
                        {payments.Payment_Status}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              ) : (
                <p style={{ color: "red" }}>No data available</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6 border-end border-primary border-2">
          <div className="card">
            <div className="card-header edAnCh text-white d-flex justify-content-between align-items-center p-2">
              <h5 className="m-0">This Month's Invoices</h5>
              <i class="fa-solid fa-calendar"></i>
            </div>
            <div className="card-body">
              <div style={{ height: "220px" }}>
                <Bar data={chartData} options={options} />
              </div>
              <div className="text-center mt-3">
                <h6>
                  Total Invoices: {data.reduce((a, b) => a + b, 0)} Invoices
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card">
            <div className="card-header edAnCh text-white d-flex justify-content-between align-items-center p-2">
              <h5 className="m-0">Invoice Details</h5>
              <i class="fa-solid fa-circle-info"></i>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 border-end border-primary">
                  <div className="col-md-12 mt-2">
                    <div className="alert alert-success d-flex justify-content-evenly align-self-center p-0">
                      <p className="m-auto">
                        <strong>Paid Invoices: </strong>
                      </p>
                      <p className="m-auto">
                        {InvoiceDashboardData?.InvoiceDashboardData?.Paid ?? (
                          <p className="text-danger">No Paid invoices</p>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="alert alert-info d-flex justify-content-evenly align-self-center p-0">
                      <p className="m-auto">
                        <strong>Partially Paid Invoices: </strong>
                      </p>
                      <p className="m-auto">
                        {InvoiceDashboardData?.InvoiceDashboardData
                          ?.Parcialy_Paid ?? (
                          <p className="text-danger">
                            No Parcially Paid invoices
                          </p>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="alert alert-danger d-flex justify-content-evenly align-self-center p-0">
                      <p className="m-auto">
                        <strong>Unpaid Invoices: </strong>
                      </p>
                      <p className="m-auto">
                        {InvoiceDashboardData?.InvoiceDashboardData?.Unpaid ?? (
                          <p className="text-danger">No Unpaid invoices</p>
                        )}
                      </p>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-success w-100 rounded-pill dbCiBtn"
                    onClick={() => navigate("/InvoicesForm")}
                  >
                    Create Invoice
                  </button>
                </div>
                <div className="col-md-6 border-start border-primary text-center">
                  <div className="">
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        margin: "5% auto",
                      }}
                    >
                      <Doughnut
                        options={leaveoptions}
                        data={leaveData}
                        {...props}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinanceDashboard;
