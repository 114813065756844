import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getDepartmemt, getOnboardingSetting, getShift, getWeeklyOff } from "@Networking/APIs/Employee/EmployeeApi";
import { fetchEmployeData, postEmployeeWorkStore } from "@Networking/APIs/Employee/EmployeStoreApi";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { resetValidateError } from "@Networking/Slices/Employee/EmployeStoreSlice";
import Loader from "@Layout/Loader/loader";

const WorkDetails = ({ nextStep, prevStep,progress }) => {
   
  const dispatch = useDispatch();

  const [employeeId, setEmployeeId] = useState(null);
  useEffect(() => {
    const storedEmployeeId = employeData?.edit_employee?.id;
    if (storedEmployeeId) {
      setEmployeeId(storedEmployeeId);
    }
  }, []);


  useEffect(() => {
    dispatch(getOnboardingSetting());
    dispatch(getShift());
    dispatch(getWeeklyOff());
    dispatch(getDepartmemt());
  }, []);

  const { validateError, employeData,step3loading } = useSelector(
    (state) => state.EmployeStoreSlice
  );

  const [work_anniversary, setWorkAnniversary] = useState("");
  const [work_email, setWorkEmail] = useState("");
  const [onboarding_setting, setOnboardingSetting] = useState("");
  const [shift, setShift] = useState("");
  const [weekly_off, setWeeklyOff] = useState("");
  const [attendance_number, setAttendanceNumber] = useState("");
  const [attendance_capture, setAttendaceCapture] = useState("");
  const [department, setDepartment] = useState("");
  const [department_description, setDepartmentDescription] = useState("");
  const [empId, setEmpId] = useState(null);

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({}); 

  const prepareData = () =>{
    if(employeData){
      setEmpId(employeData?.edit_employee?.id);
      // setWorkAnniversary(employeData?.edit_employee?.work_anniversary);
      // setWorkEmail(employeData?.edit_employee?.work_email);
      setOnboardingSetting(employeData?.edit_employee?.onboarding_setting);
      setShift(employeData?.edit_employee?.shift);
      setAttendanceNumber(employeData?.edit_employee?.attendance_number);
      setDepartment(employeData?.edit_employee?.department);
     }};
  
    useEffect(() => {
      prepareData();
  }, [employeData]);




  useEffect(() => {
    setFormData({
      //  work_anniversary: work_anniversary,
      // work_email: work_email ,
      onboarding_setting: onboarding_setting ,
      shift: shift ,
      // weekly_off: weekly_off ,
      attendance_number: attendance_number ,
      // attendance_capture: attendance_capture ,
      department: department ,
      // department_description: department_description ,
      employee_id: empId ,
    });
  }, [
    // work_anniversary,
    // work_email,
    onboarding_setting,
    shift,
    // weekly_off,
    attendance_number,
    // attendance_capture,
    department,
    // department_description,
    empId,
  ]);
  

  const handleInputChange = (e) => {
    
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const { register, setValue, getValues } = useForm();

  const handleSubmit = async (e) => {

    e.preventDefault();
   
    try {
      const res = await dispatch(postEmployeeWorkStore(formData));

      if (res.payload.status === true) {
        dispatch(fetchEmployeData(empId));
        dispatch(resetValidateError());
        nextStep(); 
    }
     } catch (error) {
      const errorMessage = error.response?.data?.message || 'An unexpected error occurred. Please try again.';
    }
  };
  
  const onboardingSettingData = useSelector((state) => state.EmployeesSlice);
  const shiftData = useSelector((state) => state.EmployeesSlice);
  const weeklyOffData = useSelector((state) => state.EmployeesSlice);
  const departmentData = useSelector((state) => state.EmployeesSlice);


useEffect(() => {
  if (onboardingSettingData?.OnboardingSettingsData?.length > 0) {
    setOnboardingSetting(onboardingSettingData?.OnboardingSettingsData[0]?.name); // 0th index as default
  }
  if (shiftData?.ShiftData?.length > 1) {
    setShift(shiftData.ShiftData[1]?.shift_name); // 1st index as default
  }
}, [onboardingSettingData, shiftData]);


  return (
    <form onSubmit={handleSubmit}>
      <div className="col d-flex justify-content-between">
        <h5 className="mt-4">Step 3: Work Details</h5>
        <h5 className="mt-4 text-end "> <FontAwesomeIcon icon={faUser} className="menu-icon mx-2" />{employeData?.edit_employee?.first_name} {employeData?.edit_employee?.middle_name} {employeData?.edit_employee?.last_name}</h5>
        </div>
      <div className="progress-bar mx-5">
        <div className="progress" style={{ width: `${progress}%` }}></div>
      </div>
      <div className="row mb-2">
      {/* <div className="col-md-6 mb-2">
      <label>
      Work Anniversary
        </label>
        <input
              type="date"
              id="input"
              value={work_anniversary || employeData?.edit_employee?.work_anniversary}
              placeholder="Enter Employee Number"
              onChange={(e) => setWorkAnniversary(e.target.value)}
            />
     {validateError?.work_anniversary && (
              <span className="text-danger">{validateError?.work_anniversary[0]}</span>
            )}
      </div>
      <div className="col-md-6 mb-2">
      <label>
      Work Email
        </label>
         <input
              type="email"
              id="input"
              value={work_email || employeData?.edit_employee?.work_email}
              placeholder="Enter Work Email"
              onChange={(e) => setWorkEmail(e.target.value)}
            />
            {validateError?.work_email && (
              <span className="text-danger">{validateError?.work_email[0]}</span>
            )}
       </div> */}
       <div className="col-md-6 mb-2">
      <label>
      Onboarding Settings
        </label>
        <select
        value={onboarding_setting}
              id="input"
              onChange={(e) => setOnboardingSetting(e.target.value)}
            >
              <option value="" disabled selected>
                Select Onboarding Settings
              </option>
              {onboardingSettingData?.OnboardingSettingsData?.length > 0 ? (
                onboardingSettingData.OnboardingSettingsData.map((onboard) => (
                  <option key={onboard.id} value={onboard.name}
                  selected={onboard?.name == employeData?.edit_employee?.onboarding_setting}
                  >
                    {onboard.name}
                  </option>
                ))
              ) : (
                <option disabled>No Onboarding Settings available</option>
              )}
            </select>
            {validateError?.onboarding_setting && (
              <span className="text-danger">
                {validateError?.onboarding_setting[0]}
              </span>
            )}
        </div>
       <div className="col-md-6 mb-2">
      <label>
      Shift
        </label>
        <select
        value={shift}
              id="input"
              onChange={(e) => setShift(e.target.value)}
            >
              <option value="" disabled selected>
                Select Shift
              </option>
              {shiftData?.ShiftData?.length > 0 ? (
                shiftData.ShiftData.map((shifts) => (
                  <option key={shifts.id} value={shifts.shift_name}
                  selected={shifts?.shift_name == employeData?.edit_employee?.shift}
                  >
                    {shifts.shift_name}
                  </option>
                ))
              ) : (
                <option disabled>No Shifts available</option>
              )}
            </select>
            {validateError?.shift && (
              <span className="text-danger">
                {validateError?.shift[0]}
              </span>
            )}
       </div>
       <div className="col-md-6 mb-2">
      <label>
      Attendance Number
        </label>
        <input
              type="text"
              id="input"
              placeholder="Enter  Atttendance Number"
              value={attendance_number || employeData?.edit_employee?.attendance_number}
              onChange={(e) => setAttendanceNumber(e.target.value)}
            />  
            {validateError?.attendance_number && (
              <span className="text-danger">{validateError?.attendance_number[0]}</span>
            )}
       </div>
       {/* <div className="col-md-6 mb-2">
      <label>
      Attendance Capture Method
            </label>
            <input
              type="text"
              id="input"
              value={attendance_capture || employeData?.edit_employee?.attendance_capture}
              placeholder="Enter Atttendance Capture Method"
              onChange={(e) => setAttendaceCapture(e.target.value)}
            />
            {validateError?.attendance_capture && (
              <span className="text-danger">{validateError?.attendance_capture[0]}</span>
            )}
       </div> */}
       {/* <div className="col-md-6 mb-2">
      <label>Weekly Off
        </label>
        <select
              id="input"
              onChange={(e) => setWeeklyOff(e.target.value)}
            >
              <option value="" disabled selected>
                Select Weekly Off
              </option>
              {weeklyOffData?.WeeklyOffData?.length > 0 ? (
                weeklyOffData.WeeklyOffData.map((weekOff) => (
                  <option key={weekOff.id} value={weekOff.week_off_name}
                  selected={weekOff?.week_off_name == employeData?.edit_employee?.weekly_off}
                  >
                    {weekOff.week_off_name}
                  </option>
                ))
              ) : (
                <option disabled>No Weekly Off available</option>
              )}
            </select>
            {validateError?.weekly_off && (
              <span className="text-danger">
                {validateError?.weekly_off[0]}
              </span>
            )}
       </div> */}
       <div className="col-md-6 mb-2">
      <label>
      Department
        </label>
        <select
              id="department"
              onChange={(e) => setDepartment(e.target.value)}
            >
              <option value="" disabled selected>
                Select Department
              </option>
              {departmentData?.DepartmentData?.length > 0 ? (
                departmentData.DepartmentData.map((department) => (
                  <option
                    key={department.id}
                    value={department.department_name}
                    selected={department?.department_name == employeData?.edit_employee?.department}

                  >
                    {department.department_name}
                  </option>
                ))
              ) : (
                <option disabled>No Department available</option>
              )}
            </select>
            {validateError?.department && (
              <span className="text-danger">
                {validateError?.department[0]}
              </span>
            )}
       </div>
       {/* <div className="col-md-12 mb-2">
      <label>
      Department Description
        </label>
        <textarea
              rows="2"
              placeholder="Enter Department Description"
              id="input"
              value={department_description || employeData?.edit_employee?.department_description}
              onChange={(e) => setDepartmentDescription(e.target.value)}
            ></textarea>
             {validateError?.department_description && (
              <span className="text-danger">
                {validateError?.department_description[0]}
              </span>
            )}
       </div> */}

      </div>
      <div className="form-navigation mb-4">
        <button type="button" className="btn btn-danger" onClick={prevStep}>
          Previous
        </button>
        <button type="submit" className="btn btn-success">Next</button>
      </div>
      {
        step3loading ? <Loader/> : null
      }
    </form>
  );
};

export default WorkDetails;
