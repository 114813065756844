import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {BASE_URL, getitems} from '@Networking/APIs/NWConfig';
import Cookies from 'js-cookie';


export const getItem = createAsyncThunk(
    'get/getItem',
    async () => {  // categories should be passed as an argument
  
      // Using template literals to build the URL
      let url = BASE_URL + getitems;  // Corrected line
  
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );
  