import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { postBankStore } from "@Networking/APIs/Invoice/Bank/bankStoreApi";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBank,
} from "@Networking/APIs/Invoice/Bank/bankGetApi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getcountries } from "@Networking/APIs/Background/backgorundApi";
import Loader from "@Layout/Loader/loader";

const BankForm = ({ onClose }) => {
  //Hook
  const dispatch = useDispatch();

  //Redux
  const { validateError, loading } = useSelector(
    (state) => state.BankPostSlice
  );
  const handlebanktype = (value) => {
    setValue("accountType", value);
  };
  //UseEffects
  useEffect(() => {
    dispatch(getcountries());
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();


 // Custom upload adapter
 function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        loader.file
          .then((file) => {
            // Simulate an upload process
            const reader = new FileReader();
            reader.onload = () => {
              resolve({ default: reader.result }); // Use base64 or replace with actual upload URL
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          })
          .catch(reject);
      });
    },
  };
}

// Plugin to include the custom upload adapter
function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}

  const onSubmit = async (data) => {
    const newBank = {
      account_type: data.accountType,
      bank_title: data.bankTitle,
      bank_description: data.description, 
      currency_accept : data.currencyAccept,
      bank_rules : data.bankRules 
    };
    try {
      const res = await dispatch(postBankStore(newBank));
      if (res.payload.status == true) {
        onClose();
        const resss = await dispatch(getAllBank());


      }
    } catch (error) {
    }
  };

  return (
    <div
      className="modal fade show"
      style={{ display: "block", backdropFilter: "blur(5px)" }}
      aria-modal="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src="assets/logo/tslogo.png"
                alt="Client"
                style={{ width: "25px", height: "100%" }}
              />
              <h5 className="modal-title text-center">Add Bank</h5>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              style={{ position: "absolute", right: "10px", top: "10px" }}
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mt-2">
                <div className="col-md-12">
                  <label>Account Type</label>
                  <select
                    style={{
                      color: "#686868",
                    }}
                    {...register("accountType", {
                      required: "Account Type is required",
                    })}
                    id="account-type"
                    placeholder="Select Account Type"
                    className={`inputText ${errors.accountType ? "is-invalid" : ""
                      }`}
                    onChange={(e) => handlebanktype(e.target.value)}
                  >
                    <option value="">Select Account Type</option>
                    <option value="Direct">Direct</option>
                    <option value="Indirect">Indirect</option>
                  </select>
                  {errors.accountType && (
                    <p className="text-danger">{errors.accountType.message}</p>
                  )}
                  {validateError?.account_type && (
                    <p className="text-danger">
                      {validateError?.account_type[0]}
                    </p>
                  )}
                </div>
                </div>

              <div className="row mt-2">
                <div className="col-md-6">
                  <label>Bank Title</label>
                  <input
                    {...register("bankTitle", {
                      required: "Bank Title is required",
                      pattern: {
                        value: /^[\s\S]*$/,
                        message: "Only alphabets are allowed",
                      },
                    })}
                    id="beneficiary-name"
                    type="text"
                    className={`inputText ${errors.bankTitle ? "is-invalid" : ""
                      }`}
                    placeholder="Enter Bank Title"
                  />
                  {errors.bankTitle && (
                    <p className="text-danger">
                      {errors.bankTitle.message}
                    </p>
                  )}
                  {validateError?.bankTitle && (
                    <p className="text-danger">
                      {validateError?.bankTitle[0]}
                    </p>
                  )}
                </div>
                <div className="col-md-6">
                  <label>Currency Accept</label>
                  <input
                    {...register("currencyAccept", {
                      required: "Currency Accept is required",
                      pattern: {
                        value: /^[\s\S]*$/,
                        message: "Only alphabets are allowed",
                      },
                    })}
                    id="beneficiary-name"
                    type="text"
                    className={`inputText ${errors.currencyAccept ? "is-invalid" : ""
                      }`}
                    placeholder="Enter Currency Accept"
                  />
                  {errors.currencyAccept && (
                    <p className="text-danger">
                      {errors.currencyAccept.message}
                    </p>
                  )}
                  {validateError?.currencyAccept && (
                    <p className="text-danger">
                      {validateError?.currencyAccept[0]}
                    </p>
                  )}
                </div>
              </div>


              <div className="row mt-3">
                <div className="col-md-12">
                  <label>Bank Details :</label>
                  <Controller
                    name="description"
                    control={control}
                    rules={{ required: "Description is required" }}
                    render={({ field }) => (
                      <CKEditor
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          field.onChange(data);
                        }}
                        config={{
                          extraPlugins: [uploadPlugin],
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                            "|",
                            "insertTable",
                            "tableColumn",
                            "tableRow",
                            "mergeTableCells",
                            "|",
                            "imageUpload",
                            "mediaEmbed",
                            "|",
                            "undo",
                            "redo",
                            "|",
                            "imageTextAlternative",
                            "imageStyle:full",
                            "imageStyle:side",
                            "imageRemove", // Add this for image removal
                          ],
                          image: {
                            toolbar: [
                              "imageStyle:full",
                              "imageStyle:side",
                              "|",
                              "imageTextAlternative",
                              "imageRemove",
                              "imageUpload",
                              "imageDelete",
                            ],
                          },
                        }}
                      />
                    )}
                  />
                  {errors.description && (
                    <span className="text-danger">
                      {errors.description.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-12">
                  <label>Bank Rules:</label>
                  <Controller
                    name="bankRules"
                    control={control}
                    rules={{ required: "Bank Rules is required" }}
                    render={({ field }) => (
                      <CKEditor
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          field.onChange(data);
                        }}
                        config={{
                          extraPlugins: [uploadPlugin],
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                            "|",
                            "insertTable",
                            "tableColumn",
                            "tableRow",
                            "mergeTableCells",
                            "|",
                            "imageUpload",
                            "mediaEmbed",
                            "|",
                            "undo",
                            "redo",
                            "|",
                            "imageTextAlternative",
                            "imageStyle:full",
                            "imageStyle:side",
                            "imageRemove", // Add this for image removal
                          ],
                          image: {
                            toolbar: [
                              "imageStyle:full",
                              "imageStyle:side",
                              "|",
                              "imageTextAlternative",
                              "imageRemove",
                              "imageUpload",
                              "imageDelete",
                            ],
                          },
                        }}
                      />
                    )}
                  />
                  {errors.bankRules && (
                    <span className="text-danger">
                      {errors.bankRules.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-center py-2">
                <button type="submit" className="btn btn-gradient-info ">
                  Add Bank
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {loading ? <Loader /> : null}
    </div>
  );
};

export default BankForm;
