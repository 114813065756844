import React from 'react'

const ProjectCost = () => {
  return (
    <div>
     Project Cost 
    </div>
  )
}

export default ProjectCost;