import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DeleteJobRole, getRole, postJobRole, UpdateJobRole } from "@Networking/APIs/Employee/EmployeeApi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../masterForm.css";


const MySwal = withReactContent(Swal);


const JobRoleForm = () => {

  const [selectedName, setRole] = useState(null);
  const [nameInput, setNameInput] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm();
  
    const { type } = useParams();
  
    useEffect(() => {
      dispatch(getRole());
    }, []);
  
    const handleBack = () => {
      navigate(-1);
    };
  
    

    const roleData = useSelector((state) => state.EmployeesSlice);
  
    const onSubmit = async (data) => {

        const newJobRole = {
          name: data.name,
        };
        
    
        try {
          await dispatch(postJobRole(newJobRole));
          await dispatch(getRole());
          reset();
        } catch (error) {
        }
      };
    
      const handleEdit = (roleData) => {
        setRole(roleData);
        setNameInput(roleData.name);
        openEditModal(roleData);
      };
    
      const handleSubmitEdit = async (id, newName) => {
        const updateData = { id, name: newName }; // No need to access newName.name
        try {
          await dispatch(UpdateJobRole(updateData));
          await dispatch(getRole());
        } catch (error) {
          MySwal.fire("Error!", "Something went wrong while updating.", "error");
        }
      };
      
    
      const openEditModal = (roleData) => {
        MySwal.fire({
          title: "Edit Job Role",
          input: "text",
          inputValue: roleData.name,
          inputPlaceholder: 'Enter new name',
          showCancelButton: true,
          confirmButtonText: 'Update',
          cancelButtonText: 'Cancel',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          customClass: {
            title: 'custom-title',
            input: 'custom-input-size',
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-cancel-button'
          },
          preConfirm: () => {
            const newName = Swal.getInput().value.trim();
            if (!newName) {
              Swal.showValidationMessage("Please enter a new name");
            }
            return newName;
          },
        }).then((result) => {
          if (result.isConfirmed) {
            handleSubmitEdit(roleData.id, result.value);
          }
        });
      };

  
      const handleDelete = (roleData) => {
        const isConfirmed = window.confirm("Are you sure? You won't be able to revert this!");
      
        if (isConfirmed) {
          dispatch(DeleteJobRole(roleData.id));
          dispatch(getRole());
        }
      }   

    return (
        <>
      <div className="container-fluid">

      <div className="row">
        <div className="col-md-12 col-sm-12">
          <h6 className="">
            <i class="fa-solid fa-list me-2"></i>HRM / Employee Master's / Job Role
          </h6>
        </div>
      </div>

      <div className="m-1">

         <button
          type="button"
          className="btn btn-secondary mb-3"
          onClick={handleBack}
        >
          <i className="fa fa-arrow-left me-2"></i>
          Back
        </button>
        </div>

        <div className="card">
          <div className="card-body">
              <div className="row p-3">
                <div className="col-md-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row d-flex align-items-center">
                    <div className="col-md-3">
                      <h6>Job Role:</h6>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="Enter Job Role"
                        className="form-control"
                        id="name"
                        {...register("name", {
                          required: "Job Role is required",
                        })}
                      />
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </div>
                    <div className="col-md-3">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                  </form>
                  <div className="row mt-3 border rounded">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Job Role</th>
                              <th className="text-end">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {roleData?.roleData.length > 0 ? (
                              roleData?.roleData.map((roleData) => (
                                <tr key={roleData.id}>
                                  <td>{roleData.name}</td>
                                  <td>
                                    <div className="d-flex justify-content-end">
                                      <button
                                        type="button"
                                        className="btn btn-sm rounded btn-primary me-2"
                                        onClick={() => handleEdit(roleData)}
                                      >
                                        <i className="fa fa-edit"></i>
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-sm rounded btn-danger"
                                        onClick={() =>
                                          handleDelete(roleData)
                                        }
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="2" className="text-center">
                                  No Job Role available.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      </>
    );
  };

  export default JobRoleForm;