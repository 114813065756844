import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {BASE_URL,getMonth,monthAll,salary, SalaryEdit, SalaryStore, SalaryUpdate, SalaryDetailsId, SalarySlip} from '@Networking/APIs/NWConfig';

export const getAllMonth = createAsyncThunk(
  'get/getAllMonth',
  async () => {  
    // Using template literals to build the URL
    let url = BASE_URL + monthAll;  // Corrected line
    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const getDayHours = createAsyncThunk(
  'get/getDayHours',
  async (month) => {  
    let url = BASE_URL + getMonth;  
    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        params:{
          month: month
        },
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const getSalary = createAsyncThunk(
    'get/getSalary',
    async () => {  
     
      // Using template literals to build the URL
      let url = BASE_URL + salary;  
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );


  export const SalaryStoreApi = createAsyncThunk(
    "post/SalaryStoreApi",
    async(post) => {
      
        let url = BASE_URL + SalaryStore;
        const token = localStorage.getItem("authToken");
  
        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;
                return Promise.resolve(response);
        } catch(response) {
            return response;
        }
    }
  );


  export const getSalaryById = createAsyncThunk(
    'get/getSalaryById',
    async (id) => {  
     
      // Using template literals to build the URL
      let url = BASE_URL + SalaryEdit + id;  
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );

  export const SalaryUpdateApi = createAsyncThunk(
    "put/SalaryUpdateApi",
    async(post) => {
      
        let url = BASE_URL + SalaryUpdate + post.id;
        
        const token = localStorage.getItem("authToken");
  
        try {
            const response = (await axios.put(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;
            
                return Promise.resolve(response);
        } catch(response) {
            return response;
        }
    }
  );

  export const SalaryDetailsById = createAsyncThunk(
    'get/SalaryDetailsById',
    async (post) => {  
     
      // Using template literals to build the URL
      let url = BASE_URL + SalaryDetailsId;  
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          params: {
            month : post.month,
            id : post.id
          },
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;

        
        return Promise.resolve(response);
      } catch (error) {
        return error;
      }
    },
  );

  export const GenerateSalarySlip = createAsyncThunk(
    'get/GenerateSalarySlip',
    async (post) => {  
     
      // Using template literals to build the URL
      let url = BASE_URL + SalarySlip + post;  
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          responseType: 'blob', // Set response type to blob for binary data
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;

        
        return response;
      } catch (error) {
        return error;
      }
    },
  );