// src/components/ApplyLeave.js
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getEmployees } from "@Networking/APIs/Employee/EmployeeApi";

const SalaryMaster = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const employeeData = useSelector(
    (state) => state.EmployeesSlice.EmployeesData
  );
  useEffect(() => {
    dispatch(getEmployees());
  }, [dispatch]);

  const onSubmit = async (data) => {
    const newSalary = {
      employee_id: data.employee_id,
      annual_salary: data.annual_salary,
      joining_date: data.joining_date,
      salary_revision_date: data.salary_revision_date,
      note: data.note,
    };

    try {
      // await dispatch(postJobRole(newJobRole));
      reset();
    } catch (error) {}
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="">
              <i class="fa-solid fa-list me-2"></i>HRM / Salary Master
            </h6>
          </div>
        </div>

        <div className="card bg-gradient-info rounded-top-2 p-2 mb-4">
          <div className="row d-flex align-items-center">
            <div className="col-md-12 col-sm-12">
              <h3 className="page-title m-0  text-light">
                <span className="mx-2 text-primary">
                  <i className="mdi mdi-file  text-light"></i>
                </span>
                Salary Master
              </h3>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mt-3">
                  <div className="col-md-6 mb-2">
                    <label className="fw-bold" htmlFor="gender">
                      Employee Name
                    </label>
                    <select
                      className="inputText"
                      {...register("employee_id", {
                        required: "Employee is required",
                      })}
                    >
                      <option className="d-none" value="">
                        Select Employee
                      </option>
                      {employeeData?.employee_list?.map((employee) => (
                        <option key={employee.id} value={employee.id}>
                          {employee.first_name} {employee.last_name || ""}
                        </option>
                      ))}
                    </select>
                    {errors.employee_id && (
                      <div className="text-danger">
                        {errors.employee_id.message}
                      </div>
                    )}
                  </div>

                  <div className="col-md-6">
                    <label>Annual Salary</label>
                    <input
                      type="text"
                      placeholder="Enter Job Role"
                      className="inputText"
                      {...register("annual_salary", {
                        required: "Annual Salary is required",
                      })}
                    />
                    {errors.annual_salary && (
                      <span className="text-danger">
                        {errors.annual_salary.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <label>Joining Date</label>
                    <input
                      type="text"
                      placeholder="Enter Job Role"
                      className="inputText"
                      {...register("joining_date", {
                        required: "Joining Date is required",
                      })}
                    />
                    {errors.joining_date && (
                      <span className="text-danger">
                        {errors.joining_date.message}
                      </span>
                    )}
                  </div>

                  <div className="col-md-6">
                    <label> Salary Revision Date </label>
                    <input
                      type="text"
                      placeholder="Enter Job Role"
                      className="inputText"
                      {...register("salary_revision_date", {
                        required: "Salary Revision is required",
                      })}
                    />
                    {errors.salary_revision_date && (
                      <span className="text-danger">
                        {errors.salary_revision_date.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row mt-3">
                  <label>Remarks</label>
                  <div className="col-md-12">
                    <textarea
                      placeholder="Enter Job Role"
                      className="inputText"
                      {...register("note", {
                        required: "Remarks is required",
                      })}
                    ></textarea>
                    {errors.note && (
                      <span className="text-danger">{errors.note.message}</span>
                    )}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12 d-flex justify-content-center">
                    <button
                      type="submit"
                      className="btn btn-primary w-50 rounded-pill"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalaryMaster;
