
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DeleteOnboardSettings, getOnboardingSetting, postOnboardingSetting, updateOnboardSettings } from "@Networking/APIs/Employee/EmployeeApi";
import "../masterForm.css";

const MySwal = withReactContent(Swal);

const OnboardingSettingsForm = () => {

  const [selectedName, setSelectedName] = useState(null);
  const [nameInput, setNameInput] = useState("");
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { type } = useParams();

  useEffect(() => {
    dispatch(getOnboardingSetting());
  }, []);

  const onboardingSettingData = useSelector((state) => state.EmployeesSlice);

  const onSubmit = async (data) => {
    const newOnboardingSettings = { name: data.name };
    try {
      await dispatch(postOnboardingSetting(newOnboardingSettings));
      await dispatch(getOnboardingSetting());
      reset();
    } catch (error) {
    }
  };

  const handleEdit = (onboard) => {
    setSelectedName(onboard);
    setNameInput(onboard.name);
    openEditModal(onboard);
  };

  const handleSubmitEdit = async (id, newName) => {
    const updateData = { id, name: newName }; // No need to access newName.name
    try {
      await dispatch(updateOnboardSettings(updateData));
      await dispatch(getOnboardingSetting());
    } catch (error) {
      MySwal.fire("Error!", "Something went wrong while updating.", "error");
    }
  };
  

  const openEditModal = (onboard) => {
    MySwal.fire({
      title: "Edit Onboarding Settings",
      input: "text",
      inputValue: onboard.name,
      inputPlaceholder: 'Enter new name',
      showCancelButton: true,
      confirmButtonText: 'Update',
      cancelButtonText: 'Cancel',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      preConfirm: () => {
        const newName = Swal.getInput().value.trim();
        if (!newName) {
          Swal.showValidationMessage("Please enter a new name");
        }
        return newName;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmitEdit(onboard.id, result.value);
      }
    });
  };


  const handleDelete = (onboard) => {
    const isConfirmed = window.confirm("Are you sure? You won't be able to revert this!");
  
    if (isConfirmed) {
      dispatch(DeleteOnboardSettings(onboard.id));
      dispatch(getOnboardingSetting());
    }
  }
  


  return (
    <>
      <div className="container-fluid">

<div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="">
              <i class="fa-solid fa-list me-2"></i>HRM / Employee Master's /
              Onboard settings
            </h6>
          </div>
        </div>

        <div className="m-1">
      <button type="button" className="btn btn-secondary mb-3" onClick={() => navigate(-1)}>
        <i className="fa fa-arrow-left me-2"></i> Back
      </button>
      </div>
        <div className="card">
          <div className="card-body">
          <div className="row p-3">
          <div className="col-md-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row d-flex align-items-center">
                <div className="col-md-3">
                  <h6>Onboarding Settings:</h6>
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="Enter Onboarding Settings"
                    className="form-control"
                    id="name"
                    {...register("name", { required: "Onboarding Settings is required" })}
                  />
                  {errors.name && <span className="text-danger">{errors.name.message}</span>}
                </div>
                <div className="col-md-3">
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </div>
            </form>

            <div className="row mt-3 border rounded">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Onboarding Settings</th>
                        <th className="text-end">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {onboardingSettingData?.OnboardingSettingsData?.length > 0 ? (
                        onboardingSettingData.OnboardingSettingsData.map((onboard) => (
                          <tr key={onboard.id}>
                            <td>{onboard.name}</td>
                            <td>
                              <div className="d-flex justify-content-end">
                                <button
                                  type="button"
                                  className="btn btn-sm rounded btn-primary me-2"
                                  onClick={() => handleEdit(onboard)}
                                >
                                  <i className="fa fa-edit"></i>
                                </button>
                                <button type="button" className="btn btn-sm rounded btn-danger"
                                onClick={() =>
                                  handleDelete(onboard)
                                }>
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="2" className="text-center">No Onboarding Settings available.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default OnboardingSettingsForm;