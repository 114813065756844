import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewEmployeData } from "@Networking/APIs/Employee/EmployeStoreApi";

const EmployeeView = () => {

  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const ViewEmployee = useSelector((state) => state.EmployeesSlice);
  const [empId, setEmpId] = useState(null);

  // useEffect(() => {
  //   setEmpId(id);
  // }, [ViewEmployee]);

  useEffect(() => {
    if (id) {
      dispatch(viewEmployeData(id)); // Dispatch to fetch employee data
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (ViewEmployee) {
    }
  }, [ViewEmployee]);


  const employee = location.state?.employee;

  return (
    <>
      <div className="card text-white rounded-top-2 p-3 mx-2">
        <div className="row d-flex align-items-center">
          <div className="col-md-6 col-sm-12">
            <h3 className="page-title m-0">
              <span className="mx-2 text-primary">
                <i className="mdi mdi-file"></i>
              </span>
              EMPLOYEE VIEW -
              <span className="text-primary mx-2">
                {employee?.first_name} {employee?.last_name}
              </span>
            </h3>
          </div>
        </div>
      </div>
      <h5 className="text-primary mb-3"></h5>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 grid-margin">
            <div className="card">
              <div className="card-header btn-gradient-info text-white text-center">
                <h5> Personal Details</h5>
              </div>
              <div>
                {/* <h5 class="p-3 border border-secondary bg-light text-center rounded">
                      Personal Details
                    </h5> */}
                <div className="p-3">
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <label className="form-label" htmlFor="fullName">
                        Full Name
                      </label>
                      <div className="input-group shadow-sm">
                        <span className="input-group-text bg-white border-end-0">
                          <i class="fa-solid fa-address-card"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control border-start-0"
                          id="fullName"
                          aria-label="Full Name"
                          aria-describedby="fullName"
                          value={
                            employee
                              ? `${employee.first_name ? employee.first_name : ""
                                } 
                                 ${employee.middle_name
                                  ? employee.middle_name
                                  : ""
                                } 
                                 ${employee.last_name ? employee.last_name : ""
                                }`
                                .replace(/\s+/g, " ")
                                .trim()
                              : ""
                          }
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="col-md-4 mb-3">
                      <label className="form-label" htmlFor="gender">
                        Gender
                      </label>
                      <div className="input-group shadow-sm">
                        <span className="input-group-text bg-white border-end-0">
                          <i class="fa-solid fa-person-half-dress"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control border-start-0"
                          id="gender"
                          aria-label="Date of Anniversary"
                          aria-describedby="gender"
                          value={employee ? employee.gender : ""}
                          readOnly
                        />
                      </div>
                    </div>

                    <div class="col-md-4 mb-3">
                      <label class="form-label" htmlFor="jobRole">
                        Job Role
                      </label>
                      <div class="input-group shadow-sm">
                        <span class="input-group-text bg-white border-end-0">
                          <i class="fa-regular fa-flag"></i>
                        </span>
                        <input
                          type="text"
                          class="form-control border-start-0"
                          id="jobRole"
                          aria-label="Job Role"
                          value={employee ? employee.job_role : ""}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <label className="form-label" htmlFor="workCountry">
                        Date of Birth
                      </label>
                      <div className="input-group shadow-sm">
                        <span className="input-group-text bg-white border-end-0">
                          <i class="fa-solid fa-cake-candles"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control border-start-0"
                          id="workCountry"
                          aria-label="Work Country"
                          aria-describedby="work-country"
                          value={employee ? employee.dob : ""}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="col-md-4 mb-3">
                      <label className="form-label" htmlFor="full-name">
                        Nationality
                      </label>
                      <div className="input-group shadow-sm">
                        <span className="input-group-text bg-white border-end-0">
                          <i class="fa-solid fa-building-flag"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control border-start-0"
                          id="full-name"
                          name="fullName"
                          aria-label="Full Name"
                          aria-describedby="full-name"
                          value={employee ? employee.nationality : ""}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="col-md-4 mb-3">
                      <label className="form-label" htmlFor="anniversary-date">
                        Contact
                      </label>
                      <div className="input-group shadow-sm">
                        <span className="input-group-text bg-white border-end-0">
                          <i class="fa-solid fa-address-book"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control border-start-0"
                          id="anniversary-date"
                          name="anniversaryDate"
                          aria-label="Date of Anniversary"
                          aria-describedby="anniversary-date"
                          value={employee ? employee.contact : ""}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <label className="form-label" htmlFor="work-country">
                        Alternate Number
                      </label>
                      <div className="input-group shadow-sm">
                        <span className="input-group-text bg-white border-end-0">
                          <i class="fa-regular fa-address-book"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control border-start-0"
                          id="work-country"
                          name="workCountry"
                          aria-label="Work Country"
                          aria-describedby="work-country"
                          value={employee ? employee.alternate_number : ""}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="col-md-4 mb-3">
                      <label className="form-label" htmlFor="full-name">
                        Personal Email
                      </label>
                      <div className="input-group shadow-sm">
                        <span className="input-group-text bg-white border-end-0">
                          <i class="fa-solid fa-envelope"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control border-start-0"
                          id="full-name"
                          name="fullName"
                          aria-label="Full Name"
                          aria-describedby="full-name"
                          value={employee ? employee.personal_email : ""}
                          readOnly
                        />
                      </div>
                    </div>


                    <div class="col-md-4 mb-3">
                      <label class="form-label" htmlFor="work-country">
                        Work Email
                      </label>
                      <div class="input-group shadow-sm">
                        <span class="input-group-text bg-white border-end-0">
                          <i class="fa-regular fa-envelope"></i>
                        </span>
                        <input
                          type="text"
                          class="form-control border-start-0"
                          id="work-country"
                          name="workCountry"
                          aria-label="Work Country"
                          value={employee ? employee.work_email : ""}
                          readOnly
                        />
                      </div>
                    </div>

                  </div>
                  <div className="row border p-3">
                    <div className="col-md-6 mb-3 border-end">
                      <div className="row">
                        <h6>Current Address</h6>
                        <div className="col-md-6 mb-3">
                          <label className="form-label" htmlFor="work-country">
                            Country
                          </label>
                          <div className="input-group shadow-sm">
                            <span className="input-group-text bg-white border-end-0">
                              <i class="fa-solid fa-earth-americas"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control border-start-0"
                              id="work-country"
                              name="workCountry"
                              aria-label="Work Country"
                              aria-describedby="work-country"
                              value={employee ? employee.country : ""}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label" htmlFor="full-name">
                            State
                          </label>
                          <div className="input-group shadow-sm">
                            <span className="input-group-text bg-white border-end-0">
                              <i class="fa-solid fa-sign-hanging"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control border-start-0"
                              id="full-name"
                              name="fullName"
                              aria-label="Full Name"
                              aria-describedby="full-name"
                              value={employee ? employee.state : ""}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label
                            className="form-label"
                            htmlFor="anniversary-date"
                          >
                            City
                          </label>
                          <div className="input-group shadow-sm">
                            <span className="input-group-text bg-white border-end-0">
                              <i class="fa-solid fa-city"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control border-start-0"
                              id="anniversary-date"
                              name="anniversaryDate"
                              aria-label="Date of Anniversary"
                              aria-describedby="anniversary-date"
                              value={employee ? employee.city : ""}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label
                            className="form-label"
                            htmlFor="anniversary-date"
                          >
                            Pincode
                          </label>
                          <div className="input-group shadow-sm">
                            <span className="input-group-text bg-white border-end-0">
                              <i class="fa-solid fa-map-pin"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control border-start-0"
                              id="anniversary-date"
                              name="anniversaryDate"
                              aria-label="Date of Anniversary"
                              aria-describedby="anniversary-date"
                              value={employee ? employee.pincode : ""}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="col-md-12 mb-3">
                          <label
                            className="form-label"
                            htmlFor="anniversary-date"
                          >
                            Address
                          </label>
                          <div className="input-group shadow-sm">
                            <span className="input-group-text bg-white border-end-0">
                              <i class="fa-solid fa-location-crosshairs"></i>
                            </span>
                            <textarea
                              className="form-control border-start-0"
                              id="anniversary-date"
                              name="anniversaryDate"
                              aria-label="Date of Anniversary"
                              aria-describedby="anniversary-date"
                              value={employee ? employee.address : ""}
                              readOnly
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3 border-start">
                      <h6>Permanent Address</h6>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label" htmlFor="work-country">
                            Country
                          </label>
                          <div className="input-group shadow-sm">
                            <span className="input-group-text bg-white border-end-0">
                              <i class="fa-solid fa-earth-americas"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control border-start-0"
                              id="work-country"
                              name="workCountry"
                              aria-label="Work Country"
                              aria-describedby="work-country"
                              value={employee ? employee.country_1 : ""}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label" htmlFor="full-name">
                            State
                          </label>
                          <div className="input-group shadow-sm">
                            <span className="input-group-text bg-white border-end-0">
                              <i class="fa-solid fa-sign-hanging"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control border-start-0"
                              id="full-name"
                              name="fullName"
                              aria-label="Full Name"
                              aria-describedby="full-name"
                              value={employee ? employee.state_1 : ""}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label
                            className="form-label"
                            htmlFor="anniversary-date"
                          >
                            City
                          </label>
                          <div className="input-group shadow-sm">
                            <span className="input-group-text bg-white border-end-0">
                              <i class="fa-solid fa-city"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control border-start-0"
                              id="anniversary-date"
                              name="anniversaryDate"
                              aria-label="Date of Anniversary"
                              aria-describedby="anniversary-date"
                              value={employee ? employee.city_1 : ""}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label
                            className="form-label"
                            htmlFor="anniversary-date"
                          >
                            Pincode
                          </label>
                          <div className="input-group shadow-sm">
                            <span className="input-group-text bg-white border-end-0">
                              <i class="fa-solid fa-map-pin"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control border-start-0"
                              id="anniversary-date"
                              name="anniversaryDate"
                              aria-label="Date of Anniversary"
                              aria-describedby="anniversary-date"
                              value={employee ? employee.pincode_1 : ""}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="col-md-12 mb-3">
                          <label
                            className="form-label"
                            htmlFor="anniversary-date"
                          >
                            Address
                          </label>
                          <div className="input-group shadow-sm">
                            <span className="input-group-text bg-white border-end-0">
                              <i class="fa-solid fa-location-crosshairs"></i>
                            </span>
                            <textarea
                              type="text"
                              className="form-control border-start-0"
                              id="anniversary-date"
                              name="anniversaryDate"
                              aria-label="Date of Anniversary"
                              aria-describedby="anniversary-date"
                              value={employee ? employee.permanent_address : ""}
                              readOnly
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-4">
              <div className="card-header btn-gradient-info text-white text-center mb-4">
                <h5>Job Details</h5>
              </div>
              <div className="p-3">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="work-location">
                      Work Location
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-location-dot"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="work-location"
                        name="workLocation"
                        aria-label="Work Location"
                        value={employee ? employee.location : ""}
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="work-country">
                      Work Country
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-regular fa-flag"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="work-country"
                        name="workCountry"
                        aria-label="Work Country"
                        value={employee ? employee.work_country : ""}
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="employee-number">
                      Employee Number
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-user-tie"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="employee-number"
                        name="employeeNumber"
                        aria-label="Employee Number"
                        value={employee ? employee.employee_number : ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="time-type">
                      Time Type
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-business-time"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="time-type"
                        name="timeType"
                        aria-label="Time Type"
                        value={employee ? employee.time_type : ""}
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="joining-date">
                      Joining Date
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-calendar-plus"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="joining-date"
                        name="joiningDate"
                        aria-label="Joining Date"
                        value={employee ? employee.joining_date : ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="work-type">
                      Work Type
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-briefcase"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="work-type"
                        name="workType"
                        aria-label="Work Type"
                        value={employee ? employee.work_type : ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="reporting-manager">
                      Reporting Manager
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-people-roof"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="reporting-manager"
                        name="reportingManager"
                        aria-label="Reporting Manager"
                        value={employee ? employee.gender : ""}
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="team-leader">
                      Reporting Team Leader
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-calendar-check"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="team-leader"
                        name="teamLeader"
                        aria-label="Reporting Team Leader"
                        value={employee ? employee.gender : ""}
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="employee-status">
                      Employee Status
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-signal"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="employee-status"
                        name="employeeStatus"
                        aria-label="Employee Status"
                        value={employee ? employee.employee_status : ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="probation-policy">
                      Probation Policy
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-building-shield"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="probation-policy"
                        name="probationPolicy"
                        aria-label="Probation Policy"
                        value={employee ? employee.probation_policy : ""}
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="no-dues-date">
                      Job Title
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-chalkboard-user"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="no-dues-date"
                        name="noDuesDate"
                        aria-label="No Dues Date"
                        value={employee ? employee.job_title : ""}
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="leaving-date">
                      Leave Balance
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-cash-register"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="leaving-date"
                        name="leavingDate"
                        aria-label="Leaving Date"
                        value={employee ? employee.leave_balance : ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 mb-3">
                    <label class="form-label" htmlFor="leaving-date">
                      Remarks
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-marker"></i>
                      </span>
                      <textarea
                        class="form-control border-start-0"
                        id="leaving-date"
                        rows="2"
                        name="leavingDate"
                        aria-label="Leaving Date"
                        value={employee ? employee.remark : ""}
                        readOnly
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-4">
              <div className="card-header btn-gradient-info text-white text-center mb-4">
                <h5>Work Details</h5>
              </div>
              <div className="p-3">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="work-location">
                      Work Anniversary
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-regular fa-flag"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="work-location"
                        name="workLocation"
                        aria-label="Work Location"
                        value={employee ? employee.joining_date : ""}
                        readOnly
                      />
                    </div>
                  </div>


                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="employee-number">
                      Onboarding Settings
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-person-snowboarding"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="employee-number"
                        name="employeeNumber"
                        aria-label="Employee Number"
                        value={employee ? employee.onboarding_setting : ""}
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="reporting-manager">
                      Department
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-gopuram"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="reporting-manager"
                        name="reportingManager"
                        aria-label="Reporting Manager"
                        value={employee ? employee.department : ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="job-role">
                      Shift
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-clock"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="job-role"
                        name="jobRole"
                        aria-label="Job Role"
                        value={employee ? employee.shift : ""}
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="time-type">
                      Attendance Number
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-clipboard-user"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="time-type"
                        name="timeType"
                        aria-label="Time Type"
                        value={employee ? employee.attendance_number : ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>


              </div>
            </div>

            <div className="card mt-4">
              <div className="card-header btn-gradient-info text-white text-center">
                <h5>Compensation</h5>
              </div>

              <div className="p-3">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="work-location">
                      Bank Name
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-building-columns"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="work-location"
                        name="workLocation"
                        aria-label="Work Location"
                        value={employee ? employee.bank : ""}
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="work-country">
                      Account Holder Name
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-hand"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="work-country"
                        name="workCountry"
                        aria-label="Work Country"
                        value={employee ? employee.account_name : ""}
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="employee-number">
                      Account Number
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-file-invoice-dollar"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="employee-number"
                        name="employeeNumber"
                        aria-label="Employee Number"
                        value={employee ? employee.account_number : ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4 mb-3">
                    <label class="form-label" htmlFor="job-role">
                      IFSC Code
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-star-of-life"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-start-0"
                        id="job-role"
                        name="jobRole"
                        aria-label="Job Role"
                        value={employee ? employee.ifsc_code : ""}
                        readOnly
                      />
                    </div>
                  </div>


                </div>

                <div class="row">
                  <div class="col-md-12 mb-3">
                    <label class="form-label" htmlFor="team-leader">
                      Salary Remarks
                    </label>
                    <div class="input-group shadow-sm">
                      <span class="input-group-text bg-white border-end-0">
                        <i class="fa-solid fa-marker"></i>
                      </span>
                      <textarea
                        class="form-control border-start-0"
                        id="team-leader"
                        name="teamLeader"
                        aria-label="Reporting Team Leader"
                        value={employee ? employee.salary_remark : ""}
                        readOnly
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-4">
              <div className="card-header btn-gradient-info text-white text-center">
                <h5>Documents</h5>
              </div>

              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Document Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {ViewEmployee?.ViewEmployee?.documents?.map((doc, index) => (
                    <tr key={doc.id}>
                      <td>{index + 1}</td>
                      <td>{doc.doc_name}</td>
                      <td>
                        <a href={doc.doc_file} target="_blank" rel="noopener noreferrer">
                          <button className="btn btn-danger">
                            <i className="fa fa-file"></i> View
                          </button>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeView;
