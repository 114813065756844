import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { DeleteAttendanceRecord, DeleteHoliday, DeleteWorkingHours, getAttendanceRecord, getAttendanceRecordEdit, getHolidays, getWorkingHours, postAttendanceRecord, postAttendanceRecordUpdate, postHolidayStore, postHolidayUpdate, postWorkingHours, postWorkingHoursUpdate } from '@Networking/APIs/AttendanceRecord/attendanceRecordApi';

const AttendanceRecordSlice = createSlice({
  name: 'AttendanceRecordSlice',
  initialState: {
    loading: false,
    WorkingHoursData:[],
    HolidaysData:[],
    AttendanceRecordData:[],
    AttendanceRecordDataEdit:[],
    success: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(getWorkingHours.pending, state => {
      state.loading = true;
    });
    builder.addCase(getWorkingHours.fulfilled, (state, action) => {
      state.WorkingHoursData = action.payload;
    });
    builder.addCase(getWorkingHours.rejected, state => {
      state.loading = false;
    });

    // Holidays
    builder.addCase(getHolidays.pending, state => {
      state.loading = true;
    });
    builder.addCase(getHolidays.fulfilled, (state, action) => {
      state.HolidaysData = action.payload;
    });
    builder.addCase(getHolidays.rejected, state => {
      state.loading = false;
    });

    // Holiday Store
    builder.addCase(postHolidayStore.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postHolidayStore.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
      
     
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.message)
      }
      
    });
    builder.addCase(postHolidayStore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Add Holiday failed.';
    });

       // Update Holidays
       builder.addCase(postHolidayUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(postHolidayUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.message = action.payload;
        
        if(action.payload.status == true){
          toast.success(action.payload.message)
        }
        else{
          toast.error(action.payload.message)
        }
        
      });
      builder.addCase(postHolidayUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Add Working Hours  failed.';
      });
  

      //Delete  Holiday
      builder.addCase(DeleteHoliday.pending, (state) => {
        state.loading3 = true;
        state.error = null;
      });
      builder.addCase(DeleteHoliday.fulfilled, (state, action) => {
        state.loading3 = false;
        state.success = true;
        state.DeleteClient = action.payload;
  
        if (action.payload.status == true) {
          toast.success(action.payload.message);
        } else {
          toast.error(action.payload.message);
        }
      });
      builder.addCase(DeleteHoliday.rejected, (state, action) => {
        state.loading3 = false;
        state.error = action.error.message || "Delete failed.";
      });




    // Working Hours Store
    builder.addCase(postWorkingHours.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postWorkingHours.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
      
     
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.message)
      }
      
    });
    builder.addCase(postWorkingHours.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Add Working Hours  failed.';
    });


   // Update Working Hours
   builder.addCase(postWorkingHoursUpdate.pending, (state) => {
    state.loading = true;
    state.error = null;
  });
  builder.addCase(postWorkingHoursUpdate.fulfilled, (state, action) => {
    state.loading = false;
    state.success = true;
    state.message = action.payload;
    
    if(action.payload.status == true){
      toast.success(action.payload.message)
    }
    else{
      toast.error(action.payload.message)
    }
    
  });
  builder.addCase(postWorkingHoursUpdate.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message || 'Add Working Hours  failed.';
  });



     //Delete  Holiday
     builder.addCase(DeleteWorkingHours.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(DeleteWorkingHours.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.DeleteClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(DeleteWorkingHours.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Delete failed.";
    });



    // Attendance Record Store
    builder.addCase(postAttendanceRecord.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postAttendanceRecord.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
      
     
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.message)
      }
      
    });
    builder.addCase(postAttendanceRecord.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Add Working Hours  failed.';
    });


    // Attendance Record List
    builder.addCase(getAttendanceRecord.pending, state => {
      state.loading = true;
    });
    builder.addCase(getAttendanceRecord.fulfilled, (state, action) => {
      state.AttendanceRecordData = action.payload;
    });
    builder.addCase(getAttendanceRecord.rejected, state => {
      state.loading = false;
    });

      //Edit Attendance Record List
      builder.addCase(getAttendanceRecordEdit.pending, state => {
        state.loading = true;
      });
      builder.addCase(getAttendanceRecordEdit.fulfilled, (state, action) => {
        state.AttendanceRecordDataEdit = action.payload;
      });
      builder.addCase(getAttendanceRecordEdit.rejected, state => {
        state.loading = false;
      });
  

    // Update Attendance Record
    builder.addCase(postAttendanceRecordUpdate.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postAttendanceRecordUpdate.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
      
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.message)
      }
      
    });
    builder.addCase(postAttendanceRecordUpdate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Add Working Hours  failed.';
    });


       //Delete  Attendance Record
       builder.addCase(DeleteAttendanceRecord.pending, (state) => {
        state.loading3 = true;
        state.error = null;
      });
      builder.addCase(DeleteAttendanceRecord.fulfilled, (state, action) => {
        state.loading3 = false;
        state.success = true;
        state.DeleteClient = action.payload;
  
        if (action.payload.status == true) {
          toast.success(action.payload.message);
        } else {
          toast.error(action.payload.message);
        }
      });
      builder.addCase(DeleteAttendanceRecord.rejected, (state, action) => {
        state.loading3 = false;
        state.error = action.error.message || "Delete failed.";
      });


  },
});

export default AttendanceRecordSlice.reducer;