import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {BASE_URL, countries, states , cities, countrycode, getuser, currencyUrl} from '@Networking/APIs/NWConfig';
 
// Fetch USer Detials 
export const fetchUserData = createAsyncThunk(
  'get/fetchUserData',
  async () => {  
   
    let url = BASE_URL + getuser; 
    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url,{
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
       
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);
//Country Api
export const getcountries = createAsyncThunk(
    'get/getcountries',
    async () => {  
  
      let url = BASE_URL + countries; 
  
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );
  
//State Api
export const getstate = createAsyncThunk(
    'get/getstate',
    async (countryId) => {  
    
  
      let url = BASE_URL + states + countryId; 
  
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );
  

//City Api
  export const getcity = createAsyncThunk(
    'get/getcity',
    async (stateId) => {  
        let url = BASE_URL + cities + stateId; 
  
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );



  //country code
  export const getcountryCode = createAsyncThunk(
    'get/getcountryCode',
    async () => {  
        let url = BASE_URL + countrycode; 
  
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );
    //country code
    export const getcurrency = createAsyncThunk(
      'get/getcurrency',
      async () => {  
          let url = BASE_URL + currencyUrl; 
    
        const token = localStorage.getItem("authToken");
        try {
          const response = (await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token}`  
            }
          })).data;
          return Promise.resolve(response);
        } catch (error) {
          return Promise.reject(error);
        }
      },
    );