import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {addDepartment, addEmployeeStatus, addJobProfile, addJobRole, addOnboardSettings, addProbation, addShift, addTimeType, addWeeklyOff, addWorkLocation, addWorkType, BASE_URL,byTeamEmployee,deleteDepart,deleteJobProfile,deleteJobRole,deleteOnboardingSettings,deleteProbation,deleteShift,deleteStatusEmployee,deleteTypeTime,deleteTypeWork,deleteWeeklyOff,deleteWorkLocation,department,employeePersonalStore,employees ,employeesList,employeeStatus,jobProfile,manager,onboardingSettings,probation,role,shift,shiftUpdate,teamleader,timeType,updateDepart,updateJobProfile,updateJobRole,updateOnboadSettings,updateProbation,updateStatusEmployee,updateTypeTime,updateTypeWork,updateWeeklyOff,updateWorkLocation,weeklyOff,workLocation, workType} from '@Networking/APIs/NWConfig';
// import {BASE_URL, workLocation} from '@Networking/APIs/NWConfig';
import Cookies from 'js-cookie';


// Employee List
export const getEmployeeList = createAsyncThunk(
  'get/getEmployeeList',
  async () => {  
        let url = BASE_URL + employeesList;  // Corrected line
        const token = localStorage.getItem("authToken");

        try {
          const response = (await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token}`  
            }
          })).data;
          return Promise.resolve(response);
        } catch (error) {

          return Promise.reject(error);
        }
      },
   );

//Employee Filter
export const getEmployees = createAsyncThunk(
    'get/getEmployees',
    async () => {  
          let url = BASE_URL + employees;  // Corrected line
      
          const token = localStorage.getItem("authToken");

          try {
            const response = (await axios.get(url, {
              headers: {
                Authorization: `Bearer ${token}`  
              }
            })).data;
            return Promise.resolve(response);
          } catch (error) {

            return Promise.reject(error);
          }
        },
     );


// By Team Getting Employee
export const getByTeamEmployees = createAsyncThunk(
  'get/getByTeamEmployees',
  async () => {  
        let url = BASE_URL + byTeamEmployee;  // Corrected line
    
        const token = localStorage.getItem("authToken");

        try {
          const response = (await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token}`  
            }
          })).data;
          return Promise.resolve(response);
        } catch (error) {

          return Promise.reject(error);
        }
      },
   );


// Employee Store
export const postEmployeePersonalStore = createAsyncThunk(
  "post/postEmployeePersonalStore",
  async(post) => {

      let url = BASE_URL + employeePersonalStore;

      const token = localStorage.getItem("authToken");

      try {
          const response = (await axios.post(url, post, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;

              return Promise.resolve(response);
      } catch(error) {
          return Promise.reject(error);
      }
  }
);


// Work Location
export const getWorkLocation = createAsyncThunk(
  'get/getworkLocation',
  async () => {  

    // Using template literals to build the URL
    let url = BASE_URL + workLocation;  // Corrected line

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const postWorkLocation = createAsyncThunk(
  "post/postWorkLocation",
  async(post) => {

      let url = BASE_URL + addWorkLocation;

      const token = localStorage.getItem('authToken');

      try {
          const response = (await axios.post(url, post, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;

              return Promise.resolve(response);
      } catch(error) {
          return Promise.reject(error);
      }
  }
);

export const UpdateWorkLocation = createAsyncThunk(
  "put/UpdateWorkLocation",
  async (postData) => {
    const id = postData.id;
    const location = postData.location;
    const url = `${BASE_URL}${updateWorkLocation}/${id}`;

    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.put(url, { location }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const DeleteWorkLocation = createAsyncThunk(
  "post/DeleteWorkLocation",
  async (id) => {
    const url = `${BASE_URL}${deleteWorkLocation}/${id}`;

    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);




// Job Role
export const getRole = createAsyncThunk(
  'get/getRole',
  async () => {  

    // Using template literals to build the URL
    let url = BASE_URL + role ;  // Corrected line

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const postJobRole = createAsyncThunk(
  "post/postJobRole",
  async(post) => {

      let url = BASE_URL + addJobRole;

      const token = localStorage.getItem('authToken');

      try {
          const response = (await axios.post(url, post, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;

              return Promise.resolve(response);
      } catch(error) {
          return Promise.reject(error);
      }
  }
);

export const UpdateJobRole = createAsyncThunk(
  "put/UpdateJobRole",
  async (postData) => {
    const id = postData.id;
    const name = postData.name;
    const url = `${BASE_URL}${updateJobRole}/${id}`;

    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.put(url, { name }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const DeleteJobRole = createAsyncThunk(
  "post/DeleteJobRole",
  async (id) => {
    const url = `${BASE_URL}${deleteJobRole}/${id}`;
    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

// Job Profile
export const getJobProfile = createAsyncThunk(
  'get/getJobProfile',
  async () => {  

    // Using template literals to build the URL
    let url = BASE_URL + jobProfile ;  // Corrected line

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const postJobProfile = createAsyncThunk(
  "post/postJobProfile",
  async(post) => {

      let url = BASE_URL + addJobProfile;

      const token = localStorage.getItem('authToken');

      try {
          const response = (await axios.post(url, post, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;

              return Promise.resolve(response);
      } catch(error) {
          return Promise.reject(error);
      }
  }
);

export const UpdateJobProfile = createAsyncThunk(
  "put/UpdateJobProfile",
  async (postData) => {
    const id = postData.id;
    const profile_name = postData.profile_name;
    const url = `${BASE_URL}${updateJobProfile}/${id}`;

    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.put(url, { profile_name }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const DeleteJobProfile = createAsyncThunk(
  "post/DeleteJobProfile",
  async (id) => {
    const url = `${BASE_URL}${deleteJobProfile}/${id}`;
    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);


// Time Type
export const getTimeType = createAsyncThunk(
  'get/getTimeType',
  async () => {  

    // Using template literals to build the URL
    let url = BASE_URL + timeType ;  // Corrected line

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const postTimeType = createAsyncThunk(
  "post/postTimeType",
  async(post) => {

      let url = BASE_URL + addTimeType;

      const token = localStorage.getItem('authToken');

      try {
          const response = (await axios.post(url, post, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;

              return Promise.resolve(response);
      } catch(error) {
          return Promise.reject(error);
      }
  }
);

export const UpdateTimeType = createAsyncThunk(
  "put/UpdateTimeType",
  async (postData) => {
    const id = postData.id;
    const type_of_time = postData.type_of_time;
    const url = `${BASE_URL}${updateTypeTime}/${id}`;

    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.put(url, { type_of_time }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const DeleteTimeType = createAsyncThunk(
  "post/DeleteTimeType",
  async (id) => {
    const url = `${BASE_URL}${deleteTypeTime}/${id}`;
    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);



// Employee Status
export const getEmployeeStatus = createAsyncThunk(
  'get/getEmployeeStatus',
  async () => {  

    // Using template literals to build the URL
    let url = BASE_URL + employeeStatus ;  // Corrected line

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const postEmployeeStatus = createAsyncThunk(
  "post/postEmployeeStatus",
  async(post) => {

      let url = BASE_URL + addEmployeeStatus;

      const token = localStorage.getItem('authToken');

      try {
          const response = (await axios.post(url, post, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;

              return Promise.resolve(response);
      } catch(error) {
          return Promise.reject(error);
      }
  }
);

export const updateEmployeeStatus = createAsyncThunk(
  "put/updateEmployeeStatus",
  async (postData) => {
    const id = postData.id;
    const status = postData.status;
    const url = `${BASE_URL}${updateStatusEmployee}/${id}`;

    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.put(url, { status }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const DeleteEmployeeStatus = createAsyncThunk(
  "post/DeleteEmployeeStatus",
  async (id) => {
    const url = `${BASE_URL}${deleteStatusEmployee}/${id}`;
    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);



// Work Type
export const getWorkType = createAsyncThunk(
  'get/getWorkType',
  async () => {  

    // Using template literals to build the URL
    let url = BASE_URL + workType ;  // Corrected line

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const postWorkType = createAsyncThunk(
  "post/postWorkType",
  async(post) => {

      let url = BASE_URL + addWorkType;

      const token = localStorage.getItem('authToken');

      try {
          const response = (await axios.post(url, post, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;

              return Promise.resolve(response);
      } catch(error) {
          return Promise.reject(error);
      }
  }
);

export const updateWorkType = createAsyncThunk(
  "put/updateWorkType",
  async (postData) => {
    const id = postData.id;
    const type_of_work = postData.type_of_work;
    const url = `${BASE_URL}${updateTypeWork}/${id}`;

    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.put(url, { type_of_work }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const DeleteWorkType = createAsyncThunk(
  "post/DeleteWorkType",
  async (id) => {
    const url = `${BASE_URL}${deleteTypeWork}/${id}`;
    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);


// Probation
export const getProbation = createAsyncThunk(
  'get/getProbation',
  async () => {  

    // Using template literals to build the URL
    let url = BASE_URL + probation ;  // Corrected line

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const postProbation = createAsyncThunk(
  "post/postProbation",
  async(post) => {

      let url = BASE_URL + addProbation;

      const token = localStorage.getItem('authToken');

      try {
          const response = (await axios.post(url, post, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;

              return Promise.resolve(response);
      } catch(error) {
          return Promise.reject(error);
      }
  }
);

export const UpdateProbation = createAsyncThunk(
  "put/UpdateProbation",
  async (postData) => {
    const id = postData.id;
    const name = postData.name;
    const url = `${BASE_URL}${updateProbation}/${id}`;

    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.put(url, { name }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const DeleteProbation = createAsyncThunk(
  "post/DeleteProbation",
  async (id) => {
    const url = `${BASE_URL}${deleteProbation}/${id}`;
    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);



// Onboarding Settings
export const getOnboardingSetting = createAsyncThunk(
  'get/getOnboardingSetting',
  async () => {  

    // Using template literals to build the URL
    let url = BASE_URL + onboardingSettings ;  // Corrected line

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const postOnboardingSetting = createAsyncThunk(
  "post/postOnboardingSetting",
  async(post) => {

      let url = BASE_URL + addOnboardSettings;

      const token = localStorage.getItem('authToken');

      try {
          const response = (await axios.post(url, post, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;

              return Promise.resolve(response);
      } catch(error) {
          return Promise.reject(error);
      }
  }
);

export const updateOnboardSettings = createAsyncThunk(
  "put/updateOnboardSettings",
  async (postData) => {

    const id = postData.id;
    const name = postData.name;
    const url = `${BASE_URL}${updateOnboadSettings}/${id}`;

    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.put(url, { name }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const DeleteOnboardSettings = createAsyncThunk(
  "post/DeleteOnboardSettings",
  async (id) => {
    const url = `${BASE_URL}${deleteOnboardingSettings}/${id}`;
    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);


// Shift
export const getShift = createAsyncThunk(
  'get/getShift',
  async () => {  

    // Using template literals to build the URL
    let url = BASE_URL + shift ;  // Corrected line

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const postShift = createAsyncThunk(
  "post/postShift",
  async(post) => {

      let url = BASE_URL + addShift;

      const token = localStorage.getItem('authToken');

      try {
          const response = (await axios.post(url, post, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;

              return Promise.resolve(response);
      } catch(error) {
          return Promise.reject(error);
      }
  }
);

export const updateShift = createAsyncThunk(
  "put/updateShift",
  async (postData) => {
    const id = postData.id;
    const shift_name = postData.shift_name;
    const url = `${BASE_URL}${shiftUpdate}/${id}`;

    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.put(url, { shift_name }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const DeleteShift = createAsyncThunk(
  "post/DeleteShift",
  async (id) => {
    const url = `${BASE_URL}${deleteShift}/${id}`;
    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);


// Weekly Off
export const getWeeklyOff = createAsyncThunk(
  'get/getWeeklyOff',
  async () => {  

    // Using template literals to build the URL
    let url = BASE_URL + weeklyOff ;  // Corrected line

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const postWeeklyOff = createAsyncThunk(
  "post/postWeeklyOff",
  async(post) => {

      let url = BASE_URL + addWeeklyOff;

      const token = localStorage.getItem('authToken');

      try {
          const response = (await axios.post(url, post, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;

              return Promise.resolve(response);
      } catch(error) {
          return Promise.reject(error);
      }
  }
);

export const UpdateWeeklyOff = createAsyncThunk(
  "put/updateWeeklyOff",
  async (postData) => {
    const id = postData.id;
    const week_off_name = postData.week_off_name;
    const url = `${BASE_URL}${updateWeeklyOff}/${id}`;

    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.put(url, { week_off_name }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const DeleteWeeklyOff = createAsyncThunk(
  "post/DeleteWeeklyOff",
  async (id) => {
    const url = `${BASE_URL}${deleteWeeklyOff}/${id}`;
    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);


// Department
export const getDepartmemt = createAsyncThunk(
  'get/getDepartmemt',
  async () => {  

    // Using template literals to build the URL
    let url = BASE_URL + department ;  // Corrected line

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const postDepartmemt = createAsyncThunk(
  "post/postDepartmemt",
  async(post) => {

      let url = BASE_URL + addDepartment;

      const token = localStorage.getItem('authToken');

      try {
          const response = (await axios.post(url, post, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;

              return Promise.resolve(response);
      } catch(error) {
          return Promise.reject(error);
      }
  }
);

export const updateDepartment = createAsyncThunk(
  "put/updateDepartment",
  async (postData) => {
    const id = postData.id;
    const department_name = postData.department_name;
    const url = `${BASE_URL}${updateDepart}/${id}`;

    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.put(url, { department_name }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const DeleteDepartment = createAsyncThunk(
  "post/DeleteDepartment",
  async (id) => {
    const url = `${BASE_URL}${deleteDepart}/${id}`;
    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

// Get Manager
export const getManager = createAsyncThunk(
  'get/getManager',
  async () => {  

    // Using template literals to build the URL
    let url = BASE_URL + manager ;  // Corrected line

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

// Get Teamleader
export const getTeamleader = createAsyncThunk(
  'get/getTeamleader',
  async () => {  

    // Using template literals to build the URL
    let url = BASE_URL + teamleader ;  // Corrected line

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);