import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {announcementDelete, announcementEdit, announcementList, announcementStore, announcementUpdate, BASE_URL,} from '@Networking/APIs/NWConfig';
import Cookies from 'js-cookie';


export const postAnnouncementStore = createAsyncThunk(
    "post/postAnnouncementStore",
    async(post) => {
  
        let url = BASE_URL + announcementStore;
  
        const token = localStorage.getItem("authToken");
  
        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;
  
                return Promise.resolve(response);
        } catch(error) {
            return Promise.reject(error);
        }
    }
  );


  
export const getAnnouncementList = createAsyncThunk(
    'get/getAnnouncementList',
    async () => {  
  
      // Using template literals to build the URL
      let url = BASE_URL + announcementList;  // Corrected line
  
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );
  


  export const DeleteAnnouncement = createAsyncThunk(
    "post/DeleteAnnouncement",
    async (id) => {
      const url = `${BASE_URL}${announcementDelete}/${id}`;
  
      const token = localStorage.getItem('authToken');
      try {
        const response = (await axios.delete(url, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })).data;
  
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    }
  );


  // edit task 

export const getEditAnnouncement = createAsyncThunk(
  'get/getEditAnnouncement',
  async ({ id }) => {  

    // Using template literals to build the URL
    let url = BASE_URL + announcementEdit + id ;  // Corrected line

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);


// Update Announcement

export const postAnnouncementUpdate = createAsyncThunk(
  'put/postAnnouncementUpdate',
  async ({ NewAnnouncement , id }) => {  
    // Using template literals to build the URL
    let url = BASE_URL + announcementUpdate  + id; // Construct the URL

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.put(url , NewAnnouncement,{
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return error;
    }
  },
);