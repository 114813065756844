import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { BASE_URL, getReport } from "@Networking/APIs/NWConfig";
import axios from "axios";

const Reports = () => {
  const dispatch = useDispatch();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const getData = {
      report_type: data.report,
      start_date: data.start_date,
      end_date: data.end_date,
    };

    const url = `${BASE_URL}${getReport}`;
    const token = localStorage.getItem("authToken");

    try {
      const response = await axios.post(url, getData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      });

      const getMonthName = (date) => {
        const options = { month: "short" };
        return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
      };

      const formatDate = (date) => {
        const parsedDate = new Date(date);
        return `${parsedDate.getDate()} ${getMonthName(
          date
        )} ${parsedDate.getFullYear()}`;
      };

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileUrl = window.URL.createObjectURL(blob);

      const fileName = `${data.report}_${formatDate(
        data.start_date
      )}_To_${formatDate(data.end_date)}.xlsx`;

      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {}
  };

  return (
    <>
          <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <h6 className="">
            <i class="fa-solid fa-list me-1"></i> Finance / Reports
          </h6>
        </div>
      </div>
  

        <div className="row">
          <div className="col-md-12 grid-margin">
            <div className="card">
              <h6 className="card-header bg-gradient-info p-1 text-light text-center align-items-center">
                Generate Report By Category
              </h6>
              <div
                className="card-body"
                style={{ height: "auto", overflowY: "auto" }}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-md-3">
                      <label>Select Category</label>
                      <select
                        id="input"
                        {...register("report", {
                          required: "Please select a user",
                        })}
                      >
                        <option value="" disabled selected>
                          Select Category
                        </option>
                        <option value="invoices">Sales Report</option>
                        <option value="payment">Payment Report</option>
                        <option value="invoice_plus_payment">
                          Sales & Payment Report
                        </option>
                        {/* <option value="income">
                        Income Category
                      </option> */}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <label>To</label>
                      <input
                        id="input"
                        type="date"
                        {...register("start_date", {
                          required: "Please enter deadline date",
                        })}
                      />
                    </div>

                    <div className="col-md-3">
                      <label>From</label>
                      <input
                        id="input"
                        type="date"
                        {...register("end_date", {
                          required: "Please enter deadline date",
                        })}
                      />
                    </div>
                  </div>

                  <div className="row mt-4  d-flex align-items-end justify-content-center">
                    <div className="col-md-3">
                      <button className="btn btn-primary rounded-pill w-100">
                        Generate Report
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
