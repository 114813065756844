import { createSlice } from "@reduxjs/toolkit";
import { Logout } from "@Networking/APIs/Login/loginApi";
import { toast } from "react-toastify";

const LoginSlice = createSlice({
  name: "LoginSlice",
  initialState: {
    loading: false,
    message: "",
    success: false,
    error: null,
    userLoginData: null, // Holds the user's login information
  },
  reducers: {
    setUserData: (state, action) => {
      state.userLoginData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(Logout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Logout.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;

      if (action.payload.status === true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(Logout.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setUserData } = LoginSlice.actions;
export default LoginSlice.reducer;
