import React from 'react'

const PrmDashboard = () =>{
  return (
    <div>
      Dashboard
    </div>
  )
};

export default PrmDashboard;



