import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./logincss.css";
import { toast } from "react-toastify";

const Login = () => {
  const [hasNavigated, setHasNavigated] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGoogleLogin = () => {
    const googleAuthUrl = "https://developbo.techsimba.in/management/api/auth/google";
    // const googleAuthUrl = "https://developbo.techsimba.in/backoffice/api/auth/google";
    window.location.href = googleAuthUrl;
  };

  const parseUserData = (userData) => {
    try {
      const parsedUser = JSON.parse(decodeURIComponent(userData));
      localStorage.setItem("authToken", parsedUser.access_token);
      localStorage.setItem("name", parsedUser.user.name);
      localStorage.setItem("role", parsedUser.role);
      localStorage.setItem("userId", parsedUser.user.id);
 
       toast.success("Login successful!");
      return true;
    } catch (error) {
      toast.error("Invalid user data received.");
      return false;
    }
  };

  const handleLoginResponse = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const userData = queryParams.get("user");
    const errorMessage = queryParams.get("error");

    if (userData) {
      const success = parseUserData(userData);
      if (success) {
        navigate("/dashboard");
        setHasNavigated(true);
      }
    } else if (errorMessage) {
      toast.error(decodeURIComponent(errorMessage));
    }
  };

  useEffect(() => {
    if (!hasNavigated) {
      handleLoginResponse();
    }
  }, [hasNavigated, navigate, dispatch]);

  return (
    <div className="container-scroller" style={{ height: "98vh" }}>
      <div
        className="row"
        style={{
          height: "100%",
          backgroundImage: 'url("/assets/images/b.jpg")',
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="col-md-6"></div>
        <div
          className="col-md-6"
          style={{
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div className="card">
            <div className="card-body">
              <img
                src="https://bo.techsimba.in/assets/logo/fulllogo.png"
                alt="Google"
                style={{ width: "150px" }}
              />
            </div>
            <div className="card-body">
              <h4>Welcome to Backoffice</h4>
              <p className="card-text">
                Please login with your Google account.
              </p>
              <button
                className="btn btn-primary"
                style={{
                  backgroundColor: "red",
                  borderColor: "red",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={handleGoogleLogin}
              >
                <img
                  src="https://bo.techsimba.in/assets/images/g.png"
                  alt="Google"
                  style={{ width: "30px", marginRight: "10px" }}
                />
                Login with Google
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
