import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ViewLeave = () => {
  
  //Hooks
  const navigate = useNavigate();
  const location = useLocation();

  const leave = location.state?.leave;

  // States
  const [subject, setSubject] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [description, setDescription] = useState("");

  //UseEffects
  useEffect(() => {
    if (leave) {
      setSubject(leave.subject);
      setFromDate(leave.start_date);
      setToDate(leave.end_date);
      setDescription(leave.description);
    }
  }, [leave]);

  const handleRedirect = () => {
    navigate("/AllLeave");
  };

  return (
    <>


<div className="row">
        <div className="col-md-12 col-sm-12">
          <h6 className="">
            <i class="fa-solid fa-list me-1"></i>HRM / Leave / Manage Leave / View Leave
          </h6>
        </div>
      </div>


      <div className="card text-white rounded-top-2 p-3 mx-2 mb-4">
        <div className="row d-flex align-items-center">
          <div className="col-md-6 col-sm-12">
            <h3 className="page-title m-0">
              <span className="mx-2 text-primary">
                <i className="mdi mdi-file"></i>
              </span>
              View Leave
            </h3>
          </div>
          <div className="col-md-6 col-sm-12 text-end">
            <button
              className="btn btn-gradient-info rounded-pill w-25"
              onClick={handleRedirect}
            >
              All Leaves
            </button>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="card">
          <div className="card-body">
            <div className="">
              <div className="row p-3">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Subject</label>
                    <input className="form-select" value={subject} readOnly />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>From</label>
                    <input
                      type="date"
                      className="form-control"
                      value={fromDate}
                      readOnly
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>To</label>
                    <input
                      type="date"
                      className="form-control"
                      value={toDate}
                      readOnly
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      rows="4"
                      className="form-control"
                      value={description}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewLeave;
