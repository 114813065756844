import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, LoginUrl, SignUpUrl, Verifycode ,LogoutUrl} from "@Networking/APIs/NWConfig";
import Cookies from 'js-cookie';

//Login
export const postlogin = createAsyncThunk(
  'post/postlogin',
  async (post) => {
    let url = BASE_URL + LoginUrl;
     
    try {
      const response = (await axios.post(url, post)).data;
    //   if (response.status === 'true') {
        return Promise.resolve(response);
    //   }
    //   return Promise.reject(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
//Logout
export const Logout = createAsyncThunk(
  'post/Logout',
  async () => {
    const url = BASE_URL + LogoutUrl;
    const token = localStorage.getItem("authToken");
     

    if (!token) {
      throw new Error('No auth token found');
    }

    try {
      const response = (await axios.post(url, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;
      return Promise.resolve(response);
    } catch (error) {
      if (error.response) {
      }
      return Promise.reject(error);
    }
  }
);

//signUp
export const postsignUp = createAsyncThunk(
    "post/postsignUp",
    async(post) =>{

        let url = BASE_URL + SignUpUrl;

        try{
            const response = await axios.post(url, post)
            return Promise.resolve(response.data)
        } catch(error){
            return Promise.reject(error)
        }
    }
)
//verify code
export const GetVerifyCode = createAsyncThunk(
  "post/GetVerifyCode",
  async (post) => {
    let url = BASE_URL + Verifycode;
    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.post(url, post, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })).data;
    //   if (response.status === 'true') {
        return Promise.resolve(response);
    //   }
    //   return Promise.reject(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
)