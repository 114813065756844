import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Bar, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "chart.js/auto";
import "../Dashboard/Dashboard.css";
import Loader from "@Layout/Loader/loader";
import {
  getadminDashboardData,
  getDashboardData,
  getInvoiceDashboardData,
} from "@Networking/APIs/Dashboard/dashboardApi";

const Dashboard = ({ props, messages }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toastRef = useRef(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  useEffect(() => {
    dispatch(getInvoiceDashboardData());
    dispatch(getDashboardData());
    dispatch(getadminDashboardData());
  }, []);

  const InvoiceDashboardData = useSelector((state) => state.DashboardSlice);

  const DashboardData = useSelector((state) => state.DashboardSlice);
  console.log(DashboardData, "DashboardData");

  const AdminDashboardData = useSelector((state) => state.DashboardSlice);

  const tasks = [
    {
      name: "Bank Name 1",
      deadline: "2024-12-15",
      status: "Direct",
      statusClass: "alert-info",
      icon: "fa-check-circle", // Font Awesome icon for completed
    },
    {
      name: "Bank Name 2",
      deadline: "2024-12-20",
      status: "Indirect",
      statusClass: "alert-warning",
      icon: "fa-spinner",
    },
    {
      name: "Bank Name 3",
      deadline: "2024-12-18",
      status: "Direct",
      statusClass: "alert-info",
      icon: "fa-exclamation-circle",
    },
  ];

  const invoices = [
    {
      name: "Invoice 1",
      status: "Paid",
      created: "2024-12-10",
    },
    {
      name: "Invoice 2",
      status: "Partially Paid",
      created: "2024-12-15",
    },
    {
      name: "Invoice 3",
      status: "Unpaid",
      created: "2024-12-20",
    },
  ].map((invoice) => {
    if (invoice.status == "Paid") {
      return {
        ...invoice,
        status: "Paid",
        statusClass: "alert-success",
        icon: "fa-calendar-check",
      };
    } else if (invoice.status == "Partially Paid") {
      return {
        ...invoice,
        status: "Partially Paid",
        statusClass: "alert-warning",
        icon: "fa-sad-tear",
      };
    } else if (invoice.status == "Unpaid") {
      // Changed to else if
      return {
        ...invoice,
        status: "Unpaid",
        statusClass: "alert-danger",
        icon: "fa-sad-tear",
      };
    }
  });

  // Attendance Card
  const [selectedDate, setSelectedDate] = useState("");
  const [attendanceData, setAttendanceData] = useState({
    "2024-12-01": { checkIn: "9:00 AM", checkOut: "6:00 PM" },
    "2024-12-02": { checkIn: "9:15 AM", checkOut: "5:45 PM" },
  });

  const [monthlySummary, setMonthlySummary] = useState([
    { date: "2024-12-01", status: "Present" },
    { date: "2024-12-02", status: "Present" },
    { date: "2024-12-03", status: "Absent" },
  ]);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const renderAttendanceDetails = () => {
    if (!selectedDate) {
      return;
    }

    const attendance = attendanceData[selectedDate];
    if (attendance) {
      return (
        <div>
          <div className="row alert alert-primary m-auto">
            <div className="col-md-6">
              <p>Check-In Time: {attendance.checkIn}</p>
            </div>
            <div className="col-md-6">
              <p>Check-Out Time: {attendance.checkOut}</p>
            </div>
          </div>
        </div>
      );
    } else {
      return <p>No attendance data available for the selected date.</p>;
    }
  };

  const renderMonthlySummary = () => {
    if (monthlySummary.length === 0) {
      return <p className="text-muted">No summary available for this month.</p>;
    }

    return (
      <ul className="list-group">
        {monthlySummary.map((record, index) => (
          <li key={index} className="list-group-item">
            <strong>{record.date}:</strong> {record.status}
          </li>
        ))}
      </ul>
    );
  };

  const labels =
    InvoiceDashboardData?.InvoiceDashboardData?.perMonthCount?.map(
      (data) => data.month_name
    ) || [];
  const data =
    InvoiceDashboardData?.InvoiceDashboardData?.perMonthCount?.map(
      (data) => data.invoice_count
    ) || [];

  // Chart.js configuration
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Invoices",
        data: data,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FF7F50",
          "#FFC0CB",
          "#7FFF00",
          "#00FFFF",
          "#FF00FF",
          "#800080",
        ],
        borderColor: "#ccc",
        borderWidth: 0,
        borderRadius: 0, // Rounded bar corners
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },

      tooltip: {
        enabled: true,
        backgroundColor: "#000",
        titleColor: "#fff",
        bodyColor: "#fff",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          color: "#eaeaea",
        },
        ticks: {
          stepSize: 2,
        },
      },
    },
  };

  ChartJS.register(ArcElement, Tooltip, Legend);

  const leaveData = {
    labels: ["Paid Invoice", "Partially Paid Invoice", "Unpaid Invoice"],
    datasets: [
      {
        label: "Leave Status",
        data: [
          InvoiceDashboardData?.InvoiceDashboardData?.Paid || 0,
          InvoiceDashboardData?.InvoiceDashboardData?.Parcialy_Paid || 0,
          InvoiceDashboardData?.InvoiceDashboardData?.Unpaid || 0,
        ],
        backgroundColor: ["#198754", "#0dcaf0", "#dc3545"],
        hoverBackgroundColor: ["#198754", "#0dcaf0", "#dc3545"],
        borderWidth: 1,
      },
    ],
  };

  const leaveoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          generateLabels: (chart) => {
            const original =
              ChartJS.overrides.doughnut.plugins.legend.labels.generateLabels;
            const labels = original(chart);
            const dataset = chart.data.datasets[0].data || []; // Use the correct dataset
            labels.forEach((label, index) => {
              const count = dataset[index] || 0;
              label.text = `${label.text} (${count})`;
            });
            return labels;
          },
        },
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        display: true,
        formatter: (value) => value,
        color: "#36454F",
        font: {
          weight: "bold",
          size: 16,
        },
      },
    },
  };

  const formatDate = (date) => new Date(date).toISOString().split("T")[0];
  const todayDate = new Date().toISOString().split("T")[0]; // Define today's date

  const employeeBirthdays =
    AdminDashboardData?.AdminDashboardData?.employeeBirthday || [];

  // State for visibility, initialized every time the component loads
  const [visibleMessages, setVisibleMessages] = useState([]);

  useEffect(() => {
    // Initialize visibleMessages based on employeeBirthdays
    setVisibleMessages(employeeBirthdays.map(() => true));
  }, [employeeBirthdays]);

  const handleDelete = (index) => {
    const updatedVisibility = [...visibleMessages];
    updatedVisibility[index] = false;
    setVisibleMessages(updatedVisibility);
  };

  return (
    <>
      <div className="page-header">
        <h3 className="page-title">
          Welcome back, Admin{" "}
          <i className="fa-solid fa-hand fs-5 text-warning"></i>
        </h3>
      </div>

      {employeeBirthdays.map(
        (birthday, index) =>
          visibleMessages[index] && (
            <div className="row" key={index}>
              <div className="col-md-12">
                <div className="text-primary mb-3 p-1 dbBdMsg">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <div className="ms-3">
                        Today is the birthday of{" "}
                        <strong>
                          {birthday.employee.first_name}{" "}
                          {birthday.employee.last_name}
                        </strong>
                        ! 🎂 Wish them a happy birthday!
                      </div>
                    </div>
                    <div className="col-md-4 text-end">
                      <button
                        className="btn btn-sm"
                        onClick={() => handleDelete(index)}
                      >
                        <i className="fa-regular fa-circle-xmark text-danger"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
      )}

      <div
        className="toast"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="toast-header">
          <img src="..." className="rounded me-2" alt="..." />
          <strong className="me-auto">Bootstrap</strong>
          <small>11 mins ago</small>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
        <div className="toast-body">Hello, world! This is a toast message.</div>
      </div>

      <div className="row g-6 mt-1">
        <div className="col-md-3 col-sm-4 border-end border-primary border-2">
          <div className="card shadow custom-top-border">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <span className="h6 font-semibold text-muted text-sm d-block mb-2 border-bottom border-secondary pb-1">
                    Total Invoices
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 text-start">
                  <button
                    type="button"
                    className="btn dbMnCrLn"
                    onClick={() => navigate("/InvoicesList")}
                  >
                    View All <i class="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
                <div className="col-md-4 text-end  border-start">
                  <span className="h3 font-bold mb-0">
                    {InvoiceDashboardData?.InvoiceDashboardData?.Invoice ?? (
                      <p className="text-danger">No data available</p>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-4 border-end border-primary border-2">
          <div className="card shadow custom-top-border border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <span className="h6 font-semibold text-muted text-sm d-block mb-2 border-bottom border-secondary pb-1">
                    Total Clients
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 text-start">
                  <button type="button" className="btn dbMnCrLn">
                    View All <i class="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
                <div className="col-md-4 text-end border-start">
                  <span className="h3 font-bold mb-0">
                    {AdminDashboardData?.AdminDashboardData?.Client ?? (
                      <p className="text-danger">No data available</p>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-4 border-end border-primary border-2">
          <div className="card shadow custom-top-border border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <span className="h6 font-semibold text-muted text-sm d-block mb-2 border-bottom border-secondary pb-1">
                    Total Banks
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 text-start">
                  <button
                    type="button"
                    className="btn dbMnCrLn"
                    onClick={() => navigate("/BankDetails")}
                  >
                    View All <i class="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
                <div className="col-md-4 text-end border-start">
                  <span className="h3 font-bold mb-0">
                    {AdminDashboardData?.AdminDashboardData?.Bank ?? (
                      <p className="text-danger">No data available</p>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-4">
          <div className="card shadow custom-top-border border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <span className="h6 font-semibold text-muted text-sm d-block mb-2 border-bottom border-secondary pb-1">
                    Total Employees
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 text-start">
                  <button
                    type="button"
                    className="btn dbMnCrLn"
                    onClick={() => navigate("/Employees")}
                  >
                    View All <i class="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
                <div className="col-md-4 text-end border-start">
                  <span className="h3 font-bold mb-0">
                    {AdminDashboardData?.AdminDashboardData?.Employee ?? (
                      <p className="text-danger">No data available</p>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="row g-6 mt-3">
        {/* <div className="col-md-6 border-end border-primary border-2">
          <div className="card ">
            <div className="card-header edAnCh text-white d-flex justify-content-between align-items-center">
              <h5 className="m-0">Latest Announcements</h5>
              <i className="fa-solid fa-bullhorn"></i>
            </div>
            <div className="card-body">
              <ul className="list-group">
                {AdminDashboardData?.AdminDashboardData?.latestAnnouncements?.map(
                  (announcement, index) => (
                    <li
                      className={`list-group-item ${announcement.statusClass}`}
                      key={index}
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          <h6 className="mb-1">{announcement.title}</h6>
                          <small>
                            Event Date:{" "}
                            <strong>{announcement.start_date}</strong>
                          </small>
                        </div>
                        <div>
                          <span
                            className={`badge ${
                              announcement.start_date <= todayDate
                                ? "badge-danger"
                                : "badge-success"
                            }`}
                          >
                            <i className={`fa ${announcement.icon} me-1`}></i>
                            {
                              announcement.start_date <= todayDate ? (
                                <i className="fa fa-times-circle me-1"></i> // Font Awesome "times-circle" for "Gone"
                              ) : (
                                <i className="fa fa-check-circle me-1"></i>
                              ) // Font Awesome "check-circle" for "Upcoming"
                            }
                            {announcement.start_date <= todayDate
                              ? " Gone"
                              : " Upcoming"}
                          </span>
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div> */}
        <div className="col-md-6">
          <div className="card">
            <div className="card-header edAnCh text-white d-flex justify-content-between align-items-center p-2">
              <h5 className="m-0">Invoice Details</h5>
              <i class="fa-solid fa-receipt"></i>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 border-end border-primary">
                  <div className="col-md-12 mt-2">
                    <div className="alert alert-success d-flex justify-content-evenly align-self-center p-0">
                      <p className="m-auto">
                        <strong>Paid Invoices: </strong>
                      </p>
                      <p className="m-auto">
                        {InvoiceDashboardData?.InvoiceDashboardData?.Paid ?? (
                          <p className="text-danger">No Paid invoices</p>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="alert alert-info d-flex justify-content-evenly align-self-center p-0">
                      <p className="m-auto">
                        <strong>Partially Paid Invoices: </strong>
                      </p>
                      <p className="m-auto">
                        {InvoiceDashboardData?.InvoiceDashboardData
                          ?.Parcialy_Paid ?? (
                          <p className="text-danger">
                            No Parcially Paid invoices
                          </p>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="alert alert-danger d-flex justify-content-evenly align-self-center p-0">
                      <p className="m-auto">
                        <strong>Unpaid Invoices: </strong>
                      </p>
                      <p className="m-auto">
                        {InvoiceDashboardData?.InvoiceDashboardData?.Unpaid ?? (
                          <p className="text-danger">No Unpaid invoices</p>
                        )}
                      </p>
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn btn-success w-100 rounded-pill dbCiBtn"
                    onClick={() => navigate("/InvoicesForm")}
                  >
                    Create Invoice
                  </button>
                </div>
                <div className="col-md-6 border-start border-primary text-center">
                  <div className="">
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        margin: "5% auto",
                      }}
                    >
                      <Doughnut
                        options={leaveoptions}
                        data={leaveData}
                        {...props}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card">
            <div className="card-header edAnCh text-white d-flex justify-content-between align-items-center p-2">
              <h5 className="m-0">Invoice Payments</h5>
              <i class="fa-solid fa-file-invoice-dollar"></i>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 border-end border-primary">
                  {AdminDashboardData?.AdminDashboardData.currency &&
                    AdminDashboardData.AdminDashboardData.currency.length > 0 &&
                    // Mapping over the currency array
                    AdminDashboardData.AdminDashboardData.currency.map(
                      (currency, index) => (
                        <div className="col-md-12 mt-2" key={index}>
                          <div className="alert alert-secondary d-flex justify-content-evenly align-self-center">
                            <p className="m-auto">
                              <strong>{currency.currency_name}</strong>
                            </p>
                            <p className="m-auto">{currency.total_amount}</p>
                          </div>
                        </div>
                      )
                    )}
                  {/* <div className="col-md-12">
                    <div className="alert alert-secondary d-flex justify-content-evenly align-self-center">
                      <p className="m-auto">
                        <strong>Partially Paid Invoices: </strong>
                      </p>
                      <p className="m-auto">
                        {InvoiceDashboardData?.InvoiceDashboardData
                          ?.Parcialy_Paid ?? (
                          <p className="text-danger">
                            No Parcially Paid invoices
                          </p>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="alert alert-secondary d-flex justify-content-evenly align-self-center">
                      <p className="m-auto">
                        <strong>Unpaid Invoices: </strong>
                      </p>
                      <p className="m-auto">
                        {InvoiceDashboardData?.InvoiceDashboardData?.Unpaid ?? (
                          <p className="text-danger">No Unpaid invoices</p>
                        )}
                      </p>
                    </div>
                  </div> */}
                </div>
                <div className="col-md-6 border-start border-primary text-center justify-content-center">
                  <div className="">
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        margin: "auto auto",
                      }}
                    >
                      {/* <Doughnut
                        options={leaveoptions}
                        data={leaveData}
                        {...props}
                      /> */}
                      <strong>Total Invoice Amount INR: </strong>
                      <br></br>
                      {AdminDashboardData?.AdminDashboardData?.Total_INR ? (
                        <h3>
                          {AdminDashboardData.AdminDashboardData.Total_INR}
                        </h3>
                      ) : (
                        <span className="text-danger">
                          No Total INR data available
                        </span>
                      )}
                    </div>
                  </div>
                  <hr></hr>
                  <div className="">
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        margin: "auto auto",
                      }}
                    >
                      {/* <Doughnut
                        options={leaveoptions}
                        data={leaveData}
                        {...props}
                      /> */}
                      <strong>Total Paid Amount INR : </strong>
                      <br></br>
                      {AdminDashboardData?.AdminDashboardData
                        ?.Total_INR_paid ? (
                        <h3>
                          {AdminDashboardData.AdminDashboardData.Total_INR_paid}
                        </h3>
                      ) : (
                        <span className="text-danger">
                          No Total INR data available
                        </span>
                      )}
                    </div>
                  </div>
                  <hr></hr>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header edAnCh text-white d-flex justify-content-between align-items-center p-2">
              <h5 className="m-0">Employee on Leave</h5>
              <i className="fa-solid fa-building-columns"></i>
            </div>
            <div className="card-body">
              {AdminDashboardData?.AdminDashboardData?.employeeLeave &&
              AdminDashboardData?.AdminDashboardData?.employeeLeave.length >
                0 ? (
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center">Employee Name</th>
                      <th className="text-center">Subject</th>
                      <th className="text-center">Start Date</th>
                      <th className="text-center">End Date</th>
                      <th className="text-center">Leave Days</th>
                      <th className="text-center">Approved By</th>
                      <th className="text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AdminDashboardData?.AdminDashboardData?.employeeLeave.map(
                      (leave, index) => (
                        <tr key={index} className={leave.statusClass}>
                          <td className="text-center">
                            {leave.start_date || "No business name available"}
                          </td>
                          <td className="text-center">
                            {leave.subject || "No Data available"}
                          </td>
                          <td className="text-center">
                            {formatDate(leave.start_date)}
                          </td>
                          <td className="text-center">
                            {formatDate(leave.end_date)}
                          </td>
                          <td className="text-center">
                            {leave.leave_days || "No Data available"}
                          </td>
                          <td className="text-center">
                            {leave.Payment_Mode || "No Data available"}
                          </td>
                          <td className="text-center">
                            <span
                              className={`badge ${
                                leave.status === "approve"
                                  ? "badge-success"
                                  : leave.status === "cancel"
                                  ? "badge-info"
                                  : "badge-warning" // Default for "pending" or any other status
                              }`}
                            >
                              <i
                                className={`fa ${
                                  leave.status === "approve"
                                    ? "fa-check-circle"
                                    : leave.status === "cancel"
                                    ? "fa-exclamation-circle"
                                    : "fa-times-circle" // Default for "pending" or any other status
                                } me-1`}
                              ></i>
                              {leave.status}
                            </span>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              ) : (
                <p style={{ color: "red" }}>No data available</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header edAnCh text-white d-flex justify-content-between align-items-center p-2">
              <h5 className="m-0">Last Month's Invoices</h5>
              <i className="fa-solid fa-calendar"></i>
            </div>
            <div className="card-body">
              <div style={{ height: "220px" }}>
                <Bar data={chartData} options={options} />
              </div>
              <div className="text-center mt-3">
                <h6>
                  Total Invoices: {data.reduce((a, b) => a + b, 0)} Invoices
                </h6>
              </div>
            </div>
          </div>
          {(!InvoiceDashboardData || InvoiceDashboardData.length === 0) && (
            <div className="text-center mt-3">
              <Loader />
            </div>
          )}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-12 border-end border-primary border-2">
          <div className="card ">
            <div className="card-header edAnCh text-white d-flex justify-content-between align-items-center p-2">
              <h5 className="m-0">Payment List</h5>
              <i class="fa-solid fa-holly-berry"></i>
            </div>
            <div className="card-body">
              {AdminDashboardData?.AdminDashboardData?.payment &&
              AdminDashboardData.AdminDashboardData.payment.length > 0 ? (
                <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                  <table className="table table-bordered">
                    <thead
                      style={{
                        position: "sticky",
                        top: -1,
                        backgroundColor: "white",
                        zIndex: 1,
                      }}
                    >
                      <tr>
                        <th>Invoice Number</th>
                        <th className="text-center">Client Company</th>
                        <th className="text-center">Payment Mode</th>
                        <th className="text-center">Paid Amount</th>
                        <th className="text-center">Paid Amount(INR)</th>
                        <th className="text-center">Payment Date</th>
                        <th className="text-end">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {AdminDashboardData.AdminDashboardData.payment.map(
                        (payment, index) => (
                          <tr
                          key={index}
                          className={payment.Payment_Status === "Paid" ? "table-success text-white" : payment.Payment_Status === "Partially Paid" ? "table-info text-white" : payment.Payment_Status === "Unpaid" ? "table-danger text-white" : ""}
                        >
                            <td>{payment.invoice_number}</td>
                            <td className="text-center">
                              {payment.client_company}
                            </td>
                            <td className="text-center">
                              {payment.Payment_Mode}
                            </td>
                            <td className="text-center">
                              {payment.Paid_Amount}
                            </td>
                            <td className="text-center">
                              {payment.Invoice_amount_INR}
                            </td>
                            <td className="text-center">
                              {payment.Payment_Date}
                            </td>
                            <td className="text-end">
                              {payment.Payment_Status}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p style={{ color: "red" }}>No data available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
