import { getEmployees } from "@Networking/APIs/Employee/EmployeeApi";
import { getEditTask, postTaskUpdate, storeTask } from "@Networking/APIs/Task/taskApi";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Loader from "@Layout/Loader/loader";

const EditTask = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = location.state || {};

  const { TaskEditData, taskLoader } = useSelector((state) => state.TaskSlice);
  const employeesData = useSelector((state) => state.EmployeesSlice);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { type } = useParams();

  useEffect(() => {
    dispatch(getEmployees());

    if (id) {
      dispatch(getEditTask({ id }));
    }
  }, [dispatch, id]);

  const taskUpdateData = useSelector((state) => state.TaskSlice);


  useEffect(() => {
    if (id && taskUpdateData?.TaskEdit?.task_details) {
      reset({
        id: taskUpdateData?.TaskEdit?.task_details?.id,
        assign_to: taskUpdateData?.TaskEdit?.task_details?.assign_to,
        upload_file: taskUpdateData?.TaskEdit?.task_details?.upload_file,
        project_name: taskUpdateData?.TaskEdit?.task_details?.project_name,
        deadline_date: taskUpdateData?.TaskEdit?.task_details?.deadline_date,
        task_description: taskUpdateData?.TaskEdit?.task_details?.task_description,
        remark: taskUpdateData?.TaskEdit?.task_details?.remark,
      });
    }
  }, [taskUpdateData, id, reset]);



  useEffect(() => {
    dispatch(getEmployees());

    if (id) {
      dispatch(getEditTask({ id }));

    }
  }, [dispatch, id]);



  useEffect(() => {
    if (id && taskUpdateData?.TaskEdit?.task_details) {
      reset({
        id: taskUpdateData?.TaskEdit?.task_details?.id,
        assign_to: taskUpdateData?.TaskEdit?.task_details?.assign_to,
        project_file: taskUpdateData?.TaskEdit?.task_details?.project_file,
        project_name: taskUpdateData?.TaskEdit?.task_details?.project_name,
        deadline_date: taskUpdateData?.TaskEdit?.task_details?.deadline_date,
        task_description: taskUpdateData?.TaskEdit?.task_details?.task_description,
        remark: taskUpdateData?.TaskEdit?.task_details?.remark,
      });
    }
  }, [taskUpdateData, id, reset]);

  // const onSubmit = async (data) => {

  //   try {
  //     await dispatch(postTaskUpdate({data})).unwrap();
  //     reset();
  //   } catch (error) {
  //   }
  // };


  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    // Append form fields to FormData
    formData.append("assign_to", e.target.assign_to.value);
    formData.append("project_name", e.target.project_name.value);
    formData.append("deadline_date", e.target.deadline_date.value);
    formData.append("task_description", e.target.task_description.value);
    formData.append("remark", e.target.remark.value);


    // Append the file(s) directly from the input element
    if (e.target.upload_file.files.length > 0) {
      Array.from(e.target.upload_file.files).forEach((file, index) => {
        formData.append("upload_file", file); // Use "upload_file[]" if you support multiple files
      });
    }

    try {
      await dispatch(storeTask(formData)).unwrap();
      // reset(); // Uncomment this if you want to reset the form after submission
    } catch (error) {
    }
  };




  return (
    <div>
      <div className="card text-white rounded-top-2 p-3 mx-2 mb-4">
        <div className="row d-flex align-items-center">
          <div className="col-md-6 col-sm-12">
            <h3 className="page-title m-0">
              <span className="mx-2 text-primary">
                <i className="mdi mdi-file"></i>
              </span>
              EDIT TASK
            </h3>
          </div>
        </div>
      </div>
      <div className="container-fluid mb-4">
        <div className="card">
          <div className="card-body">
            <div className="">
              <div className="row p-3">
                <div className="col-md-12">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="row"
                    encType="multipart/form-data"
                  >
                    <div className="row mt-2">
                      <div className="col-md-6 mb-2">
                        <label>Assign To</label>
                        <select
                          id="input"
                          {...register("assign_to", {
                            required: "Please select a user",
                          })}
                        >
                          <option value="" disabled>
                            Select Employee
                          </option>
                          {employeesData?.EmployeesData?.employee_list?.length > 0 ? (
                            employeesData.EmployeesData.employee_list.map((employees) => (
                              <option key={employees.id} value={employees.id}>
                                {employees.first_name} {employees.middle_name} {employees.last_name}
                              </option>
                            ))
                          ) : (
                            <option disabled>No Employee available</option>
                          )}
                        </select>
                      </div>

                      <div className="col-md-6">
                        <label>Upload Files</label>
                        <input
                          id="input"
                          type="file"
                          multiple
                          {...register("upload_file")}
                        />
                        <div>
                          <label>Uploaded Files:</label>
                          {taskUpdateData?.TaskEdit?.task_details?.file_name}
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-8">
                        <label>Project Name</label>
                        <input
                          type="text"
                          id="input"
                          {...register("project_name", {
                            required: "Please enter project name",
                          })}
                          placeholder="Project Name"
                        />
                      </div>

                      <div className="col-md-4">
                        <label>Deadline Date</label>
                        <input
                          id="input"
                          type="date"
                          {...register("deadline_date", {
                            required: "Please enter deadline date",
                          })}
                          defaultValue={taskUpdateData?.TaskEdit?.task_details?.deadline_date || ""}
                        />
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-6 mb-2">
                        <label>Task Description</label>
                        <textarea
                          {...register("task_description", {
                            required: "Please enter task description",
                          })}
                          rows="4"
                          placeholder="Task Description"
                          id="input"
                        ></textarea>
                      </div>

                      <div className="col-md-6 mb-2">
                        <label>Remarks</label>
                        <textarea
                          {...register("remark", {
                            required: "Please enter remarks",
                          })}
                          rows="4"
                          placeholder="Remarks"
                          id="input"
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-12 mt-3 d-flex justify-content-center">
                      <button
                        type="submit"
                        className="btn btn-gradient-info rounded-pill w-50"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {taskLoader ? <Loader /> : null}
    </div>
  );
};

export default EditTask;
