import React, { useEffect, useState } from "react";
import Select from "react-select";
import "../../Invoice/invoicesForm.css";
import { useDispatch, useSelector } from "react-redux";
import { postClientStore } from "@Networking/APIs/Invoice/Client/clientApi";
import {
  getcity,
  getcountries,
  getcountryCode,
  getstate,
} from "@Networking/APIs/Background/backgorundApi";
import { useForm } from "react-hook-form";
import { getClient } from "@Networking/APIs/Invoice/Client/clientGetApi";
import Loader from "@Layout/Loader/loader";
import { toast } from "react-toastify";
import { button } from "@Layout/Color/color";

const ClientForm = ({ onClose, isGstType }) => {
  //Hooks
  const dispatch = useDispatch();

  //Redux
  const { country, state, city, countryCode } = useSelector(
    (state) => state.BackgroundSlice || {}
  );
  const { validateError, loading } = useSelector((state) => state.clientSlice);

  //States
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountryCode, setselectedCodeCountry] = useState(null);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  //UseEffects
  useEffect(() => {
    dispatch(getcountries());
    dispatch(getcountryCode());
  }, []);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption ? selectedOption.label : "");
    if (selectedOption) {
      dispatch(getstate(selectedOption.value));
    }
  };

  const handleCountryCodeChange = (selectedOption) => {
    setselectedCodeCountry(selectedOption ? selectedOption.label : "");
  };

  const handleStateChange = (newValue) => {
    setSelectedState(newValue);
    if (newValue) {
      dispatch(getcity(newValue.value));
    }
  };

  const handleCityChange = (newValue) => {
    setSelectedCity(newValue);
  };

  const onSubmit = async (data) => {
    const newClient = {
      first_name: data.clientName,
      last_name: data.clientLastName,
      business_name: data.clientBussinessName,
      client_phone: data.clientPhone,
      phone_code: selectedCountryCode ? selectedCountryCode : "",
      email: data.clientEmail,
      address: data.clientAddress,
      gst: data.clientGst,
      pan: data.clientPan,
      pincode: data.pincode,
      website: data.clientWebsite,
      country: selectedCountry ? selectedCountry : "",
      state: selectedState ? selectedState.label : "",
      city: selectedCity ? selectedCity.label : "",
    };
    try {
      const res = await dispatch(postClientStore(newClient));
      if (res.payload.status == true) {
        onClose();
        toast.success(res.payload.message);
      }
      await dispatch(getClient());
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div
      className="modal fade show"
      style={{ display: "block", backdropFilter: "blur(5px)" }}
      aria-modal="true"
    >
      <div className="modal-dialog modal-lg">
        <div
          className="modal-content"
          style={{ maxHeight: "90vh", overflow: "hidden" }}
        >
          <div
            className="modal-header"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src="assets/logo/tslogo.png"
                alt="Client"
                style={{ width: "25px", height: "100%" }}
              />
              <h5 className="modal-title text-center">Customer Information</h5>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              style={{ position: "absolute", right: "10px", top: "10px" }}
            >
              <span aria-hidden="true"></span>
            </button>
          </div>

          <div className="modal-body card-body-scrollable">
            <form onSubmit={handleSubmit(onSubmit)}>
              <h6>Customer Information</h6>
              <div className="row">
                <div className="col-md-6">
                  <label>First Name</label>
                  <input
                    type="text"
                    className={`inputText px-3 ${
                      errors.clientName ? "is-invalid" : ""
                    }`}
                    id="clientName"
                    name="clientName"
                    placeholder="Enter First Name"
                    {...register("clientName", {
                      required: "This field is required",
                      pattern: {
                        value: /^[A-Za-z\s.]+$/,
                        message: "Only alphabets are allowed",
                      },
                    })}
                  />
                  {errors.clientName && (
                    <span className="text-danger">
                      {errors.clientName.message}
                    </span>
                  )}
                </div>

                <div className="col-md-6">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className={`inputText px-3 ${
                      errors.clientLastName ? "is-invalid" : ""
                    }`}
                    id="clientLastName"
                    name="clientLastName"
                    placeholder="Enter Last Name"
                    {...register("clientLastName", {
                      required: "This field is required",
                      pattern: {
                        value: /^[A-Za-z\s.]+$/,
                        message: "Only alphabets are allowed",
                      },
                    })}
                  />
                  {errors.clientName && (
                    <span className="text-danger">
                      {errors.clientLastName?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <label>Business Name</label>
                  <input
                    type="text"
                    className={`inputText px-3 ${
                      errors.clientBussinessName ? "is-invalid" : ""
                    }`}
                    id="clientBussinessName"
                    name="clientBussinessName"
                    placeholder="Enter Business Name"
                    {...register("clientBussinessName", {
                      required: "This field is required",
                      pattern: {
                        value: /^[A-Za-z\s]+$/,
                        message: "Only alphabets, spaces, and dots are allowed",
                      },
                    })}
                  />
                  {errors.clientName && (
                    <span className="text-danger">
                      {errors.clientBussinessName?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mt-2 ">
                <div className="col-md-12 mb-2">
                  <label>Email Address</label>
                  <input
                    type="email"
                    className={`inputText  ${
                      errors.clientEmail ? "is-invalid" : ""
                    }`}
                    id="clientEmail"
                    name="clientEmail"
                    placeholder="Enter Email Address"
                    {...register("clientEmail", { required: false })}
                  />
                  {errors.clientEmail && (
                    <span className="text-danger">This field is required</span>
                  )}
                 
                </div>
                <div className="col-md-6">
                  <label>Country Code</label>
                  <Select
                    className="select-client inputText"
                    id="countryCode"
                    options={countryCode}
                    placeholder={selectedCountryCode || "Select Country Code"}
                    {...register("phoneCode")}
                    isSearchable
                    onChange={handleCountryCodeChange}
                  />
                </div>
                <div className="col-md-6">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    className="inputText px-3"
                    id="clientPhone"
                    name="clientPhone"
                    placeholder="Enter Phone Number"
                    {...register("clientPhone", {
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Only numbers are allowed",
                      },
                      validate: (value) =>
                        value === "" ||
                        /^[0-9]+$/.test(value) ||
                        "Only numbers are allowed",
                    })}
                  />
                </div>
              </div>
              <hr></hr>

              <h6>Billing Address</h6>

              <div className="row">
                <div className="col-md-6">
                  <label>Country</label>
                  <Select
                    id="clientCountry"
                    options={country}
                    placeholder={selectedCountry || "Select Country"}
                    {...register("clientCountry")}
                    isSearchable
                    className="select-client inputText"
                    onChange={handleCountryChange}
                  />
                </div>
                <div className="col-md-6">
                  <label>State</label>
                  <Select
                    id="clientState"
                    options={state}
                    placeholder={selectedState || "Select State"}
                    onChange={handleStateChange}
                    isSearchable
                    className="select-client inputText"
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-6">
                  <label>City</label>
                  <Select
                    id="clientCity"
                    options={city}
                    placeholder={selectedCity || "Select City"}
                    {...register("clientCity")}
                    onChange={handleCityChange}
                    isSearchable
                    className="select-client inputText"
                  />
                </div>
                <div className="col-md-6">
                  <label>Pincode</label>
                  <input
                    type="number"
                    className="inputText px-3"
                    id="pincode"
                    name="pincode"
                    placeholder="Enter Pincode"
                    {...register("pincode", {
                      validate: (value) =>
                        value === "" ||
                        /^[0-9]{6}$/.test(value) ||
                        "Only 6-digit numbers are allowed",
                    })}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-6">
                  <label>PAN Number</label>
                  <input
                    type="text"
                    className={`inputText px-3 `}
                    id="clientPan"
                    name="clientPan"
                    placeholder="Enter Pan"
                    {...register("clientPan")}
                  />
                </div>
                <div className="col-md-6">
                  <label>GST Number</label>
                  <input
                    type="text"
                    className={`inputText px-3 ${
                      errors.clientGst ? "is-invalid" : ""
                    }`}
                    id="clientGst"
                    name="clientGst"
                    placeholder="Enter Gstin"
                    {...register("clientGst", {
                      pattern: {
                        value: /^[a-zA-Z0-9]+$/,
                        message:
                          "GST Number must be a valid 15-character alphanumeric string",
                      },
                    })}
                  />
                  {errors.clientGst && (
                    <span className="text-danger">
                      {" "}
                      {errors.clientGst.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-12 mt-2">
                <label>Website</label>
                <input
                  type="text"
                  className={`inputText`}
                  id="clientWebsite"
                  name="clientWebsite"
                  placeholder="Enter Website"
                  {...register("clientWebsite")}
                />
              </div>
              <div className="col-md-12 mt-2">
                <label>Address</label>
                <input
                  type="text"
                  className={`inputText ${
                    errors.clientAddress ? "is-invalid" : ""
                  }`}
                  id="clientAddress"
                  name="clientAddress"
                  placeholder="Enter Address"
                  {...register("clientAddress", {
                    required: "This field is required",
                  })}
                />
                {errors.clientAddress && (
                  <span className="text-danger">
                    {errors.clientAddress?.message}
                  </span>
                )}
               
              </div>
              <div className="text-center mt-4">
                <button type="submit" className={button}>
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
        {loading ? <Loader /> : null}
      </div>
    </div>
  );
};

export default ClientForm;
