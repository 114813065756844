import React, { useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  getcity,
  getcountries,
  getstate,
} from "@Networking/APIs/Background/backgorundApi";
import { getNationality } from "@Networking/APIs/Nationality/nationalityApi";
import {
  getRole,
  getWorkLocation,
} from "@Networking/APIs/Employee/EmployeeApi";

import "./MultiStepForm.css";
import { toast } from "react-toastify";
import {
  fetchEmployeData,
  leaderRole,
  postEmployeePersonalStore,
} from "@Networking/APIs/Employee/EmployeStoreApi";
import { resetValidateFields } from "@Networking/Slices/Employee/EmployeStoreSlice";
import Loader from "@Layout/Loader/loader";

const EmployeeDetails = ({ nextStep, progress }) => {
  const [employeeId, setEmployeeId] = useState(null); // Step 1: New state for employee_id

  useEffect(() => {
    dispatch(getcountries());
    dispatch(getWorkLocation());
    dispatch(getRole());
    dispatch(getNationality());
  }, []);

  const dispatch = useDispatch();
  const { register, setValue, getValues } = useForm();

  const { country, state, city } = useSelector(
    (state) => state.BackgroundSlice
  );
  const roleData = useSelector((state) => state.EmployeesSlice);
  const nationalityData = useSelector((state) => state.NationalitySlice);
  const { validateFields, error, employeData, step1Loading } = useSelector(
    (state) => state.EmployeStoreSlice
  );

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [selectedNationality, setSelectedNationality] = useState("");
  const [contact, setContact] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoName, setProfilePhotoName] = useState("");

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [address, setAddress] = useState(null);

  const [selectedCountry1, setSelectedCountry1] = useState(null);
  const [selectedState1, setSelectedState1] = useState(null);
  const [selectedCity1, setSelectedCity1] = useState(null);
  const [pincode1, setPincode1] = useState(null);
  const [permanentAddress, setPermanentAddress] = useState(null);
  const [work_email, setWorkEmail] = useState("");

  const [sameAsCurrent, setSameAsCurrent] = useState(false);
  const [isProgrammatic, setIsProgrammatic] = useState(false);
  const [empId, setEmpId] = useState(null);

  // Create formData with useEffect to update it whenever any state changes
  // const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [validateError, setValidateError] = useState([]);

  if (employeData?.length > 0) {
    setSelectedCountry({
      label: employeData?.edit_employee?.country,
      value: 0,
    });
  }

  useEffect(() => {
    if (employeData?.length > 0) {
      dispatch(fetchEmployeData(employeData?.edit_employee?.id));
    }
    setValidateError(validateFields);
  }, [validateFields]);

  const prepareData = () => {
    if (employeData) {
      setEmpId(employeData?.edit_employee?.id || "");
      setFirstName(employeData?.edit_employee?.first_name || "");
      setMiddleName(employeData?.edit_employee?.middle_name || "");
      setLastName(employeData?.edit_employee?.last_name || "");
      setGender(employeData?.edit_employee?.gender || "");
      setDob(employeData?.edit_employee?.dob || "");
      setSelectedNationality(employeData?.edit_employee?.nationality || "");
      setContact(employeData?.edit_employee?.contact || "");
      setAlternateNumber(employeData?.edit_employee?.alternate_number || "");
      setPersonalEmail(employeData?.edit_employee?.personal_email || "");
      setJobRole(employeData?.edit_employee?.job_role || "");
      setSelectedCountry(employeData?.edit_employee?.country || "");
      setSelectedState(employeData?.edit_employee?.state || "");
      setSelectedCity(employeData?.edit_employee?.city || "");
      setPincode(employeData?.edit_employee?.pincode || "");
      setAddress(employeData?.edit_employee?.address || "");
      setSelectedCountry1(employeData?.edit_employee?.country_1 || "");
      setSelectedState1(employeData?.edit_employee?.state_1 || "");
      setSelectedCity1(employeData?.edit_employee?.city_1 || "");
      setPincode1(employeData?.edit_employee?.pincode_1 || "");
      setPermanentAddress(employeData?.edit_employee?.permanent_address || "");
      setProfilePhotoName(employeData?.profile_photo_name || "");
    }
  };

  useEffect(() => {
    prepareData();
  }, [employeData]);

  // Now `formData` will always have the updated values
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    if (selectedOption) {
      dispatch(getstate(selectedOption.value));
    }
  };

  const handleStateChange = (newValue) => {
    setSelectedState(newValue);
    if (newValue) {
      dispatch(getcity(newValue.value));
    }
  };

  const handleCityChange = (newValue) => {
    setSelectedCity(newValue);
  };

  const handleCountryChange1 = (selectedOption) => {
    setSelectedCountry1(selectedOption);

    if (!isProgrammatic && selectedOption) {
      // Only call the API if the event is not programmatically triggered
      dispatch(getstate(selectedOption.value));
    }
  };

  const handleStateChange1 = (newValue) => {
    setSelectedState1(newValue);

    if (!isProgrammatic && newValue) {
      // Only call the API if the event is not programmatically triggered
      dispatch(getcity(newValue.value));
    }
  };

  const handleCityChange1 = (newValue) => {
    setSelectedCity1(newValue);
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setSameAsCurrent(isChecked);

    if (isChecked) {
      setIsProgrammatic(true);

      const currentCountry = selectedCountry;
      const currentState = selectedState;
      const currentCity = selectedCity;
      const currentPincode = pincode;
      const currentAddress = getValues("address");

      setSelectedCountry1(
        currentCountry
          ? { label: currentCountry.label, value: currentCountry.value }
          : null
      );
      setSelectedState1(
        currentState
          ? { label: currentState.label, value: currentState.value }
          : null
      );
      setSelectedCity1(
        currentCity
          ? { label: currentCity.label, value: currentCity.value }
          : null
      );
      setPincode1(currentPincode);
      setPermanentAddress(currentAddress);

      if (currentCountry) {
        handleCountryChange1(currentCountry);
      }

      if (currentState) {
        handleStateChange1(currentState);
      }

      if (currentCity) {
        handleCityChange1(currentCity);
      }

      setIsProgrammatic(false);
    } else {
      setSelectedCountry1(null);
      setSelectedState1(null);
      setSelectedCity1(null);
      setPincode1("");
      setValue("permanent_address", "");
    }
  };
  const capitalizeFirstLetter = (value) =>
    value.charAt(0).toUpperCase() + value.slice(1);

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Access the uploaded file
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePhoto(reader.result); // Convert file to a base64 URL for preview
        setProfilePhotoName(file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  const [jobRoleId, setJobRoleId] = useState("");

  const ViewLeader = useSelector((state) => state.EmployeesSlice);

  useEffect(() => {
    if (jobRoleId) {
      dispatch(leaderRole(jobRoleId));
    }
  }, [jobRoleId, dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    // Append fields to FormData
    formData.append("employee_id", empId);
    formData.append("first_name", e.target.elements.firstName?.value ?? "");
    formData.append("middle_name", e.target.elements.middle_name?.value ?? "");
    formData.append("last_name", e.target.elements.last_name?.value ?? "");
    formData.append("gender", e.target.elements.gender?.value ?? "");
    formData.append("dob", e.target.elements.dob?.value ?? "");
    formData.append("nationality", e.target.elements.nationality?.value ?? "");
    formData.append("contact", e.target.elements.contact?.value ?? "");
    formData.append(
      "alternate_number",
      e.target.elements.alternate_number?.value ?? ""
    );
    formData.append(
      "personal_email",
      e.target.elements.personal_email?.value ?? ""
    );
    formData.append("job_role", e.target.elements.jobRole?.value ?? "");
    formData.append("work_email", e.target.elements.work_email?.value ?? "");
    formData.append(
      "profile_photo",
      e.target.elements.profilePhoto?.files[0] ?? null
    ); // Append file
    formData.append("country", selectedCountry?.label || selectedCountry);
    formData.append("state", selectedState?.label || selectedState);
    formData.append("city", selectedCity?.label || selectedCity);
    formData.append("pincode", e.target.elements.pin_code?.value ?? "");
    formData.append("address", e.target.elements.address?.value ?? "");
    formData.append("country_1", selectedCountry1?.label || selectedCountry1);
    formData.append("state_1", selectedState1?.label || selectedState1);
    formData.append("city_1", selectedCity1?.label || selectedCity1);
    formData.append("pincode_1", pincode1 ?? "");
    formData.append("permanent_address", permanentAddress ?? "");

    for (let [key, value] of formData.entries()) {
    }

    // Submit form data
    try {
      const res = await dispatch(postEmployeePersonalStore(formData)); // Dispatch FormData
      if (res.payload.status === true) {
        const employeeId = res.payload.employee_id;
        setEmployeeId(employeeId);
        dispatch(resetValidateFields());
        dispatch(fetchEmployeData(employeeId));
        nextStep(employeeId);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An unexpected error occurred. Please try again.";
    }
  };

  useEffect(() => {
    if (nationalityData?.NationalityData?.length > 0) {
      setSelectedNationality(
        nationalityData?.NationalityData[229]?.nationality
      );
    }
  }, [nationalityData]);

  return (
    
    <>
      
    

      <form onSubmit={handleSubmit}>
        <h5 className="mt-4">Step 1: Employee Details</h5>
        <div className="progress-bar ">
          <div className="progress" style={{ width: `${progress}%` }}></div>
        </div>
        <div className="row mb-2">
          <div className="col-md-6 mb-2">
            <label>First Name</label>
            <input
              id="input"
              type="text"
              name="firstName"
              placeholder="Enter First Name"
              value={
                firstName
                  ? firstName?.charAt(0).toUpperCase() + firstName?.slice(1)
                  : ""
              }
              onChange={(e) => {
                const updateFirstName = capitalizeFirstLetter(e.target.value);
                setFirstName(updateFirstName);
                setValidateError((prevErrors) => ({
                  ...prevErrors,
                  first_name: null,
                }));
              }}
            />
            {validateError?.first_name && (
              <span className="text-danger">
                {validateError?.first_name[0]}
              </span>
            )}
          </div>

          <div className="col-md-6 mb-2">
            <label>Middle Name (Optional)</label>
            <input
              id="input"
              type="text"
              name="middle_name"
              placeholder="Enter Middle Name"
              value={
                middleName
                  ? middleName?.charAt(0).toUpperCase() + middleName?.slice(1)
                  : ""
              }
              onChange={(e) => {
                const updateMiddleName = capitalizeFirstLetter(e.target.value);
                setMiddleName(updateMiddleName);
                setValidateError((prevErrors) => ({
                  ...prevErrors,
                  middle_name: null,
                }));
              }}
            />

            {validateError?.middle_name && (
              <span className="text-danger">
                {validateError?.middle_name[0]}
              </span>
            )}
          </div>

          <div className="col-md-6 mb-2">
            <label>Last Name</label>
            <input
              id="input"
              type="text"
              name="last_name"
              placeholder="Enter Last Name"
              value={
                lastName
                  ? lastName?.charAt(0).toUpperCase() + lastName?.slice(1)
                  : ""
              }
              onChange={(e) => {
                const updateLastName = capitalizeFirstLetter(e.target.value);

                setLastName(updateLastName);
                setValidateError((prevErrors) => ({
                  ...prevErrors,
                  last_name: null,
                }));
              }}
            />
            {validateError?.last_name && (
              <span className="text-danger">{validateError?.last_name[0]}</span>
            )}
          </div>

          <div className="col-md-6 mb-2">
            <label>Gender</label>
            <select
              id="gender"
              value={gender}
              name="gender"
              onChange={(e) => {
                setGender(e.target.value);
                setValidateError((prevErrors) => ({
                  ...prevErrors,
                  gender: null,
                }));
              }}
            >
              <option value="" disabled selected>
                Select Gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
            {validateError?.gender && (
              <span className="text-danger">{validateError?.gender[0]}</span>
            )}
          </div>

          <div className="col-md-6 mb-2">
            <label>Date of Birth</label>
            <input
              id="input"
              type="date"
              name="dob"
              placeholder="Enter Date of Birth"
              value={dob}
              onChange={(e) => {
                setDob(e.target.value);
                setValidateError((prevErrors) => ({
                  ...prevErrors,
                  dob: null,
                }));
              }}
            />
            {validateError?.dob && (
              <span className="text-danger">{validateError?.dob[0]}</span>
            )}
          </div>

          <div className="col-md-6 mb-2">
            <label>Nationality</label>
            <select
              id="nationality"
              name="nationality"
              value={selectedNationality}
              onChange={(e) => {
                setSelectedNationality(e.target.value);
                setValidateError((prevErrors) => ({
                  ...prevErrors,
                  nationality: null,
                }));
              }}
            >
              <option value="" disabled selected>
                Select Nationality
              </option>
              {nationalityData?.NationalityData?.length > 0 ? (
                nationalityData.NationalityData.map((nationality) => (
                  <option key={nationality.id} value={nationality.nationality}>
                    {nationality.nationality}
                  </option>
                ))
              ) : (
                <option disabled>No Nationality available</option>
              )}
            </select>
            {validateError?.nationality && (
              <span className="text-danger">
                {validateError?.nationality[0]}
              </span>
            )}
          </div>

          <div className="col-md-6 mb-2">
            <label>Contact</label>
            <input
              id="input"
              type="number"
              name="contact"
              placeholder="Enter Contact"
              value={contact || employeData?.edit_employee?.contact}
              onChange={(e) => {
                setContact(e.target.value);
                setValidateError((prevErrors) => ({
                  ...prevErrors,
                  contact: null,
                }));
              }}
            />
            {validateError?.contact && (
              <span className="text-danger">{validateError?.contact[0]}</span>
            )}
          </div>

          <div className="col-md-6 mb-2">
            <label>Alternate Number</label>
            <input
              id="input"
              type="number"
              name="alternate_number"
              placeholder="Enter Alternate Number"
              value={alternateNumber}
              onChange={(e) => {
                setAlternateNumber(e.target.value);
                setValidateError((prevErrors) => ({
                  ...prevErrors,
                  alternate_number: null,
                }));
              }}
            />
            {validateError?.alternate_number && (
              <span className="text-danger">
                {validateError?.alternate_number[0]}
              </span>
            )}
          </div>

          <div className="col-md-6 mb-2">
            <label>Personal Email</label>
            <input
              id="input"
              type="email"
              name="personal_email"
              value={personalEmail}
              onChange={(e) => {
                setPersonalEmail(e.target.value);
                setValidateError((prevErrors) => ({
                  ...prevErrors,
                  personal_email: null,
                }));
              }}
              placeholder="Enter Personal Email"
            />
            {validateError?.personal_email && (
              <span className="text-danger">
                {validateError?.personal_email[0]}
              </span>
            )}
          </div>

          <div className="col-md-6 mb-2">
            <label>Work Email</label>
            <input
              type="email"
              id="input"
              name="work_email"
              value={work_email || employeData?.edit_employee?.work_email}
              placeholder="Enter Work Email"
              onChange={(e) => setWorkEmail(e.target.value)}
            />
            {validateError?.work_email && (
              <span className="text-danger">
                {validateError?.work_email[0]}
              </span>
            )}
          </div>

          <div className="col-md-6 mb-2">
            <label for="job_role">Job Role</label>
            <select
              id="job_role"
              value={
                jobRole
                  ? jobRole?.charAt(0).toUpperCase() + jobRole?.slice(1)
                  : ""
              }
              name="jobRole"
              onChange={(e) => {
                // const updateJobRole = capitalizeFirstLetter(e.target.value);
                // setJobRole(updateJobRole);

                const selectedRole = roleData.roleData?.find(
                  (role) => role.name === e.target.value
                );

                // Update both states for ID and Name
                setJobRoleId(selectedRole.id);
                setJobRole(selectedRole.name);

                setValidateError((prevErrors) => ({
                  ...prevErrors,
                  job_role: null,
                }));
              }}
            >
              <option value="" disabled selected>
                Select Job Role
              </option>
              {roleData?.roleData?.length > 0 ? (
                roleData.roleData.map((role) => (
                  <option key={role.id} value={role.name}>
                    {role.name}
                  </option>
                ))
              ) : (
                <option disabled>No Job Role available</option>
              )}
            </select>
            {validateError?.job_role && (
              <span className="text-danger">{validateError?.job_role[0]}</span>
            )}
          </div>

          <div className="col-md-6 mb-2">
            <label className="d-block mt-1">Profile Photo</label>
            <div className="pt-1 rounded d-flex align-items-center">
              <input
                type="file"
                accept="image/*"
                name="profilePhoto"
                onChange={handleImageChange}
                className="form-control me-2" // Adds space between input and image preview
              />
            </div>
            {profilePhotoName && (
              <div>
                {profilePhotoName?.charAt(0).toUpperCase() +
                  profilePhotoName?.slice(1)}
              </div>
            )}
            {validateError?.profile_photo && (
              <span className="text-danger">
                {validateError?.profile_photo[0]}
              </span>
            )}
          </div>
        </div>

        <div class="row mb-2 border">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                checked={sameAsCurrent}
                onChange={handleCheckboxChange}
              />
              <label class="form-check-label fw-bold" for="flexCheckDefault">
                Same as current address
              </label>
            </div>
          </div>

          <div class="col-md-6 mt-2 mb-2 border-end">
            <h6>Current Address</h6>
            <div class="row mb-2">
              <div class="col-md-6 mb-2">
                <div class="form-group">
                  <label class="fw-bold" for="country">
                    Country
                  </label>
                  <Select
                    id="country"
                    name="country"
                    options={country ? country : ""}
                    placeholder={
                      selectedCountry
                        ? selectedCountry.label || selectedCountry
                        : "Select Country"
                    }
                    {...register("country")}
                    isSearchable
                    onChange={(e) => {
                      handleCountryChange(e);
                      setValidateError((prevErrors) => ({
                        ...prevErrors,
                        country: null,
                      }));
                    }}
                  />
                  {validateError?.country && (
                    <span className="text-danger">
                      {validateError?.country[0]}
                    </span>
                  )}
                </div>
              </div>
              <div class="col-md-6 mb-2">
                <div class="form-group">
                  <label class="fw-bold" for="state">
                    State
                  </label>
                  <Select
                    options={state}
                    name="state"
                    placeholder={
                      selectedState
                        ? selectedState.label || selectedState
                        : "Select State"
                    }
                    {...register("state")}
                    isSearchable
                    onChange={(e) => {
                      handleStateChange(e);
                      setValidateError((prevErrors) => ({
                        ...prevErrors,
                        state: null,
                      }));
                    }}
                  />
                  {validateError?.state && (
                    <span className="text-danger">
                      {validateError?.state[0]}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6 mb-2">
                <div class="form-group">
                  <label class="fw-bold" for="city">
                    City
                  </label>
                  <Select
                    options={city}
                    name="city"
                    placeholder={
                      selectedCity
                        ? selectedCity.label || selectedCity
                        : "Select City"
                    }
                    {...register("city")}
                    onChange={(e) => {
                      handleCityChange(e);
                      setValidateError((prevErrors) => ({
                        ...prevErrors,
                        city: null,
                      }));
                    }}
                    isSearchable
                  />
                  {validateError?.city && (
                    <span className="text-danger">
                      {validateError?.city[0]}
                    </span>
                  )}
                </div>
              </div>
              <div class="col-md-6 mb-2">
                <div class="form-group">
                  <label class="fw-bold" for="pincode">
                    Pincode
                  </label>
                  <input
                    id="input"
                    type="text"
                    name="pin_code"
                    value={pincode}
                    onChange={(e) => {
                      setPincode(e.target.value);
                      setValidateError((prevErrors) => ({
                        ...prevErrors,
                        pincode: null,
                      }));
                    }}
                    placeholder="Enter Pincode"
                  />
                  {validateError?.pincode && (
                    <span className="text-danger">
                      {validateError?.pincode[0]}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-12 mb-2">
                <div class="form-group">
                  <label class="fw-bold" for="address">
                    Address
                  </label>
                  <textarea
                    class="p-3"
                    rows="2"
                    placeholder="Enter Address"
                    id="address"
                    name="address"
                    value={
                      address
                        ? address?.charAt(0).toUpperCase() + address?.slice(1)
                        : ""
                    }
                    {...register("address")}
                    onChange={(e) => {
                      const updateAddress = capitalizeFirstLetter(
                        e.target.value
                      );
                      setAddress(updateAddress);
                      setValidateError((prevErrors) => ({
                        ...prevErrors,
                        address: null,
                      }));
                    }}
                  >
                    {employeData?.edit_employee?.address}
                  </textarea>
                  {validateError?.address && (
                    <span className="text-danger">
                      {validateError?.address[0]}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 mt-2 mb-2 border-start">
            <h6>Permanent Address</h6>
            <div class="row mb-2">
              <div class="col-md-6 mb-2">
                <div class="form-group">
                  <label class="fw-bold" for="clientCountry">
                    Country
                  </label>
                  <Select
                    id="country1"
                    name="selectedCountry1"
                    options={country ? country : ""}
                    placeholder={
                      selectedCountry1
                        ? selectedCountry1?.label || selectedCountry1
                        : "Select Country"
                    }
                    value={selectedCountry1}
                    {...register("country1")}
                    isSearchable
                    onChange={(e) => {
                      handleCountryChange1(e);
                      setValidateError((prevErrors) => ({
                        ...prevErrors,
                        country_1: null,
                      }));
                    }}
                    isDisabled={sameAsCurrent}
                  />
                  {validateError?.country_1 && (
                    <span className="text-danger">
                      {validateError?.country_1[0]}
                    </span>
                  )}
                </div>
              </div>
              <div class="col-md-6 mb-2">
                <div class="form-group">
                  <label class="fw-bold" for="clientState">
                    State
                  </label>
                  <Select
                    id="state1"
                    name="state_1"
                    options={state}
                    placeholder={
                      selectedState1
                        ? selectedState1.label || selectedState1
                        : "Select State"
                    }
                    {...register("state1")}
                    value={getValues("state1") || selectedState1}
                    isSearchable
                    onChange={(e) => {
                      handleStateChange1(e);
                      setValidateError((prevErrors) => ({
                        ...prevErrors,
                        state_1: null,
                      }));
                    }}
                    isDisabled={sameAsCurrent}
                  />
                  {validateError?.state_1 && (
                    <span className="text-danger">
                      {validateError?.state_1[0]}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6 mb-2">
                <div class="form-group">
                  <label class="fw-bold" for="clientCity">
                    City
                  </label>
                  <Select
                    id="city1"
                    name="city_1"
                    options={city}
                    placeholder={
                      selectedCity1
                        ? selectedCity1.label || selectedCity1
                        : "Select City"
                    }
                    value={getValues("city1") || selectedCity1}
                    {...register("city1")}
                    onChange={(e) => {
                      handleCityChange1(e);
                      setValidateError((prevErrors) => ({
                        ...prevErrors,
                        city_1: null,
                      }));
                    }}
                    isSearchable
                    isDisabled={sameAsCurrent}
                  />
                  {validateError?.city_1 && (
                    <span className="text-danger">
                      {validateError?.city_1[0]}
                    </span>
                  )}
                </div>
              </div>
              <div class="col-md-6 mb-2">
                <div class="form-group">
                  <label class="fw-bold" for="pincode_1">
                    Pincode
                  </label>
                  <input
                    id="input"
                    type="text"
                    name="pincode_1"
                    value={pincode1}
                    placeholder="Enter Pincode"
                    onChange={(e) => {
                      setPincode1(e.target.value);
                      setValidateError((prevErrors) => ({
                        ...prevErrors,
                        pincode_1: null,
                      }));
                    }}
                    readOnly={sameAsCurrent}
                  />
                  {validateError?.pincode_1 && (
                    <span className="text-danger">
                      {validateError?.pincode_1[0]}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-12 mb-2">
                <div class="form-group">
                  <label class="fw-bold" for="permanent_address">
                    Address
                  </label>
                  <textarea
                    class="p-3"
                    rows="2"
                    name="permanent_address"
                    placeholder="Enter Address"
                    id="permanent_address"
                    value={
                      permanentAddress
                        ? permanentAddress?.charAt(0).toUpperCase() +
                          permanentAddress?.slice(1)
                        : ""
                    }
                    {...register("permanent_address")}
                    onChange={(e) => {
                      const updatePermanentAddress = capitalizeFirstLetter(
                        e.target.value
                      );
                      setPermanentAddress(updatePermanentAddress);
                      setValidateError((prevErrors) => ({
                        ...prevErrors,
                        permanent_address: null,
                      }));
                    }}
                    disabled={sameAsCurrent}
                  ></textarea>
                  {validateError?.permanent_address && (
                    <span className="text-danger">
                      {validateError?.permanent_address[0]}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-success mt-2 mb-5">
          Next
        </button>
      </form>
      {step1Loading ? <Loader /> : null}
    </>
  );
};

export default EmployeeDetails;
