import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteJobProfile,
  getJobProfile,
  postJobProfile,
  UpdateJobProfile,
} from "@Networking/APIs/Employee/EmployeeApi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../masterForm.css";

const MySwal = withReactContent(Swal);

const JobTitleForm = () => {
  const [selectedLocation, setJobProfile] = useState(null);
  const [locationInput, setLocationInput] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { type } = useParams();

  useEffect(() => {
    dispatch(getJobProfile());
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const jobProfileData = useSelector((state) => state.EmployeesSlice);

  const onSubmit = async (data) => {
    const newJobProfile = {
      profile_name: data.profile_name,
    };

    try {
      await dispatch(postJobProfile(newJobProfile));
      await dispatch(getJobProfile());
      reset();
    } catch (error) {}
  };

  const handleEdit = (profile) => {
    setJobProfile(profile);
    setLocationInput(profile.profile_name);
    openEditModal(profile);
  };

  const handleSubmitEdit = async (id, newName) => {
    const updateData = { id, profile_name: newName }; // No need to access newName.name
    try {
      await dispatch(UpdateJobProfile(updateData));
      await dispatch(getJobProfile());
    } catch (error) {
      MySwal.fire("Error!", "Something went wrong while updating.", "error");
    }
  };

  const openEditModal = (profile) => {
    MySwal.fire({
      title: "Edit Work Location",
      input: "text",
      inputValue: profile.profile_name,
      inputPlaceholder: "Enter New Location",
      showCancelButton: true,
      confirmButtonText: "Update",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      customClass: {
        title: "custom-title",
        input: "custom-input-size",
        confirmButton: "custom-confirm-button",
        cancelButton: "custom-cancel-button",
      },
      preConfirm: () => {
        const location = Swal.getInput().value.trim();
        return location;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmitEdit(profile.id, result.value);
      }
    });
  };

  const handleDelete = (profile) => {
    const isConfirmed = window.confirm(
      "Are you sure? You won't be able to revert this!"
    );

    if (isConfirmed) {
      dispatch(DeleteJobProfile(profile.id));
      dispatch(getJobProfile());
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="">
              <i class="fa-solid fa-list me-2"></i>HRM / Employee Master's / Job
              Title
            </h6>
          </div>
        </div>

        <div className="m-1">
          <button
            type="button"
            className="btn btn-secondary mb-3"
            onClick={handleBack}
          >
            <i className="fa fa-arrow-left me-2"></i>
            Back
          </button>
        </div>

        <div className="card">
          <div className="card-body">
              <div className="row p-3">
                <div className="col-md-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row d-flex align-items-center">
                      <div className="col-md-3">
                        <h6>Job Title:</h6>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          placeholder="Enter Job Title"
                          className="form-control"
                          id="profile_name"
                          {...register("profile_name", {
                            required: "Job Title is required",
                          })}
                        />
                        {errors.profile_name && (
                          <span className="text-danger">
                            {errors.profile_name.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-3">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="row mt-3 border rounded">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Job Title</th>
                              <th className="text-end">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {jobProfileData?.JobProfileData.length > 0 ? (
                              jobProfileData?.JobProfileData.map((profile) => (
                                <tr key={profile.id}>
                                  <td>{profile.profile_name}</td>
                                  <td>
                                    <div className="d-flex justify-content-end">
                                      <button
                                        type="button"
                                        className="btn btn-sm rounded btn-primary me-2"
                                        onClick={() => handleEdit(profile)}
                                      >
                                        <i className="fa fa-edit"></i>
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-sm rounded btn-danger"
                                        onClick={() => handleDelete(profile)}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="2" className="text-center">
                                  No work locations available.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobTitleForm;
