import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DeleteWorkType, getWorkType, postWorkType, updateWorkType } from "@Networking/APIs/Employee/EmployeeApi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../masterForm.css";
import { workType } from "@Networking/APIs/NWConfig";


const MySwal = withReactContent(Swal);


const WorkTypeForm = () => {

  const [selectedName, setWorkType] = useState(null);
  const [nameInput, setNameInput] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm();
  
    const { type } = useParams();
  
    useEffect(() => {
      dispatch(getWorkType());
    }, []);
  
    const handleBack = () => {
      navigate(-1);
    };
  
    

    const workTypeData = useSelector((state) => state.EmployeesSlice);
  
    const onSubmit = async (data) => {

        const newWorkType = {
          type_of_work: data.type_of_work,
        };
        
    
        try {
          await dispatch(postWorkType(newWorkType));
          await dispatch(getWorkType());
          reset();
        } catch (error) {
        }
      };

      const handleEdit = (workType) => {
        setWorkType(workType);
        setNameInput(workType.type_of_work);
        openEditModal(workType);
      };
    
      const handleSubmitEdit = async (id, newName) => {
        const updateData = { id, type_of_work: newName }; // No need to access newName.name
        try {
          await dispatch(updateWorkType(updateData));
          await dispatch(getWorkType());
        } catch (error) {
          MySwal.fire("Error!", "Something went wrong while updating.", "error");
        }
      };
      
    
      const openEditModal = (workType) => {
        MySwal.fire({
          title: "Edit Work Type",
          input: "text",
          inputValue: workType.type_of_work,
          inputPlaceholder: 'Enter new name',
          showCancelButton: true,
          confirmButtonText: 'Update',
          cancelButtonText: 'Cancel',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          customClass: {
            title: 'custom-title',
            input: 'custom-input-size',
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-cancel-button'
          },
          preConfirm: () => {
            const newName = Swal.getInput().value.trim();
            if (!newName) {
              Swal.showValidationMessage("Please enter a new name");
            }
            return newName;
          },
        }).then((result) => {
          if (result.isConfirmed) {
            handleSubmitEdit(workType.id, result.value);
          }
        });
      };
    
  

      const handleDelete = (workType) => {
        const isConfirmed = window.confirm("Are you sure? You won't be able to revert this!");
      
        if (isConfirmed) {
          dispatch(DeleteWorkType(workType.id));
          dispatch(getWorkType());
        }
      }
      

    return (
        <>

<div className="container-fluid">

<div className="row">
        <div className="col-md-12 col-sm-12">
          <h6 className="">
            <i class="fa-solid fa-list me-2"></i>HRM / Employee Master's / Work Type
          </h6>
        </div>
      </div>


      <div className="m-1">
        <button
         type="button"
         className="btn btn-secondary mb-3"
         onClick={handleBack}
       >
         <i className="fa fa-arrow-left me-2"></i>
         Back
       </button>
       </div>

       
        <div className="card">
            <div className="card-body">
              <div className="row p-3">
                <div className="col-md-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row d-flex align-items-center">
                    <div className="col-md-3">
                      <h6>Work Type:</h6>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="Enter Work Type"
                        className="form-control"
                        id="type_of_work"
                        {...register("type_of_work", {
                          required: "Work Type is required",
                        })}
                      />
                      {errors.type_of_work && (
                        <span className="text-danger">
                          {errors.type_of_work.message}
                        </span>
                      )}
                    </div>
                    <div className="col-md-3">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                  </form>
                  <div className="row mt-3 border rounded">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Work Type</th>
                              <th className="text-end">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {workTypeData?.WorkTypeData.length > 0 ? (
                              workTypeData?.WorkTypeData.map((workType) => (
                                <tr key={workType.id}>
                                  <td>{workType.type_of_work}</td>
                                  <td>
                                    <div className="d-flex justify-content-end">
                                      <button
                                        type="button"
                                        className="btn btn-sm rounded btn-primary me-2"
                                        onClick={() => handleEdit(workType)}
                                      >
                                        <i className="fa fa-edit"></i>
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-sm rounded btn-danger"
                                        onClick={() =>
                                          handleDelete(workType)
                                        }
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="2" className="text-center">
                                  No Time Types available.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      </>
    );
  };

export default WorkTypeForm;