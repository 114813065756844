import React from "react";
import { Link } from "react-router-dom";
import "../mastersForm/masterForm.css";

const EmployeeMaster = () => {
  const sections = [
    { title: "Work Locations", path: "/workLocation", icon: "mdi-map-marker" },
    { title: "Job Role", path: "/jobRole", icon: "mdi-briefcase" },
    { title: "Job Title", path: "/jobTitle", icon: "mdi-account-badge" },
    { title: "Time Type", path: "/timeType", icon: "mdi-clock" },
    { title: "Work Type", path: "/workType", icon: "mdi-hammer" },
    { title: "Employee Status", path: "/employeeStatus", icon: "mdi-account" },
    { title: "Probation Policy", path: "/probationPolicy", icon: "mdi-check" },
    {
      title: "Onboarding Settings",
      path: "/onboardSettings",
      icon: "mdi-cogs",
    },
    { title: "Shifts", path: "/shifts", icon: "mdi-calendar" },
    { title: "Weekly Off", path: "/weeklyOff", icon: "mdi-calendar-remove" },
    { title: "Departments", path: "/departments", icon: "mdi-office-building" },
  ];

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <h6 className="">
            <i class="fa-solid fa-list me-2"></i>HRM / Employee Master's 
          </h6>
        </div>
      </div>

      <div className="card bg-gradient-info rounded-top-2 p-2 mb-4">
        <div className="row d-flex align-items-center">
          <div className="col-md-12 col-sm-12">
            <h3 className="page-title m-0  text-light">
              <span className="mx-2 text-primary">
                <i className="mdi mdi-file  text-light"></i>
              </span>
              Employee Masters
            </h3>
          </div>
        </div>
      </div>

      <div className="row gy-4">
          {sections.map((section, index) => (
            <div
              className="col-md-4 col-sm-6"
              key={index}
            >
              <div className="card shadow h-100 border-0">
                {/* Card Header */}

                {/* Card Body */}
                  <div className="row ">
                    <div className="col-md-12">
                <div className="card-body text-center">
                      <div className="card-header bg-gradient-info text-white ">
                        <h5 className="card-title text-light mb-0 ">
                          {section.title}
                        {/* <i className={`mdi ${section.icon} fs-4 me-3`}></i> */}
                        <Link
                        to={section.path}
                        className="btn btn-gradient-info py-2 text-white w-100  border mt-2 rounded-pill emMvBtn"
                      >
                        View  <i class="fa-solid fa-arrow-right ms-2"></i>
                      </Link>
                      </h5>

                      </div>
                    </div>
                 
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
    </div>
  );
};

export default EmployeeMaster;
