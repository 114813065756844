import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {BASE_URL,TaskEdit,TaskList,taskStore, TaskUpdate} from '@Networking/APIs/NWConfig';
 

 
  // Task store
export const storeTask = createAsyncThunk(
    "post/storeTask",
    async(post) => {
        let url = BASE_URL + taskStore; 
      
        const token = localStorage.getItem("authToken");

        try {
            const response = (await axios.post(url, post, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
            })).data;

                return Promise.resolve(response);
        } catch(error) {
            return error;
        }
    }
);


export const getTaskList = createAsyncThunk(
    'get/getTaskList',
    async () => {  

      let url = BASE_URL + TaskList;  
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );


// edit task 

export const getEditTask = createAsyncThunk(
  'get/getEditTask',
  async ({ id }) => {  

    // Using template literals to build the URL
    let url = BASE_URL + TaskEdit + id ;  // Corrected line

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);


// Update Task

export const postTaskUpdate = createAsyncThunk(
  'post/postTaskUpdate',
  async ({ data , id }) => {  
    // Using template literals to build the URL
    let url = BASE_URL + TaskUpdate  + id; // Construct the URL

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.post(url , data,{
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return error;
    }
  },
);