import { createSlice } from '@reduxjs/toolkit';
import leaveShowApi from '@Networking/APIs/Leave/leaveShowApi';

const leaveShowSlice = createSlice({
  name: 'LeaveShowSlice',
  initialState: {
    leaves: [],
    loading: false,
    apiErrors: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(leaveShowApi.pending, (state) => {
        state.loading = true;
        state.apiErrors = {};
      })
      .addCase(leaveShowApi.fulfilled, (state, action) => {
        state.loading = false;
        state.leaves = action.payload.leaves;  
      })
      .addCase(leaveShowApi.rejected, (state, action) => {
        state.loading = false;
        state.apiErrors = action.payload || {};
      });
  },
});


export default leaveShowSlice.reducer;
