// src/api/leaveApi.js
import axios from 'axios';
import {BASE_URL, leaveStore,} from '@Networking/APIs/NWConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';

// Employee Store
export const applyLeaveApi = createAsyncThunk(
  "post/applyLeaveApi",
  async(post) => {

      let url = BASE_URL + leaveStore;
      const token = localStorage.getItem("authToken");

      try {
          const response = (await axios.post(url, post, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;
              return Promise.resolve(response);
      } catch(response) {
          return response;
      }
  }
);

export default applyLeaveApi;