import React, { useEffect, useMemo, useState } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchLeaveList,
  fetchTotalLeavesByEmployee,
  fetchEmployeeList,
} from "@Networking/APIs/Leave/allLeaveApi";
import "bootstrap/dist/css/bootstrap.min.css";

//GobalFilter
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => (
  <span>
    <input
      value={globalFilter || ""}
      onChange={(e) => setGlobalFilter(e.target.value || undefined)}
      placeholder="Search all columns"
      className="form-control"
      style={{ width: "200px", display: "inline-block", marginLeft: "10px" }}
    />
  </span>
);

//Status
const getStatusClass = (status) => {
  switch (status) {
    case "Approved":
      return "badge btn btn-success text-white";
    case "Pending":
      return "badge badge-warning text-white";
    case "Cancel":
      return "badge badge-danger text-white";
    default:
      return "";
  }
};

const TableComponent = ({ data, columns, title }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
    useGlobalFilter,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="card">
        <div className="card-header bg-gradient-info p-1 text-light text-center align-items-center">
        <h6>{title}</h6>
      </div>
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <GlobalFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
        <table {...getTableProps()} className="table table-hover">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="d-flex flex-wrap justify-content-between align-items-center p-3 border-top mt-3">
          <div>
            <span className="text-muted">
              Page <strong>{pageIndex + 1}</strong> of{" "}
              <strong>{pageOptions.length}</strong>
            </span>
          </div>
          <div className="pagination-controls d-flex align-items-center">
            <button
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              className="btn btn-light btn-sm mx-1"
              title="First Page"
            >
              <i className="bi bi-chevron-double-left"></i>
            </button>
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="btn btn-light btn-sm mx-1"
              title="Previous Page"
            >
              <i className="bi bi-chevron-left"></i>
            </button>

            <div className="d-flex">
              {pageOptions.map((page, index) => (
                <button
                  key={index}
                  onClick={() => gotoPage(page)}
                  className={`btn btn-sm mx-1 ${
                    pageIndex === page ? "btn-primary text-white" : "btn-light"
                  }`}
                  style={{
                    borderRadius: "50%",
                    width: "35px",
                    height: "35px",
                    padding: "5px",
                  }}
                  title={`Page ${page + 1}`}
                >
                  {page + 1}
                </button>
              ))}
            </div>

            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className="btn btn-light btn-sm mx-1"
              title="Next Page"
            >
              <i className="bi bi-chevron-right"></i>
            </button>
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              className="btn btn-light btn-sm mx-1"
              title="Last Page"
            >
              <i className="bi bi-chevron-double-right"></i>
            </button>
          </div>
          <div className="d-flex align-items-center">
            <label htmlFor="pageSize" className="me-2 mb-0 text-muted">
              Show:
            </label>
            <select
              id="pageSize"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
              className="form-select form-select-sm"
              style={{ width: "80px" }}
            >
              {[10, 20, 30, 40, 50].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

const LeaveList = () => {
  //Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: leaveData,
    employeeData,
    totalLeaves,
  } = useSelector((state) => state.LeaveAllSlice || {});
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");

  //UseEffects
  useEffect(() => {
    dispatch(fetchLeaveList());
    dispatch(fetchEmployeeList());
  }, [dispatch]);

  const handleEdit = (employee) => {
    navigate(`/LeaveEdit/${employee.id}`, { state: { employee } });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedEmployee || !selectedMonth) {
      alert("Please select both employee and month");
      return;
    }

    const postData = {
      user_id: selectedEmployee,
      month: selectedMonth,
    };

    dispatch(fetchTotalLeavesByEmployee(postData));
  };

  const teamLeavesColumns = useMemo(
    () => [
      { Header: "Sn No", Cell: ({ row }) => row.index + 1 },
      { Header: "Name", accessor: "user.name" },
      { Header: "Subject", accessor: "subject" },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span className={`p-2 ${getStatusClass(value)}`}>{value}</span>
        ),
      },
      { Header: "Approve By", accessor: "approver.name" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <button
            className="btn btn-sm btn-primary me-1"
            onClick={() => handleEdit(row.original)}
            disabled={row.original.status === "Approved"}
          >
            <i className="fa fa-pencil"></i>
          </button>
        ),
      },
    ],
    []
  );

  const allLeavesColumns = useMemo(
    () => [
      { Header: "Sn No", Cell: ({ row }) => row.index + 1 },
      { Header: "Name", accessor: "user.name" },
      { Header: "Subject", accessor: "subject" },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span className={`p-2 ${getStatusClass(value)}`}>{value}</span>
        ),
      },
      { Header: "Approve By", accessor: "approver.name" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <button
            className="btn btn-sm btn-primary me-1"
            onClick={() => handleEdit(row.original)}
            disabled={row.original.status === "Approved"}
          >
            <i className="fa fa-pencil"></i>
          </button>
        ),
      },
    ],
    []
  );

  return (
    <div className="container-fluid">

      <div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="">
              <i class="fa-solid fa-list me-1"></i>HRM / Leave / All Leave
            </h6>
          </div>
        </div>

    
      <div className="container-scroller mt-4">
        <div
          className="card border-0 rounded-lg mb-4"
          style={{ padding: "20px" }}
        >
          <form onSubmit={handleSubmit}>
            <div className="row mt-2 d-flex align-items-center p-1">
              <div className="col-md-4 mb-2">
                <label htmlFor="employeeSelect">Employee Name</label>
                <select
                  className="form-select"
                  id="employeeSelect"
                  value={selectedEmployee}
                  onChange={(e) => setSelectedEmployee(e.target.value)}
                >
                  <option value="" disabled>
                    Select Employee
                  </option>
                  {employeeData?.employee_list?.map((employee) => (
                    <option key={employee.user_id} value={employee.user_id}>
                      {employee.first_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3 mb-2">
                <label htmlFor="workingMonth">Select Month</label>
                <input
                  type="month"
                  id="workingMonth"
                  className="form-control"
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                />
              </div>
              <div className="col-md-3 mb-2">
                <label className="fw-bold" htmlFor="holidayDate">
                  Total Leaves
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="holidayDate"
                  value={totalLeaves}
                  readOnly
                />
              </div>
              <div className="col-md-2 mb-2">
                <button
                  type="submit"
                  className="btn btn-primary rounded-pill w-100"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
        <TableComponent
          data={leaveData?.team_leaves || []}
          columns={teamLeavesColumns}
          title="Team Leaves"
        />
        <br></br>
        <TableComponent
          data={leaveData?.leave || []}
          columns={allLeavesColumns}
          title="All Leaves"
        />
      </div>
    </div>
  );
};

export default LeaveList;
