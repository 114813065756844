import React from 'react'

const UserManagement = () => {
  return (
    <div>
      UserManagement
    </div>
  )
}

export default UserManagement;