import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, nationality,  } from "@Networking/APIs/NWConfig";
import Cookies from 'js-cookie';

export const getNationality = createAsyncThunk(
    'get/getNationality',
    async () => {  

      // Using template literals to build the URL
      let url = BASE_URL + nationality;  // Corrected line
  
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );
