import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar, Doughnut } from "react-chartjs-2";
import { Modal, Button, ProgressBar } from "react-bootstrap";
import {
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "chart.js/auto";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  getadminDashboardData,
  getDashboardData,
  updateBirthdayStatus,
} from "@Networking/APIs/Dashboard/dashboardApi";

const HRMDashboard = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDashboardData());
    dispatch(getadminDashboardData());
  }, []);

  const DashboardData = useSelector((state) => state.DashboardSlice);
  console.log(DashboardData, "DashboardData");

  // const AdminDashboardData = useSelector((state) => state.DashboardSlice);

  const todayDate = new Date().toISOString().split("T")[0]; // Define today's date

  const workingHoursData = [
    { day: "Monday", hours: 8 },
    { day: "Tuesday", hours: 6 },
    { day: "Wednesday", hours: 7 },
    { day: "Thursday", hours: 9 },
    { day: "Friday", hours: 8 },
    { day: "Saturday", hours: 5 },
  ];

  // Extract labels (days) and data (hours) for the chart
  const labels = workingHoursData.map((data) => data.day);
  const data = workingHoursData.map((data) => data.hours);

  // Chart.js configuration
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Working Hours",
        data: data,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
        borderColor: "#ccc",
        borderWidth: 0,
        borderRadius: 0, // Rounded bar corners
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#000",
        titleColor: "#fff",
        bodyColor: "#fff",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          color: "#eaeaea",
        },
        ticks: {
          stepSize: 2,
        },
      },
    },
  };

  // Attendance Card
  const [selectedDate, setSelectedDate] = useState("");
  const [attendanceData, setAttendanceData] = useState({
    "2024-12-01": { checkIn: "9:00 AM", checkOut: "6:00 PM" },
    "2024-12-02": { checkIn: "9:15 AM", checkOut: "5:45 PM" },
  });

  const [monthlySummary, setMonthlySummary] = useState([
    { date: "2024-12-01", status: "Present" },
    { date: "2024-12-02", status: "Present" },
    { date: "2024-12-03", status: "Absent" },
  ]);


  const renderAttendanceDetails = () => {
    if (!selectedDate) {
      return;
    }

    const attendance = attendanceData[selectedDate];
    if (attendance) {
      return (
        <div>
          <div className="row alert alert-primary m-auto">
            <div className="col-md-6">
              <p>Check-In Time: {attendance.checkIn}</p>
            </div>
            <div className="col-md-6">
              <p>Check-Out Time: {attendance.checkOut}</p>
            </div>
          </div>
        </div>
      );
    } else {
      return <p>No attendance data available for the selected date.</p>;
    }
  };

  const renderMonthlySummary = () => {
    if (monthlySummary.length === 0) {
      return <p className="text-muted">No summary available for this month.</p>;
    }

    return (
      <ul className="list-group">
        {monthlySummary.map((record, index) => (
          <li key={index} className="list-group-item">
            <strong>{record.date}:</strong> {record.status}
          </li>
        ))}
      </ul>
    );
  };

  ChartJS.register(ArcElement, Tooltip, Legend);

  const leaveData = {
    labels: ["Paid Leave", "Unpaid Leave"],
    datasets: [
      {
        label: "Leave Status",
        data: [20, 10], // Replace with your data
        backgroundColor: ["#4caf50", "#f44336"], // Colors for the segments
        hoverBackgroundColor: ["#66bb6a", "#ef5350"], // Hover colors
        borderWidth: 1, // Border width around segments
      },
    ],
  };

  const leaveoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",

        plugins: {
          legend: {
            display: true,
            position: "bottom",
            labels: {
              generateLabels: (chart) => {
                const original =
                  ChartJS.overrides.doughnut.plugins.legend.labels
                    .generateLabels;
                const labels = original(chart);
                const dataset = chart.data.datasets[0];

                labels.forEach((label, index) => {
                  const count = dataset.data[index];
                  label.text = `${label.text} (${count})`; // Add the count next to the label
                });

                return labels;
              },
            },
          },
        },
      },
      tooltip: {
        enabled: true, // Disable default tooltips
      },
      // Custom plugin to draw the numbers on the donut
      datalabels: {
        display: true,
        formatter: (value) => value, // Display the count (number of leaves)
        color: "#36454F", // Text color
        font: {
          weight: "bold",
          size: 16,
        },
      },
    },
  };

  const [isBirthday, setIsBirthday] = useState(false);
  const [isId, setIsId] = useState("");
  const [isShow, setIsShow] = useState(
    DashboardData?.DashboardData?.totalvijaynagarCount);

  const userName = localStorage.getItem("name");

  const bdayData =
    (DashboardData?.DashboardData?.employeeBirthday &&
      DashboardData.DashboardData.employeeBirthday.map((data) => ({
        id: data.id,
        name: data.employee.first_name + " " + data.employee.last_name,
      }))) ||
    [];

  useEffect(() => {
    if (bdayData && bdayData.length > 0) {
      setIsBirthday(true);
      setIsId(bdayData[0].id);
    }
  }, [bdayData]);

  useEffect(() => {
    if (isBirthday && isId) {
      Swal.fire({
        title: `Happy Birthday!`,
        text: "Enjoy your special day 🎉",
        confirmButtonText: "Thank you!",
        imageUrl:
          "https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExamZiMzd3aHc0eDU3aG55MGtzbTkzaHlyM2FwcTN0M2k2dDJyeHJmdyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/qnWjDNWHm9Wy1zLwG8/giphy.gif", // URL of the GIF
        imageWidth: 400,
        imageHeight: 300,
        imageAlt: "Birthday image",
      }).then((result) => {
        if (result.isConfirmed) {
          handleSubmitEdit(isId);
        }
      });
    }
  }, [isBirthday]);

  const handleSubmitEdit = async (id) => {
    console.log(id, "uesuhd");
    const updateData = { id }; // No need to access newName.name
    try {
      await dispatch(updateBirthdayStatus(updateData));
      console.log(id, "748787878");
    } catch (error) {
      console.log(error, "error in updateBirthdayStatus");
    }
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setIsShow(DashboardData?.DashboardData[value] || "");
  };

  return (
    <>
      <div className="page-header">
        <h3 className="page-title">
          Welcome back, {userName}{" "}
          <i className="fa-solid fa-hand fs-5 text-warning"></i>
        </h3>
      </div>

      <div className="row g-6 mt-2">
        <div className="col-md-12 border-end border-primary border-2">
          <div className="row">
           
            <div className="col-md-3 col-sm-4 border-end border-primary border-2">
          <div className="card shadow custom-top-border border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <span className="h6 font-semibold text-muted text-sm d-block mb-2 border-bottom border-secondary pb-1">
                    Total Employees
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 text-start">
                  <button type="button" className="btn dbMnCrLn">
                    View All <i class="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
                <div className="col-md-4 text-end border-start">
                  <span className="h3 font-bold mb-0">
                  {DashboardData?.DashboardData?.totalEmployeesCount ?? (
                          <p className="text-danger">No data available</p>
                        )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 col-sm-4 border-end border-primary border-2">
          <div className="card shadow custom-top-border border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <span className="h6 font-semibold text-muted text-sm d-block mb-2 border-bottom border-secondary pb-1">
                    Total Employee on leave
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 text-start">
                  <button type="button" className="btn dbMnCrLn">
                    View All <i class="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
                <div className="col-md-4 text-end border-start">
                  <span className="h3 font-bold mb-0">
                  {DashboardData?.DashboardData?.totalleaves ?? (
                          <p className="text-danger">No data available</p>
                        )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

            <div className="col-md-6">
              <div className="col-md-12 col-sm-4 ">
                <div className="card shadow custom-top-border">
                  <div className="card-body">
                    <div className="row align-items-center">
                      {/* Dropdown Section */}
                      <div className="col-md-10">
                        {/* <label htmlFor="statsDropdown" className="form-label">
                          Select Item for Stats
                        </label> */}
                        <select
                          id="statsDropdown"
                          className="form-select border-0 shadow-sm"
                          onChange={handleSelectChange}
                          defaultValue=""
                          size="3" 
                          >                          
                          {/* <option value="" disabled>
                            Select Item
                          </option> */}
                          <option value="totalpalasiaCount">
                            Total employees in Palasia
                          </option>
                          <option value="totalvijaynagarCount"
                          
                          >
                            Total employees in Vijay Nagar
                          </option>
                          <option value="totalMaleCount">
                            Total male employees
                          </option>
                          <option value="totalFemaleCount">
                            Total female employees
                          </option>
                        </select>
                      </div>

                      {/* Display Selected Data */}
                      <div className="col-md-2 text-center">
                        <div className=" p-2 ">
                          <h1 className="text-mute m-0">
                            {isShow ? `${isShow}` : ""}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2"></div>
          <div className="row mt-2">
            
            <div className="col-md-6">
              <div className="card shadow-sm">
                <div className="card-header edAnCh text-white d-flex justify-content-between align-items-center p-2">
                  <h5 className="m-0">Attendance Details</h5>
                  <i class="fa-solid fa-clipboard-user"></i>
                </div>
                <div className="card-body">
                  <div className="row p-0">
                    <div className="col-md-6">
                      <input
                        type="date"
                        className="inputText"
                      />
                    </div>
                  </div>
                  <div className="mt-3">{renderAttendanceDetails()}</div>
                  <hr />
                  <h6>Monthly Attendance Summary</h6>
                  {renderMonthlySummary()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr></hr>
      <div className="row g-6 mt-3">
        <div className="col-md-3 border-end border-primary border-2">
          <div className="card ">
            <div className="card-header edAnCh text-white d-flex justify-content-between align-items-center p-2">
              <h5 className="m-0">Business Unit</h5>
              <i class="fa-solid fa-chalkboard-user"></i>
            </div>
            <div className="card-body">
              {DashboardData?.DashboardData?.Business_unit &&
              DashboardData.DashboardData.Business_unit.length > 0 ? (
                <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                  <table
                    className="table table-striped table-bordered"
                    style={{ maxHeight: "50px", overflowY: "auto" }}
                  >
                    <thead style={{ position: "sticky", top: -1, zIndex: 1 }}>
                      <tr>
                        <th className="text-center">Department</th>
                      </tr>
                    </thead>
                    <tbody>
                      {DashboardData.DashboardData.Business_unit.map(
                        (units, index) => (
                          <tr key={index}>
                            <td className="text-center">{units.department}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p style={{ color: "red" }}>No data available</p>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-3 border-end border-primary border-2">
          <div className="card ">
            <div className="card-header edAnCh text-white d-flex justify-content-between align-items-center p-2">
              <h5 className="m-0">Job Roles</h5>
              <i class="fa-solid fa-briefcase"></i>
            </div>
            <div className="card-body">
              {DashboardData?.DashboardData?.job_role &&
              DashboardData.DashboardData.job_role.length > 0 ? (
                <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                  <table className="table table-striped table-bordered">
                    <thead
                      style={{
                        position: "sticky",
                        top: -1,
                        backgroundColor: "white",
                        zIndex: 1,
                      }}
                    >
                      <tr>
                        <th className="text-center">Job Role</th>
                      </tr>
                    </thead>
                    <tbody>
                      {DashboardData.DashboardData.job_role.map(
                        (units, index) => (
                          <tr key={index}>
                            <td className="text-center">{units.job_title}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p style={{ color: "red" }}>No data available</p>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-6 border-end border-primary border-2">
          <div className="card ">
            <div className="card-header edAnCh text-white d-flex justify-content-between align-items-center p-2">
              <h5 className="m-0">Holidays List</h5>
              <i class="fa-solid fa-holly-berry"></i>
            </div>
            <div className="card-body">
              {DashboardData?.DashboardData?.job_role &&
              DashboardData.DashboardData.job_role.length > 0 ? (
                <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                  <table className="table table-striped table-bordered">
                    <thead
                      style={{
                        position: "sticky",
                        top: -1,
                        backgroundColor: "white",
                        zIndex: 1,
                      }}
                    >
                      <tr>
                        <th>Holidays</th>
                        <th className="text-center">Type</th>
                        <th className="text-center">Day</th>
                        <th className="text-end">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {DashboardData.DashboardData.Holiday_List.map(
                        (holiday, index) => (
                          <tr key={index}>
                            <td>{holiday.occasion}</td>
                            <td className="text-center">
                              {holiday.leave_type}
                            </td>
                            <td className="text-center">{holiday.day}</td>
                            <td className="text-end">{holiday.date}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p style={{ color: "red" }}>No data available</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6 border-end border-primary border-2">
          <div className="card ">
            <div className="card-header edAnCh text-white d-flex justify-content-between align-items-center p-2">
              <h5 className="m-0">Latest Announcements</h5>
              <i className="fa-solid fa-bullhorn"></i>
            </div>
            <div className="card-body">
              <ul className="list-group">
                {DashboardData?.DashboardData?.latestAnnouncements?.map(
                  (announcement, index) => (
                    <li
                      className={`list-group-item ${announcement.statusClass}`}
                      key={index}
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          <h6 className="mb-1">{announcement.title}</h6>
                          <small>
                            Event Date:{" "}
                            <strong>{announcement.start_date}</strong>
                          </small>
                        </div>
                        <div>
                          <span
                            className={`badge ${
                              announcement.start_date <= todayDate
                                ? "badge-danger"
                                : "badge-success"
                            }`}
                          >
                            <i className={`fa ${announcement.icon} me-1`}></i>
                            {
                              announcement.start_date <= todayDate ? (
                                <i className="fa fa-times-circle me-1"></i> // Font Awesome "times-circle" for "Gone"
                              ) : (
                                <i className="fa fa-check-circle me-1"></i>
                              ) // Font Awesome "check-circle" for "Upcoming"
                            }
                            {announcement.start_date <= todayDate
                              ? " Gone"
                              : " Upcoming"}
                          </span>
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card ">
            <div className="card-header edAnCh text-white d-flex justify-content-between align-items-center p-2">
              <h5 className="m-0">Latest Tasks</h5>
              <i className="fa-solid fa-bullhorn"></i>
            </div>
            <div className="card-body">
              <ul className="list-group">
                {DashboardData?.DashboardData?.latestAnnouncements?.map(
                  (announcement, index) => (
                    <li
                      className={`list-group-item ${announcement.statusClass}`}
                      key={index}
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          <h6 className="mb-1">{announcement.title}</h6>
                          <small>
                            Event Date:{" "}
                            <strong>{announcement.start_date}</strong>
                          </small>
                        </div>
                        <div>
                          <span
                            className={`badge ${
                              announcement.start_date <= todayDate
                                ? "badge-danger"
                                : "badge-success"
                            }`}
                          >
                            <i className={`fa ${announcement.icon} me-1`}></i>
                            {
                              announcement.start_date <= todayDate ? (
                                <i className="fa fa-times-circle me-1"></i> // Font Awesome "times-circle" for "Gone"
                              ) : (
                                <i className="fa fa-check-circle me-1"></i>
                              ) // Font Awesome "check-circle" for "Upcoming"
                            }
                            {announcement.start_date <= todayDate
                              ? " Gone"
                              : " Upcoming"}
                          </span>
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4 border-end border-primary border-2">
          <div className="card">
            <div className="card-header edAnCh text-white d-flex justify-content-between align-items-center p-2">
              <h5 className="m-0">Last Week's Working Hours</h5>
              <i class="fa-solid fa-clock"></i>
            </div>
            <div className="card-body">
              <div style={{ height: "220px" }}>
                <Bar data={chartData} options={options} />
              </div>
              <div className="text-center mt-3">
                <h6>Total Hours: {data.reduce((a, b) => a + b, 0)} Hours</h6>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </>
  );
};

export default HRMDashboard;
