import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployeeStatus,
  getJobProfile,
  getManager,
  getProbation,
  getTeamleader,
  getTimeType,
  getWorkLocation,
  getWorkType,
} from "@Networking/APIs/Employee/EmployeeApi";
import { getNationality } from "@Networking/APIs/Nationality/nationalityApi";
import { setEmployee } from "@Networking/Slices/Leave/leaveEditSlice";
import { toast } from "react-toastify";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  fetchEmployeData,
  postEmployeeJobStore,
} from "@Networking/APIs/Employee/EmployeStoreApi";
import { resetValidateError } from "@Networking/Slices/Employee/EmployeStoreSlice";
import Loader from "@Layout/Loader/loader";

const JobDetails = ({ nextStep, prevStep, progress }) => {
  const { validateFields, employeData, step2loading } = useSelector(
    (state) => state.EmployeStoreSlice
  );
  const dispatch = useDispatch();
  const { register, setValue, getValues } = useForm();

  const [employeeId, setEmployeeId] = useState(null);
  useEffect(() => {
    const storedEmployeeId = employeData?.edit_employee?.id;
    if (storedEmployeeId) {
      setEmployeeId(storedEmployeeId);
    }
  }, []);

  useEffect(() => {
    dispatch(getWorkLocation());
    dispatch(getNationality());
    dispatch(getJobProfile());
    dispatch(getTimeType());
    dispatch(getWorkType());
    dispatch(getEmployeeStatus());
    dispatch(getProbation());
    dispatch(getManager());
    dispatch(getTeamleader());
  }, []);

  const { validateError, error } = useSelector(
    (state) => state.EmployeStoreSlice
  );

  const [location, setLocation] = useState("");
  const [work_country, setWorkCountry] = useState("");
  const [job_title, setJobTitle] = useState("");
  const [time_type, setTimeType] = useState("");
  const [joining_date, setJoiningDate] = useState("");
  const [work_type, setWorkType] = useState("");
  const [reporting_manager, setReportingManager] = useState("");
  const [team_id, setReportingTeamleader] = useState("");
  const [employee_status, setEmployeeStatus] = useState("");
  const [probation_policy, setProbationPolicy] = useState(null);
  const [remark, setRemarks] = useState(null);
  const [empId, setEmpId] = useState(null);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const prepareData = () => {
    if (employeData) {
      setLocation(employeData?.edit_employee?.location);
      setWorkCountry(employeData?.edit_employee?.work_country);
      setJobTitle(employeData?.edit_employee?.job_title);
      setTimeType(employeData?.edit_employee?.time_type);
      setJoiningDate(employeData?.edit_employee?.joining_date);
      setWorkType(employeData?.edit_employee?.work_type);
      setReportingManager(employeData?.edit_employee?.reporting_manager);
      setReportingTeamleader(employeData?.edit_employee?.team_id);
      setEmployeeStatus(employeData?.edit_employee?.employee_status);
      setProbationPolicy(employeData?.edit_employee?.probation_policy);
      setRemarks(employeData?.edit_employee?.remark);
      setEmpId(employeData?.edit_employee?.id);

    }
  };

  useEffect(() => {
    prepareData();
  }, [employeData]);

  const ViewLeader = useSelector((state) => state.EmployeesSlice);

  useEffect(() => {
  }, [ViewLeader]);


  useEffect(() => {
    setFormData({
      location: location,
      work_country: work_country,
      job_title: job_title,
      time_type: time_type,
      joining_date: joining_date,
      work_type: work_type,
      reporting_manager: reporting_manager,
      team_id: team_id,
      employee_status: employee_status,
      probation_policy: probation_policy,
      remark: remark,
      employee_id: empId,
    });
  }, [
    location,
    work_country,
    job_title,
    time_type,
    joining_date,
    work_type,
    reporting_manager,
    team_id,
    employee_status,
    probation_policy,
    remark,
    empId,
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const capitalizeFirstLetter = (value) =>
    value.charAt(0).toUpperCase() + value.slice(1);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!employeeId) {
    //   toast.error("Employee ID is missing. Please try again.");
    //   return;
    // }

    // nextStep();

    try {
      const res = await dispatch(postEmployeeJobStore(formData));

      if (res.payload.status === true) {
        dispatch(fetchEmployeData(empId));
        dispatch(resetValidateError());
        nextStep();
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An unexpected error occurred. Please try again.";
    }
  };

  const workLocationData = useSelector((state) => state.EmployeesSlice);
  const nationalityData = useSelector((state) => state.NationalitySlice);
  const jobProfileData = useSelector((state) => state.EmployeesSlice);
  const timeTypeData = useSelector((state) => state.EmployeesSlice);
  const workTypeData = useSelector((state) => state.EmployeesSlice);
  const employeeStatusData = useSelector((state) => state.EmployeesSlice);
  const probationData = useSelector((state) => state.EmployeesSlice);
  
  const managerData = useSelector((state) => state.EmployeesSlice);
  const teamleaderData = useSelector((state) => state.EmployeesSlice);

  useEffect(() => {
    if (nationalityData?.NationalityData?.length > 0) {
      setWorkCountry(nationalityData?.NationalityData[229]?.country); // 0th index as default
    }
    if (timeTypeData?.TimeTypeData?.length > 0) {
      setTimeType(timeTypeData?.TimeTypeData[2]?.type_of_time); // 1st index as default
    }
    if (workTypeData?.WorkTypeData?.length > 0) {
      setWorkType(workTypeData?.WorkTypeData[0]?.type_of_work); // 1st index as default
    }
  }, [workLocationData, timeTypeData, workTypeData]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="col d-flex justify-content-between">
        <h5 className="mt-4 ">Step 2: Job Details</h5>
        <h5 className="mt-4 text-end ">
          {" "}
          <FontAwesomeIcon icon={faUser} className="menu-icon mx-2" />
          {employeData?.edit_employee?.first_name}{" "}
          {employeData?.edit_employee?.middle_name}{" "}
          {employeData?.edit_employee?.last_name}
        </h5>
      </div>
      <div className="progress-bar mx-5">
        <div className="progress" style={{ width: `${progress}%` }}></div>
      </div>
      <div className="row mb-2">
        <div className="col-md-6 mb-2">
          <label>Work Location</label>
          <select onChange={(e) => setLocation(e.target.value)}>
            <option value="" selected disabled>
              Select Work Location
            </option>
            {workLocationData?.workLocationData?.length > 0 ? (
              workLocationData.workLocationData.map((location) => (
                <option
                  key={location?.id}
                  value={location?.id}
                  selected={
                    location?.id == employeData?.edit_employee?.location
                  }
                >
                  {location?.location}
                </option>
              ))
            ) : (
              <option disabled>No locations available</option>
            )}
          </select>
          {validateError?.location && (
            <span className="text-danger">{validateError?.location[0]}</span>
          )}
        </div>
        <div className="col-md-6 mb-2">
          <label>Work Country</label>
          <select
            value={work_country}
            onChange={(e) => setWorkCountry(e.target.value)}
          >
            <option value="" selected disabled>
              Select Work Country
            </option>
            {nationalityData?.NationalityData?.length > 0 ? (
              nationalityData.NationalityData.map((nationality) => (
                <option
                  key={nationality?.id}
                  value={nationality?.country}
                  selected={
                    nationality?.country ==
                    employeData?.edit_employee?.work_country
                  }
                >
                  {nationality?.country}
                </option>
              ))
            ) : (
              <option disabled>No Country available</option>
            )}
          </select>
          {validateError?.work_country && (
            <span className="text-danger">
              {validateError?.work_country[0]}
            </span>
          )}
        </div>

        <div className="col-md-6 mb-2">
          <label>Job Title</label>
          <select onChange={(e) => setJobTitle(e.target.value)}>
            <option value="" selected disabled>
              Select Job Title
            </option>
            {jobProfileData?.JobProfileData?.length > 0 ? (
  jobProfileData.JobProfileData.map((jobs) => {
    const cleanedProfileName = jobs?.profile_name.trim(); // Remove extra whitespace or newline
   
    return (
      <option
        key={jobs.id}
        value={cleanedProfileName}
        selected={
          cleanedProfileName === employeData?.edit_employee?.job_title
        }
      >
        {cleanedProfileName}
      </option>
    );
  })
) : (
  <option disabled>No Job Titles available</option>
)}

          </select>
          {validateError?.job_title && (
            <span className="text-danger">{validateError?.job_title[0]}</span>
          )}
        </div>
        <div className="col-md-6 mb-2">
          <label>Time Type</label>
          <select
            value={time_type}
            onChange={(e) => setTimeType(e.target.value)}
          >
            <option value="" selected disabled>
              Select Time Type
            </option>
            {timeTypeData?.TimeTypeData?.length > 0 ? (
              timeTypeData.TimeTypeData.map((times) => (
                <option
                  key={times.id}
                  value={times.type_of_time}
                  selected={
                    times.type_of_time == employeData?.edit_employee?.time_type
                  }
                >
                  {times.type_of_time}
                </option>
              ))
            ) : (
              <option disabled>No Time Type available</option>
            )}
          </select>
          {validateError?.time_type && (
            <span className="text-danger">{validateError?.time_type[0]}</span>
          )}
        </div>
        <div className="col-md-6 mb-2">
          <label>Joining Date</label>
          <input
            type="date"
            id="input"
            value={joining_date || employeData?.edit_employee?.joining_date}
            placeholder="Enter Enter Work Location"
            onChange={(e) => setJoiningDate(e.target.value)}
          />
          {validateError?.joining_date && (
            <span className="text-danger">
              {validateError?.joining_date[0]}
            </span>
          )}
        </div>
        <div className="col-md-6 mb-2">
          <label>Work Type</label>
          <select
            value={work_type}
            onChange={(e) => setWorkType(e.target.value)}
          >
            <option value="" selected disabled>
              Select Work Type
            </option>
            {workTypeData?.WorkTypeData?.length > 0 ? (
              workTypeData.WorkTypeData.map((worktype) => (
                <option
                  key={worktype.id}
                  value={worktype.type_of_work}
                  selected={
                    worktype.type_of_work ==
                    employeData?.edit_employee?.work_type
                  }
                >
                  {worktype.type_of_work}
                </option>
              ))
            ) : (
              <option disabled>No Work Type available</option>
            )}
          </select>
          {validateError?.work_type && (
            <span className="text-danger">{validateError?.work_type[0]}</span>
          )}
        </div>
        <div className="col-md-6 mb-2">
          <label>Reporting Manager</label>
          <select onChange={(e) => setReportingManager(e.target.value)}>
            <option value="" selected disabled>
              Select Reporting Manager
            </option>
            {managerData?.ManagerData?.length > 0 ? (
              managerData.ManagerData.map((managers) => (
                <option
                  key={managers.id}
                  value={managers.id}
                  selected={
                    managers?.id ==
                    employeData?.edit_employee?.reporting_manager
                  }
                >
                  {managers.name}
                </option>
              ))
            ) : (
              <option disabled>No Manager available</option>
            )}
          </select>
          {validateError?.reporting_manager && (
            <span className="text-danger">
              {validateError?.reporting_manager[0]}
            </span>
          )}
        </div>
        <div className="col-md-6 mb-2">
          <label>Reporting Leader</label>
          <select onChange={(e) => setReportingTeamleader(e.target.value)}>
            <option value="" selected disabled>
              Select Reporting Leader
            </option>
            {ViewLeader?.ViewLeader?.length > 0 ? (
              ViewLeader?.ViewLeader.map((leader) => (
                <option
                  key={leader.id}
                  value={leader.id}
                  // selected={
                  //   leader.id == employeData?.edit_employee?.team_id
                  // }
                >
                  {leader.name}
                </option>
              ))
            ) : (
              <option disabled>No leaders available</option>
            )}
           </select>
          {validateError?.team_id && (
            <span className="text-danger">{validateError?.team_id[0]}</span>
          )}
        </div>

        <div className="col-md-6 mb-2">
          <label>Employee Status</label>
          <select onChange={(e) => setEmployeeStatus(e.target.value)}>
            <option value="" selected disabled>
              Select Employee Status
            </option>
            {employeeStatusData?.EmployeeStatusData?.length > 0 ? (
              employeeStatusData.EmployeeStatusData.map((status) => (
                <option
                  key={status.id}
                  value={status.status}
                  selected={
                    status?.status ==
                    employeData?.edit_employee?.employee_status
                  }
                >
                  {status.status}
                </option>
              ))
            ) : (
              <option disabled>No Employee Status available</option>
            )}
          </select>
          {validateError?.employee_status && (
            <span className="text-danger">
              {validateError?.employee_status[0]}
            </span>
          )}
        </div>
        <div className="col-md-6 mb-2">
          <label>Probation Policy</label>
          <select onChange={(e) => setProbationPolicy(e.target.value)}>
            <option value="" selected disabled>
              Select Work Location
            </option>
            {probationData?.ProbationData?.length > 0 ? (
              probationData?.ProbationData.map((probs) => (
                <option
                  key={probs.id}
                  value={probs.name}
                  selected={
                    probs?.name == employeData?.edit_employee?.probation_policy
                  }
                >
                  {probs.name}
                </option>
              ))
            ) : (
              <option disabled>No Probation Policy available</option>
            )}
          </select>
          {validateError?.probation_policy && (
            <span className="text-danger">
              {validateError?.probation_policy[0]}
            </span>
          )}
        </div>
        <div className="col-md-12 mb-2">
  <label>Remarks</label>
  <textarea
    id="input"
    placeholder="Enter remarks"
    value={
      remark
        ? remark.charAt(0).toUpperCase() + remark.slice(1)
        : employeData?.edit_employee?.remark
        ? employeData.edit_employee.remark.charAt(0).toUpperCase() + employeData.edit_employee.remark.slice(1)
        : ""
    }
    
    onChange={(e) => {
      const updateRemark = capitalizeFirstLetter(e.target.value)
      setRemarks(updateRemark)}}
  ></textarea>
  {validateError?.remark && (
    <span className="text-danger">{validateError?.remark[0]}</span>
  )}
</div>
      </div>
      <div className="form-navigation mb-4">
        <button
          type="button"
          className="btn btn-danger"
          onClick={prevStep}
        >
          Previous
        </button>
        <button type="submit" className="btn btn-success">
          Next
        </button>
      </div>
      {
        step2loading ? <Loader/> : null
      }
    </form>
  );
};

export default JobDetails;
