import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchEmployeeList, fetchLeaveList, fetchTotalLeavesByEmployee } from '@Networking/APIs/Leave/allLeaveApi'; // Import the API functions

const leaveAllSlice = createSlice({
    name: 'LeaveAllSlice',
    initialState: {
        data: [],
        totalLeaves: 0,
        employeeData: [],
        loading: false,
        error: null,
        apiErrors: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLeaveList.pending, (state) => {
                state.loading = true;
                state.apiErrors = {};
            })
            .addCase(fetchLeaveList.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchLeaveList.rejected, (state, action) => {
                state.loading = false;
                state.apiErrors = action.error.message;
            })
            .addCase(fetchEmployeeList.pending, (state) => {
                state.loading = true;
                state.apiErrors = {};
            })
            .addCase(fetchEmployeeList.fulfilled, (state, action) => {
                state.loading = false;
                state.employeeData = action.payload;
            })
            .addCase(fetchEmployeeList.rejected, (state, action) => {
                state.loading = false;
                state.apiErrors = action.error.message;
            })
            .addCase(fetchTotalLeavesByEmployee.pending, (state) => {
                state.loading = true;
                state.apiErrors = {};
            })
            .addCase(fetchTotalLeavesByEmployee.fulfilled, (state, action) => {
                state.totalLeaves = action.payload?.data?.length;
            })
            .addCase(fetchTotalLeavesByEmployee.rejected, (state, action) => {
                state.loading = false;
                state.apiErrors = action.error.message || {};
            })
    },
});



export default leaveAllSlice.reducer;
