import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { postAnnouncementStore } from "@Networking/APIs/Announcement/announcementApi";
import { getManager } from "@Networking/APIs/Employee/EmployeeApi";

const NewAnnouncement = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getManager());
  }, []);

  const managerData = useSelector((state) => state.EmployeesSlice);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedManagers, setSelectedManagers] = useState([]);

  const handleCheckboxChange = (manager) => {
    setSelectedManagers((prev) =>
      prev.some((item) => item.id === manager.id)
        ? prev.filter((item) => item.id !== manager.id)
        : [...prev, manager]
    );
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedManagers(managerData?.ManagerData || []);
    } else {
      setSelectedManagers([]);
    }
  };

  // Custom upload adapter
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file
            .then((file) => {
              // Simulate an upload process
              const reader = new FileReader();
              reader.onload = () => {
                resolve({ default: reader.result }); // Use base64 or replace with actual upload URL
              };
              reader.onerror = reject;
              reader.readAsDataURL(file);
            })
            .catch(reject);
        });
      },
    };
  }

  // Plugin to include the custom upload adapter
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const onSubmit = async (data) => {
    const NewAnnouncement = {
      title: data.title,
      start_date: data.start_date,
      description: data.description,
    };

    try {
      await dispatch(postAnnouncementStore(NewAnnouncement));
      navigate("/AnnouncementList");
    } catch (error) {}
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="">
              <i class="fa-solid fa-list me-2"></i>HRM / Announcement / New
              Announcement
            </h6>
          </div>
        </div>

        <div className="card bg-gradient-info rounded-top-2 p-2 mb-3">
          <div className="row d-flex align-items-center">
            <div className="col-md-12 col-sm-12">
              <h3 className="page-title m-0  text-light">
                <span className="mx-2 text-primary">
                  <i className="mdi mdi-file  text-light"></i>
                </span>
                Create New Announcement
              </h3>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="">
              <div className="row p-3">
                <div className="col-md-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row ">
                      <div className="col-md-12">
                        <label>Title:</label>
                        <input
                          id="input"
                          type="text"
                          {...register("title", {
                            required: "Title is required",
                          })}
                        />
                        {errors.title && (
                          <span className="text-danger">
                            {errors.title.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <label>Date:</label>
                        <input
                          id="input"
                          type="date"
                          {...register("start_date", {
                            required: "Start date is required",
                          })}
                        />
                        {errors.start_date && (
                          <span className="text-danger">
                            {errors.start_date.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label>Select Manager</label>
                        <div className="custom-dropdown">
                          <div className="input-group">
                            <input
                              type="text"
                              className="inputText"
                              placeholder="Select Reporting Manager"
                              onClick={() => setDropdownOpen(!dropdownOpen)}
                            />
                          </div>
                          {dropdownOpen && (
                            <ul
                              className="dropdown-menu show"
                              style={{ maxHeight: "200px", overflowY: "auto" }}
                            >
                              {managerData?.ManagerData?.length > 0 ? (
                                <>
                                  <li className="dropdown-item">
                                    <label className="form-check-label">
                                      <input
                                        type="checkbox"
                                        className="form-check-input me-2"
                                        checked={
                                          selectedManagers.length ===
                                          managerData.ManagerData.length
                                        }
                                        onChange={handleSelectAll}
                                      />
                                      Select All
                                    </label>
                                  </li>
                                  {managerData.ManagerData.map((manager) => (
                                    <li
                                      key={manager.id}
                                      className="dropdown-item"
                                    >
                                      <label className="form-check-label">
                                        <input
                                          type="checkbox"
                                          className="form-check-input me-2"
                                          checked={selectedManagers.some(
                                            (item) => item.id === manager.id
                                          )}
                                          onChange={() =>
                                            handleCheckboxChange(manager)
                                          }
                                        />
                                        {manager.name}
                                      </label>
                                    </li>
                                  ))}
                                </>
                              ) : (
                                <li className="dropdown-item text-muted">
                                  No Manager available
                                </li>
                              )}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-12">
                        <label>Description:</label>
                        <Controller
                          name="description"
                          control={control}
                          rules={{ required: "Description is required" }}
                          render={({ field }) => (
                            <CKEditor
                              editor={ClassicEditor}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                field.onChange(data);
                              }}
                              config={{
                                extraPlugins: [uploadPlugin],
                                toolbar: [
                                  "heading",
                                  "|",
                                  "bold",
                                  "italic",
                                  "link",
                                  "bulletedList",
                                  "numberedList",
                                  "blockQuote",
                                  "|",
                                  "insertTable",
                                  "tableColumn",
                                  "tableRow",
                                  "mergeTableCells",
                                  "|",
                                  "imageUpload",
                                  "mediaEmbed",
                                  "|",
                                  "undo",
                                  "redo",
                                  "|",
                                  "imageTextAlternative",
                                  "imageStyle:full",
                                  "imageStyle:side",
                                  "imageRemove", // Add this for image removal
                                ],
                                image: {
                                  toolbar: [
                                    "imageStyle:full",
                                    "imageStyle:side",
                                    "|",
                                    "imageTextAlternative",
                                    "imageRemove",
                                    "imageUpload",
                                    "imageDelete",
                                  ],
                                },
                              }}
                            />
                          )}
                        />
                        {errors.description && (
                          <span className="text-danger">
                            {errors.description.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-12 text-center">
                        <button
                          type="submit"
                          className="btn btn-primary w-50 rounded-pill"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewAnnouncement;
