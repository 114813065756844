import { createSlice } from '@reduxjs/toolkit';
import { EditClientStore, postClientStore } from '@Networking/APIs/Invoice/Client/clientApi';
import { getClient } from '@Networking/APIs/Invoice/Client/clientGetApi';
import { toast } from 'react-toastify';
import { DeleteClientStore } from '@Networking/APIs/Invoice/Client/Deleteclient';

const clientSlice = createSlice({
  name: 'clientSlice',
  initialState: {
    loading: false,
    loading3:false,
    message: '',
    DeleteClient:{},
    ClientGet:{},
    updateClient:{},
    success: false,
    validateError:[],
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(postClientStore.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postClientStore.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
     
      if(action.payload.status == true){
        // toast.success(action.payload.message)
      }
      else{
         toast.error(action.payload.message)
         state.validateError = action.payload.response.data.message;
       }
      
    });
    builder.addCase(postClientStore.rejected, (state, action) => {
      state.loading = false;
      toast.error(action.payload.message)
      state.error = action.error.message || 'Login failed.';
    });

    //get Client
      builder.addCase(getClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(getClient.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.ClientGet = action.payload;
      });
      builder.addCase(getClient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Login failed.';
      });


      //edit client
      builder.addCase(EditClientStore.pending, (state) => {
        state.loading3 = true;
        state.error = null;
      });
      builder.addCase(EditClientStore.fulfilled, (state, action) => {
        state.loading3 = false;
        state.success = true;
        state.updateClient = action.payload;

        if(action.payload.status == true){
          // toast.success(action.payload.message)
        }
        else{
           toast.error(action.payload.message)
           state.validateError = action.payload.response.data.message;
           
        }
      });
      builder.addCase(EditClientStore.rejected, (state, action) => {
        state.loading3 = false;
        state.error = action.error.message || 'Login failed.';
      });

      //Delete Client
      builder.addCase(DeleteClientStore.pending, (state) => {
        state.loading3 = true;
        state.error = null;
      });
      builder.addCase(DeleteClientStore.fulfilled, (state, action) => {
        state.loading3 = false;
        state.success = true;
        state.DeleteClient = action.payload;

        if(action.payload.status == true){
          toast.success(action.payload.message)
        }
        else{
          toast.error(action.payload.message)
        }
      });
      builder.addCase(DeleteClientStore.rejected, (state, action) => {
        state.loading3 = false;
        state.error = action.error.message || 'Login failed.';
      });
  },
});

export default clientSlice.reducer;