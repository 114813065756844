import { SalaryUpdateApi } from "@Networking/APIs/Salary/salaryApi";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import Loader from "@Layout/Loader/loader";

const EditSalary = () => {
  const {register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const { loading, apiErrors } = useSelector((state) => state.SalarySlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hourlyRate, setHourlyRate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [deductedHours, setDeductedHours] = useState(0);
  const [deductedSalary, setDeductedSalary] = useState(0);
  const totalWorkingHours = watch("total_hours", 0);
  const location = useLocation();
  const { employee, employee_name } = location.state || {};

  

useEffect(() => {
    const monthlySalary = employee.employee_salary;
    const rate = (parseFloat(monthlySalary) / employee.total_hours).toFixed(2);
    setHourlyRate(rate);
    const calculatedDeductedHours = employee.total_hours - (parseFloat(totalWorkingHours) || 0);
    setDeductedHours(calculatedDeductedHours);

    const calculatedDeductedSalary = hourlyRate * calculatedDeductedHours;
    setDeductedSalary(calculatedDeductedSalary);

});

const simulateLoading = async () => {
  setIsLoading(true);
  await new Promise((resolve) => setTimeout(resolve, 2000));
  setIsLoading(false);
  navigate('/Salary');
};

const handleBack = () => {
  navigate(-1);
};

useEffect(() => {
  if (employee) {
    reset({
      working_days: employee.working_days,
      working_hours: employee.working_hours,
      employee_salary: employee.employee_salary,
      total_hours: employee.total_hours,
      employee_advance: employee.employee_advance,
      medicalaim_deduction: employee.medicalaim_deduction,
      other_deduction_label: employee.other_deduction_lable,
      other_deduction_amount: employee.other_deduction_amount,
      working_month: employee?.working_month,
      advance_amount: employee?.advance_amount,
      remark: employee?.remark,
      payment_status: employee?.payment_status
    });
  } 
}, [reset]);

  const onSubmit = (data) => {
    
    try {
      // Use unwrap to extract the payload directly.
      simulateLoading();
      const result = dispatch(SalaryUpdateApi(data)).unwrap();
  
      if (result.status == true) {
        toast.success(result.message); 
        simulateLoading();
        reset();  
        navigate('/Salary');
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error('Failed to apply leave. Please try again.');
    }
    // Submit the data to your API here
  };

  return (
    <>
          <div className="container-fluid">

    <div className="row">
        <div className="col-md-12 col-sm-12">
          <h6 className="">
            <i class="fa-solid fa-list me-2"></i>HRM / Salary / My Salary / Action
          </h6>
        </div>
      </div>
    

      <button
                type="button"
                className="btn btn-secondary mb-3"
                onClick={handleBack}
            >
                <i className="fa fa-arrow-left me-2"></i>
                Back
            </button>

            <div className="card bg-gradient-info rounded-top-2 p-2 mb-2">
        <div className="row d-flex align-items-center">
          <div className="col-md-12 col-sm-12">
            <h3 className="page-title m-0  text-light">
              <span className="mx-2 text-primary">
                <i className="mdi mdi-file  text-light"></i>
              </span>
              Edit Salary
            </h3>
          </div>
        </div>
      </div>



        <div className="card">
          <div className="card-body">
          {/* <div className="card shadow"> */}
          <div className="row p-3">
            <div className="col-md-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Working Month and Employee Name */}
              <input
                      className="form-control"
                      type="hidden"
                      {...register("id")}
                      value={employee.id}
                    />
              <div className="row mb-2">
                <div className="col-md-6 mb-2">
                  <div class="form-group">
                    <label className="fw-bold" htmlFor="lastName">
                      Working Month
                    </label>
                    <select
                      className="form-control"
                      {...register("working_month", {
                        required: "Working month is required",
                      })}
                      readOnly
                    >
                      <option className="d-none">
                        {employee?.working_month}
                      </option>
                    </select>
                    {errors?.working_month && (
                      <div className="text-danger">
                        {errors?.working_month.message}
                      </div>
                    )}
                    {apiErrors?.working_month && <span className="text-danger">{apiErrors?.working_month[0]}</span>}
                  </div>
                </div>
                <div className="col-md-6 mb-2">
                  <div class="form-group">
                    <label className="fw-bold" htmlFor="gender">
                      Employee Name
                    </label>
                    <select
                      className="form-control"
                      {...register("employee_id", {
                        required: "Employee is required",
                      })}
                      readOnly
                    >
                      <option value={employee.employee_id}>{employee_name}</option>
                    </select>
                    {errors?.employee_id && (
                      <div className="text-danger">
                        {errors?.employee_id.message}
                      </div>
                    )}
                    {apiErrors?.employee_id && <span className="text-danger">{apiErrors?.employee_id[0]}</span>}
                  </div>
                </div>
              </div>

              {/* Working Days and Hours */}
              <div className="row mb-2">
                <div className="col-md-6 mb-2">
                  <div class="form-group">
                    <label className="fw-bold" htmlFor="lastName">
                      Working Days
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      {...register("working_days")}
                      placeholder="Working Days"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-2">
                  <div class="form-group">
                    <label className="fw-bold" htmlFor="gender">
                      Working Hours
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      {...register("working_hours")}
                      placeholder="Working Hours"
                      readOnly
                    />
                  </div>
                </div>
              </div>

              {/* Employee Salary and Advance */}

              <div className="row mb-2">
                <div className="col-md-6 mb-2">
                  <div class="form-group">
                    <label className="fw-bold" htmlFor="lastName">
                      Employee Salary
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      {...register("employee_salary")}
                      placeholder="Employee Salary"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-2">
                  <div class="form-group">
                    <label className="fw-bold" htmlFor="gender">
                      Employee Advance
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      {...register("employee_advance", {
                        required: "Advance is required",
                      })}
                      placeholder="Employee Advance"
                    />
                    {errors?.employee_advance && (
                      <div className="text-danger">
                        {errors?.employee_advance.message}
                      </div>
                    )}
                    {apiErrors?.employee_advance && <span className="text-danger">{apiErrors?.employee_advance[0]}</span>}
                  </div>
                </div>
              </div>

              {/* Total Hours and Mediclaim Deduction */}

              <div className="row mb-2">
                <div className="col-md-6 mb-2">
                  <div class="form-group">
                    <label className="fw-bold" htmlFor="lastName">
                      Total Working Hours
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      {...register("total_hours", {
                        required: "Total hours are required",
                      })}
                      placeholder="Total Hours"
                    />
                    {errors?.total_hours && (
                      <div className="text-danger">
                        {errors?.total_hours.message}
                      </div>
                    )}
                    {apiErrors?.total_hours && <span className="text-danger">{apiErrors?.total_hours[0]}</span>}
                  </div>
                </div>
                <div className="col-md-6 mb-2">
                  <div class="form-group">
                    <label className="fw-bold" htmlFor="gender">
                      Mediclaim Deduction
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      {...register("medicalaim_deduction")}
                      placeholder="Mediclaim Deduction"
                    />
                  </div>
                </div>
              </div>

              {/* Other Deduction and Advance Amount */}

              <div className="row mb-2">
                <div className="col-md-6 mb-2">
                  <div class="form-group">
                    <label className="fw-bold" htmlFor="lastName">
                      Other Deduction Label
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      {...register("other_deduction_label", {
                        required: "Label is required",
                      })}
                      placeholder="Other Expenditure"
                      value={employee.other_deduction_lable}
                    />
                    {errors?.other_deduction_label && (
                      <div className="text-danger">
                        {errors?.other_deduction_label.message}
                      </div>
                    )}
                    {apiErrors?.other_deduction_label && <span className="text-danger">{apiErrors?.other_deduction_label[0]}</span>}
                  </div>
                </div>
                <div className="col-md-6 mb-2">
                  <div class="form-group">
                    <label className="fw-bold" htmlFor="gender">
                      Other Deduction Amount
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      {...register("other_deduction_amount", {
                        required: "Amount is required",
                      })}
                      placeholder="Other Deduction"
                    />
                    {errors?.other_deduction_amount && (
                      <div className="text-danger">
                        {errors?.other_deduction_amount.message}
                      </div>
                    )}
                    {apiErrors?.other_deduction_amount && <span className="text-danger">{apiErrors?.other_deduction_amount[0]}</span>}
                  </div>
                </div>
              </div>

              {/* Advance Amount and Total Salary */}

              <div className="row mb-2">
                <div className="col-md-6 mb-2">
                  <div class="form-group">
                    <label className="fw-bold" htmlFor="lastName">
                      Advance Amount
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      {...register("advance_amount", {
                        required: "Advance amount is required",
                      })}
                      placeholder="Advance Amount"
                    />
                    {errors?.advance_amount && (
                      <div className="text-danger">
                        {errors?.advance_amount.message}
                      </div>
                    )}
                    {apiErrors?.advance_amount && <span className="text-danger">{apiErrors?.advance_amount[0]}</span>}
                  </div>
                </div>
                <div className="col-md-6 mb-2">
                  <div class="form-group">
                    <label className="fw-bold" htmlFor="gender">
                      Total Salary
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      {...register("total_salary")}
                      placeholder="Total Salary"
                      value={employee.total_salary}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-2">
                  <div class="form-group">
                    <label className="fw-bold" htmlFor="gender">
                      Payment Status
                    </label>
                    <select
                      className="form-control"
                      {...register("payment_status", {
                        required: "Payment status is required",
                      })}
                    >
                      <option value="">Select one of these</option>
                      <option value="Pending">Pending</option>
                      <option value="Paid">Paid</option>
                      <option value="Unpaid">Unpaid</option>
                    </select>
                    {errors?.payment_status && (
                      <div className="text-danger">
                        {errors?.payment_status.message}
                      </div>
                    )}
                    {apiErrors?.payment_status && <span className="text-danger">{apiErrors?.payment_status[0]}</span>}
                  </div>
                </div>
                <div className="col-md-6 mb-2">
                        <div className="form-group">
                          <label className="fw-bold" htmlFor="gender">Remark </label>
                          <textarea
                            className="form-control"
                            {...register("remark")}
                            placeholder="Enter remark"
                            rows={4} // Adjust the number of rows as needed
                          />  
                        </div>
                      </div>
              </div>

              <div className="p-3 border">
                    <div className="row">
                        <div className='col-md-3'>
                          <h6>HOURLY RATE (₹) : </h6>
                        </div>
                        <div className='col-md-6'>
                          <input 
                            className="form-control" 
                            type="text" 
                            {...register("hourly_rate")} 
                            readOnly 
                            value={hourlyRate}
                          />
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className='col-md-3'>
                          <h6>DEDUCTED HOURS : </h6>
                        </div> 
                        <div className='col-md-6'>
                          <input 
                              className="form-control" 
                              type="text" 
                              {...register("deducted_hours")} 
                              readOnly 
                              value={deductedHours}
                            />
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className='col-md-3'>
                          <h6>DEDUCTED SALARY :</h6>
                        </div>
                        <div className='col-md-6'>
                          <input 
                              className="form-control" 
                              type="text" 
                              {...register("deducted_salary")} 
                              readOnly 
                              value={deductedSalary}
                            />
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className='col-md-3'>
                          <h6>PAID LEAVE :</h6>
                        </div>
                        <div className='col-md-6'>
                          <input 
                              className="form-control" 
                              type="text" 
                              {...register("paid_leaves")} 
                              readOnly 
                              value={employee?.paid_leaves || "0"}
                            />
                        </div>
                    </div>
                  </div>

              {/* Submit and Reset Buttons */}
              <div className="row mt-4">
                {/* <div className="col-md-6 text-center">
                  <button type="reset" className="btn btn-danger">
                    Reset
                  </button>
                </div> */}
                <div className="col-md-12 text-center">
                  <button type="submit" className="btn btn-primary rounded-pill">
                    Update Salary
                  </button>
                </div>
                {isLoading && <Loader />}
              </div>
            </form>
          </div>
          {/* </div> */}
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default EditSalary;
