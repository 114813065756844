import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {BASE_URL, PartialPaymentDelete, partialPaymentUrl, partialviewUrl ,paymentList,paymenturl} from '@Networking/APIs/NWConfig';
 

 
  // Payment store
export const storePayment = createAsyncThunk(
    "post/storeInvoice",
    async(post) => {

        let url = BASE_URL + partialPaymentUrl; 

        const token = localStorage.getItem("authToken");

        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;
                 return response;
        } catch(error) {
            return error;
        }
    }
);


export const PartialyView = createAsyncThunk(
    'get/PartialyView',
    async (paymentId) => {  
     
      let url =    + partialviewUrl  + paymentId; 
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );


  //Partial Payment List 
  export const PartialyList = createAsyncThunk(
    'get/PartialyList',
    async (invoiceId) => {  
     
      let url = BASE_URL +  paymentList + invoiceId;
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );

// Delete Partial payment 
export const DeletePartialPayment = createAsyncThunk(
  'post/PartialPaymentDelete',
  async (id) => {
      if (!id) {
          return Promise.reject(new Error("ID is required for deletion."));
      }
      const url = `${BASE_URL}${PartialPaymentDelete}${id}`;
      const token = localStorage.getItem("authToken");
      if (!token) {
          return Promise.reject(new Error("Authorization token is missing."));
      }
      try {
          const response = await axios.delete(url, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          });
          return response.data;
      } catch (error) {
          // Log specific error messages
          const errorMessage = error.response?.data?.message || error.message || "An error occurred.";
          return Promise.reject(new Error(errorMessage));
      }
  }
);



 // fetch invoice 
  export const PartialyPaid = createAsyncThunk(
    'get/PartialyPaid',
    async (invoiceId) => {  
     
      let url = BASE_URL + paymenturl  + invoiceId; 
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );
 
 