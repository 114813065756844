import {
  componentheaderStep1,
  componentheaderStep2,
  textDiv,
} from "@Layout/Color/color";
import { useState } from "react";
import BugForm from "./bugForm";
import { FeatureForm } from "./featureForm";

export const SwitchForm = () => {
  const [isFeatureForm, setIsFeatureForm] = useState(true); // State to toggle between forms

  const handleSwitch = (formType) => {
    setIsFeatureForm(formType === "feature"); // Switch form based on the type
  };

  return (
    <div>
      <div className={componentheaderStep1}>
        <div className={componentheaderStep2}></div>
        <div className={componentheaderStep2}>
          <div className={textDiv}>
            <h3 className="page-title m-0">
              <span className="mx-2 text-primary">
                <i className="mdi mdi-file"></i>
              </span>
              {isFeatureForm ? "FEATURE FORM" : "BUG FORM"}
            </h3>
          </div>

          <div
            className={`btn-group ${textDiv}`}
            role="group"
            aria-label="Switch Form"
          >
            {/* Button for Feature Form */}
            <button
              type="button"
              className={`btn ${
                isFeatureForm ? "btn-primary" : "btn-outline-primary"
              }`}
              onClick={() => handleSwitch("feature")}
            >
              Feature Form
            </button>

            {/* Button for Bug Form */}
            <button
              type="button"
              className={`btn ${
                !isFeatureForm ? "btn-primary" : "btn-outline-primary"
              }`}
              onClick={() => handleSwitch("bug")}
            >
              Bug Form
            </button>
          </div>
        </div>
      </div>

      <div className="card mb-3">
        <div className="card-body">
          {/* Switch buttons */}

          {/* Conditional rendering of forms */}
          <div className="mt-3">
            {isFeatureForm ? <FeatureForm /> : <BugForm />}
          </div>
        </div>
      </div>
    </div>
  );
};
