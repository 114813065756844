import React, { useState } from "react";
import "../../Invoice/invoicesForm.css";
import { postItemStore } from "@Networking/APIs/Invoice/Item/itemApi";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { getItem } from "@Networking/APIs/Invoice/Item/getitem";
import { toast } from "react-toastify";
import { button } from "@Layout/Color/color";

const ItemForm = ({ onClose }) => {
  //Hooks
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //Redux
  const { validateError, loading } = useSelector((state) => state.ItemSlice);

  //States
  const [itemPrice, setItemPrice] = useState("");

  const handlePriceChange = (e) => {
    const formattedValue = e.target.value.replace(/[^0-9.]/g, "");
    setItemPrice(formattedValue);
  };

  const onSubmit = async (data) => {
    const newItem = {
      name: data.itemName,
      price: parseFloat(data.itemPrice),
      description: data.itemDescription,
    };

    try {
      const res = await dispatch(postItemStore(newItem));
      if (res.payload.status == true) {
        onClose();
        toast.success(res.payload.message);
      }

      await dispatch(getItem());
    } catch (error) {
    }
  };

  return (
    <div
      className="modal fade show"
      style={{ display: "block", backdropFilter: "blur(5px)" }}
      aria-modal="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src="assets/logo/tslogo.png"
                alt="Client"
                style={{ width: "25px", height: "100%" }}
              />
              <h5 className="modal-title">Add Product</h5>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              style={{ position: "absolute", right: "10px", top: "10px" }}
            >
              <span aria-hidden="true"></span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>

<div className="row">
              <div className="col-md-12">
                <label>Product Name</label>
                <input
                  type="text"
                  className={`inputText ${
                    errors.itemName ? "is-invalid" : ""
                  }`}
                  id="itemName"
                  placeholder="Enter Product Name"
                  {...register("itemName", {
                    required: "Product name is required",
                  })}
                />
                {errors.itemName && (
                  <span className="text-danger">{errors.itemName.message}</span>
                )}
                {validateError.name && (
                  <span className="text-danger">{validateError.name[0]}</span>
                )}
              </div>
              </div>

              <div className="row mt-2">
              <div className="col-md-12">
                <label>Product Price</label>
                <input
                  type="text"
                  className={`inputText ${
                    errors.itemPrice ? "is-invalid" : ""
                  }`}
                  id="itemPrice"
                  placeholder="Enter Product Price"
                  {...register("itemPrice", {
                    required: "Product Price is required",
                    validate: (value) =>
                      value > 0 || "Price must be greater than 0",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Only numbers are allowed",
                    },
                  })}
                />
                {errors.itemPrice && (
                  <span className="text-danger">
                    {errors.itemPrice.message}
                  </span>
                )}
                {validateError?.price && (
                  <span className="text-danger">{validateError.price[0]}</span>
                )}
              </div>
              </div>

              <div className="row mt-2">
              <div className="col-md-12">
                <label>Product Description</label>
                <textarea
                  className={`inputText ${
                    errors.itemDescription ? "is-invalid" : ""
                  }`}
                  id="itemDescription"
                  placeholder="Enter Product description"
                  {...register("itemDescription", {
                    required: "Product Description is required",
                  })}
                />
                {errors.itemDescription && (
                  <span className="text-danger">
                    {errors.itemDescription.message}
                  </span>
                )}
                {validateError.description && (
                  <span className="text-danger">
                    {validateError.description[0]}
                  </span>
                )}
              </div>
              </div>

              <button type="submit" className={button}>
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemForm;
