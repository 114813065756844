import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserData } from '@Networking/APIs/Background/backgorundApi';
import { toast } from 'react-toastify';
import Loader from "@Layout/Loader/loader";

export const ProtectedRoute = ({ allowedRoles }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { userData, error } = useSelector((state) => ({
    userData: state.BackgroundSlice.userData,
    error: state.BackgroundSlice.error,
  }));
 
  useEffect(() => {
    const intervalId = setInterval(() => {
    const res =  dispatch(fetchUserData());
     if(res){
       clearInterval(intervalId);  
    }
     }, 2000); // Retry every 2 seconds
     return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [dispatch, userData]);

  useEffect(() => {
    if (error) {
      if (error === 'Request failed with status code 401') {
        toast.error("Unauthorized access. Please log in.");
        navigate("/");
      }
    } else if (userData) {
      const userRole = userData.role_id;

      if (!userRole) {
        navigate("/");
      } else if (allowedRoles && !allowedRoles.includes(userRole)) {
        toast.error("You are not authorized to access this page.");
        navigate(-1);
      }
    }
  }, [userData, error, allowedRoles, navigate]);

  if (userData && location.pathname === '/') {
    return <Navigate to="/dashboard" />;
  }

  if (!userData && !error) {
    return <div><Loader /></div>;
  }

  if (error === 'Request failed with status code 401') {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};
