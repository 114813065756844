import { getProjectList, postProjectStore } from '@Networking/APIs/PMS/Project/projectApi';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';


const ProjectSlice = createSlice({
  name: 'ProjectSlice',
  initialState: {
    loading: false,
    success: false,
    ProjectListData: [],
    error: null,
  },

  extraReducers: (builder) => {
    
    builder.addCase(postProjectStore.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postProjectStore.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
      
     
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.message)
      }
      
    });
    builder.addCase(postProjectStore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Add Announcement failed.';
    });

 
   // Project List
    builder.addCase(getProjectList.pending, state => {
      state.loading = true;
    });
    builder.addCase(getProjectList.fulfilled, (state, action) => {
      state.ProjectListData = action.payload;
    });
    builder.addCase(getProjectList.rejected, state => {
      state.loading = false;
    });



  },
});

export default ProjectSlice.reducer;