import { configureStore } from "@reduxjs/toolkit";
import clientDetailReducer from "@Networking/Slices/Invoice/Client/clientSlice";
import backgroundReducer from "@Networking/Slices/Background/backgroundSlice";
import loginReducer from "@Networking/Slices/Login/loginSlice";
import ItemReducer from "@Networking/Slices/Invoice/Item/itemSlice";
import categoryReducer from "@Networking/Slices/Invoice/Category/categorySlice";
import BankStoreReducer from "@Networking/Slices/Invoice/Bank/bankStoreSlice";
import CompanyStoreReducer from "@Networking/Slices/Invoice/Company/companyStoreSlice";
import GetInReducer from "@Networking/Slices/Invoice/InvoiceNumberSlice";
import PaymentReducer from "@Networking/Slices/Invoice/payment/paymentSlice";
import LogoReducer from "@Networking/Slices/Invoice/Logo/logoSlice";
import AttendanceRecordReducer from "@Networking/Slices/AttendanceRecord/attendanceRecordSlice";
import NationalityReducer from "@Networking/Slices/Nationality/nationalitySlice";
import AnnouncementReducer from "@Networking/Slices/Announcement/announcementSlice";
import employeesReducer from "@Networking/Slices/Employee/employeeSlice";
import GetSalaryReducer from "@Networking/Slices/Salary/salarySlice";
import leaveApplyReducer from "@Networking/Slices/Leave/leaveApplySlice";
import leaveEditReducer from "@Networking/Slices/Leave/leaveEditSlice";
import LeaveShowReducer from "@Networking/Slices/Leave/leaveShowSlice";
import EmployeeNameReducer from "@Networking/Slices/Leave/employeeNameSlice";
import EmployeStoreReducer from "@Networking/Slices/Employee/EmployeStoreSlice";
import AllLeaveReducer from "@Networking/Slices/Leave/leaveAllSlice";
import TaskReducer from "@Networking/Slices/Task/taskSlice";
import ProfileReducer from "@Networking/Slices/Profile/profileSlice"
import SwitchReducer from "@Networking/Slices/SwitchForm/SwitchFormSlice";
import DashboardReducer from "@Networking/Slices/Dashboard/dashboardSlice";
import ProjectReducer from "@Networking/Slices/PMS/Project/projectSlice";


export const store = configureStore({
  reducer: {
    BackgroundSlice: backgroundReducer,
    LoginSlice: loginReducer,
    clientSlice: clientDetailReducer,
    ItemSlice: ItemReducer,
    CategorySlice: categoryReducer,
    BankPostSlice: BankStoreReducer,
    CompanyStoreSlice: CompanyStoreReducer,
    GetInSlice: GetInReducer,
    PartialyPaymentSlice: PaymentReducer,
    LogoSlice: LogoReducer,
    AttendanceRecordSlice: AttendanceRecordReducer,
    NationalitySlice: NationalityReducer,
    AnnouncementSlice: AnnouncementReducer,
    SalarySlice: GetSalaryReducer,
    EmployeesSlice: employeesReducer,
    leaveSlice: leaveApplyReducer,
    leaveEditSlice: leaveEditReducer,
    LeaveShowSlice: LeaveShowReducer,
    EmployeeNameSlice: EmployeeNameReducer,
    EmployeStoreSlice: EmployeStoreReducer,
    LeaveAllSlice: AllLeaveReducer,
    TaskSlice: TaskReducer,
    ProfileSlice: ProfileReducer,
    SwitchSlice:SwitchReducer,
    DashboardSlice:DashboardReducer,
    ProjectSlice: ProjectReducer,
  },
});
