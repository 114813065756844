import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { EditBankStore } from "@Networking/APIs/Invoice/Bank/bankStoreApi";
import { getcountries } from "@Networking/APIs/Background/backgorundApi";
import Loader from "@Layout/Loader/loader";
import { getAllBank } from "@Networking/APIs/Invoice/Bank/bankGetApi";

const EditBankForm = ({ onClose, currentBank }) => {
  const dispatch = useDispatch();
  const { loading3 } = useSelector((state) => state.BankPostSlice);

  // States to store form data
  const [formData, setFormData] = useState({
    accountType: "",
    bank_title: "",
    description: "",
    currency_accept: "",
    bank_rules: "",
  });

  // States for selected country and account type
  const [selectedAccountType, setSelectedAccountType] = useState("");

  const {
    register,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const handleAccountTypeChange = (e) => {
    setSelectedAccountType(e.target.value);
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Fetch countries when component mounts
  useEffect(() => {
    dispatch(getcountries());
    dispatch(getAllBank());
  }, [dispatch]);

  // Custom upload adapter
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file
            .then((file) => {
              // Simulate an upload process
              const reader = new FileReader();
              reader.onload = () => {
                resolve({ default: reader.result }); // Use base64 or replace with actual upload URL
              };
              reader.onerror = reject;
              reader.readAsDataURL(file);
            })
            .catch(reject);
        });
      },
    };
  }

  // Plugin to include the custom upload adapter
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  // Set the initial values when currentBank is available
  useEffect(() => {
    if (currentBank) {
      setFormData({
        bank_title: currentBank?.label || "",
        description: currentBank?.bank_description || "",
        accountType: currentBank?.account_type || "",
        currency_accept: currentBank?.currency_accept || "",
        bank_rules: currentBank?.bank_rules || "",
      });
      setSelectedAccountType(currentBank?.account_type || "");
    }
  }, [currentBank]);

  // Submit form handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform basic validation (you can enhance this logic)
    if (
      !formData.bank_title ||
      !formData.description ||
      !selectedAccountType ||
      !formData.currency_accept ||
      !formData.bank_rules
    ) {
      alert("Please fill all required fields.");
      return;
    }

    // Prepare the data to be submitted
    const newBank = {
      id: currentBank?.value || "",
      bank_title: formData.bank_title,
      bank_description: formData.description,
      account_type: selectedAccountType,
      currency_accept: formData.currency_accept,
      bank_rules: formData.bank_rules,
    };
    try {
      const res = await dispatch(EditBankStore(newBank));
      onClose(); // Close the modal on success
      await dispatch(getAllBank());
    } catch (error) {}
  };

  return (
    <div
      className="modal fade show"
      style={{ display: "block", backdropFilter: "blur(5px)" }}
      aria-modal="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src="assets/logo/tslogo.png"
                alt="Client"
                style={{ width: "25px", height: "100%" }}
              />
              <h5 className="modal-title text-center">Edit Bank</h5>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              style={{ position: "absolute", right: "10px", top: "10px" }}
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <label>Account Type</label>
                  <select
                    id="account_type"
                    value={selectedAccountType}
                    onChange={handleAccountTypeChange}
                    className="inputText"
                  >
                    <option value="" disabled>
                      {currentBank?.account_type}
                    </option>
                    <option value="Direct">Direct</option>
                    <option value="Indirect">Indirect</option>
                  </select>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-12">
                  <label>Bank Title</label>
                  <input
                    title="Only alphabets are allowed"
                    name="bank_title" // Ensure the name matches the state key
                    value={formData.bank_title || ""} // Provide a fallback to prevent uncontrolled input
                    onChange={handleInputChange} // Updates state on input change
                    type="text"
                    className="inputText"
                    placeholder="Beneficiary Name"
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-12">
                  <label>Currency Accept</label>
                  <input
                    title="Only alphabets are allowed"
                    name="currency_accept"
                    value={formData.currency_accept || ""} // Ensure value doesn't break when undefined
                    onChange={handleInputChange}
                    type="text"
                    className="inputText"
                    placeholder="Beneficiary Name"
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-12">
                  <label>Bank Details</label>
                  <Controller
                    name="description"
                    control={control}
                    rules={{ required: "Description is required" }}
                    render={({ field }) => (
                      <CKEditor
                        // data={currentBank?.bank_description || ""}
                        data={
                          field.value || currentBank?.bank_description || ""
                        }
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          field.onChange(data);
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            description: data,
                          }));
                        }}
                        config={{
                          extraPlugins: [uploadPlugin],
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                            "|",
                            "insertTable",
                            "tableColumn",
                            "tableRow",
                            "mergeTableCells",
                            "|",
                            "imageUpload",
                            "mediaEmbed",
                            "|",
                            "undo",
                            "redo",
                            "|",
                            "imageTextAlternative",
                            "imageStyle:full",
                            "imageStyle:side",
                            "imageRemove", // Add this for image removal
                          ],
                          image: {
                            toolbar: [
                              "imageStyle:full",
                              "imageStyle:side",
                              "|",
                              "imageTextAlternative",
                              "imageRemove",
                              "imageUpload",
                              "imageDelete",
                            ],
                          },
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-12">
                  <label>Bank Rules</label>
                  <Controller
                    name="bank_rules"
                    control={control}
                    rules={{ required: "Bank Rules is required" }}
                    render={({ field }) => (
                      <CKEditor
                        // data={currentBank?.bank_description || ""}
                        data={field.value || currentBank?.bank_rules || ""}
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          field.onChange(data);
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            bank_rules: data,
                          }));
                        }}
                        config={{
                          extraPlugins: [uploadPlugin],
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                            "|",
                            "insertTable",
                            "tableColumn",
                            "tableRow",
                            "mergeTableCells",
                            "|",
                            "imageUpload",
                            "mediaEmbed",
                            "|",
                            "undo",
                            "redo",
                            "|",
                            "imageTextAlternative",
                            "imageStyle:full",
                            "imageStyle:side",
                            "imageRemove", // Add this for image removal
                          ],
                          image: {
                            toolbar: [
                              "imageStyle:full",
                              "imageStyle:side",
                              "|",
                              "imageTextAlternative",
                              "imageRemove",
                              "imageUpload",
                              "imageDelete",
                            ],
                          },
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center py-2">
                <button type="submit" className="btn btn-gradient-info">
                  Update Bank
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {loading3 && <Loader />}
    </div>
  );
};

export default EditBankForm;
