import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";


const CreateTeam = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);


  const ViewLeader = useSelector((state) => state.EmployeesSlice);

console.log("ViewLeader",ViewLeader);
  useEffect(() => {
  }, [ViewLeader]);


  const options = [
    { value: "apple", label: "Apple" },
    { value: "banana", label: "Banana" },
    { value: "cherry", label: "Cherry" },
    { value: "date", label: "Date" },
    { value: "elderberry", label: "Elderberry" },
    { value: "fig", label: "Fig" },
    { value: "grape", label: "Grape" },
    { value: "honeydew", label: "Honeydew" },
    { value: "kiwi", label: "Kiwi" },
    { value: "lemon", label: "Lemon" },
    { value: "mango", label: "Mango" },
    { value: "nectarine", label: "Nectarine" },
    { value: "orange", label: "Orange" },
    { value: "peach", label: "Peach" },
    { value: "pineapple", label: "Pineapple" },
    { value: "quince", label: "Quince" },
    { value: "raspberry", label: "Raspberry" },
    { value: "strawberry", label: "Strawberry" },
    { value: "watermelon", label: "Watermelon" },
  ];

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  console.log(selectedOptions);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="">
              <i class="fa-solid fa-list me-2"></i>PRM / Teams / Add New Team
            </h6>
          </div>
        </div>

        <form onSubmit={[]}>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="card">
                <h5 className="card-header text-center bg-gradient-info text-light p-1">
                  Add Team
                </h5>
                <div className="card-body">
                  <div className="row mt-2">
                    <div className="col-md-6 col-sm-6">
                      <label>Team Name</label>
                      <input className="inputText" type="text" />
                    </div>
                    <div className="col-md-6 mb-2">
                      <label>Select Teamleader</label>
                      <select
                        // onChange={(e) => setReportingTeamleader(e.target.value)}
                      >
                        <option value="" selected disabled>
                          Select Reporting Leader
                        </option>
                        {ViewLeader?.ViewLeader?.length > 0 ? (
                          ViewLeader?.ViewLeader.map((leader) => (
                            <option
                              key={leader.id}
                              value={leader.id}
                              // selected={
                              //   leader.id == employeData?.edit_employee?.team_id
                              // }
                            >
                              {leader.name}
                            </option>
                          ))
                        ) : (
                          <option disabled>No leaders available</option>
                        )}
                      </select>
                  
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12 col-sm-6">
                      <label>Select Employees</label>
                      <Select
                        options={options}
                        isMulti
                        value={selectedOptions}
                        onChange={handleChange}
                        placeholder="Select Employees..."
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12 col-sm-12">
                      <label>Description</label>
                      <textarea
                        id="description"
                        className="inputText"
                        name="description"
                        rows="4"
                        placeholder="Enter project description here..."
                      ></textarea>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12 col-sm-12 text-center">
                      <button
                        type="submit"
                        className="btn btn-primary w-50 rounded-pill"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateTeam;
