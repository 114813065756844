import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchEmployeeList } from '@Networking/APIs/Leave/allLeaveApi'; 

// Async thunk to fetch employee list
export const fetchEmployees = createAsyncThunk('employees/fetchEmployees', async () => {
    const employees = await fetchEmployeeList(); 
    return employees; // Return the fetched data
});

// Employee slice
const employeeSlice = createSlice({
    name: 'EmployeeNameSlice',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEmployees.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchEmployees.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload; // Store employees in the state
            })
            .addCase(fetchEmployees.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Selector to access employee data
export const selectEmployees = (state) => state.EmployeeNameSlice.data;
export const selectEmployeeLoading = (state) => state.EmployeeNameSlice.loading;
export const selectEmployeeError = (state) => state.EmployeeNameSlice.error;

export default employeeSlice.reducer;
