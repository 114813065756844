import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteHoliday,
  getHolidays,
  getWorkingHours,
  postHolidayStore,
  postHolidayUpdate,
  postWorkingHours,
} from "@Networking/APIs/AttendanceRecord/attendanceRecordApi";
import AttendanceRecord from "./AttendanceRecord";
import $ from "jquery";
import {
  DeleteDepartment,
  getDepartmemt,
  getEmployees,
  updateDepartment,
} from "@Networking/APIs/Employee/EmployeeApi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const FixedHolidays = () => {
  const holidaysData = useSelector((state) => state.AttendanceRecordSlice);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState(null);

  const [selectedName, setDepartment] = useState(null);
  const [nameInput, setNameInput] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getHolidays());
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { type } = useParams();

  const handleBack = () => {
    navigate(-1);
  };

  const attendaceRecordData = useSelector(
    (state) => state.AttendanceRecordSlice
  );
  const employeesData = useSelector((state) => state.EmployeesSlice);

  const onSubmit = async (data) => {
    const newHoliday = {
      occasion: data.occasion,
      date: data.date,
      day: data.day,
    };

    try {
      await dispatch(postHolidayStore(newHoliday));
      await dispatch(getHolidays());
    } catch (error) {
    }
  };

  const handleEdit = (holiday) => {
    setSelectedHoliday(holiday);
    setIsEditModalOpen(true);
    reset({
      occasion: holiday.occasion,
      date: holiday.date,
      day: holiday.day,
    });
  };


  const onUpdate = async (data) => {
    const updateHoliday = {
      occasion: data.occasion,
      date: data.date,
      day: data.day,
    };
    try {
      await dispatch(postHolidayUpdate({ id: selectedHoliday.id, ...updateHoliday }));
      await dispatch(getHolidays());
      setIsEditModalOpen(false);
    } catch (error) {
    }
  };


  const handleDelete = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure? You won't be able to revert this!"
    );

    if (isConfirmed) {
      dispatch(DeleteHoliday({ id }));
      dispatch(getHolidays());
    }
  };

  return (
    <>
   
      <div className="container-fluid">

      <div className="row">
        <div className="col-md-12 col-sm-12">
          <h6 className="">
            <i class="fa-solid fa-list me-2"></i>HRM / Attendance Record / Fixed Holidays
          </h6>
        </div>
      </div>

      <div className="m-1">
      <button
        type="button"
        className="btn btn-secondary mb-3"
        onClick={handleBack}
      >
        <i className="fa fa-arrow-left me-2"></i>
        Back
      </button>

      </div>

          <div className="card-body">
            <div className="card shadow">
            <div className="row p-3">
            <div className="col-md-12">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row p-2">
                  <div className="col-md-12">
                    <div className="row mb-2 d-flex align-items-center p-1">
                      <div className="col-md-4 mb-2">
                        <div class="form-group">
                          <label className="fw-bold" htmlFor="occasion">
                            Occasion
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Occasion"
                            className="form-control"
                            id="occasion"
                            {...register("occasion", { required: true })}
                          />
                          {errors.occasion && (
                            <span className="text-danger">
                              *This field is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 mb-2">
                        <div class="form-group">
                          <label className="fw-bold" htmlFor="date">
                            Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="date"
                            {...register("date", { required: true })}
                          />
                          {errors.date && (
                            <span className="text-danger">
                              *This field is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 mb-2">
                        <div className="form-group">
                          <label htmlFor="day">Day</label>
                          <select
                            className="form-select"
                            id="day"
                            {...register("day", { required: true })}
                          >
                            <option value="" disabled selected>
                              Select Day
                            </option>
                            <option value="Sunday">Sunday</option>
                            <option value="Monday">Monday</option>
                            <option value="Tuesday">Tuesday</option>
                            <option value="Wednesday">Wednesday</option>
                            <option value="Thursday">Thursday</option>
                            <option value="Friday">Friday</option>
                            <option value="Saturday">Saturday</option>
                          </select>
                          {errors.day && (
                            <span className="text-danger">
                              *This field is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-2 mb-2">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div className="col-md-12">
                <div className="table-responsive border rounded">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>DATE</th>
                        <th>DAY</th>
                        <th>OCCASION</th>
                        <th>TYPE OF LEAVE</th>
                        <th className="text-end">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {holidaysData?.HolidaysData?.holidays?.length > 0 ? (
                        holidaysData?.HolidaysData?.holidays?.map(
                          (holidays) => (
                            <tr key={holidays.id}>
                              <td>{holidays.date}</td>
                              <td>{holidays.day}</td>
                              <td>{holidays.occasion}</td>
                              <td>{holidays.leave_type}</td>
                              <td>
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary me-2"
                                    onClick={() => handleEdit(holidays)}
                                  >
                                    <i className="fa fa-edit"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                    onClick={() => handleDelete(holidays.id)}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan="5">No holidays available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            </div>
            </div>
          </div>
      </div>
      {/* Edit Modal */}
      {isEditModalOpen && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Holiday</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setIsEditModalOpen(false)}
                ></button>
              </div>
              <form onSubmit={handleSubmit(onUpdate)}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label htmlFor="occasion" className="form-label">
                      OCCASION
                    </label>
                    <input
                      type="text"
                      id="occasion"
                      className="form-control"
                      {...register("occasion", { required: true })}
                    />
                    {errors.occasion && (
                      <span className="text-danger">
                        *This field is required
                      </span>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="date" className="form-label">
                      DATE
                    </label>
                    <input
                      type="date"
                      id="date"
                      className="form-control"
                      {...register("date", { required: true })}
                    />
                    {errors.date && (
                      <span className="text-danger">
                        *This field is required
                      </span>
                    )}
                  </div>
                  
                  <div className="mb-3">
                    <label htmlFor="day" className="form-label">
                      Day
                    </label>
                    <select
                      id="day"
                      className="form-select"
                      {...register("day", { required: true })}
                    >
                      <option value="" disabled>
                        Select Day
                      </option>
                      <option value="Sunday">Sunday</option>
                      <option value="Monday">Monday</option>
                      <option value="Tuesday">Tuesday</option>
                      <option value="Wednesday">Wednesday</option>
                      <option value="Thursday">Thursday</option>
                      <option value="Friday">Friday</option>
                      <option value="Saturday">Saturday</option>
                    </select>
                    {errors.day && (
                      <span className="text-danger">
                        *This field is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="modal-footer text-center`">
                  <div className="row">
                    <div className="col-md-12">
                      <button type="submit" className="btn btn-success">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FixedHolidays;
