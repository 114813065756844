import React from 'react'
import { Link, useNavigate } from "react-router-dom";

export default function () {
  return (
    <div>
         <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth">
          <div className="row flex-grow">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left p-5">
                <div className="brand-logo">
                  <img src="../../assets/logo/fulllogo.png"/>
                </div>
                <h4>Enter OTP</h4>
                <form className="pt-3" >
                  <div className="form-group">
                    <input type="email" className="form-control form-control-lg" 
                     
                    id="exampleInputEmail1" placeholder="Enter OTP here"/>
                  </div>
                  
                  <div className="mt-3 d-grid gap-2 text-center">
                  <Link to="/resetpassword">
                    <button type="submit" className="btn btn-block btn-gradient-success btn-lg font-weight-large auth-form-btn w-100" >Verify OTP</button>
                    </Link>
                    <Link to="/getotp">
                    <button type="submit" className="btn btn-outline-info btn-lg font-weight-large  rounded-pill w-75 mt-3 text-center" >Resend OTP 
                    </button>
                    </Link>
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">  </div>
                  
                </form>
              </div>
            </div>
          </div>
        </div>
       
      </div>
     
    </div>
    </div>
  )
}
