import {
  DeleteAnnouncement,
  getAnnouncementList,
} from "@Networking/APIs/Announcement/announcementApi";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

const AnnouncementsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAnnouncementList());
  }, []);

  const { AnnouncementListData } = useSelector(
    (state) => state.AnnouncementSlice
  );

  const colors = [
    "alert alert-primary",
    "alert alert-success",
    "alert alert-warning",
    "alert alert-danger",
    "alert alert-primary",
    "alert alert-secondary",
  ]; // Add more colors if needed

  const handleEdit = (id) => {
    navigate("/editAnnouncement", { state: { id } });
  };

  const handleDelete = (data) => {
    const isConfirmed = window.confirm(
      "Are you sure? You won't be able to revert this!"
    );

    if (isConfirmed) {
      dispatch(DeleteAnnouncement(data.id));
      dispatch(getAnnouncementList());
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="">
              <i class="fa-solid fa-list me-2"></i>HRM / Announcement /
              Announcement List
            </h6>
          </div>
        </div>

        <div className="card bg-gradient-info rounded-top-2 p-2 mb-3">
          <div className="row d-flex align-items-center">
            <div className="col-md-12 col-sm-12">
              <h3 className="page-title m-0  text-light">
                <span className="mx-2 text-primary">
                  <i className="mdi mdi-file  text-light"></i>
                </span>
                All Announcements
              </h3>
            </div>
          </div>
        </div>

        <div className="card ">
          <div className="card-body">
            {AnnouncementListData?.announcement_data?.length > 0 ? (
              AnnouncementListData.announcement_data.map((data, index) => {
                const createdAt = new Date(data.created_at);
                const formattedDate = createdAt.toLocaleDateString("en-GB"); // Example: 18/10/2024
                const formattedTime = createdAt.toLocaleTimeString("en-GB", {
                  hour: "2-digit",
                  minute: "2-digit",
                }); // Example: 13:18
                return (
                  <React.Fragment key={data.id}>
                    <div
                      className={`card shadow alert ${
                        colors[index % colors.length]
                      }`}
                    >
                      <div className="row">
                        <div className="col-md-10">
                          <h4 className="card-title text-secondary ">
                            Event Name: {data.title}
                          </h4>
                          <h4 className="card-title text-secondary">
                            Event Date: {data.start_date}
                          </h4>
                        </div>
                        <div className="col-md-1">
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => handleEdit(data.id)}
                          >
                            <i className="fa fa-edit"></i>
                          </button>
                        </div>
                        <div className="col-md-1">
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => handleDelete(data)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </div>
                        <div className="col-md-12 mt-3 text-secondary">
                          {/* Displaying CKEditor content with HTML */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data.description,
                            }}
                          />
                        </div>
                        <div className="col-md-12 text-end">
                          <p className="fw-bold text-secondary">
                            Posted On: {formattedDate} {formattedTime}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Add a horizontal line after each card, except for the last card */}
                    {index <
                      AnnouncementListData.announcement_data.length - 1 && (
                      <hr />
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <h3>No Announcement Found</h3>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AnnouncementsList;
