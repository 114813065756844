import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

const CreateTask = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const ViewLeader = useSelector((state) => state.EmployeesSlice);

  console.log("ViewLeader", ViewLeader);
  useEffect(() => {}, [ViewLeader]);

  const options = [
    { value: "apple", label: "Apple" },
    { value: "banana", label: "Banana" },
    { value: "cherry", label: "Cherry" },
    { value: "date", label: "Date" },
    { value: "elderberry", label: "Elderberry" },
    { value: "fig", label: "Fig" },
    { value: "grape", label: "Grape" },
    { value: "honeydew", label: "Honeydew" },
    { value: "kiwi", label: "Kiwi" },
    { value: "lemon", label: "Lemon" },
    { value: "mango", label: "Mango" },
    { value: "nectarine", label: "Nectarine" },
    { value: "orange", label: "Orange" },
    { value: "peach", label: "Peach" },
    { value: "pineapple", label: "Pineapple" },
    { value: "quince", label: "Quince" },
    { value: "raspberry", label: "Raspberry" },
    { value: "strawberry", label: "Strawberry" },
    { value: "watermelon", label: "Watermelon" },
  ];

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  console.log(selectedOptions);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="">
              <i class="fa-solid fa-list me-2"></i>PRM / Tasks / Add New Task
            </h6>
          </div>
        </div>

        <form onSubmit={[]}>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="card">
                <h5 className="card-header text-center bg-gradient-info text-light p-1">
                  Add Task
                </h5>
                <div className="card-body">
                  <div className="row mt-2">
                    <div className="col-md-6 mb-2">
                      <label>Select Project</label>
                      <Select
                        className="select-client inputText"
                        id="countryCode"
                        // options={ClientGet}
                        // placeholder={selectedCountryCode || "Select Country Code"}
                        // {...register("phoneCode")}
                        isSearchable
                        // onChange={handleCountryCodeChange}
                      />
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <label>Assigned To</label>
                      <select
                      // onChange={(e) => setReportingTeamleader(e.target.value)}
                      >
                        <option value="" selected disabled>
                          Select Reporting Leader
                        </option>
                        {ViewLeader?.ViewLeader?.length > 0 ? (
                          ViewLeader?.ViewLeader.map((leader) => (
                            <option
                              key={leader.id}
                              value={leader.id}
                              // selected={
                              //   leader.id == employeData?.edit_employee?.team_id
                              // }
                            >
                              {leader.name}
                            </option>
                          ))
                        ) : (
                          <option disabled>No leaders available</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12 col-sm-12">
                      <label>Task Name</label>
                      <input className="inputText" type="text" />
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-6 col-sm-6">
                      <label>Start Date</label>
                      <input className="inputText" type="date" />
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <label>End Date</label>
                      <input className="inputText" type="date" />
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3 col-sm-6">
                      <label>Hours</label>
                      <input className="inputText " type="text" />
                    </div>
              
                    <div className="col-md-3 col-sm-6">
                      <label>Status</label>
                      <select class="inputText">
                        <option selected>Select Status</option>
                        <option value="todo">To Do</option>
                        <option value="inprogress">In Progress</option>
                        <option value="completed">Completed</option>
                        <option value="cancelled">Cancelled</option>
                      </select>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <label>Attachments</label>
                      <input className="inputText p-2" type="file" />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12 col-sm-12">
                      <label>Description</label>
                      <textarea
                        id="description"
                        className="inputText"
                        name="description"
                        rows="4"
                        placeholder="Enter project description here..."
                      ></textarea>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12 col-sm-12 text-center">
                      <button
                        type="submit"
                        className="btn btn-primary w-50 rounded-pill"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateTask;
