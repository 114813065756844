// src/components/ApplyLeave.js
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch, useSelector } from "react-redux";
import {
  applyLeave,
  clearErrors,
} from "@Networking/Slices/Leave/leaveApplySlice";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "@Layout/Loader/loader";
import applyLeaveApi from "@Networking/APIs/Leave/applyLeaveApi";

const ApplyLeave = () => {
  //Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Redux
  const { loading, apiErrors } = useSelector((state) => state.leaveSlice);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  //States
  const [isLoading, setIsLoading] = useState(false);

  const simulateLoading = async () => {
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsLoading(false);
  };

  const onSubmit = async (data) => {
    const payload = {
      subject: data.subject,
      start_date: data.fromDate,
      end_date: data.toDate,
      description: data.description,
    };

    simulateLoading();
    try {
      const result = await dispatch(applyLeaveApi(payload)).unwrap();

      if (result.status == true) {
        toast.success(result.message);
        reset();
        simulateLoading();
        navigate("/AllLeave");
      } else if (result.status == false) {
        toast.error(result.error);
      }
    } catch (error) {
      toast.error("Failed to apply leave. Please try again.");
    }
  };

  return (
    <div className="container-fluid">
      <div className="container-scroller">
        <ToastContainer />

        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="">
              <i class="fa-solid fa-list me-1"></i> HRM / Leave / Apply Leave
            </h6>
          </div>
        </div>

        <div className="card bg-gradient-info rounded-top-2 p-2 mb-3">
          <div className="row d-flex align-items-center">
            <div className="col-md-12 col-sm-12">
              <h3 className="page-title m-0  text-light">
                <span className="mx-2 text-primary">
                  <i className="mdi mdi-file  text-light"></i>
                </span>
                Apply Leave
              </h3>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row p-2">
                  <div className="col-md-12">
                    <label>Subject</label>
                    <input
                      className="inputText"
                      {...register("subject", {
                        required: "Subject is required",
                      })}
                    />
                    {errors.subject && (
                      <span className="text-danger">
                        {errors.subject.message}
                      </span>
                    )}
                    {apiErrors.subject && (
                      <span className="text-danger">
                        {apiErrors.subject[0]}
                      </span>
                    )}
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label>From</label>
                      <input
                        type="date"
                        className="inputText"
                        {...register("fromDate", {
                          required: "From date is required",
                        })}
                      />
                      {errors.fromDate && (
                        <span className="text-danger">
                          {errors.fromDate.message}
                        </span>
                      )}
                      {apiErrors.start_date && (
                        <span className="text-danger">
                          {apiErrors.start_date[0]}
                        </span>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label>To</label>
                      <input
                        type="date"
                        className="inputText"
                        {...register("toDate", {
                          required: "To date is required",
                        })}
                      />
                      {errors.toDate && (
                        <span className="text-danger">
                          {errors.toDate.message}
                        </span>
                      )}
                      {apiErrors.toDate && (
                        <span className="text-danger">
                          {apiErrors.toDate[0]}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-12">
                      <label>Description</label>
                      <textarea
                        className="inputText"
                        {...register("description", {
                          required: "Description is required",
                        })}
                      ></textarea>
                      {errors.description && (
                        <span className="text-danger">
                          {errors.description.message}
                        </span>
                      )}
                      {apiErrors.description && (
                        <span className="text-danger">
                          {apiErrors.description[0]}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 d-flex justify-content-center">
                    <button
                      type="submit"
                      className="btn btn-primary mt-3 rounded-pill w-50"
                      disabled={loading}
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </button>
                    {isLoading && <Loader />}
                  </div>
                </div>

                {apiErrors.leave_days && (
                  <div className="alert alert-danger mt-3">
                    {apiErrors.leave_days[0]}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyLeave;
