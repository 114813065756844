import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, companies } from "@Networking/APIs/NWConfig";
import Cookies from 'js-cookie';

// GenerateInvoice
export const postCompanyStore = createAsyncThunk(
    "post/postCompanyStore",
    async(post) => {

        let url = BASE_URL + companies; 

        const token = localStorage.getItem("authToken");

        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;
                return Promise.resolve(response);
        } catch(error) {
            return Promise.reject(error);
        }
    }
);