import { getNationality } from '@Networking/APIs/Nationality/nationalityApi';
import { createSlice } from '@reduxjs/toolkit';


const NationalitySlice = createSlice({
    name: 'NationalitySlice',
    initialState: {
      loading: false,
      NationalityData:[],
      success: false,
      error: null,
    },
  
    extraReducers: (builder) => {
      builder.addCase(getNationality.pending, state => {
        state.loading = true;
      });
      builder.addCase(getNationality.fulfilled, (state, action) => {
        state.NationalityData = action.payload;
      });
      builder.addCase(getNationality.rejected, state => {
        state.loading = false;
      });
    },
  });
  
  export default NationalitySlice.reducer;