//Submit Button Class
export const button = "btn btn-gradient-info rounded-pill";

//Icon Class
export const dangerButton = "btn btn-danger btn-sm mx-1";
export const successButton = "btn btn-success btn-sm mx-1";
export const warningButton = "btn btn-warning btn-sm mx-1";
export const primaryButton = "btn btn-primary btn-sm mx-1";
export const lightButton = "btn btn-light btn-sm mx-1";

//Status Class
export const dangerStatus = "badge badge-danger text-danger bg-light";
export const successStatus = "badge badge-success text-success bg-light";
export const infoStatus = "badge badge-info text-info bg-light";
export const cancelStatus = "badge badge-info text-primary bg-light"

//Component Header Card
export const componentheaderStep1 = "col-md-12 card text-white rounded-top-2 p-2 mb-2";
export const componentheaderStep2 = "row justify-content-between align-items-center";
export const textDiv = "col-md-6 col-sm-12";
export const searchDiv = "col-md-6 col-sm-12 text-end";

//Table Body
export const tableDiv = "card-body";

//Container
export const container = "container-fluid";

//Card Class
export const headerCard = "card-header bg-gradient-info text-white text-center";
export const card = "col-md-12 card mb-3 rounded";

//Paggination
export const pagginationContainter = "d-flex flex-wrap justify-content-between align-items-center p-3 border-top mt-3";
export const textMuted = "text-muted";
export const PagginationController = "pagination-controls d-flex align-items-center";
export const selectPage = "form-select form-select-sm";