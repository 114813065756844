import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {BASE_URL, clientGet } from '@Networking/APIs/NWConfig';
import Cookies from 'js-cookie';


//Client Api
export const getClient = createAsyncThunk(
    'get/getClient',
    async () => {  
  
      let url = BASE_URL + clientGet; 
  
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );
  