import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmployeData, postEmployeeCompensationStore } from "@Networking/APIs/Employee/EmployeStoreApi";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { resetValidateError } from "@Networking/Slices/Employee/EmployeStoreSlice";
import { useNavigate } from "react-router-dom";
import Loader from "@Layout/Loader/loader";


const Compensation = ({ nextStep, prevStep, progress }) => {
  const dispatch = useDispatch();

  const [employeeId, setEmployeeId] = useState(null);
  useEffect(() => {
    const storedEmployeeId = employeData?.edit_employee?.id;
    if (storedEmployeeId) {
      setEmployeeId(storedEmployeeId);
    }
  }, []);


const { validateError, employeData, step4loading} = useSelector(
  (state) => state.EmployeStoreSlice
);
const Navigate = useNavigate();

const [bank, setBank] = useState("");
const [account_name, setAccountName] = useState("");
const [account_number, setAccountNumber] = useState("");
const [ifsc_code, setIfscCode] = useState("");
const [annual_salary, setAnnualSalary] = useState("");
// const [salary_revision_date, setSalaryRevision] = useState("");
const [salary_remark, setSalaryRemark] = useState("");
const [empId, setEmpId] = useState(null);



const [formData, setFormData] = useState({});
const [errors, setErrors] = useState({}); 

const prepareData = () =>{
  if(employeData){
    setEmpId(employeData?.edit_employee?.id);
    setBank(employeData?.edit_employee?.bank);
    setAccountName(employeData?.edit_employee?.account_name);
    setAccountNumber(employeData?.edit_employee?.account_number);
    setIfscCode(employeData?.edit_employee?.ifsc_code);
    setAnnualSalary(employeData?.edit_employee?.annual_salary);
    // setSalaryRevision(employeData?.edit_employee?.salary_revision_date);
    setSalaryRemark(employeData?.edit_employee?.salary_remark);
   }};

  useEffect(() => {
    prepareData();
}, [employeData]);

const capitalizeFirstLetter = (value) =>
  value.charAt(0).toUpperCase() + value.slice(1);

useEffect(() => {
  setFormData({
     bank: bank  ,
    account_name: account_name  ,
    account_number: account_number  ,
    ifsc_code: ifsc_code  ,
    // annual_salary: annual_salary  ,
    // salary_revision_date: salary_revision_date  ,
    salary_remark: salary_remark  , 
    employee_id: empId  , 
  });
}, [
  bank,
  account_name,
  account_number,
  ifsc_code,
  // annual_salary,
  // salary_revision_date,
  salary_remark,
  empId,
]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    
    try {
      const res = await dispatch(postEmployeeCompensationStore(formData));

      if (res.payload.status === true) {
        dispatch(fetchEmployeData(empId));
        dispatch(resetValidateError());
        Navigate("/Employees"); 
    }
     } catch (error) {
      const errorMessage = error.response?.data?.message || 'An unexpected error occurred. Please try again.';
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="col d-flex justify-content-between">
        <h5 className="mt-4">Step 4: Compensation</h5>
        <h5 className="mt-4 text-end "> <FontAwesomeIcon icon={faUser} className="menu-icon mx-2" />{employeData?.edit_employee?.first_name} {employeData?.edit_employee?.middle_name} {employeData?.edit_employee?.last_name}</h5>
        </div>
      <div className="progress-bar mx-5">
        <div className="progress" style={{ width: `${progress}%` }}></div>
      </div>
       <div className="row">
      <div className="col-md-6 mb-2">
      <label>
      Bank Name
        </label>
        <input
        id="input"
              type="text"
              placeholder="Enter Bank Name"
              value={
                bank
                  ? bank.charAt(0).toUpperCase() + bank.slice(1)
                  : employeData?.edit_employee?.bank
                  ? employeData.edit_employee.bank.charAt(0).toUpperCase() + employeData.edit_employee.bank.slice(1)
                  : ""
              }
              onChange={(e) =>{
                const updatebank = capitalizeFirstLetter(e.target.value)
                setBank(updatebank)}}
             />
              {validateError?.bank && (
              <span className="text-danger">{validateError?.bank[0]}</span>
            )}
        </div>
        <div className="col-md-6 mb-2">
      <label>
      Account Holder Name
        </label>
        <input
        id="input"
              type="text"
              placeholder="Enter Account Holder Name"
              value={
                account_name
                  ? account_name.charAt(0).toUpperCase() + account_name.slice(1)
                  : employeData?.edit_employee?.account_name
                  ? employeData.edit_employee.account_name.charAt(0).toUpperCase() + employeData.edit_employee.account_name.slice(1)
                  : ""
              }
              onChange={(e) => {
                const updateAccountName = capitalizeFirstLetter(e.target.value)
                setAccountName(updateAccountName)}}
             />
              {validateError?.account_name && (
              <span className="text-danger">{validateError?.account_name	[0]}</span>
            )}
        </div>
        <div className="col-md-6 mb-2">
      <label>
      Account Number
        </label>
        <input
        id="input"
              type="number"
              placeholder="Enter Account Number"
              value={account_number || employeData?.edit_employee?.account_number}
              onChange={(e) => setAccountNumber(e.target.value)}
             />
              {validateError?.account_number && (
              <span className="text-danger">{validateError?.account_number[0]}</span>
            )}
        </div>
        
        <div className="col-md-6 mb-2">
      <label>
      IFSC Code
        </label>
        <input
        id="input"
              type="text"
              placeholder="Enter IFSC Code"
              value={ifsc_code || employeData?.edit_employee?.ifsc_code}
              onChange={(e) => setIfscCode(e.target.value)}
             />
              {validateError?.ifsc_code && (
              <span className="text-danger">{validateError?.ifsc_code[0]}</span>
            )}
        </div>
        {/* <div className="col-md-6 mb-2">
      <label>
      Annual Salary
        </label>
        <input
        id="input"
              type="text"
              placeholder="Enter Annual Salary"
              value={annual_salary || employeData?.edit_employee?.annual_salary}
              onChange={(e) => setAnnualSalary(e.target.value)}
             />
              {validateError?.annual_salary && (
              <span className="text-danger">{validateError?.annual_salary[0]}</span>
            )}
        </div> */}
        {/* <div className="col-md-6 mb-2">
      <label>
      Salary Revision Date
        </label>
        <input
        id="input"
              type="date"
              placeholder="Enter IFSC Code"
              value={salary_revision_date || employeData?.edit_employee?.salary_revision_date}
              onChange={(e) => setSalaryRevision(e.target.value)}
             />
             {validateError?.salary_revision_date && (
              <span className="text-danger">{validateError?.salary_revision_date[0]}</span>
            )}
        </div> */}
        <div className="col-md-12 mb-2">
      <label>
      Salary Remarks
        </label>
        <textarea
        id="input"
        className="myTextArea"
              type="date"
              placeholder="Enter Salary Remarks"
              value={
                salary_remark
                  ? salary_remark.charAt(0).toUpperCase() + salary_remark.slice(1)
                  : employeData?.edit_employee?.salary_remark
                  ? employeData.edit_employee.salary_remark.charAt(0).toUpperCase() + employeData.edit_employee.salary_remark.slice(1)
                  : ""
              }
                onChange={(e) => {
                  const updateRemark = capitalizeFirstLetter(e.target.value)
                  setSalaryRemark(updateRemark)}}
                >
              </textarea>
              {validateError?.salary_remark && (
              <span className="text-danger">{validateError?.salary_remark[0]}</span>
            )}
        </div>
       
      </div>

      <div className="form-navigation mb-4">
        <button type="button" className="btn btn-danger" onClick={prevStep}>
          Previous
        </button><button type="submit" className="btn btn-success">
          Submit
        </button>
      </div>
      {
        step4loading ? <Loader/> : null
      }
    </form>
  );
};

export default Compensation;
