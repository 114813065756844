import React, { useMemo, useState, useEffect, useRef } from "react";
 import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  SoftCancel,
  getallinvoices,
} from "@Networking/APIs/Invoice/invoiceApi";
import { useDispatch, useSelector } from "react-redux";
import Loader from "@Layout/Loader/loader";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { getcurrency } from "@Networking/APIs/Background/backgorundApi";
import {
  warningButton,
  dangerButton,
  primaryButton,
  successButton,
  dangerStatus,
  successStatus,
  infoStatus,
   tableDiv,
   cancelStatus,
} from "@Layout/Color/color";
import './invoiceList.css';


// Global filter component for searching
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <span className="ms-4">
            <input
              value={globalFilter || ""}
              onChange={(e) => setGlobalFilter(e.target.value || undefined)}
              placeholder="Search all columns"
              className="ps-2 rounded inputText"
              style={{
                width: "200px",
                display: "inline-block",
              }}
            />
          </span>
        </div>

        <div className="col-md-6 text-end">
          <span className="me-4">
            <select
              style={{
                width: "200px",
                display: "inline-block",
                // height: "10px",
              }}
              value={globalFilter || ""}
              onChange={(e) => setGlobalFilter(e.target.value)}
              className="inputText"
            >
              <option value="" className="text-dark" selected>
                <b>All Invoices</b>
              </option>
              <option value="Paid" className="text-success">
                Paid
              </option>
              <option value="Unpaid" className="text-danger">
                Unpaid
              </option>
              <option value="Partially Paid" className="text-info">
                Partially Paid
              </option>
            </select>
          </span>
        </div>
      </div>
    </>
  );
};

// Define CSS classes for status background colors
const getStatusClass = (status) => {
  switch (status) {
    case "Unpaid":
      return dangerStatus;
    case "Paid":
      return successStatus;
    case "Partially Paid":
      return infoStatus;
    case "Cancelled":
      return cancelStatus;
    default:
      return "";
  }
};

const InvoiceList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { AllInvoices } = useSelector((state) => state.GetInSlice);
  const { currencyCode } = useSelector((state) => state.BackgroundSlice);
  const [invoiceData, setInvoiceData] = useState([]);
  const cardRef = useRef(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const tableRef = useRef(null);

  const tableHeaderRef = useRef(null);
  const tableBodyRef = useRef(null);
  // Fetch invoices once
  const role = localStorage.getItem("role_id");

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getallinvoices());
        await dispatch(getcurrency());
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    setIsLoadingMore(true);
    if (AllInvoices) {
      setInvoiceData(AllInvoices.data);
      setIsLoadingMore(false);
    }
  }, [AllInvoices]);
console.log(invoiceData,"invoiceData");
  const data = useMemo(() => {
    return invoiceData
      ? invoiceData.map((invoice) => {
          const matchedCurrency = currencyCode.find(
            (currency) => currency.value === invoice.currency
          );

          // Extract category - handle cases with no products or multiple products
          const category =
            invoice.products && invoice.products.length > 0
              ? invoice.products[0].categoryLabel || "N/A"
              : "N/A";

          return {
            ID: invoice.id || "NA",
            Invoice_No: invoice.invoice_number || "N/A",
            Invoice_Date: invoice.invoice_date || "N/A",
            invoice_url: invoice.invoice_url || "N/A",
            Due_Date: invoice.due_date || "N/A",
            status: invoice.status || "N/A",
            // Use matchedCurrency here for each invoice
            total: `${matchedCurrency?.logo || invoice.currency} ${
              invoice.total || "N/A"
            } `,
            amount_due: invoice?.amount_to_be_paid || "N/A",
            Discount: invoice.discount || "N/A",
            Currency: invoice.currency || "N/A",
            Templete: invoice.template || "N/A",
            item_names: invoice.item_names || "N/A",
            invoiceBy: invoice?.invoice_by || "N/A",

            // Client Details
            client_name: invoice.client_name || "N/A",
            Client_Address: invoice.client_address || "N/A",
            business_name: invoice.business_name || "N/A",
            Client_City: invoice.client_city || "N/A",
            Client_State: invoice.client_state || "N/A",
            Client_Country: invoice.client_country || "N/A",
            Client_Pincode: invoice.client_pincode || "N/A",
            Client_Email: invoice.client_email || "N/A",
            Client_Phone: invoice.client_phone || "N/A",

            // Company Details
            Company_Name: invoice.company_name || "N/A",
            Company_Short_Name: invoice.company_short_name || "N/A",
            Company_Address: invoice.company_address || "N/A",
            Company_City: invoice.company_city || "N/A",
            Company_State: invoice.company_state || "N/A",
            Company_Country: invoice.company_country || "N/A",
            Company_Pincode: invoice.company_pincode || "N/A",
            Company_Email: invoice.company_email || "N/A",
            Company_Contact: invoice.company_contact || "N/A",
            Company_GST: invoice.company_gst || "N/A",
            Company_Website: invoice.company_website || "N/A",
            Company_Logo: invoice.company_logo || "N/A",

            // Bank Details
            Bank_Name: invoice.bank_name || "N/A",
            Branch: invoice.branch || "N/A",
            Account_Name: invoice.account_name || "N/A",
            Account_Number: invoice.account_number || "N/A",
            Account_Type: invoice.account_type || "N/A",
            Bank_Address: invoice.bank_address || "N/A",
            Bank_City: invoice.bank_city || "N/A",
            IFSC_Code: invoice.ifsc_code || "N/A",

            // Other Details
            Reference_Number: invoice.reference_number || "N/A",
            Terms_Condition: invoice.terms_condition || "N/A",

            // Other Amounts
            other_amounts:
              invoice?.other_amounts?.map((otheramounts) => ({
                name: otheramounts?.name || "N/A",
                amount: otheramounts?.amount || "N/A",
              })) || [],

            // Product Details
            Products:
              invoice?.products?.map((product) => ({
                itemLabel: product?.itemLabel || "N/A",
                Quantity: product?.quantity || "N/A",
                Hours: product?.hours || "N/A",
                Price: product?.price || "N/A",
                Discount_value: product?.discountValue || "N/A",
                category: product?.categoryLabel || "NA",
                Discribtion: product?.description,
              })) || [],
          };
        })
      : null;
  }, [invoiceData, currencyCode]);

  const handleEdit = (invoiceId) => {
     
    navigate("/InvoiceEdit", { state: { invoiceId } });
  };

  const handleSoftcancel = async (invoiceId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to cancel this invoice?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, cancel it!",
      cancelButtonText: "No, keep it",
    });

    if (!result.isConfirmed) {
      return; // Exit if the user cancels
    }
    setIsLoadingMore(true);
    
    try {
      const response = await dispatch(SoftCancel(invoiceId)).unwrap();
      await dispatch(getallinvoices());
      setIsLoadingMore(false);
      toast.success("Invoices cancel successfully");
    } catch (error) {
      setIsLoadingMore(false);
      toast.error("Invoices Cancel failed");
    }
  };

  const handleView = (invoice) => {
    console.log(invoice); 
    const currentUrl = invoice.invoice_url;
    // window.open(currentUrl, "_blank");
    if(invoice.id <= 1335){
      const newUrl = `https://developbo.techsimba.in/backoffice/public/downloadInvoice/${currentUrl}`;

      window.open(newUrl, '_blank');
     }
     else{
      const newUrl =currentUrl;
      window.open(newUrl, '_blank');
     }
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "Paid":
        return "#bdefa2"; // Green background for Paid
      case "Unpaid":
        return "#ffff"; // Red background for Unpaid
      case "Partially Paid":
        return "#f4d9a6"; // Yellow background for Partially Paid
      default:
        return "#a6a6a6"; // Default background
    }
  };
  

  const handlePartial = (invoiceId) => {
    console.log(invoiceId);
    navigate("/InvoicePartially", { state: { invoiceId } });
  };


  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;

  // Filter invoices based on search term and selected status
  const filteredInvoices = invoiceData.filter(invoice => {
    const matchesSearchTerm = invoice.invoice_number.toLowerCase().includes(searchTerm.toLowerCase()) || invoice.business_name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = selectedStatus ? invoice.status === selectedStatus : true;
    return matchesSearchTerm && matchesStatus;
  });

  // Handle pagination
  const indexOfLastInvoice = currentPage * itemsPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - itemsPerPage;
  const currentInvoices = filteredInvoices.slice(indexOfFirstInvoice, indexOfLastInvoice);
  const totalPages = Math.ceil(filteredInvoices.length / itemsPerPage);

  // Change page handler
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  

  return (
    <>
 <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <h6>
            <i className="fa-solid fa-list me-1"></i> Finance / Invoice / Manage Invoice
          </h6>
        </div>
      </div>

      <div className="card mt-3 invoice-card mb-5" ref={tableRef}>
        <h5 className="card-header text-center bg-gradient-info text-light p-1">
          Invoice List
        </h5>

        <div className={tableDiv} style={{ height: "auto", overflowY: "auto" }}>
        <div className="row mb-3 justify-content-between">
  <div className="col-md-3">
    <label htmlFor="invoiceSearch" className="form-label">Search by Invoice Number </label>
    <input
      type="text"
      className="form-control mt-2 border border-1"
      id="invoiceSearch"
      placeholder="Search Here..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
  </div>
  <div className="col-md-3">
    <label htmlFor="statusFilter" className="form-label">Filter by Status</label>
    <select
      className="form-control border border-1"
      id="statusFilter"
      value={selectedStatus}
      onChange={(e) => setSelectedStatus(e.target.value)}
    >
      <option value="">All Invoices</option>
      <option value="Paid">Paid</option>
      <option value="Unpaid">Unpaid</option>
      <option value="Partially Paid">Partially Paid</option>
      <option value="Cancelled">Cancelled</option>
    </select>
  </div>
</div>


  <div className="table-responsive">
  <table className="listtable table-bordered">
    <thead className="table-light">
      <tr>
        <th className="text-center py-2">Invoice Number</th>
        <th className="text-center py-2">Client Name</th>
        <th className="text-center py-2">Total</th>
        <th className="text-center py-2">Payment Status</th>
        <th className="text-center py-2">Due Amount</th>
        <th className="text-center py-2">Due Date</th>
        <th className="text-center py-2">Action</th>
      </tr>
    </thead>
    <tbody>
      {currentInvoices.length > 0 ? (
        currentInvoices.map((invoice) => {
          const matchedCurrency = currencyCode.find(
            (currency) => currency.value === invoice.currency
          );

          // Define the class based on the invoice status
          const statusClass = 
            invoice.status == "Paid"
              ? "paid"
              : invoice.status == "Unpaid"
              ? "unpaid"
              : invoice.status == "Partially Paid"
              ? "partial"
              : "canceld"; // Default to 'canceld' if no match

          return (
            <tr key={invoice.id} 
           
            
             
            style={{
              fontSize: "16px",
              lineHeight: "2",
              backgroundColor:
                invoice.status === "Paid"
                  ? "#bdefa2" // Green for Paid
                  : invoice.status === "Unpaid"
                  ? "#ffff" // White for Unpaid
                  : invoice.status === "Partially Paid"
                  ? "#f4d9a6" // Yellow for Partially Paid
                  : invoice.status === "Cancelled"
                  ? "#a6a6a6" // Gray for Cancelled
                  : "#f1f1f1", // Default color if no status
            }}>
              <td className="text-center" >
                {invoice.invoice_number || "N/A"}
              </td>
              <td className="text-center" >
                <strong>
                  {invoice.client.first_name || "N/A"} {invoice.client.last_name || "N/A"}
                </strong>
                <br />
                {invoice.business_name || "N/A"}
              </td>
              <td className="text-center" >
                {matchedCurrency?.logo || ""} {invoice.total || "0.00"}
              </td>
              <td className="text-center" >
              <span
                  style={{
                    display: "inline-block",
                    padding: "0px 10px",
                    borderRadius: "5px",
                    backgroundColor:
                      invoice.status === "Paid"
                        ? "#4CAF50" // Green for Paid
                        : invoice.status === "Unpaid"
                        ? "#F44336" // Red for Unpaid
                        : invoice.status === "Partially Paid"
                        ? "orange" // Yellow for Partially Paid
                        : invoice.status === "Cancelled"
                        ? "#9E9E9E" // Gray for Cancelled
                        : "#f1f1f1", // Default color for unknown status
                    color: "#fff", // White text color
                  }}
                >
                  {invoice.status || "N/A"}
                </span>
                </td>
              <td className="text-center" >
                {matchedCurrency?.logo || ""} {invoice.amount_to_be_paid || "0.00"}
              </td>
              <td className="text-center" >
              <td className="text-center">
              {invoice.id < "1336" ? (
                <>
                  
                   {invoice.due_date ? new Date(invoice.due_date).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric"
                  }) : "N/A"}
                </>
              ) : (
                invoice.due_date || "N/A"
              )}
            </td>


              </td>
              <td className="text-center" >
                <div className="d-flex justify-content-center fa-lg">
                  <div
                    className={primaryButton}
                    onClick={() => handleView(invoice)}
                    title="View PDF"
                  >
                    <i className="fa-solid fa-file-pdf text-light"></i>
                  </div>
                  <div
                    className={warningButton}
                    onClick={() => handleEdit(invoice.id)}
                    title="Edit Invoice"
                  >
                    <i className="fa-solid fa-pen-to-square text-light"></i>
                  </div>
                  <div
                    className={successButton}
                    onClick={() => handlePartial(invoice.id)}
                    title="Partial Payment"
                  >
                    <i className="fa-solid fa-money-bill-wave text-light"></i>
                  </div>
                  <div
                    className={dangerButton}
                    onClick={() => handleSoftcancel(invoice.id)}
                    title="Cancel Invoice"
                  >
                    <i className="fa-regular fa-times-circle text-light"></i>
                  </div>
                </div>
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan="7" className="text-center" style={{ fontSize: "18px", padding: "20px" }}>
            No Data Found
          </td>
        </tr>
      )}
    </tbody>
  </table>
</div>


          {isLoadingMore && (
            <div className="text-center mt-3">
              <Loader />
            </div>
          )}

          {/* Pagination */}
          <div className="d-flex justify-content-center mt-3">
            <nav>
              <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''} mx-2`}>
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${currentPage === index + 1 ? 'active' : ''} mx-2`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''} mx-2`}>
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>


    </>
  );
};

export default InvoiceList;
