import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteEmployeeStatus,
  getEmployeeStatus,
  postEmployeeStatus,
  updateEmployeeStatus,
} from "@Networking/APIs/Employee/EmployeeApi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../masterForm.css";

const MySwal = withReactContent(Swal);

const EmployeeStatusForm = () => {
  const [selectedName, setEmployeeStatus] = useState(null);
  const [nameInput, setNameInput] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { type } = useParams();

  useEffect(() => {
    dispatch(getEmployeeStatus());
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const employeeStatusData = useSelector((state) => state.EmployeesSlice);

  const onSubmit = async (data) => {
    const newEmployeeStatus = {
      status: data.status,
    };

    try {
      await dispatch(postEmployeeStatus(newEmployeeStatus));
      await dispatch(getEmployeeStatus());
      reset();
      // await dispatch(postWorkingHours(newWorkingHours));
      // await dispatch(getWorkingHours());
    } catch (error) {}
  };

  const handleEdit = (empStatus) => {
    setEmployeeStatus(empStatus);
    setNameInput(empStatus.status);
    openEditModal(empStatus);
  };

  const handleSubmitEdit = async (id, newName) => {
    const updateData = { id, status: newName }; // No need to access newName.name
    try {
      await dispatch(updateEmployeeStatus(updateData));
      await dispatch(getEmployeeStatus());
    } catch (error) {
      MySwal.fire("Error!", "Something went wrong while updating.", "error");
    }
  };

  const openEditModal = (empStatus) => {
    MySwal.fire({
      title: "Edit Employee Status",
      input: "text",
      inputValue: empStatus.status,
      inputPlaceholder: "Enter new name",
      showCancelButton: true,
      confirmButtonText: "Update",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      customClass: {
        title: "custom-title",
        input: "custom-input-size",
        confirmButton: "custom-confirm-button",
        cancelButton: "custom-cancel-button",
      },
      preConfirm: () => {
        const newName = Swal.getInput().value.trim();
        if (!newName) {
          Swal.showValidationMessage("Please enter a new name");
        }
        return newName;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmitEdit(empStatus.id, result.value);
      }
    });
  };

  const handleDelete = (empStatus) => {
    const isConfirmed = window.confirm(
      "Are you sure? You won't be able to revert this!"
    );

    if (isConfirmed) {
      dispatch(DeleteEmployeeStatus(empStatus.id));
      dispatch(getEmployeeStatus());
    }
  };

  return (
    <>
          <div className="container-fluid">

      <div className="row">
        <div className="col-md-12 col-sm-12">
          <h6 className="">
            <i class="fa-solid fa-list me-2"></i>HRM / Employee Master's /
            Employee Status
          </h6>
        </div>
      </div>

      <div className="m-1">
      <button
        type="button"
        className="btn btn-secondary mb-3"
        onClick={handleBack}
      >
        <i className="fa fa-arrow-left me-2"></i>
        Back
      </button>
      </div>

        <div className="card">
          <div className="card-body">
          <div className="row p-3">
          <div className="col-md-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row d-flex align-items-center">
                <div className="col-md-3">
                  <h6>Employee Status:</h6>
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="Enter Employee Status"
                    className="form-control"
                    id="status"
                    {...register("status", {
                      required: "Employee Status is required",
                    })}
                  />
                  {errors.status && (
                    <span className="text-danger">{errors.status.message}</span>
                  )}
                </div>
                <div className="col-md-3">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
            <div className="row mt-3 border rounded">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Employee Status</th>
                        <th className="text-end">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employeeStatusData?.EmployeeStatusData.length > 0 ? (
                        employeeStatusData?.EmployeeStatusData.map(
                          (empStatus) => (
                            <tr key={empStatus.id}>
                              <td>{empStatus.status}</td>
                              <td>
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    className="btn btn-sm rounded btn-primary me-2"
                                    onClick={() => handleEdit(empStatus)}
                                  >
                                    <i className="fa fa-edit"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm rounded btn-danger"
                                    onClick={() => handleDelete(empStatus)}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan="2" className="text-center">
                            No Time Types available.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeStatusForm;
