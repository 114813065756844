import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, LogoPost } from "@Networking/APIs/NWConfig";
import Cookies from 'js-cookie';

export const postLogo = createAsyncThunk(
    "post/postLogo",
    async (post) => {
        const url = `${BASE_URL}${LogoPost}`; // Use template literals for better readability
        const token = localStorage.getItem("authToken");

        try {
            const response = await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data' // Set the content type for file uploads
                }
            });

            return response.data; // Simply return the data directly
        } catch (error) {

            // Handle specific error types
            if (error.response) {
                if (error.response.status === 400) {
                    throw new Error("Invalid data format");
                } else if (error.response.status === 401) {
                    throw new Error("Unauthorized access");
                }
            }
            throw new Error("An error occurred. Please try again later."); // Fallback error message
        }
    }
);