import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { getEmployees } from "@Networking/APIs/Employee/EmployeeApi";
import { fetchEmployeData } from "@Networking/APIs/Employee/EmployeStoreApi";
import { dangerStatus, infoStatus, successStatus } from "@Layout/Color/color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faSearch,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "@Layout/Loader/loader";

// Utility function to chunk array
const chunkArray = (array, chunkSize) => {
  const results = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    results.push(array.slice(i, i + chunkSize));
  }
  return results;
};

const Employees = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { EmployeesData , loadingg} = useSelector((state) => state.EmployeesSlice);
  const [searchQuery, setSearchQuery] = useState("");

  // Fetch Employees on Component Mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getEmployees());
      } catch (err) {}
    };
    fetchData();
  }, [dispatch]);

  // Format Employee Data
  const formatData = (data) =>
    data?.map((employee) => ({
      ...employee,
      EMP_ID: employee.id,
      Name: `${employee.first_name} ${employee.middle_name || ""} ${
        employee.last_name
      }`,
      Designation: employee.job_title,
      Email: employee.work_email,
      Phone: employee.contact,
      Status: employee.employee_status,
      Image: employee.profile || "https://via.placeholder.com/100",
    })) || [];

  const filterEmployees = (employees, query) => {
    if (!employees) return [];

    const lowerQuery = query.toLowerCase();
    return employees.filter((employee) => {
      const name = employee?.Name?.toLowerCase() || "";
      const designation = employee?.Designation?.toLowerCase() || "";
      const email = employee?.Email?.toLowerCase() || "";
      const phone = employee?.Phone?.toLowerCase() || "";

      return (
        name.includes(lowerQuery) ||
        designation.includes(lowerQuery) ||
        email.includes(lowerQuery) ||
        phone.includes(lowerQuery)
      );
    });
  };

  const filteredManagers = useMemo(
    () => filterEmployees(formatData(EmployeesData?.managers), searchQuery),
    [EmployeesData, searchQuery]
  );
  const filteredTeamLeaders = useMemo(
    () => filterEmployees(formatData(EmployeesData?.teamLeaders), searchQuery),
    [EmployeesData, searchQuery]
  );
  const filteredEmployees = useMemo(
    () => filterEmployees(formatData(EmployeesData?.employees), searchQuery),
    [EmployeesData, searchQuery]
  );

  // Get Status Badge Class
  const getStatusClass = (status) => {
    switch (status) {
      case "Inactive":
        return "badge rounded-pill badge-danger text-white";
      case "Active":
        return "badge rounded-pill badge-success text-white";
      default:
        return "badge rounded-pill badge-info text-white";
    }
  };

  // Handle Edit Button Click
  const handleEdit = (employee) => {
    dispatch(fetchEmployeData(employee.EMP_ID));
    navigate(`/AddEmployee`, { state: { employee } });
  };

  // Handle View Button Click
  const handleView = (employee) => {
    navigate(`/EmployeeView/${employee.EMP_ID}`, { state: { employee } });
  };

  // Render Carousel
  const  renderCarousel = (dataChunks, label, carouselId) => (
    <div className="card mb-3 p-2">
      
      <div className="m-1">
        <div className="card-header bg-gradient-info p-1 text-light text-center align-items-center">
          
          <div className="fw-bold">
            <FontAwesomeIcon
              icon={faUserCircle}
              style={{ marginRight: "8px" }}
            />
            
            {label} (
            {label === "Employees"
              ? EmployeesData?.employees_count
              : label === "Managers"
              ? EmployeesData?.managers_count
              : label === "Team Leaders"
              ? EmployeesData?.teamLeaders_count
              : "N/A"}
            )
          </div>
        
        </div>
        <hr />
      </div>
      {EmployeesData ? (
        <div id={carouselId} className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            {dataChunks.length > 0 ? (
              dataChunks?.map((chunk, index) => (
                <div
                  key={index}
                  className={`carousel-item ${index === 0 ? "active" : ""}`}
                >
                  <div className="d-flex justify-content-center">
                    {chunk.map((employee) => (
                      <div
                        className="card mx-2 shadow-sm h-100 p-2 "
                        key={employee.EMP_ID}
                        style={{ width: "270px", borderRadius: "10px" }}
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <p className="text-muted text-center mb-2 alert alert-warning p-0">
                              {employee.Designation}
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 text-center">
                            <img
                              src={
                                employee.profile_photo
                                  ? `https://developbo.techsimba.in/management/public/profile_Photos/${employee.profile_photo}`
                                  : "https://icons.veryicon.com/png/o/miscellaneous/user-avatar/user-avatar-male-5.png"
                              }
                              className="rounded-circle"
                              alt={`${employee?.Name || "Employee"}'s profile`}
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                                marginBottom: "10px",
                                border: "2px solid #dee2e6",
                              }}
                            />
                          </div>
                        </div>

                        <div className="alert alert-primary p-0">
                          <h6 className="text-center text-truncate m-0">
                            {employee.Name}
                          </h6>
                        </div>

                        <div className="d-flex col-md-12 justify-content-between border rounded-pill">
                          <div className="col-md-9 text-start">
                            <span
                              className={`badge ${getStatusClass(
                                employee.Status
                              )} p-1`}
                              style={{ fontSize: "0.8rem" }}
                            >
                              {employee.Status}
                            </span>
                          </div>
                          <div className="col-md-3 text-end">
                            <span
                              className="text-primary"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleView(employee)}
                              aria-label={`View details of ${employee.Name}`}
                              title="View Details"
                            >
                              <FontAwesomeIcon icon={faEye} className="me-2" />
                            </span>
                          {/* </div>
                          <div className="col-md-1 text-end"> */}
                            <span
                              className="text-danger"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleEdit(employee)}
                              aria-label={`Edit details of ${employee.Name}`}
                              title="Edit Details"
                            >
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className="me-2"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center">No Data Available</div>
            )}
          </div>

          {/* Carousel Controls */}
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target={`#${carouselId}`}
            data-bs-slide="prev"
            aria-label="Previous slide"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target={`#${carouselId}`}
            data-bs-slide="next"
            aria-label="Next slide"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      ) : (
        "N/A"
      )}
    </div>
  );

  // Chunk Employee Data for Carousel
  const managerChunks = chunkArray(filteredManagers, 4);
  const teamLeaderChunks = chunkArray(filteredTeamLeaders, 4);
  const employeeChunks = chunkArray(filteredEmployees, 4);

  return (
    <>
<div className="container-fluid">
<div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="">
              <i class="fa-solid fa-list me-1"></i> HRM / Manage Employees 
            </h6>
          </div>
        </div>

  
      <div className="mb-2">
        <input
          type="text"
          className="inputText rounded-pill p-2"
          placeholder="Search employees..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {/* <span className="input-group-text">
          <FontAwesomeIcon icon={faSearch} />
        </span> */}
      </div>
      {renderCarousel(managerChunks, "Managers", "managerCarousel")}
      {renderCarousel(teamLeaderChunks, "Team Leaders", "teamLeaderCarousel")}
      {renderCarousel(employeeChunks, "Employees", "employeeCarousel")}
      
      {loadingg && (
            <div className="text-center mt-2">
              <Loader />
            </div>
          )}
      </div>


    </>
  );
};

export default Employees;
