import { fetchUserProfile, userProfileImage } from '@Networking/APIs/Profile/profileApi';
import { createSlice } from '@reduxjs/toolkit';
 import { toast } from 'react-toastify';


const ProfileSlice = createSlice({
  name: 'ProfileSlice',
  initialState: {
    success: false,
    error: null,
    loading: false,

    apiErrors: {},
    ProfileData:[],
  },

  extraReducers: (builder) => {
    builder
    .addCase(fetchUserProfile.pending, state => {
      state.loading = true;
    })
    .addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.ProfileData = action.payload;
      state.loading = false;
    })
    .addCase(fetchUserProfile.rejected, state => {
      state.loading = false;
    })
    
    .addCase(userProfileImage.pending, (state) => {
      state.loading = true;
      state.apiErrors = {};
    })
    .addCase(userProfileImage.fulfilled, (state, action) => {
      state.loading = false;
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.messages)
        state.apiErrors = action.payload.response.data.messages;
      }
    })
    .addCase(userProfileImage.rejected, (state, action) => {
      state.loading = false;
      state.apiErrors = action.payload || {};
    });

  },
});

export default ProfileSlice.reducer;