import axios from 'axios';
import { BASE_URL, userProfile, userUploadImage } from '@Networking/APIs/NWConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchUserProfile = createAsyncThunk(
    "get/fetchUserProfile",
    async() => {
  
        let url = BASE_URL + userProfile;
        const token = localStorage.getItem("authToken");
        try {
            const response = (await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;
            return Promise.resolve(response);
        } catch(response) {
            return response;
        }
    }
  );


//   Edit Profile Pic
export const userProfileImage = createAsyncThunk(
    "post/userProfileImage",
    async(post) => {
        let url = BASE_URL + userUploadImage ;
        const token = localStorage.getItem("authToken");
  
        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;
                return Promise.resolve(response);
        } catch(response) {
            return response;
        }
    }
  );
  