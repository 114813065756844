import { CategoryStore, deleteCategory, getCategory } from '@Networking/APIs/Invoice/Category/CategoryGetApi';
import {createSlice} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const CategorySlice = createSlice({
  name: 'CategorySlice',
  initialState: {
    Category: '',
    loading1:false,
    CategoryGet:[],
    validateError:[],
    loading:false
  },
  extraReducers: builder => {
    builder.addCase(getCategory.pending, state => {
      state.loading = true;
    });
    builder.addCase(getCategory.fulfilled, (state, action) => {
      state.CategoryGet = action.payload;
    });
    builder.addCase(getCategory.rejected, state => {
      state.loading = false;
    });
    builder.addCase(CategoryStore.pending, state => {
      state.loading1 = true;
    });
    builder.addCase(CategoryStore.fulfilled, (state, action) => {
      state.loading1 = false;
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.message)
        state.validateError = action.payload.response.data.message;
      }
    });
    builder.addCase(CategoryStore.rejected, state => {
      state.loading1 = false;
    });

    builder.addCase(deleteCategory.pending, state => {
      state.loading1 = true;
    });
    builder.addCase(deleteCategory.fulfilled, (state, action) => {
      state.loading1 = false;
     });
    builder.addCase(deleteCategory.rejected, state => {
      state.loading1 = false;
    });
  },
});

//Reducer
export default CategorySlice.reducer;
