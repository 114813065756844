// src/slice/leaveSlice.js
import applyLeaveApi from '@Networking/APIs/Leave/applyLeaveApi';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const leaveSlice = createSlice({
  name: 'leaveSlice',
  initialState: {
    loading: false,
    apiErrors: {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(applyLeaveApi.pending, (state) => {
        state.loading = true;
        state.apiErrors = {};
      })
      .addCase(applyLeaveApi.fulfilled, (state, action) => {
        state.loading = false;
        if(action.payload.status == true){
          toast.success(action.payload.message)
        }
        else{
          toast.error(action.payload.messages)
          state.apiErrors = action.payload.response.data.messages;
        }
      })
      .addCase(applyLeaveApi.rejected, (state, action) => {
        state.loading = false;
        state.apiErrors = action.payload || {};
      });
  },
});


export default leaveSlice.reducer;
