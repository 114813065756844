import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import {
  DeleteDepartment,
  getDepartmemt,
  getEmployeeList,
  updateDepartment,
} from "@Networking/APIs/Employee/EmployeeApi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  DeleteAttendanceRecord,
  getAttendanceRecord,
  postAttendanceRecord,
} from "@Networking/APIs/AttendanceRecord/attendanceRecordApi";
import { getAllMonth } from "@Networking/APIs/Salary/salaryApi";

const AllAttendanceRecord = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getDays = useSelector((state) => state.SalarySlice.getDays);

  const [selectedMonth, setSelectedMonth] = useState("");
  const [defaultHours, setDefaultHours] = useState("");
  const [selectEmployee, setSelectedEmployee] = useState("");
  const [leaveBalance, setLeaveBalance] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const { type } = useParams();

  const { EmployeeData } = useSelector((state) => state.EmployeesSlice);

  const attendaceRecordData = useSelector(
    (state) => state.AttendanceRecordSlice
  );

  console.log(attendaceRecordData, "attendaceRecordData");

  useEffect(() => {
    dispatch(getEmployeeList());
    dispatch(getDepartmemt());
    dispatch(getAttendanceRecord());
    dispatch(getAllMonth());
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const monthData = useSelector((state) => state.SalarySlice?.monthData);

  const handleEdit = (id) => {
    navigate("/editAttendanceRecord", { state: { id } });
  };

  const handleDelete = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure? You won't be able to revert this!"
    );

    if (isConfirmed) {
      dispatch(DeleteAttendanceRecord({ id }));
      dispatch(getAttendanceRecord());
    }
  };

  useEffect(() => {
    if (attendaceRecordData?.AttendanceRecordData?.data?.length > 0) {
      const dataTable = $("#myTable").DataTable({
        paging: true,
        searching: true,
        ordering: true,
        info: true,
        responsive: true,
        pageLength: 5,
        lengthMenu: [5, 10, 25, 50, 75, 100],
      });

      return () => {
        dataTable.destroy();
      };
    }
  }, [attendaceRecordData]);

  const handleMonthChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedMonth(selectedValue);

    const matchingMonth = monthData?.data?.find(
      (item) => item.month === selectedValue
    );

    const hours = matchingMonth?.hours || "";
    setDefaultHours(hours);

    setValue("working_month", selectedValue);
    setValue("working_hours", hours);
  };

  useEffect(() => {
    const workingData = monthData?.data;
    if (workingData?.length > 0) {
      const lastRow = workingData[workingData.length - 1];
      setSelectedMonth(lastRow.month);
      setDefaultHours(lastRow.hours);

      setValue("working_month", lastRow.month);
      setValue("working_hours", lastRow.hours);
    }
  }, [monthData, setValue]);

  const onSubmit = async (data) => {
    const newAttendanceRecord = {
      employee_id: data.employee_id,
      working_hours: defaultHours,
      working_month: selectedMonth,
      leave_this_month: data.leave_this_month,
      total_working_hours: data.total_working_hours,
      leave_type: data.leave_type,
      paid_leave: data.paid_leave,
      unpaid_leave: data.unpaid_leave,
      leave_balance: data.leave_balance,
      employee_advance: data.employee_advance,
      remark: data.remark,
    };
    try {
      await dispatch(postAttendanceRecord(newAttendanceRecord));
      reset();
      dispatch(getAttendanceRecord());
    } catch (error) {}
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="">
              <i class="fa-solid fa-list me-2"></i>HRM / Attendance Record /
              Employee Monthly Attendance
            </h6>
          </div>
        </div>

        <div className="m-1">
          <button
            type="button"
            className="btn btn-secondary mb-3"
            onClick={handleBack}
          >
            <i className="fa fa-arrow-left me-2"></i>
            Back
          </button>
        </div>

        <div className="card mb-2">
          <div className="card-body">
              <div className="row p-3">
                <div className="col-md-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label>Employee Name</label>
                        <select
                          id="input"
                          {...register("employee_id", { required: true })}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            setSelectedEmployee(selectedValue);

                            const matchingEmployee =
                              EmployeeData?.EmployeeData?.employee_list?.find(
                                (item) => item.id === parseInt(selectedValue)
                              );

                            setLeaveBalance(
                              matchingEmployee?.leave_balance || ""
                            );
                          }}
                        >
                          <option value="" disabled selected>
                            Select Employee
                          </option>
                          {EmployeeData?.employee_list?.length > 0 ? (
                            EmployeeData.employee_list.map((employees) => (
                              <option key={employees.id} value={employees.id}>
                                {employees.first_name} {employees.middle_name}{" "}
                                {employees.last_name}
                              </option>
                            ))
                          ) : (
                            <option disabled>No Employee available</option>
                          )}
                        </select>

                        {errors.employee_id && (
                          <span className="text-danger">
                            *Employee name is required
                          </span>
                        )}
                      </div>

                      <div className="col-md-4">
                        <label>Working Month</label>
                        <select
                          id="input"
                          {...register("working_month", {
                            required: "Working Month is required",
                          })}
                          value={selectedMonth}
                          onChange={handleMonthChange}
                        >
                          {monthData?.data?.map((item) => (
                            <option key={item.id} value={item.month}>
                              {item.month}
                            </option>
                          ))}
                        </select>
                        {errors.working_month && (
                          <span className="text-danger">
                            Working Month is required
                          </span>
                        )}
                      </div>

                      <div className="col-md-4">
                        <label>Working Hours</label>
                        <input
                          type="number"
                          id="input"
                          value={defaultHours}
                          readOnly
                          {...register("working_hours", {
                            required: "Working Hours is required",
                          })}
                        />
                        {errors.working_hours && (
                          <span className="text-danger">
                            {errors.working_hours.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label>Leave Type</label>
                        <input
                          type="text"
                          placeholder="Enter Leave Type"
                          id="input"
                          {...register("leave_type", {
                            required: "Leave Type is required",
                          })}
                        />
                        {errors.leave_type && (
                          <span className="text-danger">
                            {errors.leave_type.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-4">
                        <label>Leave This Month</label>
                        <input
                          type="number"
                          placeholder="Enter Leave This Month"
                          id="input"
                          {...register("leave_this_month", {
                            required: "Leave This Month is required",
                          })}
                        />
                        {errors.leave_this_month && (
                          <span className="text-danger">
                            {errors.leave_this_month.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-4">
                        <label>Total Working Hours</label>
                        <input
                          type="number"
                          placeholder="Enter Total Working Hours"
                          id="input"
                          {...register("total_working_hours", {
                            required: "Total Working Hours is required",
                          })}
                        />
                        {errors.total_working_hours && (
                          <span className="text-danger">
                            {errors.total_working_hours.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label>Unpaid Leaves</label>
                        <input
                          type="number"
                          placeholder="Enter Unpaid Leaves"
                          id="input"
                          {...register("unpaid_leave", {
                            required: "Unpaid Leaves is required",
                          })}
                        />
                        {errors.unpaid_leave && (
                          <span className="text-danger">
                            {errors.unpaid_leave.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-4">
                        <label>Leave Balance</label>
                        <input
                          type="number"
                          placeholder="Enter Leave Balance"
                          id="input"
                          value={leaveBalance}
                          {...register("leave_balance", {
                            required: "Leave Balance is required",
                          })}
                        />
                        {errors.leave_balance && (
                          <span className="text-danger">
                            {errors.leave_balance.message}
                          </span>
                        )}
                      </div>

                      <div className="col-md-4">
                        <label>Paid Leaves</label>
                        <input
                          type="number"
                          placeholder="Enter Paid Leaves"
                          id="input"
                          {...register("paid_leave", {
                            required: "Paid Leaves is required",
                          })}
                        />
                        {errors.paid_leave && (
                          <span className="text-danger">
                            {errors.paid_leave.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label>Employee Advance</label>
                        <input
                          type="number"
                          placeholder="Enter Employee Advance"
                          id="input"
                          {...register("employee_advance", {
                            required: "Employee Advance is required",
                          })}
                        />
                        {errors.employee_advance && (
                          <span className="text-danger">
                            {errors.employee_advance.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-8">
                        <label>Remarks</label>
                        <textarea
                          type="text"
                          placeholder="Enter Remarks"
                          id="input"
                          {...register("remark", {
                            required: "Remarks is required",
                          })}
                        ></textarea>
                        {errors.department_name && (
                          <span className="text-danger">
                            {errors.department_name.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12 text-center">
                        <button
                          type="submit"
                          className="btn btn-primary w-50 rounded-pill"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                  <hr></hr>
                </div>
              </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-body">
            <div className="card shadow">
              <div className="row p-3">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="table-responsive"
                        style={{ maxHeight: "85%", overflowY: "auto" }}
                      >
                        <table
                          id="myTable"
                          className="table table-striped table-hover table-bordered align-middle text-center"
                        >
                          <thead
                            className="table-primary"
                            style={{ position: "sticky", top: -1 }}
                          >
                            <tr>
                              <th>Employee Name</th>
                              <th>Working Month</th>
                              <th>Working Hours</th>
                              <th>Leave This Month</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {attendaceRecordData?.AttendanceRecordData?.data?.map(
                              (attendanceRecord) => (
                                <tr key={attendanceRecord.id}>
                                  <td>
                                    {attendanceRecord?.employee?.first_name}{" "}
                                    {attendanceRecord?.employee?.middle_name
                                      ? attendanceRecord?.employee?.middle_name
                                      : ""}{" "}
                                    {attendanceRecord?.employee?.last_name}
                                  </td>

                                  <td>{attendanceRecord.working_month}</td>
                                  <td>{attendanceRecord.working_hours}</td>
                                  <td>{attendanceRecord.leave_this_month}</td>
                                  <td>
                                    <button
                                      className="btn btn-sm btn-success me-1"
                                      onClick={() =>
                                        handleEdit(attendanceRecord.id)
                                      }
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </button>
                                    <button
                                      className="btn btn-sm btn-danger"
                                      onClick={() =>
                                        handleDelete(attendanceRecord.id)
                                      }
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllAttendanceRecord;
