import { createSlice } from '@reduxjs/toolkit';
import { postItemStore } from '@Networking/APIs/Invoice/Item/itemApi';
import { getItem } from '@Networking/APIs/Invoice/Item/getitem';
import { toast } from 'react-toastify';

const ItemSlice = createSlice({
  name: 'ItemSlice',
  initialState: {
    loading: false,
    ItemData:[],
    itemDetail: {},
    validateError:[],
    success: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(postItemStore.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postItemStore.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.itemDetail = action.payload;
      if(action.payload.status == true){
        // toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.message)
        state.validateError = action.payload.response.data.message;
      }
    });
    builder.addCase(postItemStore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Login failed.';
    });
    builder.addCase(getItem.pending, state => {
      state.loading = true;
    });
    builder.addCase(getItem.fulfilled, (state, action) => {
      state.ItemData = action.payload;
    });
    builder.addCase(getItem.rejected, state => {
      state.loading = false;
    });
  },
});

export default ItemSlice.reducer;