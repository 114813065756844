import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "component/LoginForm/Login";
import './App.css';
import Dashboard from "component/Dashboard/Dashboard";
import { Mainlayout } from "./Layout/mainLayout";
import InvoiceForm from "component/Invoice/InvoiceForm";

import VerifyCode from "component/LoginForm/VerifyCode";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProtectedRoute } from "component/ProtectedRoute/protectedRoute";
import Signup from "component/LoginForm/signUp";
import InvoiceList from "component/Invoice/InvoiceList";
import { ProtectedOtp } from "component/ProtectedRoute/ProtectedOtp";
import PdfComponent from "component/PDF/pdfView";
import Loader from "@Layout/Loader/loader";
import Profile from "component/Profile/profile";
import InvoiceEdit from "component/Invoice/invoiceEdit";
import { InvoicePartiallyForm } from "component/Invoice/partiallyPaidForm";
import ForgotPassword from "component/LoginForm/ForgotPassword";
import ResetPassword from "component/LoginForm/ResetPassword";
import GetOtp from "component/LoginForm/GetOtp";
import ResetSuccess from "component/LoginForm/ResetSuccess";
import Employees from "component/Employee/Employees";
import ViewEmployee from "component/Employee/ViewEmployee";
import EditEmployee from "component/Employee/EditEmployee";
import Salary from "component/Salary/Salary";
import CreateSalary from "component/Salary/CreateSalary";
import LeaveList from "component/Leave/LeaveList";
import EditLeave from "component/Leave/EditLeave";
import EmployeeMaster from "component/Employee/mastersForm/EmployeeMaster";
import AttendanceRecord from "component/Attendance/AttendanceRecord";
import AnnouncementsList from "component/Announcement/AnnouncementsList";
import NewAnnouncement from "component/Announcement/NewAnnouncement";
import AssignTask from "component/Task/AssignTask";
import AllTasks from "component/Task/AllTasks";
import AllLeave from "component/Leave/AllLeave";
import ApplyLeave from "component/Leave/ApplyLeave";
import EditApplyLeave from "component/Leave/EditApplyLeave";
import ViewLeave from "component/Leave/ViewLeave";
import EmployeeForm from "component/Employee/EmployeeForm";

import WorkLocationForm from "component/Employee/mastersForm/workLocation/workLocation";
import JobRoleForm from "component/Employee/mastersForm/jobRole/jobRole";
import JobTitleForm from "component/Employee/mastersForm/jobTitle/jobTitle";
import TimeTypeForm from "component/Employee/mastersForm/timeType/timeType";
import WorkTypeForm from "component/Employee/mastersForm/workType/workType";
import EmployeeStatusForm from "component/Employee/mastersForm/employeeStatus/employeeStatus";
import ProbationPolicyForm from "component/Employee/mastersForm/probationPolicy/probationPolicy";
import OnboardingSettingsForm from "component/Employee/mastersForm/onboardingSettings/onboardingSettings";
import ShiftsForm from "component/Employee/mastersForm/shifts/shifts";
import WeeklyOffForm from "component/Employee/mastersForm/weeklyOff/weeklyOff";
import DepartmentsForm from "component/Employee/mastersForm/departments/departments";
import AllAttendanceRecord from "component/Attendance/AllAttendanceRecord";
import EditSalary from "component/Salary/EditSalary";
import FixedHolidays from "component/Attendance/FixedHolidays";
import WorkingHours from "component/Attendance/WorkingHours";
import EditAttendanceRecords from "component/Attendance/EditAttendanceRecords";
import EditTask from "component/Task/EditTask";
import Reports from "component/Reports/Reports";
import Master from "component/Invoice/Masters/Master";
import BankDetails from "component/Invoice/Masters/BankDetails";
import Category from "component/Invoice/Masters/Category";
import MySalary from "component/Salary/MySalary";
import { FeatureForm, SwitchForm } from "component/FeatureForm/SwitchForm";
import SalaryMaster from "component/Salary/SalaryMaster";
import EmployeeDashboard from "component/Dashboard/EmployeeDashboard";
import FinanceDashboard from "component/Dashboard/FinanceDashboard";
import EditAnnouncement from "component/Announcement/EditAnnouncement";
import DevForm from "component/Invoice/DevForm";
import HRMDashboard from "component/Dashboard/HRMDashboard";
import PrmDashboard from "component/PMS/PrmDashboard";
import ProjectList from "component/PMS/ProjectList/ProjectList";
import TeamList from "component/PMS/TeamList/TeamList";
import TaskList from "component/Task/AllTasks";
import WorkLog from "component/PMS/WorkLog/WorkLog";
import ExpenseTracking from "component/PMS/ExpenseTracking/ExpenseTracking";
import ProjectCost from "component/PMS/ProjectCost/ProjectCost";
import UserManagement from "component/PMS/UserManagement/UserManagement";
import ReportsInsights from "component/PMS/ReportsInsights/ReportsInsights";
import TasksList from "component/PMS/TasksList/TasksList";
import AddNewProject from "component/PMS/ProjectList/AddNewProject";
import CreateTeam from "component/PMS/TeamList/CreateTeam";
import CreateTask from "component/PMS/TasksList/CreateTask";
import CreateExpenseTrack from "component/PMS/ExpenseTracking/CreateExpenseTrack";
import NotFound from "@Layout/NotFound";

const AppContent = () => {

  return (
    <>

      <Routes>
        {/* Public routes */}
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/getotp" element={<GetOtp />} />
        <Route path="/resetsuccess" element={<ResetSuccess />} />
        {/* <Route element={<ProtectedOtp/>}>
          <Route path="/otp" element={<VerifyCode />} />
        </Route> */}
        {/* Protected routes */}

        <Route element={<ProtectedRoute allowRoles={["1"]}/>}>

          <Route path="/InvoicePartially" element={<Mainlayout><InvoicePartiallyForm /></Mainlayout>} />

        </Route>

        <Route element={<ProtectedRoute allowRoles={["1","2"]}/>}>
           <Route path="/financeDashboard" element={<Mainlayout><FinanceDashboard/></Mainlayout>}/>
          <Route path="/InvoicesForm" element={<Mainlayout><InvoiceForm /></Mainlayout>} />
          <Route path="/employeeDashboard" element={<Mainlayout><HRMDashboard /></Mainlayout>} />
          <Route path="/dashboard" element={<Mainlayout><Dashboard /></Mainlayout>} />
          <Route path="/InvoiceEdit" element={<Mainlayout><InvoiceEdit /></Mainlayout>} />
          <Route path="/InvoicesList" element={<Mainlayout><InvoiceList /></Mainlayout>} />
          <Route path="/Invoice-Master" element={<Mainlayout><Master /></Mainlayout>} />
          <Route path="/BankDetails" element={<Mainlayout><BankDetails /></Mainlayout>} />
          <Route path="/Category" element={<Mainlayout><Category /></Mainlayout>} />
          <Route path="/AddEmployee" element={<Mainlayout><EmployeeForm /></Mainlayout>} />
          <Route path="/EmployeeView/:id" element={<Mainlayout><ViewEmployee /></Mainlayout>} />
          <Route path="/Employees" element={<Mainlayout><Employees /></Mainlayout>} />
          <Route path="/EmployeeEdit/:id" element={<Mainlayout><EditEmployee /></Mainlayout>} />
          <Route path="/CreateSalary" element={<Mainlayout><CreateSalary /></Mainlayout>} />
          <Route path="/Salary" element={<Mainlayout><Salary /></Mainlayout>} />
          <Route path="/SalaryEdit/:id" element={<Mainlayout><EditSalary /></Mainlayout>} />
          <Route path="/EmployeeMaster" element={<Mainlayout><EmployeeMaster /></Mainlayout>} />
         </Route>

        
        <Route element={<ProtectedRoute allowRoles={["1", "2","3","4"]}/>}>
         
          {/* <Route element={<ProtectedRoute allowedRoles={'2'} />}> */}
          <Route path="/DevForm" element={<Mainlayout><DevForm /></Mainlayout>} />
          {/* </Route> */}
           
          <Route path="/PDF" element={<PdfComponent />} />

          <Route path="/Profile" element={<Mainlayout><Profile /></Mainlayout>} />
          <Route path="/mySalary" element={<Mainlayout><MySalary /></Mainlayout>} />
          <Route path="/leave" element={<Mainlayout><LeaveList /></Mainlayout>} />
          <Route path="/LeaveEdit/:id" element={<Mainlayout><EditLeave /></Mainlayout>} />

          <Route path="/workLocation" element={<Mainlayout><WorkLocationForm /></Mainlayout>} />
          <Route path="/jobRole" element={<Mainlayout><JobRoleForm /></Mainlayout>} />
          <Route path="/jobTitle" element={<Mainlayout><JobTitleForm /></Mainlayout>} />
          <Route path="/timeType" element={<Mainlayout><TimeTypeForm /></Mainlayout>} />
          <Route path="/workType" element={<Mainlayout><WorkTypeForm /></Mainlayout>} />
          <Route path="/employeeStatus" element={<Mainlayout><EmployeeStatusForm /></Mainlayout>} />
          <Route path="/probationPolicy" element={<Mainlayout><ProbationPolicyForm /></Mainlayout>} />
          <Route path="/onboardSettings" element={<Mainlayout><OnboardingSettingsForm /></Mainlayout>} />
          <Route path="/shifts" element={<Mainlayout><ShiftsForm /></Mainlayout>} />
          <Route path="/weeklyOff" element={<Mainlayout><WeeklyOffForm /></Mainlayout>} />
          <Route path="/departments" element={<Mainlayout><DepartmentsForm /></Mainlayout>} />

          <Route path="/attendenceRecord" element={<Mainlayout><AttendanceRecord /></Mainlayout>} />
          <Route path="/editAttendanceRecord" element={<Mainlayout><EditAttendanceRecords /></Mainlayout>} />
          <Route path="/attendance-records" element={<Mainlayout><AllAttendanceRecord /></Mainlayout>} />
          <Route path="/fixed-holidays" element={<Mainlayout><FixedHolidays /></Mainlayout>} />
          <Route path="/working-hours" element={<Mainlayout><WorkingHours /></Mainlayout>} />
          <Route path="/salaryMaster" element={<Mainlayout><SalaryMaster /></Mainlayout>} />
        

          <Route path="/AnnouncementList" element={<Mainlayout><AnnouncementsList /></Mainlayout>} />
          <Route path="/NewAnnouncement" element={<Mainlayout><NewAnnouncement /></Mainlayout>} />
          <Route path="/EditAnnouncement" element={<Mainlayout><EditAnnouncement /></Mainlayout>} />
          <Route path="/AssignTask" element={<Mainlayout><AssignTask /></Mainlayout>} />
          <Route path="/AllTask" element={<Mainlayout><AllTasks /></Mainlayout>} />
          <Route path="/editTask" element={<Mainlayout><EditTask /></Mainlayout>} />
          <Route path="/AllLeave" element={<Mainlayout><AllLeave /></Mainlayout>} />
          <Route path="/ApplyLeave" element={<Mainlayout><ApplyLeave /></Mainlayout>} />
          <Route path="/EditLeave" element={<Mainlayout><EditApplyLeave /></Mainlayout>} />
          <Route path="/ViewLeave" element={<Mainlayout><ViewLeave /></Mainlayout>} />
          <Route path="/SwitchForm" element={<Mainlayout><SwitchForm /></Mainlayout>} />
          <Route path="/reports" element={<Mainlayout><Reports /></Mainlayout>} />

          <Route path="/prmDashboard" element={<Mainlayout><PrmDashboard/></Mainlayout>} />
          <Route path="/projectList" element={<Mainlayout><ProjectList/></Mainlayout>} />
          <Route path="/addNewProject" element={<Mainlayout><AddNewProject/></Mainlayout>} />
          <Route path="/teamList" element={<Mainlayout><TeamList/></Mainlayout>} />
          <Route path="/addNewTeam" element={<Mainlayout><CreateTeam/></Mainlayout>} />
          <Route path="/addNewTask" element={<Mainlayout><CreateTask/></Mainlayout>} />
          <Route path="/tasksList" element={<Mainlayout><TasksList/></Mainlayout>} />
          <Route path="/workLog" element={<Mainlayout><WorkLog/></Mainlayout>} />
          <Route path="/expenseTracking" element={<Mainlayout><ExpenseTracking/></Mainlayout>} />
          <Route path="/addNewExpenseTrack" element={<Mainlayout><CreateExpenseTrack/></Mainlayout>} />
          <Route path="/projectCost" element={<Mainlayout><ProjectCost/></Mainlayout>} />
          <Route path="/userManagement" element={<Mainlayout><UserManagement/></Mainlayout>} />
          <Route path="/reportsInsights" element={<Mainlayout><ReportsInsights/></Mainlayout>} />


        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

const App = () => {
  return (
    <BrowserRouter>
      <AppContent />
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
