import { createSlice } from '@reduxjs/toolkit';
import { postCompanyStore } from '@Networking/APIs/Invoice/Company/companyStoreApi';
import { getCompany } from '@Networking/APIs/Invoice/Company/companyGetApi';
import { toast } from 'react-toastify';

const CompanyStoreSlice = createSlice({
  name: 'CompanyStoreSlice',
  initialState: {
    loading: false,
    CompanyStore: '',
    CompanyGet:{},
    success: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(postCompanyStore.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postCompanyStore.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.CompanyStore = action.payload;
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.message)
      }
    });
    builder.addCase(postCompanyStore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Login failed.';
    });

    builder.addCase(getCompany.pending, (state) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(getCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.CompanyGet = action.payload;
      });
      builder.addCase(getCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Login failed.';
      });
  },
});

export default CompanyStoreSlice.reducer;