import React, { useEffect, useMemo, useRef } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import fetchLeaveDetailsApi from "@Networking/APIs/Leave/leaveShowApi";
import Loader from "@Layout/Loader/loader";

//GobalFilter
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <span>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value || undefined)}
        placeholder="Search all columns"
        className="ps-2 rounded"
        style={{ width: "200px", display: "inline-block", marginLeft: "10px" }}
      />
    </span>
  );
};

const getStatusClass = (status) => {
  switch (status) {
    case "Approved":
      return "badge btn btn-success text-white";
    case "Pending":
      return "badge badge-warning text-white";
    case "Cancel":
      return "badge badge-danger text-white";
    default:
      return "";
  }
};

const AllLeave = () => {
  //Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Redux
  const { leaves, loading, error } = useSelector(
    (state) => state.LeaveShowSlice
  );
  const employeeName = localStorage.getItem("name");
  const role = localStorage.getItem("role");

  //UseEffect
  useEffect(() => {
    dispatch(fetchLeaveDetailsApi());
  }, [dispatch]);

  const data = useMemo(() => {
    if (leaves) {
      return leaves.map((leave) => ({
        ...leave,
        Name: employeeName || "N/A",
        Subject: leave.subject,
        Status: leave.status,
        ApprovedBy: leave.Approvedr?.name || "N/A",
      }));
    }
    return [];
  }, [leaves, employeeName]);

  const columns = useMemo(
    () => [
      { Header: "Name", accessor: "Name" },
      { Header: "Subject", accessor: "Subject" },
      {
        Header: "Status",
        accessor: "Status",
        Cell: ({ cell: { value } }) => (
          <span className={`p-2 ${getStatusClass(value)}`}>{value}</span>
        ),
      },
      { Header: "Approved By", accessor: "ApprovedBy" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div>
            {row.original.status === "Approved" && (
              <button
                className="btn btn-sm btn-secondary me-1"
                onClick={() => handleView(row.original)}
                // disabled={new Date(row.original.start_date) < new Date()}
              >
                <i className="fa-regular fa-eye"></i>
              </button>
            )}
            {row.original.status === "Pending" && role !== "1" && (
              <button
                className="btn btn-sm btn-primary me-1"
                onClick={() => handleEdit(row.original)}
                disabled={new Date(row.original.start_date) < new Date()}
              >
                <i className="fa fa-pencil"></i>
              </button>
            )}
          </div>
        ),
      },
    ],
    [role]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
    useGlobalFilter,
    usePagination
  );

  const { globalFilter, pageIndex, pageSize } = state;

  const handleView = (leave) => {
    navigate(`/ViewLeave`, { state: { leave } });
  };

  const handleEdit = (leave) => {
    navigate(`/EditLeave`, { state: { leave } });
  };

  if (loading)
    return (
      <p>
        <Loader />
      </p>
    );
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <h6 className="">
            <i class="fa-solid fa-list me-1"></i>HRM / Leave / Manage Leave
          </h6>
        </div>
      </div>

      <div className="card bg-gradient-info rounded-top-2 p-2 mb-3">
          <div className="row d-flex align-items-center">
            <div className="col-md-6 col-sm-6">
              <h3 className="page-title m-0  text-light">
                <span className="mx-2 text-primary">
                <i className="mdi mdi-account text-light"></i>
                </span>
                {leaves[0]?.user?.name}
                </h3>
            </div>
            <div className="col-md-6 col-sm-6 text-end">
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            </div>
          </div>
        </div>

      <div className="card shadow-lg border-0 rounded-lg">
        <div className="card-header bg-gradient-dark p-0 text-light text-center">
          <h5 className="ms-2">Leave List</h5>
        </div>
        <div
          className="card-body"
          style={{ height: "auto", overflowY: "auto" }}
        >
          <table {...getTableProps()} className="table table-hover">
            <thead
              style={{
                position: "sticky",
                top: -25,
                backgroundColor: "#f0f0f0",
              }}
            >
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th className="text-center" {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td className="text-center" {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={columns.length}>No leaves available</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="d-flex flex-wrap justify-content-between align-items-center p-3 border-top mt-3">
            <div>
              <span className="text-muted">
                Page <strong>{pageIndex + 1}</strong> of{" "}
                <strong>{pageOptions.length}</strong>
              </span>
            </div>
            <div className="pagination-controls d-flex align-items-center">
              <button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                className="btn btn-light btn-sm mx-1"
                title="First Page"
              >
                <i className="bi bi-chevron-double-left"></i>
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="btn btn-light btn-sm mx-1"
                title="Previous Page"
              >
                <i className="bi bi-chevron-left"></i>
              </button>
              <div className="d-flex">
                {pageOptions.map((page, index) => (
                  <button
                    key={index}
                    onClick={() => gotoPage(page)}
                    className={`btn btn-sm mx-1 ${
                      pageIndex === page
                        ? "btn-primary text-white"
                        : "btn-light"
                    }`}
                    style={{
                      borderRadius: "50%",
                      width: "35px",
                      height: "35px",
                      padding: "5px",
                    }}
                    title={`Page ${page + 1}`}
                  >
                    {page + 1}
                  </button>
                ))}
              </div>

              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="btn btn-light btn-sm mx-1"
                title="Next Page"
              >
                <i className="bi bi-chevron-right"></i>
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                className="btn btn-light btn-sm mx-1"
                title="Last Page"
              >
                <i className="bi bi-chevron-double-right"></i>
              </button>
            </div>
            <div className="d-flex align-items-center">
              <label htmlFor="pageSize" className="me-2 mb-0 text-muted">
                Show:
              </label>
              <select
                id="pageSize"
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                className="form-select form-select-sm"
                style={{ width: "80px" }}
              >
                {[10, 20, 30, 40, 50].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllLeave;
