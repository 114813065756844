
export const BASE_URL = "https://developbo.techsimba.in/management/api/"
// export const BASE_URL = "https://developbo.techsimba.in/backoffice/api/"

// Auth
export const SignUpUrl = "register";
export const LoginUrl = "login";
export const LogoutUrl = "logout";
export const Verifycode = "verifycode";

// Get User 
export const getuser = "user";

//Logo
export const Logo =  "company-logo" ;
export const LogoPost = "updateCompanyLogo"

 //Task
export const taskStore = "add-task";
export const TaskList = "task-list";
export const TaskEdit = "edit-task/";
export const TaskUpdate = "update-task/";

// clientS
export const client = "clients";
export const clientGet = "all/clients";
export const ClientEdit = "update/clients";
export const ClientDelete = "clients/delete";

// ItemsStore
export const items = "items";
export const getitems = "getitems";

// categorySore
export const categories = "categories";
export const categoriesGet = "get/categories"
export const deleteCategorie = "delete/categories/"

// banksStore
export const banks = "banks";
export const AllBanks = "all/banks/";
export const EditBank = "update/banks"
export const DeleteBank = "delete/banks/"


//CompanyGet
export const companiesGet = "companies/edit/1"
export const companies = "update/companies/1";

// Address
export const countries = "countries";
export const states = "states/";
export const cities = "cities/";

//country Code
export const countrycode = "countri/code";


//Invoice api
export const getinvoicenumber = "invoice/number";
export const storeinvoicenumber = "invoice/store";
export const allinvoice =  "allinvoice" ;
export const updateInvoice = "invoice/update/";
export const InvoiceSoftCancel = "invoice/cancel/";

//Invoice Payment
export const paymenturl = "invoice/payment/";

//PDFVIEW
export const PDFData = "invoice/view";
export const partialPaymentUrl = "partial/payment";
export const partialviewUrl = "partial/payment/view/";
export const paymentList = "partial/payment/list/";
export const PartialPaymentDelete = "partial/payment/delete/"
export const EditView = "invoice/view/";
export const currencyUrl = "currency";


// Leave
export const leaveShow = "leave/show";
export const leaveStore = "leave/store";
export const leaveUpdate = "leave/update/";
export const leaveView = "leave/view/";
export const leaveStatus = "leave/status/";
export const leavesByEmployee = "employee/leaves/month";

//salary
export const salary = "salary/list";
export const monthAll = "month/all";
export const getMonth = "get/month";
export const SalaryStore = "salary/store";
export const SalaryUpdate = "salary/update/";
export const SalaryEdit = "salary/edit/";
export const SalaryDetailsId = "get/employee";
export const SalarySlip = "salaryslip/view/";

//Employee
export const employeesList = "employee-list";
export const employees = "employee/filter";
export const employeePersonalStore = "personal-details";
export const employeeJobStore = "job-details";

export const employeeWorkStore = "work-details";
export const employeeCompensationStore = "compensation-details";
export const employeeDocumentStore = "upload";

export const getEmployeUrl = "edit-employee"; 
export const viewEmployee = "view-employee";
export const byTeamEmployee = "employee/byteam";




// All Attendance Record
export const attendanceRecordStore = "workingrecord/store";
export const attendanceRecordList = "workingrecord/list";
export const attendanceRecordEdit = "workingrecord/edit/";
export const attendanceRecordUpdate = "workingrecord/update/";
export const attendanceRecordDelete = "workingrecord/delete/";


//WorkLocation
export const workLocation = "workLocation";
export const addWorkLocation = "add-workLocation";
export const updateWorkLocation = "update-workLocation";
export const deleteWorkLocation = "delete-workLocation";

//Roles
export const role = "role";
export const addJobRole = "add-role";
export const updateJobRole = "update-role";
export const deleteJobRole = "delete-role";

//JobProfile
export const jobProfile = "jobProfile";
export const addJobProfile = "add-jobProfile";
export const updateJobProfile = "update-jobProfile";
export const deleteJobProfile = "delete-jobProfile";

//TimeType
export const timeType = "timeType";
export const addTimeType = "add-timeType";
export const updateTypeTime = "update-timeType";
export const deleteTypeTime = "delete-timeType";

//EmployeeStatus
export const employeeStatus = "employeeStatus";
export const addEmployeeStatus = "add-employeeStatus";
export const updateStatusEmployee = "update-employeeStatus";
export const deleteStatusEmployee = "delete-employeeStatus";


// Work Type
export  const workType = "workType";
export const addWorkType = "add-workType";
export const updateTypeWork = "update-workType";
export const deleteTypeWork = "delete-workType";


// Probation Policy
export const probation = "probation";
export const addProbation = "add-probation";
export const updateProbation = "update-probation";
export const deleteProbation = "delete-probation";


// Onboarding Settings
export const onboardingSettings = "onboardingSetting";
export const addOnboardSettings = "add-onboardingSetting";
export const updateOnboadSettings = "update-onboardingSetting";
export const deleteOnboardingSettings = "delete-onboardingSetting";

// Shifts
export const shift = "shift";
export const addShift = "add-shift";
export const shiftUpdate = "update-shift";
export const deleteShift = "delete-shift";

// Weekly off
export const weeklyOff = "weeklyOff";
export const addWeeklyOff = "add-weeklyOff";
export const updateWeeklyOff = "update-weeklyOff";
export const deleteWeeklyOff = "delete-weeklyOff";

// Departments
export const department = "department";
export const addDepartment = "add-department";
export const updateDepart = "update-department";
export const deleteDepart = "delete-department";


// Working Hours
export const workingHours = "workinghours/show";
export const workingHoursStore = "workinghours/store";
export const workingHoursDelete= "workinghours/delete/";
export const workingHoursUpdate = "workinghours/update/";

// Holidays
export const holidays = "holiday/show";
export const holidayStore = "holiday/store";
export const holidayDelete = "holiday/delete/";
export const holidayUpdate = "holiday/update/";


// Nationality
export const nationality = "nationality";


// Announcement
export const announcementStore = "add-announcement";
export const announcementList = "get-announcement";
export const announcementDelete = "delete-announcement";
export const announcementEdit = "edit-announcement/";
export const announcementUpdate = "update-announcement/";


// Manager
export const manager = "manager";

// Teamleader
export const teamleader = "teamleader";


// USer Profile
export const userProfile = "show-profile";
export const userUploadImage = "edit-image";


// Reports
export const getReport = "get/data";

//switchForm
export const featureForm = "store/form";

// Job Role Api
export const reportingLeader = "teamleader";

// Dashboard
export const adminDashboard = "admin/details";
export const invoiceDashboard = "invoice/details";
export const employeeDashboard = "employees/details";
export const birthdayUpdate = "birthday/status";


// PMS Apis

// Project Api
export const addProject = "project/store";
export const projectList = "project/list";