import { getLogo } from '@Networking/APIs/Invoice/logo/logoGetApi';
import { postLogo } from '@Networking/APIs/Invoice/logo/logoPostApi';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const LogoSlice = createSlice({
  name: 'LogoSlice',
  initialState: {
    loading: false,
    loading1:false,
    LogoGet:[],
    LogoPost: {},
    success: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(postLogo.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postLogo.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.LogoPost = action.payload;
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.message)
      }
    });
    builder.addCase(postLogo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Login failed.';
    });
    builder.addCase(getLogo.pending, state => {
      state.loading1 = true;
    });
    builder.addCase(getLogo.fulfilled, (state, action) => {
        state.loading1 = false;
      state.LogoGet = action.payload;
    });
    builder.addCase(getLogo.rejected, state => {
      state.loading1 = false;
    });
  },
});

export default LogoSlice.reducer;