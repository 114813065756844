import {
  DeleteDepartment,
  DeleteEmployeeStatus,
  DeleteJobProfile,
  DeleteJobRole,
  DeleteOnboardSettings,
  DeleteProbation,
  DeleteShift,
  DeleteTimeType,
  DeleteWeeklyOff,
  DeleteWorkLocation,
  DeleteWorkType,
  employeePersonalStore,
  getByTeamEmployees,
  getDepartmemt,
  getEmployeeList,
  getEmployees,
  getEmployeeStatus,
  getJobProfile,
  getManager,
  getOnboardingSetting,
  getProbation,
  getRole,
  getShift,
  getTeamleader,
  getTimeType,
  getWeeklyOff,
  getWorkType,
  postDepartmemt,
  postEmployeePersonalStore,
  postEmployeeStatus,
  postEmployeeStore,
  postJobProfile,
  postJobRole,
  postOnboardingSetting,
  postProbation,
  postShift,
  postTimeType,
  postWeeklyOff,
  postWorkLocation,
  postWorkType,
  updateDepartment,
  updateEmployeeStatus,
  UpdateJobProfile,
  UpdateJobRole,
  updateOnboardSettings,
  UpdateProbation,
  updateShift,
  UpdateTimeType,
  UpdateWeeklyOff,
  updateWeeklyOff,
  UpdateWorkLocation,
  updateWorkType,
} from "@Networking/APIs/Employee/EmployeeApi";
import { getWorkLocation } from "@Networking/APIs/Employee/EmployeeApi";
import { leaderRole, viewEmployeData } from "@Networking/APIs/Employee/EmployeStoreApi";
import { timeType, updateOnboadSettings } from "@Networking/APIs/NWConfig";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const EmployeesSlice = createSlice({
  name: "EmployeesSlice",
  initialState: {
    loading: false,
    loadingg: false,
    EmployeesData: [],
    workLocationData: [],
    roleData: [],
    JobProfileData: [],
    TimeTypeData: [],
    EmployeeStatusData: [],
    WorkTypeData: [],
    ProbationData: [],
    OnboardingSettingsData: [],
    ShiftData: [],
    WeeklyOffData: [],
    DepartmentData: [],
    TeamleaderData: [],
    ManagerData: [],
    ViewEmployee: [],
    ByTeamEmployee: [],
    EmployeeData: [],
    success: false,
    error: null,
    ViewLeader: [],
  },

  extraReducers: (builder) => {


    builder.addCase(getEmployees.pending, (state) => {
      state.loadingg = true;
    });
    builder.addCase(getEmployees.fulfilled, (state, action) => {
      state.EmployeesData = action.payload;
      state.loadingg = false;
    });
    builder.addCase(getEmployees.rejected, (state) => {
      state.loading = false;
      state.loadingg = false;
    });

    builder.addCase(viewEmployeData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewEmployeData.fulfilled, (state, action) => {
      state.ViewEmployee = action.payload;
    });
    builder.addCase(viewEmployeData.rejected, (state) => {
      state.loading = false;
    });


    // Employee peersonal Store
    builder.addCase(postEmployeePersonalStore.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postEmployeePersonalStore.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        // toast.error(action.payload.message);
      }
    });
    builder.addCase(postEmployeePersonalStore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "add failed.";
    });

    // Work Location Get
    builder.addCase(getWorkLocation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWorkLocation.fulfilled, (state, action) => {
      state.workLocationData = action.payload;
    });
    builder.addCase(getWorkLocation.rejected, (state) => {
      state.loading = false;
    });

    // Add Work Location
    builder.addCase(postWorkLocation.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postWorkLocation.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(postWorkLocation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Add Holiday failed.";
    });

    // Update Work Location
    builder.addCase(UpdateWorkLocation.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(UpdateWorkLocation.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.updateClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(UpdateWorkLocation.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Update failed.";
    });

    //Delete Work Location
    builder.addCase(DeleteWorkLocation.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(DeleteWorkLocation.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.DeleteClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(DeleteWorkLocation.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Login failed.";
    });

    // Get Job Role
    builder.addCase(getRole.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRole.fulfilled, (state, action) => {
      state.roleData = action.payload;
    });
    builder.addCase(getRole.rejected, (state) => {
      state.loading = false;
    });

    // Add Job Role
    builder.addCase(postJobRole.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postJobRole.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(postJobRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Add Job Role failed.";
    });

    // Update Job Role
    builder.addCase(UpdateJobRole.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(UpdateJobRole.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.updateClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(UpdateJobRole.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Update failed.";
    });

    //Delete Job Role
    builder.addCase(DeleteJobRole.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(DeleteJobRole.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.DeleteClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(DeleteJobRole.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Login failed.";
    });

    // Job Profile
    builder.addCase(getJobProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getJobProfile.fulfilled, (state, action) => {
      state.JobProfileData = action.payload;
    });
    builder.addCase(getJobProfile.rejected, (state) => {
      state.loading = false;
    });

    // Add Job Profile
    builder.addCase(postJobProfile.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postJobProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(postJobProfile.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Add Job Profile failed.";
    });

    // Update Job Profile
    builder.addCase(UpdateJobProfile.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(UpdateJobProfile.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.updateClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(UpdateJobProfile.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Update failed.";
    });

    //Delete Job Profile
    builder.addCase(DeleteJobProfile.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(DeleteJobProfile.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.DeleteClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(DeleteJobProfile.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Login failed.";
    });

    // Time Type
    builder.addCase(getTimeType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTimeType.fulfilled, (state, action) => {
      state.TimeTypeData = action.payload;
    });
    builder.addCase(getTimeType.rejected, (state) => {
      state.loading = false;
    });

    // Add Time Type
    builder.addCase(postTimeType.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postTimeType.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(postTimeType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Add Time Type failed.";
    });

    // Update Time Type
    builder.addCase(UpdateTimeType.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(UpdateTimeType.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.updateClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(UpdateTimeType.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Update failed.";
    });

    //Delete Job Profile
    builder.addCase(DeleteTimeType.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(DeleteTimeType.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.DeleteClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(DeleteTimeType.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Delete failed.";
    });

    // Employee Status
    builder.addCase(getEmployeeStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEmployeeStatus.fulfilled, (state, action) => {
      state.EmployeeStatusData = action.payload;
    });
    builder.addCase(getEmployeeStatus.rejected, (state) => {
      state.loading = false;
    });

    // Add Employee Status
    builder.addCase(postEmployeeStatus.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postEmployeeStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(postEmployeeStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Add Employee Status failed.";
    });

    // Update Employee Status
    builder.addCase(updateEmployeeStatus.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(updateEmployeeStatus.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.updateClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(updateEmployeeStatus.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Update failed.";
    });

    //Delete Employee Status
    builder.addCase(DeleteEmployeeStatus.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(DeleteEmployeeStatus.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.DeleteClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(DeleteEmployeeStatus.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Delete failed.";
    });

    // Work Type
    builder.addCase(getWorkType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWorkType.fulfilled, (state, action) => {
      state.WorkTypeData = action.payload;
    });
    builder.addCase(getWorkType.rejected, (state) => {
      state.loading = false;
    });

    // Add Work Type
    builder.addCase(postWorkType.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postWorkType.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(postWorkType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Add Time Type failed.";
    });

    // Update Work Type
    builder.addCase(updateWorkType.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(updateWorkType.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.updateClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(updateWorkType.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Update failed.";
    });

    //Delete Work Type
    builder.addCase(DeleteWorkType.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(DeleteWorkType.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.DeleteClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(DeleteWorkType.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Delete failed.";
    });

    // Probation
    builder.addCase(getProbation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProbation.fulfilled, (state, action) => {
      state.ProbationData = action.payload;
    });
    builder.addCase(getProbation.rejected, (state) => {
      state.loading = false;
    });

    // Post Probation
    builder.addCase(postProbation.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postProbation.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(postProbation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Add Probation failed.";
    });

    // Update Probation
    builder.addCase(UpdateProbation.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(UpdateProbation.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.updateClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(UpdateProbation.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Update failed.";
    });

    //Delete Probation
    builder.addCase(DeleteProbation.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(DeleteProbation.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.DeleteClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(DeleteProbation.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Delete failed.";
    });

    // Onboarding Settings
    builder.addCase(getOnboardingSetting.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOnboardingSetting.fulfilled, (state, action) => {
      state.OnboardingSettingsData = action.payload;
    });
    builder.addCase(getOnboardingSetting.rejected, (state) => {
      state.loading = false;
    });

    // Add Onboarding Settings
    builder.addCase(postOnboardingSetting.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postOnboardingSetting.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(postOnboardingSetting.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Add Onbaording Settings failed.";
    });

    // Update Onboard Settings
    builder.addCase(updateOnboardSettings.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(updateOnboardSettings.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.updateClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(updateOnboardSettings.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Update failed.";
    });

    //Delete Onboard Settings
    builder.addCase(DeleteOnboardSettings.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(DeleteOnboardSettings.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.DeleteClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(DeleteOnboardSettings.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Delete failed.";
    });

    // Shift
    builder.addCase(getShift.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getShift.fulfilled, (state, action) => {
      state.ShiftData = action.payload;
    });
    builder.addCase(getShift.rejected, (state) => {
      state.loading = false;
    });

    // Add Shifts
    builder.addCase(postShift.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postShift.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(postShift.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Add Shift failed.";
    });

    // Update Shift
    builder.addCase(updateShift.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(updateShift.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.updateClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(updateShift.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Update failed.";
    });

    //Delete Onboard Settings
    builder.addCase(DeleteShift.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(DeleteShift.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.DeleteClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(DeleteShift.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Delete failed.";
    });

    // Weekly Off
    builder.addCase(getWeeklyOff.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWeeklyOff.fulfilled, (state, action) => {
      state.WeeklyOffData = action.payload;
    });
    builder.addCase(getWeeklyOff.rejected, (state) => {
      state.loading = false;
    });

    // Add Weely Off
    builder.addCase(postWeeklyOff.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postWeeklyOff.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(postWeeklyOff.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Add Weekly Off failed.";
    });

    // Update Weekly Off
    builder.addCase(UpdateWeeklyOff.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(UpdateWeeklyOff.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.updateClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(UpdateWeeklyOff.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Update failed.";
    });

    //Delete Onboard Settings
    builder.addCase(DeleteWeeklyOff.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(DeleteWeeklyOff.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.DeleteClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(DeleteWeeklyOff.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Delete failed.";
    });

    // Department
    builder.addCase(getDepartmemt.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDepartmemt.fulfilled, (state, action) => {
      state.DepartmentData = action.payload;
    });
    builder.addCase(getDepartmemt.rejected, (state) => {
      state.loading = false;
    });

    // Post Department
    builder.addCase(postDepartmemt.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postDepartmemt.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(postDepartmemt.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Add Probation failed.";
    });

    // Update Job Role
    builder.addCase(updateDepartment.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(updateDepartment.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.updateClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(updateDepartment.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Update failed.";
    });

    //Delete Department
    builder.addCase(DeleteDepartment.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(DeleteDepartment.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.DeleteClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(DeleteDepartment.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Delete failed.";
    });

    // Manager
    builder.addCase(getManager.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getManager.fulfilled, (state, action) => {
      state.ManagerData = action.payload;
    });
    builder.addCase(getManager.rejected, (state) => {
      state.loading = false;
    });

    // Teamleader
    builder.addCase(getTeamleader.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTeamleader.fulfilled, (state, action) => {
      state.TeamleaderData = action.payload;
    });
    builder.addCase(getTeamleader.rejected, (state) => {
      state.loading = false;
    });
    
      // Reporting Leader
      builder.addCase(leaderRole.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(leaderRole.fulfilled, (state, action) => {

        state.ViewLeader = action.payload;
      });
      builder.addCase(leaderRole.rejected, (state) => {
        state.loading = false;
      });

 // Getting Employees By Team
 builder.addCase(getByTeamEmployees.pending, (state) => {
  state.loading = true;
});
builder.addCase(getByTeamEmployees.fulfilled, (state, action) => {
  state.ByTeamEmployee = action.payload;
});
builder.addCase(getByTeamEmployees.rejected, (state) => {
  state.loading = false;
});

 // Employee List
 builder.addCase(getEmployeeList.pending, (state) => {
  state.loading = true;
});
builder.addCase(getEmployeeList.fulfilled, (state, action) => {
  state.EmployeeData = action.payload;
});
builder.addCase(getEmployeeList.rejected, (state) => {
  state.loading = false;
});



  },
});

export default EmployeesSlice.reducer;
