import React, { useEffect, useMemo, useState } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProjectList } from "@Networking/APIs/PMS/Project/projectApi";

const ProjectList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ProjectListData = useSelector((state) => state.ProjectSlice);
  console.log("ProjectListData", ProjectListData);

  useEffect(() => {
    dispatch(getProjectList());
  }, [dispatch]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <h6 className="">
            <i class="fa-solid fa-list me-2"></i>PRM / Projects
          </h6>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-md-12">
          <div className="card ">
            <div className="d-flex justify-content-between align-items-center card-header text-center bg-gradient-info text-light p-1">
              <h5 className="m-0">Latest Invoices</h5>
              <button className="btn btn-sm btn-light rounded-pill"
              onClick={() => navigate("/addNewProject")}
              >
                 Add New Project
              </button>
            </div>
            <div className="card-body">
              <div class="table-responsive rounded">
                {ProjectListData?.ProjectListData?.data &&
                ProjectListData?.ProjectListData?.data.length > 0 ? (
                  <table className="table table-bordered ">
                    <thead>
                      <tr className="table-dark ">
                        <th>Project Name</th>
                        <th className="text-center">Client Name</th>
                        <th className="text-center">Start Date</th>
                        <th className="text-center">End Date</th>
                        <th className="text-center">Status</th>
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ProjectListData?.ProjectListData?.data.map(
                        (projects, index) => (
                          <tr
                          //  key={index}
                          >
                            <td>{projects?.project?.item_name}</td>
                            <td className="text-center">
                              {projects?.client?.first_name}
                            </td>
                            <td className="text-center">
                              {projects.start_date}
                            </td>
                            <td className="text-center">{projects.end_date}</td>
                            <td className="text-center">{projects.status}</td>
                            <td className="text-end">
                              <div className="d-flex justify-content-end gap-2">
                                <button
                                  className="btn btn-sm  btn-primary"
                                  onClick={() => {
                                    navigate(
                                      `/ProjectList/${projects?.project_id}`
                                    );
                                  }}
                                >
                                  <i class="fas fa-eye"></i>
                                </button>
                                <button
                                  className="btn btn-sm btn-success"
                                  onClick={() => {
                                    navigate(
                                      `/EditProject/${projects?.project_id}`
                                    );
                                  }}
                                >
                                  <i class="fas fa-edit"></i>
                                </button>
                                <button
                                  className="btn btn-sm  btn-danger"
                                  onClick={() => {
                                    navigate(
                                      `/ProjectList/${projects?.project_id}`
                                    );
                                  }}
                                >
                                  <i class="fas fa-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      )}{" "}
                    </tbody>
                  </table>
                ) : (
                  <p style={{ color: "red" }}>No data available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectList;
