import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import EmployeeDetails from "./employeformSteps/EmployeeDetails";
import JobDetails from "./employeformSteps/JobDetails";
import WorkDetails from "./employeformSteps/WorkDetails";
import Compensation from "./employeformSteps/Compensation";
import "./employeformSteps/MultiStepForm.css"; // Import the updated CSS file

const EmployeeForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();

  const nextStep = () => {
    // Show confirmation alert
    const userConfirmed = window.confirm(
      "Are you sure you want to proceed to the next step?"
    );

    if (userConfirmed) {
      setCurrentStep((prev) => Math.min(prev + 1, 4)); // Proceed to the next step
    } else {
      navigate("/Employees"); // Navigate to manageEmployee route
    }
  };

  const prevStep = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 1));
  };

  const progress = ((currentStep - 1) / 4) * 100; // Adjusted calculation for progress

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <EmployeeDetails nextStep={nextStep} progress={progress} />;
      case 2:
        return <JobDetails nextStep={nextStep} prevStep={prevStep} progress={progress} />;
      case 3:
        return <WorkDetails nextStep={nextStep} prevStep={prevStep} progress={progress} />;
      case 4:
        return <Compensation prevStep={prevStep} progress={progress} />;
      default:
        return null;
    }
  };

  return (

    
    <div className="container-fluid">
      <div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="">
              <i class="fa-solid fa-list me-1"></i> HRM / Create Employees
            </h6>
          </div>
        </div>

        <div className="card bg-gradient-info rounded-top-2 p-2 mb-2">
          <div className="row d-flex align-items-center">
            <div className="col-md-12 col-sm-12">
              <h3 className="page-title m-0  text-light">
                <span className="mx-2 text-primary">
                  <i className="mdi mdi-file  text-light"></i>
                </span>
                All Employees
              </h3>
            </div>
          </div>
        </div>
    <div className="employee-form card">

      <div className="form-content mx-2 px-2">{renderStep()}</div>
    </div>
    </div>
  );
};

export default EmployeeForm;
