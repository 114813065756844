import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteWorkingHours,
  getWorkingHours,
  postWorkingHours,
  postWorkingHoursUpdate,
} from "@Networking/APIs/AttendanceRecord/attendanceRecordApi";
import AttendanceRecord from "./AttendanceRecord";
import $ from "jquery";
import {
  getEmployees,
  updateDepartment,
} from "@Networking/APIs/Employee/EmployeeApi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const WorkingHours = () => {
  const holidaysData = useSelector((state) => state.AttendanceRecordSlice);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedWorkingHours, setWorkingHours] = useState(null);

  const [selectedName, setDepartment] = useState(null);
  const [nameInput, setNameInput] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getWorkingHours());
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { type } = useParams();

  const handleBack = () => {
    navigate(-1);
  };

  const workingHoursData = useSelector((state) => state.AttendanceRecordSlice);

  const onSubmit = async (data) => {
    const newWorkingHours = {
      month: data.month,
      days: data.days,
      hours: data.hours,
    };
    try {
      await dispatch(postWorkingHours(newWorkingHours));
      await dispatch(getWorkingHours());
    } catch (error) {}
  };

  const handleEdit = (hours) => {
    const monthMatch = hours.month.match(/^[A-Za-z]+/);
    const monthName = monthMatch ? monthMatch[0] : hours.month;
    setWorkingHours(hours);
    setIsEditModalOpen(true);
    reset({
      month: monthName,
      days: hours.days,
      hours: hours.hours,
    });
  };

  const onUpdate = async (data) => {
    const updateWorkingHours = {
      month: data.month,
      days: data.days,
      hours: data.hours,
    };
    try {
      await dispatch(
        postWorkingHoursUpdate({
          id: selectedWorkingHours.id,
          ...updateWorkingHours,
        })
      );
      await dispatch(getWorkingHours());
      setIsEditModalOpen(false);
    } catch (error) {}
  };

  const handleDelete = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure? You won't be able to revert this!"
    );

    if (isConfirmed) {
      dispatch(DeleteWorkingHours({ id }));
      dispatch(getWorkingHours());
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="">
              <i class="fa-solid fa-list me-2"></i>HRM / Attendance Record /
              Working Hours
            </h6>
          </div>
        </div>

        <div className="m-1">
        <button
          type="button"
          className="btn btn-secondary mb-3"
          onClick={handleBack}
        >
          <i className="fa fa-arrow-left me-2"></i>
          Back
        </button>
        </div>


          <div className="card-body">
          <div className="card shadow">
          <div className="row p-3">
          <div className="col-md-12">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row p-2">
                  <div className="col-md-12">
                    <div className="row mb-2 d-flex align-items-center p-1">
                      <div className="col-md-4 mb-2">
                        <div class="form-group">
                          <label for="month">Month</label>
                          <select
                            class="form-select"
                            id="month"
                            {...register("month", { required: true })}
                          >
                            <option value="" disabled selected>
                              Select Month
                            </option>
                            <option value="January">January</option>
                            <option value="February"> February</option>
                            <option value="March"> March</option>
                            <option value="April"> April</option>
                            <option value="May"> May</option>
                            <option value="June"> June</option>
                            <option value="July"> July</option>
                            <option value="August"> August</option>
                            <option value="September"> September</option>
                            <option value="October"> October</option>
                            <option value="November"> November</option>
                            <option value="December"> December</option>
                          </select>
                          {errors.month && (
                            <span className="text-danger">
                              *This field is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-3 mb-2">
                        <div class="form-group">
                          <label className="fw-bold" htmlFor="days">
                            Days
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="days"
                            {...register("days", { required: true })}
                          />
                          {errors.days && (
                            <span className="text-danger">
                              *This field is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 mb-2">
                        <div class="form-group">
                          <label className="fw-bold" htmlFor="hours	">
                            Hours
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="hours"
                            {...register("hours", { required: true })}
                          />
                          {errors.hours && (
                            <span className="text-danger">
                              *This field is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-2 mb-2">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive border rounded">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>MONTH</th>
                          <th>DAYS</th>
                          <th>WORKING HOURS</th>
                          <th className="text-end">ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {workingHoursData?.WorkingHoursData?.data?.length >
                        0 ? (
                          workingHoursData.WorkingHoursData.data.map(
                            (hours) => (
                              <tr key={hours.id}>
                                <td>{hours.month}</td>
                                <td>{hours.days}</td>
                                <td>{hours.hours}</td>
                                <td>
                                  <div className="d-flex justify-content-end">
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-primary me-2"
                                      onClick={() => handleEdit(hours)}
                                    >
                                      <i className="fa fa-edit"></i>
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-danger"
                                      onClick={() => handleDelete(hours.id)}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td colSpan="5">No working hours available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      {isEditModalOpen && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Working Hours</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setIsEditModalOpen(false)}
                ></button>
              </div>
              <form onSubmit={handleSubmit(onUpdate)}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label htmlFor="month" className="form-label">
                      Month
                    </label>
                    <select
                      id="month"
                      className="form-select"
                      {...register("month", { required: true })}
                    >
                      <option value="" disabled>
                        Select Month
                      </option>
                      <option value="January">January</option>
                      <option value="February"> February</option>
                      <option value="March"> March</option>
                      <option value="April"> April</option>
                      <option value="May"> May</option>
                      <option value="June"> June</option>
                      <option value="July"> July</option>
                      <option value="August"> August</option>
                      <option value="September"> September</option>
                      <option value="October"> October</option>
                      <option value="November"> November</option>
                      <option value="December"> December</option>
                    </select>
                    {errors.month && (
                      <span className="text-danger">
                        *This field is required
                      </span>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="days" className="form-label">
                      Days
                    </label>
                    <input
                      type="text"
                      id="days"
                      className="form-control"
                      {...register("days", { required: true })}
                    />
                    {errors.days && (
                      <span className="text-danger">
                        *This field is required
                      </span>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="hours" className="form-label">
                      DATE
                    </label>
                    <input
                      type="hours"
                      id="date"
                      className="form-control"
                      {...register("hours", { required: true })}
                    />
                    {errors.hours && (
                      <span className="text-danger">
                        *This field is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="modal-footer text-center`">
                  <div className="row">
                    <div className="col-md-12">
                      <button type="submit" className="btn btn-success">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WorkingHours;
