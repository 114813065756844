// leaveApi.js
import axios from 'axios';
import { BASE_URL, leaveStatus, leaveUpdate, leaveView } from '@Networking/APIs/NWConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';

// Employee Store
export const updateLeaveApi = createAsyncThunk(
    "put/updateLeaveApi",
    async(post) => {
        let url = BASE_URL + leaveUpdate + post.leaveId;
        const token = localStorage.getItem("authToken");
  
        try {
            const response = (await axios.put(url, post.payload, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;
                return Promise.resolve(response);
        } catch(response) {
            return response;
        }
    }
  );

export const fetchLeaveApi = createAsyncThunk(
    "get/fetchLeaveApi",
    async(leaveId) => {
  
        let url = BASE_URL + leaveView + leaveId;
  
        const token = localStorage.getItem("authToken");
  
        try {
            const response = (await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;
                return Promise.resolve(response);
        } catch(response) {
            return response;
        }
    }
  );


  export const updateLeaveStatus = createAsyncThunk(
    "put/updateLeaveStatus",
    async(post) => {
  
        let url = BASE_URL + leaveStatus + post.leaveId;
  
        const token = localStorage.getItem("authToken");
  
        try {
            const response = (await axios.put(url,{ status: post.status }, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;
                return Promise.resolve(response);
        } catch(response) {
            return response;
        }
    }
  );

 