import React, { useState } from 'react';
import { Navbar } from './Navnbar/navbar';
import SideBar from './SideBar/sidebar';
import Loader from "@Layout/Loader/loader";

export const Mainlayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    <>
      <div className={`container-scroller ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        <Navbar onToggleSidebar={handleToggleSidebar} isSidebarOpen={isSidebarOpen} />
        <div className="page-body-wrapper">
          <SideBar isSidebarOpen={isSidebarOpen} />
          <div className={`${isSidebarOpen ? 'main-panel2' : 'main-panel'}`}>
          <div className={`content-wrapper ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};