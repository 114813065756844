import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {AllBanks, BASE_URL, banksget, indirectBank} from '@Networking/APIs/NWConfig';
import Cookies from 'js-cookie';


 

 

  export const getAllBank = createAsyncThunk(
    'get/getAllBank',
    async (id) => {   
        
      let url = BASE_URL + AllBanks + id;   
      const token = localStorage.getItem("authToken");
     
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );
  
  