import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {BASE_URL, getinvoicenumber,storeinvoicenumber,allinvoice, updateInvoice ,EditView, InvoiceSoftDelete, InvoiceSoftCancel } from '@Networking/APIs/NWConfig';



// invoice number get
export const getInvoiceNumber = createAsyncThunk(
    'get/getInvoiceNumber',
    async () => {  
  
      let url = BASE_URL + getinvoicenumber; 
  
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );
  
  // GenerateInvoice
export const storeInvoice = createAsyncThunk(
    "post/storeInvoice",
    async(post) => {

        let url = BASE_URL + storeinvoicenumber; 

        const token = localStorage.getItem("authToken");

        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;

                return Promise.resolve(response);
        } catch(error) {
            return error;
        }
    }
);

// Edit Invoice
export const EditInvoice = createAsyncThunk(
  "post/EditInvoice",
  async(post) => {

      let url = BASE_URL + updateInvoice + post.invoiceId ; 

      const token = localStorage.getItem("authToken");

      try {
          const response = (await axios.post(url, post, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;

              return Promise.resolve(response);
      } catch(error) {
          return error;
      }
  }
);

// All Invoice Data
export const getallinvoices = createAsyncThunk(
  'get/getallinvoices',
  async () => {  

    let url = BASE_URL + allinvoice; 

    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);


export const getEditData = createAsyncThunk(
  'getEditData',
  async (ID) => {  
    let url = BASE_URL + EditView +ID; 
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      });
      if (response.data) { 
      return response?.data?.invoice; 
      }
      return response?.data?.invoice; 
    } catch (error) {
    }
  },
);


export const SoftCancel = createAsyncThunk(
  "post/SoftCancel",
  async (id) => {
    const url = `${BASE_URL}${InvoiceSoftCancel}${id}`;

    const token = localStorage.getItem("authToken");

        try {
            const response = (await axios.post(url,{},{
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;

                return Promise.resolve(response);
        } catch(error) {
            return error;
        }
    }
);