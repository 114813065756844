import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "@Networking/APIs/Background/backgorundApi";
import Loader from "@Layout/Loader/loader";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faMoneyBillWave,
  faUsers,
  faHandshake,
  faProjectDiagram,
  faGauge,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import { clearEmployeData } from "@Networking/Slices/Employee/EmployeStoreSlice";
import { fa42Group } from "@fortawesome/free-brands-svg-icons";
import { set } from "react-hook-form";
import { fetchUserProfile } from "@Networking/APIs/Profile/profileApi";

import "./sidebar.css";

const SideBar = ({ isSidebarOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((state) => state.BackgroundSlice.userData);
  const { ProfileData } = useSelector((state) => state.ProfileSlice);

  const [activeSection, setActiveSection] = useState(null); // Tracks the active section
  const [activCss, setActiveCss] = useState(0); // Tracks the active section
  const [firstCont, setFirstCont] = useState(false);
  const [secondCont, setSecondCont] = useState(false);
  const [thirdCont, setThirdCont] = useState(false);
  const [fourthCont, setFourthCont] = useState(false);
  const [fifthCont, setFifthCont] = useState(false);
  const [sixthCont, setSixthCont] = useState(false);

  useEffect(() => {
    dispatch(fetchUserData());
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    setEmpId(ProfileData?.employee?.id);
  }, [ProfileData]);

  const [empId, setEmpId] = useState(null);

  const name = localStorage.getItem("name");
  const role = localStorage.getItem("role");
  const role_id = parseInt(userData?.role_id);

  if (!userData) {
    return <Loader />;
  }

  // Handle section click to toggle the active section or close it if already active
  const handleSectionClick = (section, subSection) => {
    if (section == "Employee" && subSection == 4) {
      dispatch(clearEmployeData());
      window.location.reload();
    }

    if (subSection && subSection != undefined) {
      setActiveCss(subSection);
    }
    setActiveSection((prevSection) =>
      prevSection === section ? null : section
    );
  };
  // This function is used to check if a subheading is active
  const isActiveSubheading = (section) =>
    section != null && activeSection === section;

  const profilePhoto = ProfileData?.employee?.profile_photo || "N/A";

  const getImageUrl = () => {
    const imageUrl = `https://developbo.techsimba.in/management/public/profile_Photos/${profilePhoto}`;
    return imageUrl;
  };

  return (
    <nav
      className={`sidebar sidebar-offcanvas ${isSidebarOpen ? "" : "active"}`}
      id="sidebar"
    >
      <div className="nav-icon-container mb-3">
        <div className="line"></div>
        <b>TS</b>
        <div className="line"></div>
      </div>

      <div className="mt-2">
        <ul className="nav">
          {/* <li className="nav-item nav-profile">
            <Link to="/Profile" className="nav-link">
              <div className="nav-profile-image">
                <img src={getImageUrl()} />
                <span className="login-status online"></span>
              </div>
              <div className="nav-profile-text d-flex flex-column">
                <span className="font-weight-bold mb-2">{name}</span>
                <span className="text-secondary text-small">{role}</span>
              </div>
              <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
            </Link>
          </li> */}

          <div>
            {/* Dashboard */}
            {(role_id === 1 || role_id === 2) && (
              <li
                className="nav-item1"
                onClick={() => {
                  setFifthCont(!fifthCont);
                  setFirstCont(false);
                  setSecondCont(false);
                  setThirdCont(false);
                  setFourthCont(false);
                  navigate("/dashboard");
                  handleSectionClick(activeSection);
                  setActiveCss(0);
                }}
              >
                <span className="menu-content">
                  <FontAwesomeIcon icon={faGauge} className="menu-icon" />
                  <span className="menu-title1">Dashboard</span>
                </span>
              </li>
            )}
            {/* INVOICING & FINANCE */}
            {(role_id === 1 || role_id === 2) && (
              <li
                className="nav-item1"
                onClick={() => {
                  setFirstCont(!firstCont);
                  setSecondCont(false);
                  setThirdCont(false);
                  setFourthCont(false);
                }}
              >
                <span className="menu-content">
                  <FontAwesomeIcon
                    icon={faMoneyBillWave}
                    className="menu-icon"
                  />
                  <span className="menu-title1">FINANCE</span>
                </span>
                <span className="chevron-icon">
                  <i
                    className={`mdi ${
                      firstCont ? "mdi-chevron-down" : "mdi-chevron-right"
                    }`}
                  ></i>
                </span>
              </li>
            )}
            {firstCont ? (
              <div className="pb-3">
                <li
                  className={
                    activeSection === "dashboard"
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={() => handleSectionClick("dashboard")}
                >
                  <Link className="nav-link" to="/financeDashboard">
                    <FontAwesomeIcon icon={faMinus} className="menu-icon" />
                    <span className="menu-title ml-2">Dashboard</span>
                  </Link>
                </li>
                <li
                  className={
                    activeSection === "Invoicing"
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={() => handleSectionClick("Invoicing")}
                >
                  <a
                    className="nav-link d-flex justify-content-between"
                    href="#"
                  >
                    <span>
                      <FontAwesomeIcon icon={faMinus} className="menu-icon" />
                      <span className="menu-title ml-2">Invoices</span>
                    </span>
                    <span>
                      <i
                        className={`mdi ${
                          isActiveSubheading("Invoicing")
                            ? "mdi-chevron-down"
                            : "mdi-chevron-right"
                        } ml-auto`}
                      ></i>
                    </span>
                  </a>
                  {isActiveSubheading("Invoicing") && (
                    <ul className="nav flex-column sub-menu">
                      {role_id === 2 && (
                        <li
                          className={`nav-item ${
                            activCss === 1 ? "active" : ""
                          } `}
                          onClick={() => handleSectionClick("Invoicing", 1)}
                        >
                          <Link
                            className={`nav-link ${
                              activCss === 1 ? "active" : ""
                            } `}
                            to="/InvoicesForm"
                          >
                            Create Invoice
                          </Link>
                        </li>
                      )}
                      {(role_id === 2 || role_id === 1) && (
                        <li
                          className={`nav-item ${
                            activCss === 2 ? "active" : ""
                          } `}
                          onClick={() => handleSectionClick("Invoicing", 2)}
                        >
                          <Link
                            className={`nav-link ${
                              activCss === 2 ? "active" : ""
                            } `}
                            to="/InvoicesList"
                          >
                            Manage Invoices
                          </Link>
                        </li>
                      )}
                    </ul>
                  )}
                </li>
                <li
                  className={
                    activeSection === "Reports" ? "nav-item active" : "nav-item"
                  }
                  onClick={() => handleSectionClick("Reports")}
                >
                  <Link className="nav-link" to="/reports">
                    <FontAwesomeIcon icon={faMinus} className="menu-icon" />
                    <span className="menu-title ml-2">Reports</span>
                  </Link>
                </li>
              

               
                <li
                    className={
                      activeSection == "Invoice-Master"
                        ? "nav-item active"
                        : "nav-item"
                    }
                    onClick={() => handleSectionClick("Invoice-Master")}
                  >
                    <a
                      className="nav-link d-flex justify-content-between"
                      href="#"
                    >
                      <Link className="nav-link" to="/Invoice-Master">
                        <FontAwesomeIcon icon={faMinus} className="menu-icon" />
                        <span className="menu-title ml-2">Masters</span>
                      </Link>
                    </a>
                  </li>
              
              </div>
            ) : null}

            {/* HUMAN RESOURCE MANAGEMENT (HRM) */}
          
          </div>
          {/* <li
              className="nav-item1"
              onClick={() => {
                handleSectionClick("FeatureForm")
                setFourthCont(false);
                setFirstCont(false);
                setSecondCont(false);
                setThirdCont(false);
              }}
            >
            <Link to="/SwitchForm">
             <span className="menu-content">
              <FontAwesomeIcon icon={faClipboardList} className="menu-icon" />
              <span className="menu-title1">Add New Features</span>
            </span>
              </Link>
            </li> */}
        </ul>
      </div>
    </nav>
  );
};

export default SideBar;
