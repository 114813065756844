import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL,items } from "@Networking/APIs/NWConfig";
import Cookies from 'js-cookie';


export const postItemStore = createAsyncThunk(
    "post/postItemStore",
    async (post) => {
        let url = BASE_URL + items;

        const token = localStorage.getItem("authToken");

        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })).data;

            return Promise.resolve(response);
        } catch (error) {
            return error;
             
        }
    }
);