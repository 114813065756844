import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllMonth,
  getDayHours,
  SalaryStoreApi,
  SalaryDetailsById,
} from "@Networking/APIs/Salary/salaryApi";
import { getEmployees } from "@Networking/APIs/Employee/EmployeeApi";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "@Layout/Loader/loader";

const CreateSalary = () => {
  const {
    register,
    formState: { errors },
    watch,
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [salary, setSalary] = useState("");
  const [hourlyRate, setHourlyRate] = useState(0);
  const [deductedSalary, setDeductedSalary] = useState(0);
  const [deductedHours, setDeductedHours] = useState(0);
  const [totalSalary, setTotalSalary] = useState(0); // State for total salary
  const [isLoading, setIsLoading] = useState(false);
  const EmployeeSalaryDetails = useSelector(
    (state) => state.SalarySlice.SalaryDetailsId
  );
  const [TotalWorkingHours, setTotalWorkingHours] = useState(0);
  const [EmployeeAdvance, setEmployeeAdvance] = useState(0);

  const monthData = useSelector((state) => state.SalarySlice?.monthData);
  const getDays = useSelector((state) => state.SalarySlice.getDays);
  const employeeData = useSelector(
    (state) => state.EmployeesSlice.EmployeesData
  );
  const workingHours = getDays?.data?.hours || 0;
  const totalHours = watch("total_hours", 0);
  const employeeAdvance = watch("employee_advance", 0);
  const mediclaimDeduction = watch("medicalaim_deduction", 0);
  const otherDeductionAmount = watch("other_deduction_amount", 0);
  const advanceAmount = watch("advance_amount", 0);
  const { apiErrors } = useSelector((state) => state.SalarySlice);
  const [selectedMonth, setSelectedMonth] = useState("");

  useEffect(() => {
    formRef.current.reset();
  }, [location]);

  useEffect(() => {
    const calculatedDeductedHours =
      workingHours -
      (parseFloat(EmployeeSalaryDetails?.data?.total_working_hours) || 0);
    setDeductedHours(calculatedDeductedHours);

    const calculatedDeductedSalary = hourlyRate * calculatedDeductedHours;
    setDeductedSalary(calculatedDeductedSalary);
  }, [totalHours, workingHours, hourlyRate]);

  useEffect(() => {
    dispatch(getAllMonth());
    dispatch(getEmployees());
  }, [dispatch]);

  const simulateLoading = async () => {
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsLoading(false);
    navigate("/Salary");
  };

  useEffect(() => {
    const baseSalary = parseFloat(salary) || 0;
    const advance = parseFloat(employeeAdvance) || 0;
    const mediclaim = parseFloat(mediclaimDeduction) || 0;
    const otherDeduction = parseFloat(otherDeductionAmount) || 0;
    const advanceAmt = parseFloat(advanceAmount) || 0;

    // Calculate total salary
    const calculatedTotalSalary =
      baseSalary - advanceAmt - mediclaim - otherDeduction - deductedSalary;
    setTotalSalary(calculatedTotalSalary);
  }, [
    salary,
    employeeAdvance,
    mediclaimDeduction,
    otherDeductionAmount,
    advanceAmount,
  ]);

  const handleSelectChange = (event) => {
    const selectedMonth = event.target.value;
    setSelectedMonth(selectedMonth);
    dispatch(getDayHours(selectedMonth));
  };

  const handleEmployeeChange = async (event) => {
    const selectedUserId = event.target.value;

    const result = await dispatch(
      SalaryDetailsById({ id: selectedUserId, month: selectedMonth })
    ).unwrap();

    if (result.status === true) {
      toast.success(result.message);
      const salary = parseFloat(result.salary) || 0;
      setTotalWorkingHours(result.data.total_working_hours);
      setEmployeeAdvance(result.data.employee_advance);
      setSalary(salary);

      // Calculate hourly rate only if workingHours is valid
      if (workingHours > 0) {
        const rate = (salary / workingHours).toFixed(2);
        setHourlyRate(rate);
      }
    } else {
      // Handle case where data is not found
      toast.error(result.message);
      reset({
        working_days: "",
        working_hours: "",
        employee_salary: "",
        total_hours: "",
        employee_advance: "",
        medicalaim_deduction: "",
        other_deduction_label: "",
        other_deduction_amount: "",
        advance_amount: "",
        hourly_rate: "",
        deducted_hours: "",
        deducted_salary: "",
        paid_leave: "",
      });
    }
  };

  const handleSubmitSalary = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target); // Get form data
    const data = Object.fromEntries(formData.entries());

    simulateLoading();
    try {
      // Use unwrap to extract the payload directly.
      const result = dispatch(SalaryStoreApi(data)).unwrap();

      if (result.status == true) {
        toast.success(result.message); // Success feedback.
        simulateLoading();
        reset();
        navigate("/Salary");
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error("Failed to apply leave. Please try again.");
    }
    // Submit the data to your API here
  };

  return (
    <div className="container-fluid">

      <div className="row">
        <div className="col-md-12 col-sm-12">
          <h6 className="">
            <i class="fa-solid fa-list me-2"></i>HRM / Salary / Create Salary
          </h6>
        </div>
      </div>

      <div className="card bg-gradient-info rounded-top-2 p-2 mb-3">
        <div className="row d-flex align-items-center">
          <div className="col-md-12 col-sm-12">
            <h3 className="page-title m-0  text-light">
              <span className="mx-2 text-primary">
                <i className="mdi mdi-file  text-light"></i>
              </span>
              Create Salary
            </h3>
          </div>
        </div>
      </div>


      
          <div className="card">
            <div className="card-body shadow">
              <div className="row p-3">
                <div className="col-md-12">
                  <form onSubmit={handleSubmitSalary} ref={formRef}>
                    {/* Working Month and Employee Name */}
                    <div className="row mb-2">
                      <div className="col-md-6 mb-2">
                        <div className="form-group">
                          <label className="fw-bold" htmlFor="lastName">
                            Working Month
                          </label>
                          <select
                            className="form-control"
                            {...register("working_month", {
                              required: "Working month is required",
                            })}
                            onChange={handleSelectChange}
                          >
                            <option className="d-none" value="">
                              Select Month
                            </option>
                            {monthData?.data?.map((item) => (
                              <option key={item.id} value={item.month}>
                                {item.month}
                              </option>
                            ))}
                          </select>
                          {errors.working_month && (
                            <div className="text-danger">
                              {errors.working_month.message}
                            </div>
                          )}
                          {apiErrors.working_month && (
                            <span className="text-danger">
                              {apiErrors.working_month[0]}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="form-group">
                          <label className="fw-bold" htmlFor="gender">
                            Employee Name
                          </label>
                          <select
                            className="form-control"
                            {...register("employee_id", {
                              required: "Employee is required",
                            })}
                            onChange={handleEmployeeChange}
                          >
                            <option className="d-none" value="">
                              Select Employee
                            </option>
                            {employeeData?.employee_list?.map((employee) => (
                              <option key={employee.id} value={employee.id}>
                                {employee.first_name} {employee.last_name || ""}
                              </option>
                            ))}
                          </select>
                          {errors.employee_id && (
                            <div className="text-danger">
                              {errors.employee_id.message}
                            </div>
                          )}
                          {apiErrors.employee_id && (
                            <span className="text-danger">
                              {apiErrors.employee_id[0]}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Working Days and Hours */}
                    <div className="row mb-2">
                      <div className="col-md-6 mb-2">
                        <div className="form-group">
                          <label className="fw-bold" htmlFor="lastName">
                            Working Days
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("working_days")}
                            placeholder="Working Days"
                            value={getDays?.data?.days}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="form-group">
                          <label className="fw-bold" htmlFor="gender">
                            Working Hours
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("working_hours")}
                            placeholder="Working Hours"
                            value={getDays?.data?.hours || ""}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>

                    {/* Employee Salary and Advance */}
                    <div className="row mb-2">
                      <div className="col-md-6 mb-2">
                        <div className="form-group">
                          <label className="fw-bold" htmlFor="lastName">
                            Employee Salary
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("employee_salary")}
                            placeholder="Employee Salary"
                            value={EmployeeSalaryDetails?.salary}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="form-group">
                          <label className="fw-bold" htmlFor="gender">
                            Employee Advance
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("employee_advance", {
                              required: "Advance is required",
                            })}
                            placeholder="Employee Advance"
                            value={EmployeeAdvance}
                          />
                          {errors.employee_advance && (
                            <div className="text-danger">
                              {errors.employee_advance.message}
                            </div>
                          )}
                          {apiErrors.employee_advance && (
                            <span className="text-danger">
                              {apiErrors.employee_advance[0]}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Total Hours and Mediclaim Deduction */}
                    <div className="row mb-2">
                      <div className="col-md-6 mb-2">
                        <div className="form-group">
                          <label className="fw-bold" htmlFor="lastName">
                            Total Working Hours
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("total_hours", {
                              required: "Total hours are required",
                            })}
                            placeholder="Total Hours"
                            readOnly
                            value={TotalWorkingHours}
                          />
                          {errors.total_hours && (
                            <div className="text-danger">
                              {errors.total_hours.message}
                            </div>
                          )}
                          {apiErrors.total_hours && (
                            <span className="text-danger">
                              {apiErrors.total_hours[0]}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="form-group">
                          <label className="fw-bold" htmlFor="gender">
                            Mediclaim Deduction
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("medicalaim_deduction")}
                            placeholder="Mediclaim Deduction"
                          />
                          {errors.medicalaim_deduction && (
                            <div className="text-danger">
                              {errors.medicalaim_deduction.message}
                            </div>
                          )}
                          {apiErrors.medicalaim_deduction && (
                            <span className="text-danger">
                              {apiErrors.medicalaim_deduction[0]}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Other Deductions */}
                    <div className="row mb-2">
                      <div className="col-md-6 mb-2">
                        <div className="form-group">
                          <label className="fw-bold" htmlFor="lastName">
                            Other Deduction Label
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("other_deduction_lable", {
                              required: "Label is required",
                              pattern: {
                                value: /^[A-Za-z\s]+$/,
                                message: "Only alphabets are allowed",
                              },
                            })}
                            placeholder="Other Expenditure"
                          />
                          {errors.other_deduction_lable && (
                            <div className="text-danger">
                              {errors.other_deduction_lable.message}
                            </div>
                          )}
                          {apiErrors.other_deduction_lable && (
                            <span className="text-danger">
                              {apiErrors.other_deduction_lable[0]}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="form-group">
                          <label className="fw-bold" htmlFor="gender">
                            Other Deduction Amount
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("other_deduction_amount", {
                              required: "Amount is required",
                            })}
                            placeholder="Other Deduction"
                          />
                          {errors.other_deduction_amount && (
                            <div className="text-danger">
                              {errors.other_deduction_amount.message}
                            </div>
                          )}
                          {apiErrors.other_deduction_amount && (
                            <span className="text-danger">
                              {apiErrors.other_deduction_amount[0]}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Advance Amount and Total Salary */}
                    <div className="row mb-2">
                      <div className="col-md-6 mb-2">
                        <div className="form-group">
                          <label className="fw-bold" htmlFor="lastName">
                            Advance Amount
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("advance_amount", {
                              required: "Advance amount is required",
                            })}
                            placeholder="Advance Amount"
                          />
                          {errors.advance_amount && (
                            <div className="text-danger">
                              {errors.advance_amount.message}
                            </div>
                          )}
                          {apiErrors.advance_amount && (
                            <span className="text-danger">
                              {apiErrors.advance_amount[0]}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="form-group">
                          <label className="fw-bold" htmlFor="gender">
                            Total Salary
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("total_salary")}
                            placeholder="Total Salary"
                            value={totalSalary.toFixed(2)} // Show calculated total salary
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="form-group">
                          <label className="fw-bold" htmlFor="gender">
                            Remark{" "}
                          </label>
                          <textarea
                            className="form-control"
                            {...register("remark")}
                            placeholder="Enter remark"
                            rows={4} // Adjust the number of rows as needed
                          />
                        </div>
                      </div>
                    </div>

                    <div className="p-3 border">
                      <div className="row">
                        <div className="col-md-3">
                          <h6>HOURLY RATE (₹) : </h6>
                        </div>
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            type="text"
                            {...register("hourly_rate")}
                            value={hourlyRate} // Show calculated total salary
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-md-3">
                          <h6>DEDUCTED HOURS : </h6>
                        </div>
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            type="text"
                            {...register("deducted_hours")}
                            value={deductedHours} // Show calculated total salary
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-md-3">
                          <h6>DEDUCTED SALARY :</h6>
                        </div>
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            type="text"
                            {...register("deducted_salary")}
                            value={deductedSalary} // Show calculated total salary
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-md-3">
                          <h6>PAID LEAVE :</h6>
                        </div>
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            type="text"
                            {...register("paid_leave")}
                            readOnly
                            value={EmployeeSalaryDetails.data?.leave_this_month}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Submit and Reset Buttons */}
                    <div className="row mt-4">
                      <div className="col-md-6 text-center">
                        <button
                          type="reset"
                          className="btn btn-danger rounded-pill w-50"
                        >
                          Reset
                        </button>
                      </div>
                      {isLoading && <Loader />}
                      <div className="col-md-6 text-center">
                        <button
                          type="submit"
                          className="btn btn-primary rounded-pill w-50"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
};

export default CreateSalary;
