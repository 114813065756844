import React, { useState, useCallback,useEffect, useRef } from "react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import "../Invoice/invoicesForm.css";
import Swal from 'sweetalert2';
import {
  IoMdClose,
  IoMdCreate,
  IoMdPersonAdd,
  IoMdTrash,
} from "react-icons/io";
import DOMPurify from 'dompurify';
import "bootstrap/dist/css/bootstrap.min.css";
import ClientForm from "./ClientFrom/clientForm";
import ItemForm from "./ItemForm/itemForm";
import BankForm from "./Bank/bankForm";
import CompanyForm from "./Comapany/companyForm";
import { HiChevronDoubleDown, HiChevronDoubleUp } from "react-icons/hi";
import Loader from "@Layout/Loader/loader";
import { useDispatch, useSelector } from "react-redux";
import { getClient } from "@Networking/APIs/Invoice/Client/clientGetApi";
import { getCompany } from "@Networking/APIs/Invoice/Company/companyGetApi";
import {
  deleteCategory,
  getCategory,
} from "@Networking/APIs/Invoice/Category/CategoryGetApi";
import { getItem } from "@Networking/APIs/Invoice/Item/getitem";

import {
  EditInvoice,
  getallinvoices,
  getEditData,
  getInvoiceNumber,
  storeInvoice,
} from "@Networking/APIs/Invoice/invoiceApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CategoryForm from "./Category/CategoryForm";
import { format } from "date-fns";
import { getLogo } from "@Networking/APIs/Invoice/logo/logoGetApi";
import { postLogo } from "@Networking/APIs/Invoice/logo/logoPostApi";
import EditClientForm from "./ClientFrom/EditClient";
import EditBankForm from "./Bank/editBankForm";
import { DeleteClientStore } from "@Networking/APIs/Invoice/Client/Deleteclient";
import { toast } from "react-toastify";
import { getcurrency } from "@Networking/APIs/Background/backgorundApi";
import {
  handleInputChange,
  validateInput,
} from "@Layout/RegexValidation/regexValidation";
import { Alert, Checkbox, FormControlLabel } from "@mui/material";
import {
  button,
  componentheaderStep1,
  componentheaderStep2,
  container,
  textDiv,
} from "@Layout/Color/color";
import { Controller, set, useForm } from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getAllBank } from "@Networking/APIs/Invoice/Bank/bankGetApi";

const DueDateOptions = [
  { value: 0, label: "Today (Default)" },
  { value: 3, label: "In 3 days" },
  { value: 5, label: "In 5 days" },
  { value: 7, label: "In 7 days" },
  { value: 10, label: "In 10 days" },
  { value: 15, label: "In 15 days" },
  { value: "custom-date", label: "Pick a Custom Date" },
];
const paymentOption = [
  { value: "bank", label: "BANK TRANSFER" },
  { value: "payoneer", label: "PAYONEER" },
  { value: "add-payment-link", label: "ADD PAYONEER LINK" },
];
const templateOptions = [
  { value: "Amount", label: "Amount" },
  { value: "Hours", label: "Hours" },
  { value: "Quantity", label: "Quantity" },
];
const statusOption = [
  { value: "Paid", label: "Paid" },
  { value: "Unpaid", label: "Unpaid" },
  { value: "Partially Paid", label: "Partially Paid" },
];
const InvoiceEdit = () => {
  //Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { control } = useForm();
  const location = useLocation();
  const { invoiceId } = location.state || {};
  console.log(invoiceId, "invoiceId");
  //Redux
  const { EditData , Editloading } = useSelector((state) => state.GetInSlice);
  const { AllBankData, AllBankLoading } = useSelector(
    (state) => state.BankPostSlice
  );
  const { CategoryGet, loading } = useSelector((state) => state.CategorySlice);
  const { ClientGet } = useSelector((state) => state.clientSlice);
  const { ItemData } = useSelector((state) => state.ItemSlice);
  const { CompanyGet } = useSelector((state) => state.CompanyStoreSlice);
  const { LogoGet } = useSelector((state) => state.LogoSlice);
  const { InvoiceNumberGet } = useSelector((state) => state.GetInSlice);
  const { currencyCode } = useSelector((state) => state.BackgroundSlice);

  //States
  const [clientOptions, setClientOptions] = useState("");
  const [companyOptions, setCompanyOptions] = useState("");
  const [categoryOptions, setCaegoryOptions] = useState("");
  const [itemOptions, setItemOptions] = useState([]);
  const [bankOptions, setBankOptions] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const fileInputRef = useRef(null);
  const [showEditClientForm, setShowEditClientForm] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);
  const [showIndirectBankDetails, setShowIndirectBankDetails] = useState(false);
  const [template, setTemplate] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [DueDate, setDueDate] = useState("");
  const [options, setOptions] = useState(DueDateOptions);
  const [discount, setDiscount] = useState("");
  const [total, setTotal] = useState(0);
  const [gstType, setGstType] = useState(null);
  const [gstPercentage, setGstPercentage] = useState(0);
  const [showAddClientForm, setShowAddClientForm] = useState(false);
  const [showAddItemForm, setShowAddItemForm] = useState(false);
  const [showAddCategoryForm, setShowAddCategoryForm] = useState(false);
  const [showAddBankForm, setShowAddBankForm] = useState(false);
  const [showAddCompanyForm, setShowAddCompanyForm] = useState(false);
  const [showCustomize, setshowCustomize] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otherAmounts, setOtherAmounts] = useState([{ name: "", amount: 0 }]);
  const [showOtherAmountFields, setShowOtherAmountFields] = useState(false);
  const [showInvoicePreview, setShowInvoicePreview] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [Subtotal, setSubtotal] = useState(0);
  const [showEditBankForm, setShowEditBankForm] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isGstType, setIsGstType] = useState(false);
  const [discountType, setDiscountType] = useState("amount");
  const [productSubtotal, setProductSubtotal] = useState(0);
  const [currency, setCurrency] = useState();
  const [Client, setClient] = useState(null);
  const [Bank, setBank] = useState(null);
  const [INdirectBank, setINdirectBank] = useState(null);
  const [Payment, setPayment] = useState(null);
  const [PaymentLink, setPaymentLink] = useState(null);
  const [Company, setCompany] = useState(null);
  const [isCustomDateSelected, setIsCustomDateSelected] = useState(false);
  const [showDiscount1, setShowDiscount1] = useState(false);
  const [showDate1, setShowDate1] = useState(false);
  const [termsConditions, setTermsConditions] = useState(null);
  const [authorized, setAuthorized] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [products, setProducts] = useState([
    {
      item: null,
      Quantity: 1,
      Hours: 1,
      price: "",
      discountValue: "",
      description: "",
      showDiscount: showDiscount1,
      showDate: showDate1,
      itemLabel: "",
      category: null,
      categoryLabel: "",
    },
  ]);
  const [invoiceData, setInvoiceData] = useState({
    template: "",
    currency: 1,
    Client: null,
    products: [
      {
        item: null,
        Quantity: 0,
        Hours: 0,
        price: 0,
        showDate: "",
        discountValue: "",
        description: "",
      },
    ],
    Payment: null,
    INdirectBank: null,
    Bank: null,
    invoiceDate: "",
    DueDate: "",
    discount: 0,
    otherAmounts: [{ name: "", amount: 0 }],
    total: 0,
    termsConditions: "",
  });
  useEffect(() => {
    // setOverallLoading(true);
    dispatch(getEditData(invoiceId));
    dispatch(getItem());
    if (AllBankData?.data?.length > 0 && EditData?.bank_id) {
      // setOverallLoading(false);
    }
  }, [itemOptions, dispatch]);

  const [currencyRate, setCurrencyRate] = useState(0);
  const fetchExchangeRate = async () => {
    try {
      const response = await fetch(`https://api.frankfurter.app/latest?from=${currency.name}&to=INR`);
       const data = await response.json();
      const rate = data.rates.INR;  
      if (rate != undefined || rate != null) {
        setCurrencyRate(rate);
      }else{
        setCurrencyRate(0);
       }
     } catch (error) {
      // alert(`Failed to fetch ${currency.name} exchange rate. Please try again.`);
       setCurrencyRate(0.00);
    }
  }

   const [isGstChecked, setIsGstChecked] = useState(false);
  const [isDiscountChecked, setIsDiscountChecked] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);


  const editfunction = useCallback(async () => {
  
    setIsLoading(true);
    if (!EditData) return;
    handleTemplateChange({ value: EditData?.template, label: EditData?.template });
    const gstcheck = EditData?.gst_percent || 0;
    const discountTypecheck = EditData?.discount_percent || 0;


    if (gstcheck > 0) {
      handleGstOptionClick("withGst")
      setIsGstChecked(true);
    } else {
      handleGstOptionClick("withoutGst")
    }
    if (Array.isArray(EditData?.invoice_items)) {
      setProducts(
        EditData.invoice_items.map((item) => ({
          item: item.item_name,
          Quantity: item.quantity,          
          Hours: item.hours,
          price: item.price,
          discountValue: item.discountValue > 0 ? item.discountValue : item.discount_amount ,
          description: item.description,
          showDiscount: item.discount_amount > 0 ? true : false,
          showDate: item.date != null ? true : false,
          date: item.date,
          itemLabel: item.item_name,
          category: item.category,
          categoryLabel: item.category,
        }))
      );
      
    } else {
      setProducts([]); 
    }
   
    if (EditData?.invoice_items?.some(item => item.discount_amount > 0)) {
      setShowDiscount1(true)
    }  
    if (EditData?.invoice_items?.some(item => item.date != null)) {
      setShowDate1(true)
    }  
     
    
    if (discountTypecheck > 0) {
      handleDiscountOptionClick("percentage");
      setIsDiscountChecked(true);
    }
    
    
    if (EditData?.currency) {
      const matchingCurrencies = currencyCode
        .filter(item => item?.value === EditData?.currency);
    
      if (matchingCurrencies.length > 0) {
        handleCurrencyChange(matchingCurrencies[0]); 
      } else {
      }
    }
    
    
    // setTemplate(EditData?.template);
  

    if (EditData?.client) {
      handleClientSelect(EditData?.client);
    }

    const selectedBank = AllBankData?.data?.find((b) => b?.value === EditData?.banks?.id);
    
    // if(isVisible == true){
    //   setIsLoading(false);
    // }
    // else{
    //   setIsLoading(true);
    // }
      if(EditData?.payment_mode == "PAYONEER"){
        dispatch(getAllBank(2))
      }
      else{
        dispatch(getAllBank(1))
      }
     handlePaymentChange({ value: EditData?.payment_mode, label: EditData?.payment_mode });
     handleBankChange(selectedBank);
     setBank(selectedBank);
    setIsVisible(true);



    // setDueDate(EditData?.due_date);
    setDueDate({ value: EditData?.due_date, label: EditData?.due_date });
    // handleDateChange(EditData?.due_date);
    // handleInvoiceDateChange(new Date(EditData?.invoice_date));
    setInvoiceDate(EditData?.invoice_date);

    setDiscount(EditData?.discount_percent > 0 ? EditData?.discount_percent : EditData?.discount_amount);

    if (EditData?.terms_condition) {
      // Sanitize the terms_condition data to avoid XSS attacks
      const sanitizedTerms = DOMPurify.sanitize(EditData.terms_condition);
      setTermsConditions(sanitizedTerms);
    }
     
    setAuthorized(EditData?.authorized == 1 ? true : false);

    // Uncomment if needed:
    if (EditData?.other_amounts?.some(item => item.amount > 0)) {
       setShowOtherAmountFields(true);
      setOtherAmounts(EditData?.other_amounts);
    }

    setInvoiceData({
      template: EditData?.template,
      currency: EditData?.country?.id,
      Client: EditData?.client.id,
      products: EditData?.invoice_items?.map((item) => ({
        itemLabel: item.item_name,
        Quantity: item.quantity,          
        Hours: item.hours,
        price: item.price,
        discountValue: item.discountValue > 0 ? item.discountValue : item.discount_amount ,
        description: item.description,
        showDiscount: item.discount_amount > 0 ? true : false,
        showDate: item.date != null ? true : false,
        date: item.date,
        itemLabel: item.item_name,
        category: item.category,
        categoryLabel: item.category,
      })),
      Payment_mode: EditData?.payment_mode,
      INdirectBank: EditData?.banks?.id,
      Bank: EditData?.banks.id,
      invoiceDate: EditData?.invoice_date,
      DueDate:  EditData?.due_date,
      discount: EditData?.discount_percent > 0 ? EditData?.discount_percent : EditData?.discount_amount,
      otherAmounts: EditData?.other_amounts,
      subtotal: EditData?.subtotal,
      total: EditData?.total,
      termsConditions: EditData?.terms_condition,
    });


    handleStatusChange({ value: EditData?.status, label: EditData?.status });

    if (isVisible) {
      setIsLoading(false);
    };

  }, [EditData]);

  useEffect(() => {
    if (!isVisible) {
      setIsLoading(true);
    };
     editfunction();
  }, [editfunction]);























  //UseEffects
  useEffect(() => {
    dispatch(getItem());
  }, [itemOptions, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      if(currency?.name != "INR") {
      fetchExchangeRate();
      }
      dispatch(getInvoiceNumber());

      const response = await dispatch(getCompany());

      const response2 = await dispatch(getClient());

      const response3 = await dispatch(getCategory());

      const response4 = await dispatch(getItem());

     ;

      const Logo = await dispatch(getLogo());

       

      const response31 = await dispatch(getcurrency());

      setCompanyOptions(CompanyGet);

      
      if (response2?.payload?.data) {
        setClientOptions(response2?.payload?.data);
      }

      if (response3?.payload?.data) {
        setCaegoryOptions(response3?.payload?.data);
      }

      if (response4?.payload?.data) {
        setItemOptions(response4?.payload?.data);
      }

      
    };
    fetchData();
  }, []);

  useEffect(() => {
    const today = new Date();
    const formattedDate = `${String(today.getDate()).padStart(2, "0")}-${String(
      today.getMonth() + 1
    ).padStart(2, "0")}-${today.getFullYear()}`;

    setInvoiceDate(formattedDate);

    setProducts((prevProducts) =>
      prevProducts
        .map((product) => {
          if (product.showDate === true) {
            return { ...product, showDate: today };
          }
          return product;
        })
        .filter((product) => product.showDate !== today)
    );
  }, []);

  useEffect(() => {
    calculateTotal();
  }, [discountType]);

  useEffect(() => {
    const calculatedTotal = calculateTotal();
    setTotal(calculatedTotal);
  }, [products, discount, template, gstPercentage, otherAmounts]);

  useEffect(() => {
    const total = calculateTotal();
    setInvoiceData({
      ...invoiceData,
      total,
    });
  }, [total]);

  const handleStatusChange = (e) => {
    setPaymentStatus(e.value);
  }

  const handleSelectChange = (selectedOption) => {
    if (selectedOption.value === "custom-date") {
      setIsCustomDateSelected(true);
      setDueDate(null);
    } else {
      const daysToAdd = parseInt(selectedOption.value);
      const newDueDate = new Date(
        new Date().setDate(new Date().getDate() + daysToAdd)
      );
      const formattedDate = format(newDueDate, "dd-MM-yyyy");
      setDueDate({ value: selectedOption.value, label: formattedDate });
      setInvoiceData((prevData) => ({ ...prevData, DueDate: formattedDate }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        Duedate: null,
      }));
    }
  };
  
const  handlePaymentLinkChange = (e) => {
  console.log(e.target.value);
  setPaymentLink(e.target.value);
  setFormErrors((prevErrors) => ({
    ...prevErrors,
    PaymentLink: null,
  }));
}

  const handleDateChange = (date) => {
    if (date && !isNaN(date)) {
      const formattedDate = date.toISOString().split("T")[0];
      setDueDate(formattedDate);
      setInvoiceData({ ...invoiceData, DueDate: formattedDate });
    } else {
      setDueDate(null);
      setInvoiceData({ ...invoiceData, DueDate: "" });

      setFormErrors((prevErrors) => ({
        ...prevErrors,
        Duedate: null,
      }));
    }
  };

  const handleInvoiceDateChange = (date) => {
    const formattedDate =
      ("0" + date.getDate()).slice(-2) +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      date.getFullYear();
    setInvoiceDate(formattedDate);
  };

  const handleAddClient = (newClient) => {
    setClientOptions([...clientOptions, newClient]);
    setClient(newClient);
  };

  const handleAddItem = (newItem) => {
    setItemOptions([...itemOptions, newItem]);
    setProducts([...products, newItem]);
  };

  const handleAddBank = (newBank) => {
    setBankOptions([...bankOptions, newBank]);
    setBank(newBank);
  };

  const handleAddCompany = (newCompany) => {
    setCompanyOptions([...companyOptions, newCompany]);
    setCompany(newCompany);
  };

  const calculateTotal = () => {
    let totalAmount = 0;
    let updatedSubtotals = [];

    // Calculate total amount from products
    products.forEach((product) => {
      let price = parseFloat(product.price) || 0;
      const quantity = parseFloat(product.Quantity) || 0;
      const hours = parseFloat(product.Hours) || 0;
      const discountValue = parseFloat(product.discountValue) || 0;

      let baseAmount = 0;
      if (template === "Hours") {
        baseAmount = price * hours;
      } else if (template === "Quantity") {
        baseAmount = price * quantity;
      } else {
        baseAmount = price;
      }

      let discountAmount = 0;
      if (discountType === "percentage") {
        discountAmount = (discountValue / 100) * baseAmount;
      } else {
        discountAmount = discountValue;
      }

      const discountedAmount = baseAmount - discountAmount;

      updatedSubtotals.push({
        index: product.index,
        subtotal: discountedAmount,
      });
      totalAmount += discountedAmount;
    });
    setProductSubtotal(updatedSubtotals);
    setSubtotal(totalAmount);

    let discountTotalAmount = 0;
    if (discountType === "percentage") {
      discountTotalAmount = (discount / 100) * totalAmount;
    } else {
      discountTotalAmount = discount;
    }

    const GstAmount =
      (gstPercentage / 100) * (totalAmount - discountTotalAmount);

    let FinalAmount = totalAmount - discountTotalAmount + GstAmount;

    FinalAmount += otherAmounts.reduce(
      (total, amount) => total + (parseFloat(amount.amount) || 0),
      0
    );
     setTotal(FinalAmount);
     return FinalAmount;
  };

  const handleTemplateChange = (selectedOption) => {
    const value = selectedOption?.value;
    setTemplate(value);
    if (value === "Hours") {
      setProducts([
        {
          item: null,
          Quantity: 0,
          Hours: 1,
          price: "",
          discountValue: "",
          description: "",
          showDiscount: false,
          showDate: false,
          itemLabel: "",
          category: null,
          categoryLabel: "",
        },
      ]);
    } else if (value === "Quantity") {
      setProducts([
        {
          item: null,
          Quantity: 1,
          Hours: 0,
          price: "",
          discountValue: "",
          description: "",
          showDiscount: false,
          showDate: false,
          itemLabel: "",
          category: null,
          categoryLabel: "",
        },
      ]);
    }
    setInvoiceData((prevData) => ({ ...prevData, template: value }));
  };

  useEffect(() => {
    if (currency) {
      if(currency.name === "INR") {
        setCurrencyRate(1);
      }else{
         fetchExchangeRate();
      }
    }
  }, [currency]);

  const handleCurrencyChange = (selectedOption) => {
    const newCurrency = selectedOption;
    const sendCurrency = selectedOption.value;
    setCurrency(newCurrency);
    setInvoiceData((prevData) => ({ ...prevData, currency: sendCurrency }));
  };

  const handleClientSelect = (selectedOption) => {
    setShowDetails(true);
    if (selectedOption?.value === "add-new") {
    } else {
      setClient(selectedOption);
      setInvoiceData({ ...invoiceData, Client: selectedOption?.value });
    }
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      client: null,
    }));
  };

  const handleEditClient = (Client) => {
    setCurrentClient(Client);
    setShowEditClientForm(true);
    setShowDetails(false);
  };

  const handleCloseDetails = () => {
    setClient(null);
    setShowDetails(false);
  };



  const handleConfirmDelete = async (id) => {

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Client Detials?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    });

     if (result.isConfirmed) {
      setIsLoading(true);
      const res = await dispatch(DeleteClientStore(id));
      if (res.payload.status == true) {
        setClient("");
        setShowDetails(false);
        await dispatch(getClient());
        setIsLoading(false);
      } else {
        setIsLoading(false);
        toast.error(res.payload.message); 
      }
    } else {
    }
  };

  const handleBankChange = (selectedOption) => {
    setIsVisible(true);
      setBank(selectedOption);
      setInvoiceData({ ...invoiceData, Bank: selectedOption?.value });
    
  };

  // const handleINBankChange = (selectedOption) => {
  //     setShowIndirectBankDetails(true);
  //     setINdirectBank(selectedOption);
  //     setInvoiceData({ ...invoiceData, INdirectBank: selectedOption });
    
  // };



  const handlePaymentChange = (selectedOption) => {
    if (selectedOption.value === "payoneer") {
      setIsVisible(false);
      dispatch(getAllBank(2));
    } else {
      // setIsVisible(false);
      setShowIndirectBankDetails(false);
      dispatch(getAllBank(1));
    }
    setBank(null);
    setPayment(selectedOption);
    setInvoiceData({ ...invoiceData, Payment_mode: selectedOption.value });


    setFormErrors((prevErrors) => ({
      ...prevErrors,
      Payment: null,
    }));
  };

  const bankModel = () => {
    setShowIndirectBankDetails(false);
    setIsVisible(false);
    setShowAddBankForm(true);
  };

  const companyModel = () => {
    dispatch(getCompany());
    setShowAddCompanyForm(true);
  };

  const handleProductChange = (index, field, value) => {
    const newProducts = [...products];
    newProducts[index][field] = value;
    if (field === "item" && value) {
      const selectedItem = ItemData?.data?.find((item) => item.value === value);
      if (selectedItem) {
        newProducts[index].price =
          selectedItem.price || newProducts[index].price;
        newProducts[index].description =
          selectedItem.description || newProducts[index].description;
      }
    }
    setProducts(newProducts);
    setInvoiceData({ ...invoiceData, products: newProducts });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [`item-${index}`]: null,
      [`category-${index}`]: null,
      [`price-${index}`]: null,
      [`description-${index}`]: null,
    }));
  };

  const ItemModel = () => {
    setShowAddItemForm(true);
  };

  const CategoryModel = () => {
    setShowAddCategoryForm(true);
  };
  const handleCategoryDelete = async (id) => {

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Category Detials?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    });
  
     if (result.isConfirmed) {
      const res = await dispatch(deleteCategory(id));
      dispatch(getCategory());

      if (res.payload.status == true) {
        toast.success(res.payload.message);
      }
    } else {
    }
  };

  const addProduct = () => {
    setProducts([
      ...products,
      {
        item: null,
        Quantity: 0,
        Hours: 0,
        price: "",
        description: "",
        showDiscount: showDiscount1,
        showDate: showDate1,
        itemLabel: "",
        category: null,
        categoryLabel: "",
      },
    ]);
  };

  const removeProduct = (index) => {
    if (products.length > 1) {
      setProducts(products.filter((_, i) => i !== index));
      setInvoiceData({
        ...invoiceData,
        products: products.filter((_, i) => i !== index),
      });
    }
  };

  const handleCheckboxChange = (field) => {
    if (field == "showDiscount") {
      if (showDiscount1 === true) {
        setShowDiscount1(false);
      } else {
        setShowDiscount1(true);
      }
    } else {
      if (showDate1 === true) {
        setShowDate1(false);
      } else {
        setShowDate1(true);
      }
    }
    const updatedProducts = products.map((product, i) => {
      return {
        ...product,
        [field]: !product[field],
        discountValue: product.showDiscount ? "" : product.discountValue,
      };
    });
    setProducts(updatedProducts);
  };

  const handleGstPercentageChange = (e) => {
    const percentage = parseFloat(e.target.value) || 0;
    setGstPercentage(percentage);
    setInvoiceData({ ...invoiceData, gstPercentage: percentage });
  };

  const handleOtherAmountChange = (index, field, newValue) => {
    if (field === "name") {
      if (!validateInput(newValue)) {
        alert("Invalid input for name: Numbers are not allowed.");
        return;
      }
    }
    const newOtherAmounts = [...otherAmounts];
    newOtherAmounts[index][field] = newValue;
    setOtherAmounts(newOtherAmounts);
    setInvoiceData({ ...invoiceData, otherAmounts: newOtherAmounts });
  };

  const addOtherAmount = () => {
    setOtherAmounts([...otherAmounts, { name: "", amount: "" }]);
    setInvoiceData({
      ...invoiceData,
      otherAmounts: [...otherAmounts, { name: "", amount: 0 }],
    });
  };

  const removeOtherAmount = (index) => {
    if (otherAmounts.length > 1) {
      setOtherAmounts(otherAmounts.filter((_, i) => i !== index));
      setInvoiceData({
        ...invoiceData,
        otherAmounts: otherAmounts.filter((_, i) => i !== index),
      });
    }
  };

  const toggleOtherAmountFields = (field) => {
    const updatedProducts = otherAmounts.map((product, i) => {
      return {
        ...product,
        [field]: !product[field],
        amount: showOtherAmountFields ? 0 : otherAmounts.amount,
        name: showOtherAmountFields ? "" : otherAmounts.name,
      };
    });
    setOtherAmounts(updatedProducts);
    setShowOtherAmountFields(!showOtherAmountFields);
  };

  const simulateLoading = async () => {
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsLoading(false);
  };

  const validateForm = () => {
    const errors = {};

    if (!Client) {
      errors.client = "Please select a client.";
    }
    if (!Payment) {
      errors.Payment = "Please select a Payment Mode";
    }
    if (!Bank) {
      errors.bank = "Please select a Payment Mode.";
    }
    // if (!Client) {
    //   errors.Payment = "Please select a payment.";
    // }
    if (termsConditions === "") {
      errors[`terms-conditions`] = "Please enter Terms & Conditions.";
    }
    if (!DueDate) {
      errors.Duedate = "Please select a Due Date.";
    }

    products.forEach((product, index) => {
      if (!product.item) {
        errors[`item-${index}`] = "Please select an item.";
      }
      if (!product.category) {
        errors[`category-${index}`] = "Please select a category.";
      }
      if (template === "Hours" || template === "Quantity") {
        if (!product.Hours && template === "Hours") {
          errors[`Hours-${index}`] = "Please enter the Hours.";
        }
        if (!product.Quantity && template === "Quantity") {
          errors[`Quantity-${index}`] = "Please enter the Quantity.";
        }
        if (!product.price) {
          errors[`price-${index}`] = "Please enter the amount.";
        }
      } else {
        if (!product.price) {
          errors[`price-${index}`] = "Please enter the amount.";
        }
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleGstOptionClick = (type) => {
 
  if (type == "withGst") {
    handleCurrencyChange({
      value: 101,
      label: "INR-₹",
      currencyName: "Indian rupee",
      logo: "₹",
      name: "INR",
    });
    setCurrencyRate(1);
    setGstPercentage(18);
    setIsGstType(true);
    setIsDisabled(true);
    setShowInvoicePreview(false);
    setIsGstChecked(true);
   } else {
    handleCurrencyChange({
      value: 1,
      label: "USD-$",
      currencyName: "US Dollar",
      logo: "$",
      name: "USD",
    });
    setGstPercentage(0);
    setIsDisabled(false);
    setIsGstType(false);
    setShowInvoicePreview(false);
    setIsGstChecked(false);
   };

  } 


  

  const handleDiscountOptionClick = (type) => {
    if (type == "percentage") {
      setIsDiscountChecked(true);
      setDiscountType("percentage");
    } else {
      setIsDiscountChecked(false);
      setDiscountType("amount");
    }
  };

  const handleFileChange = (event) => {
    if (event) {
      dispatch(postLogo(event.target.files)).then((response) => {
        if (response) {
          dispatch(getLogo());
        }
      });
    }
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  // Custom upload adapter
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file
            .then((file) => {
              // Simulate an upload process
              const reader = new FileReader();
              reader.onload = () => {
                resolve({ default: reader.result }); // Use base64 or replace with actual upload URL
              };
              reader.onerror = reject;
              reader.readAsDataURL(file);
            })
            .catch(reject);
        });
      },
    };
  }

  // Plugin to include the custom upload adapter
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const handleChange = (event) => {
    setAuthorized(event.target.checked);
  };

  const handleSubmit = (e) => {

    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);
      const today = new Date();
      const formattedDate = `${String(today.getDate()).padStart(
        2,
        "0"
      )}-${String(today.getMonth() + 1).padStart(
        2,
        "0"
      )}-${today.getFullYear()}`;
      const total = calculateTotal();
      const finalInvoiceData = {
        ...invoiceData,
        total,
        invoiceDate: invoiceDate || formattedDate,
        currencyRate: currencyRate,
        termsConditions: termsConditions,
        authorized: authorized,
        discountType: discountType,
        gstType: gstType,
        gstPercentage: gstPercentage,
        subtotal: Subtotal,
        invoiceId,
      };

      dispatch(EditInvoice(finalInvoiceData))
        .then((response) => {
          if (response.payload && response.payload.status === true) {
            dispatch(getallinvoices());
            navigate("/InvoicesList");
            setIsLoading(false);
          } else {
            toast.error(response.payload.response.data.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          toast.error("An error occurred while storing the invoice.");
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="container-fluid">
    <div className="mb-2">
      <div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="">
            <i class="fa-solid fa-list me-1"></i> Finance/ Invoice / Edit Invoice
            </h6>
          </div>
      </div>


      <div className="card bg-gradient-info rounded-top-2 p-2 mb-3">
        <div className="row d-flex align-items-center">
          <div className="col-md-6 col-sm-6">
            <h3 className="page-title m-0  text-light">
              <span className="mx-2 text-light">
              <i className="mdi mdi-file  text-light me-1"></i>
                Invoice No. {EditData ? EditData?.invoice_number : null}
              </span>
            </h3>
          </div>
          <div className="col-md-6 col-sm-6 text-end">
            <h5 className="page-title m-0  text-light">
              <span className="mx-2 text-primary">
              </span>
             Edit Invoice 
              </h5>
          </div>
        </div>
      </div>

         <div className="row">
          <div className="col-md-8">
            <div className="card mb-1">
              <div className="card-body">
                <div className="d-flex g-3">
                  <div className="col-lg-6 col-md-6  justify-content-between">
                    <div className="d-flex col-lg-12 justify-content-start pb-2">
                      <div>
                        <label className="business-name">GST:</label>
                        <label className="business-name mx-2">With:</label>
                        <input
                          type="radio"
                          name="gstOption"
                          onClick={() => handleGstOptionClick("withGst")}
                          checked={isGstChecked}
                        />
                      </div>
                      <div>
                        <label className="business-name mx-2">Without:</label>
                        <input
                          type="radio"
                          name="gstOption"
                          onClick={() => handleGstOptionClick("withoutGst")}
                          defaultChecked
                        />
                      </div>
                    </div>
                    <div className="d-flex col-lg-12 justify-content-start pt-2">
                      <div>
                        <label className="business-name">Discount:</label>
                        <label className="business-name mx-2">(in %):</label>
                        <input
                          type="radio"
                          name="discountOption"
                          onClick={() =>
                            handleDiscountOptionClick("percentage")
                          }
                          checked={isDiscountChecked}
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <label className="business-name mx-1">
                          (in {currency?.logo}):
                        </label>
                        <input
                          type="radio"
                          name="discountOption"
                          onClick={() => handleDiscountOptionClick("amount")}
                          defaultChecked
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="row">
                      <div className="col-12 col-md-5 m-0 p-0">
                        <label
                          htmlFor="template-select"
                          className="business-name"
                        >
                          Template:
                        </label>
                        <Select
                          id="template-select"
                          options={templateOptions}
                          placeholder={template ? template : "Template"}
                          value={template}
                          onChange={handleTemplateChange}
                          isSearchable={false}
                          className="select-template"
                        />
                      </div>
                      <div className="col-12 col-md-4 m-0 p-0">
                        <label
                          htmlFor="currency-select"
                          className="business-name"
                        >
                          Currency:
                        </label>
                        <Select
                          id="currency-select"
                          options={currencyCode}
                          placeholder={currency ? currency.label : "Currency"}
                          value={currency?.value}
                          onChange={handleCurrencyChange}
                          isSearchable={false}
                          className="select-client"
                          isDisabled={isDisabled}
                        />
                      </div>
                      <div className="col-12 col-md-3 m-0 p-0">
                         <label
                          htmlFor="currency-select"
                          className="business-name"
                        >
                          Ex. Rate:
                        </label>
                         <input
                         className="form-control my-1"
                          type="number"
                          name="currency_rate"
                          id="currency_rate"
                          value={currencyRate}
                          onChange={setCurrencyRate}
                          />
                       </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card shadow">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group1">
                      <label htmlFor="client-select" className="business-name">
                        Invoice To:
                      </label>
                      <div className="select-client-container form-row">
                        <div className="col-12">
                          {!showDetails ? (
                            <Select
                              id="client-select"
                              onInputChange={handleInputChange}
                              options={[
                                {
                                  value: "add-new",
                                  label: (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <IoMdPersonAdd className="add-client-icon Icon-Style " />
                                      <strong
                                        style={{
                                          marginRight: "8px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        Add New Client
                                      </strong>
                                    </div>
                                  ),
                                },
                                ...(ClientGet?.data?.map((client) => ({
                                  value: client,
                                  label: client.label,
                                })) || []),
                              ]}
                              placeholder="Select Client"
                              value={Client}
                              onChange={(selectedOption) => {
                                if (selectedOption.value === "add-new") {
                                  setShowAddClientForm(true);
                                } else {
                                  handleClientSelect(selectedOption.value);
                                }
                              }}
                              isSearchable
                              className="select-client"
                            />
                          ) : (
                            <div className="client-details p-3 border rounded bg-light d-flex justify-content-between align-items-start">
                              <div>
                                <h5 className="text-primary fs-6">
                                  Client Details:
                                </h5>
                                <p className="mb-1 fs-7">
                                  <strong>Name:</strong>{" "}
                                  <span className="text-dark">
                                    {Client.label}
                                  </span>
                                </p>
                                <p className="mb-0 fs-7">
                                  <strong>Address:</strong>{" "}
                                  <span className="text-dark">
                                    {Client.address}
                                  </span>
                                </p>
                                {isGstType ? (
                                  <p className="mb-1 fs-7">
                                    <strong>Gst:</strong>{" "}
                                    <span className="text-dark">
                                      {Client.gst}
                                    </span>
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex  align-items-end">
                                <button
                                  className="btn btn-link p-0"
                                  onClick={() => handleEditClient()}
                                >
                                  <IoMdCreate
                                    className="text-primary"
                                    size={24}
                                  />
                                </button>
                                <button
                                  className="btn btn-link p-0 ml-2"
                                  onClick={() =>
                                    handleConfirmDelete(Client.value)
                                  }
                                >
                                  <IoMdTrash
                                    className="text-danger"
                                    size={24}
                                  />
                                </button>

                                <button
                                  className="btn btn-link p-0"
                                  onClick={handleCloseDetails}
                                >
                                  <IoMdClose
                                    className="text-danger"
                                    size={24}
                                  />
                                </button>
                              </div>
                            </div>
                          )}
                          {formErrors.client && (
                            <div className="text-danger mt-1">
                              {formErrors.client}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 {showAddClientForm && (
                  <ClientForm
                    clientOptions={clientOptions}
                    setClientOptions={setClientOptions}
                    Client={Client}
                    isGstType={isGstType}
                    setClient={setClient}
                    onClose={() => setShowAddClientForm(false)}
                    onAddClient={handleAddClient}
                  />
                )}

                {showEditClientForm && (
                  <EditClientForm
                    clientOptions={clientOptions}
                    setClientOptions={setClientOptions}
                    currentClient={Client}
                    onClose={() => setShowEditClientForm(false)}
                  />
                )}

                <div className="d-flex justify-content-end mx-2">
                  <a
                    className="text-primary"
                    onClick={() => setshowCustomize(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className="fas fa-edit"
                      style={{ marginRight: "5px" }}
                    ></i>{" "}
                    customize
                  </a>
                </div>
                {products.map((product, index) => (
                  <div className="form-group">
                    {showCustomize ? (
                      <div>
                        <div
                          className="modal fade show d-block"
                          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">
                                  Customize Options
                                </h5>
                                <Link
                                  type="button"
                                  className="close fs-4"
                                  onClick={() => setshowCustomize(false)}
                                >
                                  &times;
                                </Link>
                              </div>
                              <div className="modal-body">
                                <div className="form-row">
                                  <div className="col-md-6 d-flex justify-content-center align-items-center">
                                    <label className="text-dark mb-0 me-2">
                                      Discount:
                                    </label>
                                    <input
                                      type="checkbox"
                                      checked={product.showDiscount}
                                      onChange={(e) =>
                                        handleCheckboxChange("showDiscount")
                                      }
                                    />
                                  </div>
                                  <div className="col-md-6 d-flex justify-content-center align-items-center">
                                    <label className="text-dark mb-0 me-2">
                                      Date:
                                    </label>
                                    <input
                                      type="checkbox"
                                      checked={product.showDate}
                                      onChange={() =>
                                        handleCheckboxChange("showDate")
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}

                    <div key={index} className="product-row p-2 mt-2">
                      <div className="row d-flex flex-row">
                        <div className="col-md-4 pt-1 m-0">
                          <label
                            htmlFor={`item-select-${index}`}
                            className="text-dark"
                          >
                            Product:
                          </label>
                          <div className="select-client-container form-row">
                            <div className="col-md-12">
                              <CreatableSelect
                                id={`item-select-${index}`}
                                options={[
                                  {
                                    value: "addItem",
                                    label: (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <IoMdPersonAdd className="add-client-icon Icon-Style" />
                                        <strong
                                          style={{
                                            marginRight: "8px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          Add Product
                                        </strong>
                                      </div>
                                    ),
                                  },
                                  ...(ItemData.data?.map((item) => ({
                                    value: item.value,
                                    label: item.label,
                                  })) || []),
                                ]}
                                placeholder={product.itemLabel || "Select"}
                                value={product.item}
                                onChange={(selectedOption) => {
                                  if (selectedOption.value === "addItem") {
                                    ItemModel();
                                  } else {
                                    handleProductChange(
                                      index,
                                      "item",
                                      selectedOption.value
                                    );
                                    handleProductChange(
                                      index,
                                      "itemLabel",
                                      selectedOption.label
                                    );
                                  }
                                }}
                                isSearchable
                                className="select-item"
                              />

                              {formErrors[`item-${index}`] && (
                                <div className="text-danger">
                                  {formErrors[`item-${index}`]}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 pt-1 m-0">
                          <label
                            htmlFor={`category-select-${index}`}
                            className="text-dark"
                          >
                            Internal Cty:
                          </label>
                          <div className="select-client-container form-row">
                            <div className="col-md-12">
                              <Select
                                id={`category-select-${index}`}
                                onInputChange={handleInputChange}
                                options={[
                                  {
                                    value: "addCategory",
                                    label: (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <IoMdPersonAdd className="add-client-icon Icon-Style" />
                                        <strong
                                          style={{
                                            marginRight: "8px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          Add Category
                                        </strong>
                                      </div>
                                    ),
                                  },
                                  ...(CategoryGet?.data?.map((category) => ({
                                    value: category.value,
                                    plainLabel: category.label,
                                    label: (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        className="d-flex justify-content-between"
                                      >
                                        <span style={{ marginLeft: "10px" }}>
                                          {category.label}{" "}
                                        </span>
                                        <IoMdTrash
                                          className="text-danger"
                                          size={24}
                                          onClick={() =>
                                            handleCategoryDelete(category.value)
                                          }
                                        />
                                      </div>
                                    ),
                                  })) || []),
                                ]}
                                placeholder="Select"
                                value={
                                  product.category
                                    ? {
                                        value: product.category,
                                        label: product.categoryLabel,
                                      }
                                    : null
                                }
                                onChange={(selectedOption) => {
                                  if (selectedOption.value === "addCategory") {
                                    CategoryModel();
                                  } else {
                                    handleProductChange(
                                      index,
                                      "category",
                                      selectedOption.value
                                    );
                                    handleProductChange(
                                      index,
                                      "categoryLabel",
                                      selectedOption.plainLabel ||
                                        selectedOption.label
                                    );
                                  }
                                }}
                                isSearchable
                                className="select-item"
                              />

                              {formErrors[`category-${index}`] && (
                                <div className="text-danger">
                                  {formErrors[`category-${index}`]}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {template === "Hours" || template === "Quantity" ? (
                          <>
                            <div className="col-2 pt-1 m-0">
                              <label
                                htmlFor={`Quantity-Hours-${index}`}
                                className="text-dark"
                              >
                                {template === "Quantity" ? "Qty" : "Hours:"}
                              </label>

                              <input
                                id={`Quantity-Hours-${index}`}
                                className="form-control form-control1 mt-2"
                                style={{ width: "100%" }}
                                type="number"
                                placeholder={
                                  template === "Hours" ? "Hours" : "Quantity"
                                }
                                value={
                                  template === "Hours"
                                    ? product.Hours
                                    : product.Quantity
                                }
                                onChange={(e) =>
                                  handleProductChange(
                                    index,
                                    template === "Hours" ? "Hours" : "Quantity",
                                    e.target.value
                                  )
                                }
                              />
                              {formErrors[`Hours-${index}`] && (
                                <div className="text-danger">
                                  {formErrors[`Hours-${index}`]}
                                </div>
                              )}
                              {formErrors[`Quantity-${index}`] && (
                                <div className="text-danger">
                                  {formErrors[`Quantity-${index}`]}
                                </div>
                              )}
                            </div>
                            {template == "Hours" ? (
                              <div className="col-md-2 pt-1">
                                <label
                                  htmlFor={`price-${index}`}
                                  className="text-dark "
                                >
                                  Rate({currency.logo}):
                                </label>
                                <input
                                  id={`price-${index}`}
                                  type="text"
                                  placeholder="Rate"
                                  style={{ width: "100%" }}
                                  value={product.price}
                                  onChange={(e) =>
                                    handleProductChange(
                                      index,
                                      "price",
                                      e.target.value.replace(/[^0-9.]/g, "")
                                    )
                                  }
                                  className="form-control form-control1 mt-2"
                                />
                                {formErrors[`price-${index}`] && (
                                  <div className="text-danger">
                                    {formErrors[`price-${index}`]}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="col-md-2 pt-1 ">
                                <label
                                  htmlFor={`price-${index}`}
                                  className="text-dark "
                                >
                                  Price({currency.logo}):
                                </label>
                                <input
                                  id={`price-${index}`}
                                  type="text"
                                  placeholder="Price"
                                  style={{ width: "100%" }}
                                  value={product.price}
                                  onChange={(e) =>
                                    handleProductChange(
                                      index,
                                      "price",
                                      e.target.value.replace(/[^0-9.]/g, "")
                                    )
                                  }
                                  className="form-control form-control1 mt-2"
                                />
                                {formErrors[`price-${index}`] && (
                                  <div className="text-danger">
                                    {formErrors[`price-${index}`]}
                                  </div>
                                )}
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="col-md-4 pt-1 m-0">
                            <label
                              htmlFor={`price-${index}`}
                              className="text-dark"
                            >
                              Amount({currency?.logo}):
                            </label>
                            <input
                              id={`price-${index}`}
                              type="text"
                              placeholder="Amount"
                              style={{ width: "100%" }}
                              value={product.price}
                              onChange={(e) =>
                                handleProductChange(
                                  index,
                                  "price",
                                  e.target.value.replace(/[^0-9.]/g, "")
                                )
                              }
                              className="form-control form-control1 mt-2"
                            />
                            {formErrors[`price-${index}`] && (
                              <div className="text-danger">
                                {formErrors[`price-${index}`]}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      {(product.showDiscount || product.showDate) && (
                        <div className="form-row justify-content-between">
                          {product.showDiscount && (
                            <div className="col-5 form-control1 my-2">
                              <label
                                htmlFor={`discount`}
                                className="bussiness-name"
                              >
                                Discount(
                                {discountType === "percentage"
                                  ? "%"
                                  : currency.logo}
                                ):
                              </label>
                              <input
                                      id="discount"
                                      type="text"
                                      placeholder="Discount Value"
                                      style={{ width: "100%" }}
                                      className="form-control form-control1 mt-2"
                                      value={product.discountValue}
                                      onChange={(e) => {
                                        let value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                                        if (value === "" || value === "-") {
                                          // Handle case when input is completely cleared
                                          handleProductChange(index, "discountValue", "");
                                          return;
                                        }

                                        if (Subtotal && Subtotal > 0) {
                                          if (discountType === "percentage") {
                                            const numericValue = parseFloat(value);
                                            if (!isNaN(numericValue)) {
                                              if (numericValue < 0 || numericValue > 99) {
                                                alert("Discount percentage must be between 0 and 99. Please choose a valid discount type.");
                                              } else {
                                                handleProductChange(index, "discountValue", numericValue);
                                              }
                                            }
                                          } else {
                                            if (parseFloat(value) <= 0 || parseFloat(value) > (Subtotal - 1)) {
                                              alert("Discount amount cannot be greater than the total.");
                                            } else {
                                             handleProductChange(index, "discountValue", value);
                                            }
                                          }
                                        }
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.key === "Backspace" && product.discountValue.length === 1) {
                                          // If backspace is pressed and only one character exists, clear the input
                                          handleProductChange(index, "discountValue", "");
                                        }
                                      }}
                                    />

                            </div>
                          )}
                          {product.showDate && (
                            <div className="col-5 form-control1 my-2">
                              <label htmlFor={`date`}>Date:</label>
                              <input
                                id={`date`}
                                type="date"
                                placeholder="Date"
                                className="form-control form-control1 mt-2"
                                style={{ width: "100%" }}
                                pattern="\d{2}-\d{2}-\d{4}"
                                value={product.date}
                                onChange={(e) =>
                                  handleProductChange(
                                    index,
                                    "date",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}
                      <div className="row">
                        <div className="col-md-12 px-3 pt-1 form-group1 text-dark form-control1">
                          <label
                            htmlFor={`description-${index}`}
                            className="business-name"
                          >
                            Description:
                          </label>
                          <Controller
                            name="description"
                            control={control}
                            rules={{ required: "Description is required" }}
                            render={({ field }) => (
                              <CKEditor
                                editor={ClassicEditor}
                                data={product.description || ""}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  handleProductChange(
                                    index,
                                    "description",
                                    data
                                  );
                                }}
                                config={{
                                  extraPlugins: [uploadPlugin],
                                  toolbar: [
                                    "heading",
                                    "|",
                                    "bold",
                                    "italic",
                                    "link",
                                    "bulletedList",
                                    "numberedList",
                                    "blockQuote",
                                    "|",
                                    "insertTable",
                                    "tableColumn",
                                    "tableRow",
                                    "mergeTableCells",
                                    "|",
                                    "imageUpload",
                                    "mediaEmbed",
                                    "|",
                                    "undo",
                                    "redo",
                                    "|",
                                    "imageTextAlternative",
                                    "imageStyle:full",
                                    "imageStyle:side",
                                    "imageRemove", // Add this for image removal
                                  ],
                                  image: {
                                    toolbar: [
                                      "imageStyle:full",
                                      "imageStyle:side",
                                      "|",
                                      "imageTextAlternative",
                                      "imageRemove",
                                      "imageUpload",
                                      "imageDelete",
                                    ],
                                  },
                                }}
                              />
                            )}
                          />
                          {formErrors[`description-${index}`] && (
                            <div className="text-danger">
                              {formErrors[`description-${index}`]}
                            </div>
                          )}
                        </div>
                        <strong className="business-name text-end">
                          Subtotal : {productSubtotal[index]?.subtotal}
                          {/* {product && product.price
                            ? product.discountValue && product.discountValue > 0
                              ? (
                                  (product.price -
                                    (discountType === "percentage"
                                      ? (product.price *
                                          product.discountValue) /
                                        100
                                      : product.discountValue)) *
                                  (product.Hours
                                    ? product.Hours
                                    : product.Quantity
                                    ? product.Quantity
                                    : 1)
                                ).toFixed(2)
                              : (
                                  product.price *
                                  (product.Hours
                                    ? product.Hours
                                    : product.Quantity
                                    ? product.Quantity
                                    : 1)
                                ).toFixed(2)
                            : "0.00"} */}
                        </strong>
                      </div>

                      {index !== 0 && (
                        <div className="form-group">
                          <button
                            className="remove-product-button"
                            onClick={() => removeProduct(index)}
                            style={{
                              background: "transparent",
                              border: "none",
                              cursor: "pointer",
                            }}
                          >
                            <IoMdClose className="text-danger" size={24} />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}

                <div className="row">
                  <div className="col-md-12">
                    <Link className="" onClick={addProduct}>
                      <i                                                                                                                                                                                                       
                        className="fas fa-plus"
                        style={{ marginRight: "5px" }}
                      ></i>{" "}
                      Add Product
                    </Link>
                  </div>
                </div>
                <div className="mt-2 mb-2">
                  <div className="col-md-12 form-control1">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <label
                        htmlFor="terms-conditions"
                        className="business-name"
                      >
                        Terms & Conditions:
                      </label>
                      <FormControlLabel
                        control={
                          <Checkbox
                          authorized={authorized}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "controlled" }}
                            checked={authorized}
                          />
                        }
                        label="Authorized"
                      />
                    </div>

                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <CKEditor
                          editor={ClassicEditor}
                          data={termsConditions}
                           onChange={(event, editor) => {
                            const data = editor.getData();
                            setTermsConditions(data);
                          }}
                          config={{
                            extraPlugins: [uploadPlugin],
                            toolbar: [
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "link",
                              "bulletedList",
                              "numberedList",
                              "blockQuote",
                              "|",
                              "insertTable",
                              "tableColumn",
                              "tableRow",
                              "mergeTableCells",
                              "|",
                              "imageUpload",
                              "mediaEmbed",
                              "|",
                              "undo",
                              "redo",
                              "|",
                              "imageTextAlternative",
                              "imageStyle:full",
                              "imageStyle:side",
                              "imageRemove",
                            ],
                            image: {
                              toolbar: [
                                "imageStyle:full",
                                "imageStyle:side",
                                "|",
                                "imageTextAlternative",
                                "imageRemove",
                                "imageUpload",
                                "imageDelete",
                              ],
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div>
              <div className="card mb-1 shadow">
                <div className="card-body">
                  <div className="business-card">
                    <div className="logo" style={{ cursor: "pointer" }}>
                      <div className="actions">
                        {LogoGet?.logo ? (
                          <img
                            src={`https://developbo.techsimba.in/management/public/logos/${LogoGet?.logo}`}
                            alt="Logo"
                            style={{
                              width: "70px",
                              height: "50px",
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <span>No logo available</span>
                        )}
                      </div>
                       <div className="business-name1 text-end mx-2">
                        {CompanyGet?.company?.company_name}
                       </div>
                      <div>
                        {!isOpen ? (
                          <HiChevronDoubleDown onClick={toggleAccordion} />
                        ) : (
                          <HiChevronDoubleUp onClick={toggleAccordion} />
                        )}
                      </div>
                    </div>
                    {isOpen && (
                      <div className="details">
                        <div className="address text-dark form-row">
                          <div className="col-md-4">
                            <i className="fas fa-map-marker-alt m-2"></i>
                          </div>
                          <div className="col-md-8 fs-6">
                         <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(CompanyGet?.company?.company_address) }} /> 
                            </div>
                        </div>
                        <div className="contact">
                          <div className="email">
                            <div className="col-md-4">
                              <i className="fas fa-envelope m-2"></i>
                            </div>
                            <div className="col-md-8 fs-6">
                              <span>{CompanyGet?.company?.company_email}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="actions">
                      <div className="text-start">
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            fileInputRef.current?.click();
                          }}
                        >
                          Add logo
                        </a>

                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          accept="image/*"
                          onChange={handleFileChange}
                        />
                      </div>
                      <div className="text-center">
                        <a href="#" onClick={companyModel}>
                          Edit information
                        </a>
                      </div>
                    </div>
                  </div>
                  {showAddCompanyForm && (
                    <CompanyForm
                      companyOptions={companyOptions}
                      setCompanyOptions={setCompanyOptions}
                      Company={Company}
                      setCompany={setCompany}
                      onClose={() => setShowAddCompanyForm(false)}
                      onAddCompany={handleAddCompany}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="card mb-1 shadow">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-md-12">
                    <div className="form-group1">
                      <label htmlFor="bank-select" className="business-name">
                        Payment Mode:
                      </label>
                      <div className="select-client-container form-row">
                        <div className="col-12">
                          <Select
                            id="PaymentMode-select"
                            options={paymentOption}
                            onInputChange={handleInputChange}
                            placeholder="Select Mode"
                            value={Payment}
                            onChange={handlePaymentChange}
                            isSearchable
                            className="select-client"
                          />
                          {formErrors.Payment && (
                            <div className="text-danger">
                              {formErrors.Payment}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {Payment && (Payment.value === "PAYONEER" || Payment.value === "BANK TRANSFER") ? (
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group1">
                        <label htmlFor="bank-select" className="business-name">
                          Select Bank:
                        </label>
                        <div className="select-client-container form-row">
                          <div className="col-12">
                            <Select
                              id="client-select"
                              options={[
                                ...(AllBankData?.data?.map((bank) => ({
                                  value: bank,
                                  label: bank.label,
                                })) || []),
                              ]}
                              placeholder="Select Bank"
                              value={Bank}
                              onChange={(selectedOption) => {
                                if (selectedOption.value === "add-new") {
                                  bankModel();
                                } else {
                                  handleBankChange(selectedOption.value);
                                }
                              }}
                              isSearchable
                              className="select-client"
                            />

                            {formErrors.bank && (
                              <div className="text-danger">
                                {formErrors.bank}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                  {Payment && Payment.value === "add-payment-link" ? (
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group1">
                         <div className="select-client-container form-row">
                          <div className="col-12 ">
                            <textarea
                              type="text"
                              onInputChange={handlePaymentLinkChange}
                              className="form-control form-control1"
                              placeholder="Add Payment Link"
                              value={PaymentLink}
                              name="PaymentLink"
                              onChange={(e) => handlePaymentLinkChange(e)}
                            ></textarea>
                             {formErrors.PaymentLink && (
                              <div className="text-danger">
                                {formErrors.PaymentLink}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}


                {Bank && isVisible && (
                  <div className="client-details p-3 border rounded bg-light d-flex justify-content-between align-items-start mt-3">
                    <div>
                      <h5 className="text-primary fs-6">Bank Details:</h5>
                      <p className="mb-1 fs-7">
                        <strong>Bank Name:</strong>{" "}
                        <span className="text-dark">
                          {Bank.label || "No additional info available."}
                        </span>
                      </p>
                      <p className="mb-1 fs-7">
                        <strong>Bank Description:</strong>{" "}
                        <span className="text-dark"  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Bank?.bank_description || "No additional info available.") }}>
                         </span>
                      </p>
                     </div>
                  </div>
                )}
                </div>
            </div>
            {/* <div className="card mb-1 shadow">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-md-12">
                    <div className="form-group1">
                      <label htmlFor="bank-select" className="business-name">
                        Payment Mode:
                      </label>
                      <div className="select-client-container form-row">
                        <div className="col-12">
                          <Select
                            id="PaymentMode-select"
                            options={paymentOption}
                            onInputChange={handleInputChange}
                            placeholder="Select Mode"
                            value={Payment}
                            onChange={handlePaymentChange}
                            isSearchable
                            className="select-client"
                          />
                          {formErrors.bank && (
                            <div className="text-danger">
                              {formErrors.bank}
                            </div>
                          )}
                           
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {Payment ? (
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group1">
                        <label htmlFor="bank-select" className="business-name">
                          Select Bank:
                        </label>
                        <div className="select-client-container form-row">
                          <div className="col-12">
                            <Select
                              id="client-select"
                              options={[
                                ...(AllBankData?.data?.map((bank) => ({
                                  value: bank,
                                  label: bank.label,
                                })) || []),
                              ]}
                              placeholder="Select Bank"
                              value={Bank}
                              onChange={(selectedOption) => {
                                if (selectedOption.value === "add-new") {
                                  bankModel();
                                } else {
                                  handleBankChange(selectedOption.value);
                                }
                              }}
                              isSearchable
                              className="select-client"
                            />
                             {formErrors.bank && (
                              <div className="text-danger">
                                {formErrors.bank}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {Bank && isVisible && (
                  <div className="client-details p-3 border rounded bg-light d-flex justify-content-between align-items-start mt-3">
                    <div>
                      <h5 className="text-primary fs-6">Bank Details:</h5>
                      <p className="mb-1 fs-7">
                        <strong>Bank:</strong>{" "}
                        <span className="text-dark">
                          {Bank.label || "No additional info available."}
                        </span>
                      </p>
                      <p className="mb-1 fs-7">
                        <strong>Branch:</strong>{" "}
                        <span className="text-dark">
                          {Bank?.bank_branch || "No additional info available."}
                        </span>
                      </p>
                      <p className="mb-1 fs-7">
                        <strong>Account Number:</strong>{" "}
                        <span className="text-dark">
                          {Bank.account_number ||
                            "No additional info available."}
                        </span>
                      </p>
                      <p className="mb-0 fs-7">
                        <strong>Branch Code:</strong>{" "}
                        <span className="text-dark">
                          {Bank?.branch_code || "No additional info available."}
                        </span>
                      </p>
                    </div>
                  </div>
                )}

                {/* {showAddBankForm && (
                  <BankForm
                    bankOptions={bankOptions}
                    setBankOptions={setBankOptions}
                    Bank={Bank}
                    setBank={setBank}
                    onClose={() => setShowAddBankForm(false)}
                    onAddBank={handleAddBank}
                  />
                )}

                {showEditBankForm && (
                  <EditBankForm
                    currentBank={Bank ? Bank : INdirectBank}
                    currentClientState={Bank}
                    onClose={() => setShowEditBankForm(false)}
                  />
                )} */}

                {/* {Payment && Payment.value === "payoneer" && (
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group1">
                        <label htmlFor="bank-select" className="business-name">
                          Select Payoneer Bank:
                        </label>
                        <div className="select-client-container form-row">
                          <div className="col-12">
                            <Select
                              id="bank-select"
                              options={[
                                ...(IndirectBank?.data?.map((bank) => ({
                                  value: bank,
                                  label: bank.label,
                                })) || []),
                              ]}
                              placeholder="Select Bank"
                              value={INdirectBank}
                              onChange={(selectedOption) => {
                                if (selectedOption.value === "add-new") {
                                  bankModel();
                                } else {
                                  handleINBankChange(selectedOption.value);
                                }
                              }}
                              isSearchable
                              className="select-client"
                            />
                            {formErrors.IndirectBank && (
                              <div className="text-danger">
                                {formErrors.IndirectBank}
                              </div>
                            )}
                          </div>
                        </div>
                        {showIndirectBankDetails && INdirectBank && (
                          <div className="client-details p-3 border rounded bg-light mt-3 d-flex justify-content-between align-items-start">
                            <div>
                              <h5 className="text-primary fs-6">
                                Indirect Bank Details:
                              </h5>
                              <p className="mb-1 fs-7">
                                <strong>Name:</strong>{" "}
                                <span className="text-dark">
                                  {INdirectBank.label ||
                                    "No additional info available."}
                                </span>
                              </p>
                              <p className="mb-1 fs-7">
                                <strong>Account Number:</strong>{" "}
                                <span className="text-dark">
                                  {INdirectBank.account_number ||
                                    "No additional info available."}
                                </span>
                              </p>
                              <p className="mb-1 fs-7">
                                <strong>Branch Code:</strong>{" "}
                                <span className="text-dark">
                                  {INdirectBank.branch_code ||
                                    "No additional info available."}
                                </span>
                              </p>
                              <p className="mb-0 fs-7">
                                <strong>Swift Code:</strong>{" "}
                                <span className="text-dark">
                                  {INdirectBank.swift_code ||
                                    "No additional info available."}
                                </span>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )} 
              </div>
            </div> */}

            <div className="card mb-3 shadow">
              <div className="card-body">
              <div className="row justify-content-between align-items-center col-12 mb-3">
                  <div className="col-md-4">
                    <div className="form-group1">
                      <label className="business-name" htmlFor="due-date">
                        Payment Status:
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                       <Select
                        id="payment-status"
                        options={statusOption}
                        value={paymentStatus}
                        placeholder={paymentStatus ? paymentStatus : "Unpaid"}
                        onChange={handleStatusChange}
                        className="select-payment-status"
                      />
                   </div>
                </div>
                <div className="row justify-content-between align-items-center col-12 mb-3">
                  <div className="col-md-4 ">
                    <div className="form-group1">
                      <label className="business-name" htmlFor="invoice-date">
                        Invoice Date:
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <ReactDatePicker
                      selected={
                        invoiceDate
                          ? new Date(invoiceDate.split("-").reverse().join("-"))
                          : null
                      }
                      onChange={handleInvoiceDateChange}
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      readOnly
                    />
                  </div>
                </div>

                <div className="row justify-content-between align-items-center col-12 mb-3">
                  <div className="col-md-4">
                    <div className="form-group1">
                      <label className="business-name" htmlFor="due-date">
                        Due Date:
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {!isCustomDateSelected ? (
                      <Select
                        id="due-date"
                        options={options}
                        value={DueDate}
                        onChange={handleSelectChange}
                        className="select-due-date"
                      />
                    ) : (
                      <ReactDatePicker
                        selected={
                          DueDate && !isNaN(new Date(DueDate))
                            ? new Date(DueDate)
                            : null
                        }
                        onChange={handleDateChange}
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Pick Date 📅"
                        className="form-control"
                        id="Duedate"
                      />
                    )}
                    {formErrors.Duedate && (
                      <div className="text-danger mt-1">
                        {formErrors.Duedate}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row justify-content-between align-items-center col-12 mb-3">
                  <div className="col-md-4">
                    <div className="form-group1">
                      <label className="business-name" htmlFor="discount">
                        Discount (
                        {discountType === "percentage" ? "%" : currency?.logo}) :
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                  <input
                        id="discount"
                        type="text"
                        className="form-control form-control1"
                        placeholder="Discount"
                        value={discount}
                        onChange={(e) => {
                          let value = e.target.value.replace(/[^0-9.]/g, ""); // Remove invalid characters
                          if (value === "" || value === "-") {
                            // Handle case when the input is completely cleared
                            setDiscount("");
                            setInvoiceData({
                              ...invoiceData,
                              discount: "",
                            });
                            return;
                          }

                          // Handle discount logic
                          if (Subtotal && Subtotal > 0) {
                            if (discountType === "percentage") {
                              const numericValue = parseFloat(value);
                              if (!isNaN(numericValue)) {
                                if (numericValue <= 0 || numericValue > 99) {
                                  alert("Discount percentage must be between 0 and 99. Please choose a valid discount type.");
                                } else {
                                  setDiscount(value);
                                  setInvoiceData({
                                    ...invoiceData,
                                    discount: value,
                                  });
                                }
                              }
                            } else {
                              if (parseFloat(value) <= 0 || parseFloat(value) > (Subtotal - 1)) {
                                alert("Discount amount cannot be greater than the total.");
                              } else {
                                setDiscount(value);
                                setInvoiceData({
                                  ...invoiceData,
                                  discount: value,
                                });
                              }
                            }
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Backspace" && discount.length === 1) {
                            // If the backspace is pressed and there's only one character, clear the input
                            setDiscount("");
                            setInvoiceData({
                              ...invoiceData,
                              discount: "",
                            });
                          }
                        }}
                      />
                   </div>
                </div>

                {isGstType && (
                  <>
                    <div className="row mb-3 justify-content-between align-items-center col-12">
                      <div className="col-md-4">
                        <div className="form-group1">
                          <label
                            className="business-name"
                            htmlFor="gst-percentage"
                          >
                            <strong>GST (%):</strong>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <input
                          id="gst-percentage"
                          type="number"
                          className="form-control"
                          placeholder="GST Percentage"
                          value={gstPercentage}
                          onChange={handleGstPercentageChange}
                          readOnly
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="row justify-content-between align-items-center col-12 mb-3">
                  <div className="col-md-6">
                    <div className="form-group1">
                      <label className="business-name" htmlFor="other-amount">
                        Other Amount:
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 text-end">
                    <Link
                      onClick={toggleOtherAmountFields}
                      style={{
                        color: showOtherAmountFields ? "red" : "#0D6EFD",
                        textDecoration: "none",
                      }}
                    >
                      {showOtherAmountFields ? (
                        "Close"
                      ) : (
                        <>
                          <i
                            className="fas fa-plus"
                            style={{ marginRight: "5px" }}
                          ></i>
                          Add
                        </>
                      )}
                    </Link>
                  </div>
                </div>

                {showOtherAmountFields && (
                  <div>
                    {otherAmounts.map((otherAmount, index) => (
                      <div key={index} className="form-row other-amount-row">
                        <div className="form-group">
                          <label
                            className="business-name"
                            htmlFor={`other-amount-name-${index}`}
                          >
                            Name:
                          </label>
                          <input
                            id={`other-amount-name-${index}`}
                            onInputChange={handleInputChange}
                            type="text"
                            className="form-control"
                            placeholder="Enter Name"
                            value={otherAmount.name}
                            onChange={(e) =>
                              handleOtherAmountChange(
                                index,
                                "name",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor={`other-amount-${index}`}>
                            Amount:
                          </label>
                          <input
                            id={`other-amount-${index}`}
                            type="text"
                            className="form-control"
                            placeholder="Enter Amount"
                            value={otherAmount.amount}
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                              handleOtherAmountChange(index, "amount", value);
                            }}
                          />
                        </div>

                        {index !== 0 && (
                          <div className="form-group text-end">
                            <button
                              className="btn btn-sm btn-danger remove-other-amount-button"
                              onClick={() => removeOtherAmount(index)}
                            >
                              <IoMdTrash className="mr-1" />
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                    <div className="form-group">
                      <Link className="" onClick={addOtherAmount}>
                        <i
                          className="fas fa-plus"
                          style={{ marginRight: "5px" }}
                        ></i>
                        Add More
                      </Link>
                    </div>
                  </div>
                )}
                <hr />
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <strong className="mx-2">SubTotal :</strong>
                      <strong className="mx-2">
                        {(Subtotal || 0).toFixed(2)} {currency?.logo}
                      </strong>
                    </div>
                    <hr />

                    <div className="form-group">
                      <strong className="m-2">Total:</strong>
                      <strong className="m-2">
                        {(total || 0).toFixed(2)} {currency?.logo}
                      </strong>
                    </div>
                  </div>
                </div>

                <div className="row m-2">
                  <button
                    className={button}
                    type="submit"
                    onClick={handleSubmit} >
                    Submit Invoice
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showAddItemForm && (
          <ItemForm
            itemOptions={itemOptions}
            setItemOptions={setItemOptions}
            onClose={() => setShowAddItemForm(false)}
            onAddClient={handleAddItem}
          />
        )}
        {showAddCategoryForm && (
          <CategoryForm onClose={() => setShowAddCategoryForm(false)} />
        )}
        {isLoading && <Loader />}
      </div>
      {Editloading && AllBankLoading ? <Loader /> : null}
    </div>
  );
};

export default InvoiceEdit;
