import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { adminDashboard, BASE_URL, birthdayUpdate, employeeDashboard, invoiceDashboard } from '../NWConfig';


// Admin Dashboard
export const getadminDashboardData = createAsyncThunk(
  'get/getadminDashboardData',
  async () => {   
      
    let url = BASE_URL + adminDashboard;   
    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);


// Invoice Dashboard
export const getInvoiceDashboardData = createAsyncThunk(
  'get/getInvoiceDashboardData',
  async () => {   
      
    let url = BASE_URL + invoiceDashboard;   
    const token = localStorage.getItem("authToken");
   
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

// Employee Dashboard
export const getDashboardData = createAsyncThunk(
    'get/getDashboardData',
    async () => {   
        
      let url = BASE_URL + employeeDashboard;   
      const token = localStorage.getItem("authToken");
     
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );
  

// Update Birthday Status
export const updateBirthdayStatus = createAsyncThunk(
  "post/updateBirthdayStatus",
  async(id) => {

    let url = BASE_URL + birthdayUpdate; 

    const token = localStorage.getItem("authToken");
    try {
          const response = (await axios.post(url,id ,{
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;
          console.log("response", response);
          
              return Promise.resolve(response);
      } catch(response) {
          return response;
      }
  }
);
  
  