import { createSlice } from '@reduxjs/toolkit';
import { EditBankStore, postBankStore } from '@Networking/APIs/Invoice/Bank/bankStoreApi';
import { getAllBank, getBank, getBanklist, getindirctBank } from '@Networking/APIs/Invoice/Bank/bankGetApi';
import { toast } from 'react-toastify';
import { DeleteBankStore } from '@Networking/APIs/Invoice/Bank/deleteBankApi';

const BankStoreSlice = createSlice({
  name: 'BankPostSlice',
  initialState: {
    loading: false,
    loading3:false,
    BankLoading:false,
    AllBankLoading:false,
    BankStore: '',
    DirectBankData:[],
    IndirectBankData:[],
    AllBankData:[],
    EditDirect:[],
    DeleteDirect:[],
    success: false,
    error: null,
    validateError:[],
  },

  extraReducers: (builder) => {
    builder.addCase(postBankStore.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postBankStore.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.BankStore = action.payload;
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.message)
        
        state.validateError = action.payload.response.data;
         
      }
    });
    builder.addCase(postBankStore.rejected, (state, action) => {
       state.loading = false;
      state.error = action.error.message || 'Login failed.';
    });

    // Get AllBanks 
    builder.addCase(getAllBank.pending, (state) => {
      state.AllBankLoading = true;
     state.error = null;
   });
   builder.addCase(getAllBank.fulfilled, (state, action) => {
      state.AllBankLoading = false;
     state.success = true;
     state.AllBankData = action.payload;

     if (action.meta.arg === 1) {
      state.DirectBankData = action.payload;
    } else if (action.meta.arg === 2) {
      state.IndirectBankData = action.payload;
    }

   });
   builder.addCase(getAllBank.rejected, (state, action) => {
      state.AllBankLoading = false;
     state.error = action.error.message || 'Login failed.';
   });

    
   //Edit Bank
   builder.addCase(EditBankStore.pending, (state) => {
    state.loading3 = true;
   state.error = null;
 });
 builder.addCase(EditBankStore.fulfilled, (state, action) => {
    state.loading3 = false;
   state.success = true;
   state.EditDirect = action.payload;
   if(action.payload.status == true){
    toast.success(action.payload.message)
  }
  else{
    toast.error(action.payload.message)
  }
 });
 builder.addCase(EditBankStore.rejected, (state, action) => {
    state.loading3 = false;
   state.error = action.error.message || 'Login failed.';
 });

 //Delete Bank
 builder.addCase(DeleteBankStore.pending, (state) => {
  state.loading = true;
 state.error = null;
});
builder.addCase(DeleteBankStore.fulfilled, (state, action) => {
  state.loading = false;
 state.success = true;
 state.DeleteDirect = action.payload;
 if(action.payload.status == true){
  toast.success(action.payload.message)
}
else{
  toast.error(action.payload.message)
}
});
builder.addCase(DeleteBankStore.rejected, (state, action) => {
  state.loading = false;
 state.error = action.error.message || 'Login failed.';
});


  },
});

export default BankStoreSlice.reducer;