import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import {
  PartialyView,
  storePayment,
  PartialyPaid,
  PartialyList,
  DeletePartialPayment,
} from "@Networking/APIs/Invoice/Payment/PartialyPayment";
import "datatables.net-dt";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "@Layout/Loader/loader";
import { toast } from "react-toastify";
import { getAllBank } from "@Networking/APIs/Invoice/Bank/bankGetApi";
import {
  button,
  card,
  componentheaderStep1,
  componentheaderStep2,
  container,
  headerCard,
  textDiv,
} from "@Layout/Color/color";

export const InvoicePartiallyForm = () => {
  //Hook
  const location = useLocation();
  const { invoiceId } = location.state || {};
  const dispatch = useDispatch();

  //Redux
  const { invoicData, validateError, paymentList, loading, isLoading1 } =
    useSelector((state) => state.PartialyPaymentSlice);
  const { AllBankData } = useSelector((state) => state.BankPostSlice);
  const today = new Date().toISOString().split("T")[0];

  //States
  
  const [bankId1, setbankId1] = useState(
    invoicData?.invoice?.banks?.id || ""
  );
  const [referenceNumber1, setReferenceNumber1] = useState("");
  const [paidAmount1, setPaidAmount1] = useState(0);
  const [exchangeRate1, setExchangeRate1] = useState("" || 1);
  const [paidAmountINR1, setPaidAmountINR1] = useState("");
  const [paymentDate1, setPaymentDate1] = useState(formatDate(new Date()));
  const [deductionAtSource1, setDeductionAtSource1] = useState(0);
  const [bankCharges1, setBankCharges1] = useState(0);
  const [comment1, setComment1] = useState();

  const [bankId, setbankId] = useState("");
  const [referenceNumber, setReferenceNumber] = useState(""); 
  const [paidAmount, setPaidAmount] = useState(0);
  const [exchangeRate, setExchangeRate] = useState("" || 1);
  const [paidAmountINR, setPaidAmountINR] = useState("");
  const [paymentDate, setPaymentDate] = useState(formatDate(new Date()));
  const [deductionAtSource, setDeductionAtSource] = useState(0);
  const [bankCharges, setBankCharges] = useState(0);
  const [comment, setComment] = useState();

  const [chequeNumber, setChequeNumber] = useState("");
  const [chequeDate, setChequeDate] = useState(formatDate(new Date()));
  const [chequeAmount, setChequeAmount] = useState("");
  const [chequeImage, setChequeImage] = useState("");

  const [cashPaymentDate, setCashPaymentDate] = useState(formatDate(new Date()));
  const [cashAmount, setCashAmount] = useState("");

  const [ddDate, setDdDate] = useState(formatDate(new Date()));
  const [ddNumber, setDdNumber] = useState("");
  const [ddAmount, setDdAmount] = useState("");
  const [ddImage, setDdImage] = useState("");

  const [amountToBePaid, setAmountToBePaid] = useState("");
  const [docImage, setDocImage] = useState("");
  // local bank states

  // International bank states
   
  const [formErrors, setFormErrors] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  const [activeTab, setActiveTab] = useState("online");
  const [status, setStatus] = useState("");
  const [isPayoneerMode, setIsPayoneerMode] = useState(false);
 
  // Fetch banks and initialize invoice data when `invoicData` changes
  useEffect(() => {
    if (invoicData) {
      setAmountToBePaid(invoicData?.invoice?.amount_to_be_paid || 0);
      setStatus(invoicData?.invoice?.status || "");

      if (invoicData?.invoice?.banks?.bank_title) {
        setbankId1(invoicData.invoice.banks.id);
      }
      if (
        invoicData?.invoice?.payment_mode === "payoneer" ||
        invoicData?.invoice?.payment_mode === "ADD PAYONEER LINK"
      ) {
        setIsPayoneerMode(true);
      } else {
        setIsPayoneerMode(false);
      }
    }
    dispatch(getAllBank(3));
    // setPaidAmountINR1(0.00)
  }, [invoicData, dispatch]);

  // Fetch partial payment details when `invoiceId` changes
  useEffect(() => {
    if (invoiceId) {
      dispatch(PartialyList(invoiceId));
      dispatch(PartialyView(invoiceId));
      dispatch(PartialyPaid(invoiceId));
    }
  }, [invoiceId, dispatch]);

  // Update the selected bank name based on `AllBankData`
  useEffect(() => {
    if (AllBankData?.data) {
      const selectedBank = AllBankData.data.find((bank) => bank.value === 8);
      if (selectedBank) {
        setbankId(selectedBank.value);
      }
    }
  }, [AllBankData]);

  // Calculate and set the amount in INR for foreign payments
  useEffect(() => {
    const changerate = paidAmount1 * exchangeRate1;
    setPaidAmountINR1(changerate.toFixed(2));

    const changerateforlocalbank = (paidAmount1 - deductionAtSource1) * exchangeRate1 - bankCharges1;
    setPaidAmount(changerateforlocalbank.toFixed(2));  // Assuming `setPaidAmountINR` exists

}, [paidAmount1, exchangeRate1, deductionAtSource1, bankCharges1]); 


  useEffect(() => {
    const changerate = paidAmount * exchangeRate;
    const changerate1 = changerate.toFixed(2);
    setPaidAmountINR(changerate1);
  }, [paidAmount, exchangeRate]);
 
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setPaymentDate(formatDate(today));
    setPaymentDate1(formatDate(today));
    setChequeDate(formatDate(today));
    setCashPaymentDate(formatDate(today));
    setDdDate(formatDate(today));
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    reset();
    setSelectedOption("");
    console.log(tab);
    if(tab === "Offline"){
      setIsPayoneerMode(false);
    }
  };

  const handleBankChange = (v) => {
    if (
      v == "8"
    ) {
      setIsPayoneerMode(false);
    }

    setbankId1(v);
  };

  const handleChange = (e) => {
    setPaymentDate1(e.target.value);
  };

  const handleBankChangeLoocal = (e) => {
    //  setbankId(e);

    if (
      e != "8"
    ) {
      setIsPayoneerMode(true);
      setbankId1(e);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleDeleteConfirmation = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to Delete this Transaction?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete it!",
      cancelButtonText: "No, keep it",
    });
    if (!result.isConfirmed) {
      return; // Exit if the user cancels
    }

    try {
      await dispatch(DeletePartialPayment(id));
      toast.success("Deleted Successfully");
      await dispatch(PartialyList(invoiceId));
      await dispatch(PartialyPaid(invoiceId));
    } catch (error) {}
  };

  const reset = () => {
    setReferenceNumber1("");
    setPaidAmount1(0);
    setExchangeRate1(1);
    setPaidAmountINR1(0);
    setDeductionAtSource1(0);
    setBankCharges1(0);

    setReferenceNumber("");
    setPaidAmountINR(0);
    setPaymentDate(formatDate(new Date()));
    setDeductionAtSource(0);
    setBankCharges(0);

    setChequeNumber("");
    setChequeDate(formatDate(new Date()));
    setChequeAmount(0);
    setChequeImage("");

    setCashPaymentDate(formatDate(new Date()));
    setCashAmount(0);

    setDdDate(formatDate(new Date()));
    setDdNumber("");
    setDdAmount(0);
    setDdImage("");
    setComment("");
  };

  // Utility function to format the date
  function formatDate(date) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(date).toLocaleDateString("en-GB", options);
  }

  // const dueAmount = invoicData?.invoice?.amount_to_be_paid - paidAmount;
  // console.log(dueAmount);
  const validateForm = () => {
   
    const errors = {};
    let paidAmounttoatal;

    if (activeTab === "online") {
      if (isPayoneerMode === false) {
        paidAmounttoatal = paidAmountINR; // Online and currency 101
      } else {
        paidAmounttoatal = paidAmount1; // Online and other currencies
      }
      
      if (isPayoneerMode === true) {
        if (!bankId1) errors.bankId1 = "Please select a Bank.";
        if (!referenceNumber1)
          errors.referenceNumber1 = "Please Insert Reference Number.";
        if (!referenceNumber)
          errors.referenceNumber = "Please Insert Reference Number.";
        if (!paidAmount1 || paidAmount1 == 0)
          errors.paidAmount1 = "Please Insert Paid Amount.";
        if (!paidAmountINR1 || paidAmountINR1 == 0)
          errors.paidAmountINR1 = "Please Insert Paid Amount.";
        if (!exchangeRate1 || exchangeRate1 == 0)
          errors.exchangeRate1 = "Please Insert Exchange Rate.";
        if (!paymentDate1) errors.paymentDate1 = "Please Select Payment Date.";
        if (!paidAmountINR1)
          errors.paidAmountINR1 = "Please Insert Payment Amount.";
        if (!paidAmount || paidAmount == 0)
          errors.paidAmount = "Please Insert Paid Amount.";
        if (!paidAmountINR || paidAmountINR == 0)
          errors.paidAmountINR = "Please Insert Reference Number.";
        if (!exchangeRate || exchangeRate == 0)
          errors.exchangeRate = "Please Insert Exchange Rate.";
        if (!exchangeRate || exchangeRate == 0)
          errors.exchangeRate = "Please Insert Exchange Rate.";
      }

      
      if (isPayoneerMode === false) {
        if (!bankId) errors.bankId = "Please Select a Bank.";
        if (!referenceNumber)
          errors.referenceNumber = "Please Insert Reference Number.";
        if (!paidAmount || paidAmount == 0)
          errors.paidAmount = "Please Insert Paid Amount.";
        if (!exchangeRate || exchangeRate == 0)
          errors.exchangeRate = "Please Insert Exchange Rate.";
        if (!paidAmountINR || paidAmountINR == 0)
          errors.paidAmountINR = "Please Insert Reference Number.";
        if (!paymentDate) errors.paymentDate = "Please Select Payment Date.";
        
      }
    }

    if (activeTab === "Offline") {
      paidAmounttoatal = paidAmountINR; // Offline always uses INR

      if (!selectedOption) errors.paymentMethod = "Please Select Payment Mode.";
      if (selectedOption === "Cheque") {
        if (!chequeNumber)
          errors.chequeNumber = "Please Insert Reference Number.";
        if (!chequeDate) errors.chequeDate = "Please Select a Payment Date.";
        if (!chequeAmount) errors.chequeAmount = "Please Insert Cheque Amount.";
        if (!docImage) errors.chequeImage = "Please upload the cheque image.";
      }
      if (selectedOption === "Cash") {
        if (!cashPaymentDate)
          errors.cashPaymentDate = "Please select a payment date.";
        if (!cashAmount)
          errors.cashAmount = "Please insert the payment amount.";
      }
      if (selectedOption === "DD") {
        if (!ddDate) errors.ddDate = "Please select a DD date.";
        if (!ddNumber) errors.ddNumber = "Please insert a DD number.";
        if (!ddAmount) errors.ddAmount = "Please insert DD amount.";
        if (!docImage) errors.ddImage = "Please upload the DD image.";
      }
    }
 
    setFormErrors(errors);
    return { isValid: Object.keys(errors).length === 0, paidAmounttoatal };
  };
 
  const validateAmount = () => {
    if(amountToBePaid == 0){
      toast.error("Amount already paid");
      return false;
    }
    let checkAmount;
    if (isPayoneerMode === false) {
      checkAmount =
        parseFloat(paidAmount) 
     } else {
      checkAmount =
        parseFloat(paidAmount1) 
     }
    if (parseFloat(amountToBePaid) < parseFloat(checkAmount)) {
      toast.error("Check Paying Amount");
      return false;
    } else if (amountToBePaid <= 0) {
      toast.success("Amount Already Paid");
      return false;
    }
    return true;
  };

  const onSubmit = async (data) => {
    
    const { isValid } = validateForm();
   
    if (isValid) {
      console.log("payload");
      if (validateAmount()) {
        try {
          // Prepare the payload
          let payload = {};
          if (activeTab === "online") {
            if (isPayoneerMode) {
              payload = {
                invoice_id: invoicData?.invoice?.id || null,
                Payment_Mode: activeTab,
                isPayoneerMode: isPayoneerMode,

                bank_id1: bankId1 || null,
                bank_1_refrence: referenceNumber1 || null,
                bank_1_paid_amount: paidAmount1 || null,
                bank_1_exchange_rate: exchangeRate1 || null,
                bank_1_paid_amount_inr: paidAmountINR1 || null,
                bank_1_payment_date: paymentDate1 || null,
                bank_1_deduction_at_source: deductionAtSource1 || null,
                bank_1_charges: bankCharges1 || null,
                comment1: comment1,

                bank_id: bankId || null,
                Cheque_DD_TX_N_Id: referenceNumber,
                Paid_Amount: paidAmount || null,
                exchangeRate: exchangeRate || null,
                paid_amount_INR: paidAmountINR || null,
                Payment_Date: paymentDate || null,
                deduction_sour: deductionAtSource,
                bank_char: bankCharges || null,
                comment: comment,
                
              };
            } else {
              payload = {
                invoice_id: invoicData?.invoice?.id || null,
                Payment_Mode: activeTab,
                isPayoneerMode: isPayoneerMode,

                bank_id: bankId || null,
                Cheque_DD_TX_N_Id: referenceNumber,
                Paid_Amount: paidAmount || null,
                exchangeRate: exchangeRate || null,
                paid_amount_INR: paidAmountINR || null,
                Payment_Date: paymentDate || null,
                deduction_sour: deductionAtSource,
                bank_char: bankCharges || null,
                comment: comment,

              };
            }
          } else {
            if (selectedOption === "Cheque") {
              payload = {
                invoice_id: invoicData?.invoice?.id || null,
                Payment_Mode: activeTab,
                offline_payment: selectedOption,
                isPayoneerMode: false,

                paid_amount_INR: chequeAmount,
                Cheque_DD_TX_N_Id: chequeNumber,
                Paid_Amount: chequeAmount || null,
                Payment_Date: chequeDate || null,
                Comment: comment,
                Payment_Status: status,
              };
            }
            if (selectedOption === "Cash") {
              payload = {
                invoice_id: invoicData?.invoice?.id || null,
                Payment_Mode: activeTab,
                offline_payment: selectedOption,
                isPayoneerMode: false,

                paid_amount_INR: cashAmount,
                Paid_Amount: cashAmount || null,
                Payment_Date: cashPaymentDate || null,
                Comment: comment,
                Payment_Status: status,
              };
            }
            if (selectedOption === "DD") {
              payload = {
                invoice_id: invoicData?.invoice?.id || null,
                Payment_Mode: activeTab,
                offline_payment: selectedOption,
                isPayoneerMode: false,

                paid_amount_INR: ddAmount,
                Payment_Date: ddDate || null,
                Cheque_DD_TX_N_Id: ddNumber,
                Paid_Amount: ddAmount || null,
                Comment: comment,
                Payment_Status: status,
              };
            }
          }
          console.log(payload ,"this is last payload data");
          const formData = new FormData();
          // Loop through the payload and append each field to the FormData
          for (const key in payload) {
            if (payload[key] !== undefined && payload[key] !== null) {
              formData.append(key, payload[key]);
            }
          }
          // Attach the file separately if `docImage` is a valid file
          if (docImage) {
            formData.append("scan_documents", docImage); // File field
          }
          
          // Dispatch API action or make API call
          const res = await dispatch(storePayment(formData));
          if (res.payload.status == true) {
            reset();
            await dispatch(PartialyList(invoiceId));
            await dispatch(PartialyPaid(invoiceId));
          } else {
            toast.error("Refresh and Re-Fill Form");
          }
        } catch (error) {
          // Error handling
          toast.error("Error submitting payment.");
          console.error("Submission error:", error);
        }
      }
    }
  };
  const formatNumber = (value) => {
    if (!value || isNaN(value)) return "N/A"; // Handle null, undefined, or non-numeric values
    return Number(value).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }); // Ensures two decimal places
  };
  
  // console.log(paymentList, "paymentList");
  return (
    <>
    <div className="container-fluid">
      <div className="row">
          <div className="col-md-12 col-sm-12">
            <h6 className="">
              <i class="fa-solid fa-list me-1"></i> Finance/ Invoice / Invoice Payments
             </h6>
          </div>
        </div>

        {invoicData ? (
  <div className="card">
    <div className={headerCard}>
      <h5 className="mb-0 text-light">Invoice Details</h5>
    </div>
    <div className="card-body">
      <div className="table-responsive">
        <table className="table table-bordered text-center">
          <thead className="table-light">
            <tr>
              <th>Invoice Date</th>
              <th>Invoice Number</th>
              <th>Invoice To</th>
              <th>Invoice Amount ({invoicData?.invoice?.country?.currency_code})</th>
              {isPayoneerMode === true && (
                <>              
              <th>Exchange Rate ({invoicData?.invoice?.country?.currency_code})</th>
              <th>Invoice Amount (INR) </th>
              </>
               )}
              <th>Amount to be Paid ({invoicData?.invoice?.country?.currency_code})</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{invoicData?.invoice?.invoice_date || "N/A"}</td>
              <td>{invoicData?.invoice?.invoice_number || "N/A"}</td>
              <td>
                {invoicData?.invoice?.client?.business_name} (
                {invoicData?.invoice?.client?.first_name}{" "}
                {invoicData?.invoice?.client?.last_name})
              </td>
              <td>
              {formatNumber(invoicData?.invoice?.total) }
                  
              </td>

              {isPayoneerMode === true && (
                 <>  
              <td>{invoicData?.invoice?.currency_todays_rate || "N/A"}</td>
              <td> {formatNumber(invoicData?.invoice?.total_in_INR || "N/A")}</td>
              </>

)}
              <td> {formatNumber(invoicData?.invoice?.amount_to_be_paid || "N/A")}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
) : (
  <Loader />
)}

       {amountToBePaid != 0 && (
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className={headerCard}>
              <h5 className="mb-0 text-light">Payment Form</h5>
            </div>

            <div className="row mt-2">
              <div className="col-md-6">
                <div className="d-flex justify-content-around nav nav-pills mb-3">
                  <button
                    className={`nav-link w-100 fw-bold ${
                      activeTab === "online" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("online")}
                  >
                    Online
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex justify-content-around nav nav-pills mb-3">
                  <button
                    className={`nav-link w-100 fw-bold ${
                      activeTab === "Offline" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("Offline")}
                  >
                    Offline
                  </button>
                </div>
              </div>
            </div>

            {/* Form Content */}
            <div className="row">
              <div className="col-md-12">
                {activeTab === "online" && (
                  <div>
                    {isPayoneerMode && (
                      <div className="row gy-2 px-3">
                        <h6 className="bg-secondary text-light fs-7 border rounded text-center">
                        Intermediary Bank 
                        </h6>
                        <div className="col-md-6 mb-2">
                          <label htmlFor="bank1">Bank</label>
                          <select
                            id="bank1"
                            name="bank1"
                            onChange={(e) => handleBankChange(e.target.value)}
                            value={bankId1}
                          >
                            <option value="" disabled>
                              Select Bank
                            </option>
                            {AllBankData?.data?.map((bank) => (
                              <option key={bank.value} value={bank.value}>
                                {bank.label}
                              </option>
                            ))}
                          </select>
                          {formErrors.bankId1 && (
                            <div className="text-danger">
                              {formErrors.bankId1}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label>Reference Number</label>
                          <input
                            type="text"
                            placeholder="Enter Reference Number"
                            value={referenceNumber1}
                            className="inputText"
                            onChange={(e) => {
                              setReferenceNumber1(e.target.value);
                              setFormErrors((prevErrors) => ({
                                ...prevErrors,
                                referenceNumber1: "",
                              }));
                            }}
                          />
                          {formErrors.referenceNumber1 && (
                            <span className="text-danger">
                              {formErrors.referenceNumber1}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label>
                            Paid Amount (
                            {invoicData?.invoice?.country?.currency_code})
                          </label>
                          <input
                            type="text"
                            className="inputText"
                            value={paidAmount1}
                            onChange={(e) => {
                              setPaidAmount1(e.target.value);
                              setFormErrors((prevErrors) => ({
                                ...prevErrors,
                                paidAmount1: "",
                              }));
                            }}
                          />
                          {formErrors.paidAmount1 && (
                            <span className="text-danger">
                              {formErrors.paidAmount1}
                            </span>
                          )}
                        </div>

                        <div className="col-md-4">
                          <label>Exchange Rate</label>
                          <input
                            type="text"
                            className="inputText"
                            onChange={(e) => {
                              setExchangeRate1(e.target.value);
                              setFormErrors((prevErrors) => ({
                                ...prevErrors,
                                exchangeRate1: "",
                              }));
                            }}
                            value={exchangeRate1}
                          />
                          {formErrors.exchangeRate1 && (
                            <span className="text-danger">
                              {formErrors.exchangeRate1}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label>Paid Amount (INR)</label>
                          <input
                            type="text"
                            className="inputText"
                            value={paidAmountINR1}
                            onChange={(e) => {
                              setPaidAmountINR1(e.target.value);
                              setFormErrors((prevErrors) => ({
                                ...prevErrors,
                                paidAmountINR1: "",
                              }));
                            }}
                          />
                          {formErrors.paidAmountINR1 && (
                            <span className="text-danger">
                              {formErrors.paidAmountINR1}
                            </span>
                          )}
                        </div>

                        <div className="col-md-4">
                          <label>Payment Date</label>
                          <input
                            type="date"
                            className="inputText"
                            max={today}
                            onChange={handleChange}
                            value={paymentDate1}
                          />
                          {formErrors.paymentDate1 && (
                            <span className="text-danger">
                              {formErrors.paymentDate1}
                            </span>
                          )}
                        </div>

                        <div className="col-md-4">
                          <label>Deduction at Source ($)</label>
                          <input
                            type="text"
                            className="inputText"
                            onChange={(e) =>
                              setDeductionAtSource1(e.target.value)
                            }
                            value={deductionAtSource1}
                          />
                        </div>
                        <div className="col-md-4">
                          <label>Bank Charges (INR)</label>
                          <input
                            type="text"
                            className="inputText"
                            onChange={(e) => setBankCharges1(e.target.value)}
                            value={bankCharges1}
                          />
                        </div>
                        <div className="col-md-12  mt-2 ">
                    <div className="">
                      <label htmlFor="comment" className="text-dark fs-7 m-1">
                        Comment
                      </label>
                      <textarea
                        className="inputText"
                        id="comment"
                        placeholder="Enter comment here..."
                        value={comment1}
                        onChange={(e) => {
                          setComment1(e.target.value);
                          setFormErrors((prevErrors) => ({
                            ...prevErrors,
                            commentErr1: "",
                          }));
                        }}
                      />
                      {formErrors.commentErr1 && (
                        <span className="text-danger">
                          {formErrors.commentErr1}
                        </span>
                      )}
                    </div>
                  </div>
                      </div>
                    )}

                    <div className="row gy-2 px-3 mt-2">
                      <h6 className="bg-secondary text-light fs-7 border rounded text-center">
                        Local Bank
                      </h6>
                      <div className="col-md-6 mb-2">
                        <label>Bank</label>
                        <select
                          id="nationality"
                          name="nationality"
                          value={bankId}
                          onChange={(e) =>
                            handleBankChangeLoocal(e.target.value)
                          }
                        >
                          <option value="" disabled>
                            Select Bank
                          </option>
                          {AllBankData?.data?.length > 0 ? (
                            AllBankData?.data.map((bank) => (
                              <option
                                key={bank.value}
                                value={bank.value}
                                selected={bank.id === bankId}
                              >
                                {bank.label}
                              </option>
                            ))
                          ) : (
                            <option disabled>No Bank available</option>
                          )}
                        </select>
                        {formErrors.bankId && (
                          <span className="text-danger">
                            {formErrors.bankId}
                          </span>
                        )}
                      </div>

                      <div className="col-md-6">
                        <label>Reference Number</label>
                        <input
                          type="text"
                          className="inputText"
                          placeholder="Enter Reference Number"
                          value={referenceNumber}
                          onChange={(e) => {
                            setReferenceNumber(e.target.value);
                            setFormErrors((prevErrors) => ({
                              ...prevErrors,
                              referenceNumber: "",
                            }));
                          }}
                        />
                        {formErrors.referenceNumber && (
                          <span className="text-danger">
                            {formErrors.referenceNumber}
                          </span>
                        )}
                      </div>
                      <div className="col-md-4">
                          <label>
                            Paid Amount  
                          </label>
                          <input
                            type="text"
                            className="inputText"
                            value={paidAmount}
                            onChange={(e) => {
                              setPaidAmount(e.target.value);
                              setFormErrors((prevErrors) => ({
                                ...prevErrors,
                                paidAmount: "",
                              }));
                            }}
                          />
                          {formErrors.paidAmount && (
                            <span className="text-danger">
                              {formErrors.paidAmount}
                            </span>
                          )}
                        </div>

                        <div className="col-md-4">
                          <label>Exchange Rate</label>
                          <input
                            type="text"
                            className="inputText"
                            onChange={(e) => {
                              setExchangeRate(e.target.value);
                              setFormErrors((prevErrors) => ({
                                ...prevErrors,
                                exchangeRate: "",
                              }));
                            }}
                            value={exchangeRate}
                          />
                          {formErrors.exchangeRate && (
                            <span className="text-danger">
                              {formErrors.exchangeRate}
                            </span>
                          )}
                        </div>

                      <div className="col-md-4">
                        <label>Paid Amount (INR)</label>
                        <input
                          type="text"
                          className="inputText"
                          onChange={(e) => {
                            setPaidAmountINR(e.target.value);
                            setFormErrors((prevErrors) => ({
                              ...prevErrors,
                              paidAmountINR: "",
                            }));
                          }}
                          value={paidAmountINR}
                        />
                        {formErrors.paidAmountINR && (
                          <span className="text-danger">
                            {formErrors.paidAmountINR}
                          </span>
                        )}
                      </div>

                      <div className="col-md-4">
                        <label>Payment Date</label>
                        <input
                          type="date"
                          className="inputText"
                          max={today}
                          onChange={(e) => setPaymentDate(e.target.value)}
                          value={paymentDate}
                        />
                        {formErrors.paymentDate && (
                          <span className="text-danger">
                            {formErrors.paymentDate}
                          </span>
                        )}
                      </div>

                      <div className="col-md-4">
                        <label>Deduction at Source</label>
                        <input
                          type="text"
                          className="inputText"
                          onChange={(e) => setDeductionAtSource(e.target.value)}
                          value={deductionAtSource}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>Bank Charges</label>
                        <input
                          type="text"
                          name="bankCharges2"
                          className="inputText"
                          onChange={(e) => setBankCharges(e.target.value)}
                          value={bankCharges}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "Offline" && (
                  <div>
                    <div className="row m-1 p-0">
                      <div className="col-md-4 d-flex align-items-center justify-content-center text-center">
                        <select
                          id="paymentMethod"
                          value={selectedOption}
                          onChange={handleOptionChange}
                          className="border-none"
                        >
                          <option value="" selected disabled>
                            Select Payment Method
                          </option>
                          <option value="Cheque">Cheque</option>
                          <option value="Cash">Cash</option>
                          <option value="DD">DD</option>
                        </select>
                        {formErrors.paymentMethod && (
                          <span className="text-danger">
                            {formErrors.paymentMethod}
                          </span>
                        )}
                      </div>

                      <div className="col-md-8 d-flex align-items-center justify-content-center text-center">
                        {selectedOption === "Cheque" && (
                          <h6>
                            <span className="text-decoration-underline bg-light px-3">
                              Enter Cheque Details
                            </span>
                          </h6>
                        )}
                        {selectedOption === "Cash" && (
                          <h6>
                            <span className="text-decoration-underline bg-light px-3">
                              Enter Cheque Details
                            </span>
                          </h6>
                        )}
                        {selectedOption === "DD" && (
                          <h6>
                            <span className="text-decoration-underline bg-light px-3">
                              Enter Cheque Details
                            </span>
                          </h6>
                        )}
                      </div>
                    </div>
                    <hr></hr>

                    {/* Cheque Form */}
                    {selectedOption === "Cheque" && (
                      <div>
                        <div className="row m-1 gy-2">
                          <div className="col-md-6">
                            <label>Cheque Number</label>
                            <input
                              type="text"
                              name="chequeNumber"
                              className="inputText"
                              value={chequeNumber}
                              onChange={(e) => {
                                setChequeNumber(e.target.value);
                                setFormErrors((prevErrors) => ({
                                  ...prevErrors,
                                  chequeNumber: "",
                                }));
                              }}
                            />
                            {formErrors.chequeNumber && (
                              <span className="text-danger">
                                {formErrors.chequeNumber}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label>Cheque Date</label>
                            <input
                              type="date"
                              name="chequeDate"
                              className="inputText no-future-dates"
                              max={today}
                              onChange={(e) => setChequeDate(e.target.value)}
                              value={chequeDate}
                            />
                            {formErrors.chequeDate && (
                              <span className="text-danger">
                                {formErrors.chequeDate}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label>Cheque Amount</label>
                            <input
                              type="text"
                              name="chequeAmount"
                              className="inputText"
                              value={chequeAmount}
                              onChange={(e) => {
                                setChequeAmount(e.target.value);
                                setFormErrors((prevErrors) => ({
                                  ...prevErrors,
                                  chequeNumber: "",
                                }));
                              }}
                            />
                            {formErrors.chequeAmount && (
                              <span className="text-danger">
                                {formErrors.chequeAmount}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label>Cheque Image</label>
                            <input
                              type="file"
                              name="chequeImage"
                              className="inputText"
                              onChange={(e) => setDocImage(e.target.files[0])}
                            />
                            {formErrors.chequeImage && (
                              <span className="text-danger">
                                {formErrors.chequeImage}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Cash Form */}
                    {selectedOption === "Cash" && (
                      <div className="row m-1 gy-2">
                        <div className="col-md-6">
                          <label>Payment Date</label>
                          <input
                            type="date"
                            name="cashPaymentDate"
                            className="inputText"
                            max={today}
                            value={cashPaymentDate}
                            onChange={(e) => setCashPaymentDate(e.target.value)}
                          />
                          {formErrors.cashPaymentDate && (
                            <span className="text-danger">
                              {formErrors.cashPaymentDate}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label>Amount</label>
                          <input
                            type="text"
                            name="cashAmount"
                            className="inputText"
                            value={cashAmount}
                            onChange={(e) => {
                              setCashAmount(e.target.value);
                              setFormErrors((prevErrors) => ({
                                ...prevErrors,
                                cashAmount: "",
                              }));
                            }}
                          />
                          {formErrors.cashAmount && (
                            <span className="text-danger">
                              {formErrors.cashAmount}
                            </span>
                          )}
                        </div>
                      </div>
                    )}

                    {/* DD Form */}
                    {selectedOption === "DD" && (
                      <div className="row m-1 gy-2">
                        <div className="col-md-6">
                          <label>DD Date</label>
                          <input
                            type="date"
                            name="ddDate"
                            className="inputText"
                            max={today}
                            onChange={(e) => setDdDate(e.target.value)}
                            value={ddDate}
                          />
                          {formErrors.ddDate && (
                            <span className="text-danger">
                              {formErrors.ddDate}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label>DD Number</label>
                          <input
                            type="text"
                            name="ddNumber"
                            className="inputText"
                            value={ddNumber}
                            onChange={(e) => {
                              setDdNumber(e.target.value);
                              setFormErrors((prevErrors) => ({
                                ...prevErrors,
                                ddNumber: "",
                              }));
                            }}
                          />
                          {formErrors.ddNumber && (
                            <span className="text-danger">
                              {formErrors.ddNumber}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label>DD Amount</label>
                          <input
                            type="text"
                            name="ddAmount"
                            className="inputText"
                            value={ddAmount}
                            onChange={(e) => {
                              setDdAmount(e.target.value);
                              setFormErrors((prevErrors) => ({
                                ...prevErrors,
                                ddAmount: "",
                              }));
                            }}
                          />
                          {formErrors.ddAmount && (
                            <span className="text-danger">
                              {formErrors.ddAmount}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6">
                          <label>DD Image</label>
                          <input
                            type="file"
                            name="ddImage"
                            className="inputText"
                            onChange={(e) => setDocImage(e.target.files[0])}
                          />
                          {formErrors.ddImage && (
                            <span className="text-danger">
                              {formErrors.ddImage}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="row px-3">
                  <div className="col-md-12  mt-2 ">
                    <div className="">
                      <label htmlFor="comment" className="text-dark fs-7 m-1">
                        Comment
                      </label>
                      <textarea
                        className="inputText"
                        id="comment"
                        placeholder="Enter comment here..."
                        value={comment}
                        onChange={(e) => {
                          setComment(e.target.value);
                          setFormErrors((prevErrors) => ({
                            ...prevErrors,
                            commentErr: "",
                          }));
                        }}
                      />
                      {formErrors.commentErr && (
                        <span className="text-danger">
                          {formErrors.commentErr}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="col-md-12 mb-2 mt-2 text-center">
                  <button
                    type="button"
                    className="btn btn-primary rounded-pill"
                    onClick={onSubmit}
                  >
                    Add Invoice Payment
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </div>
       )}
  {paymentList?.data?.length > 0 ? (
  paymentList?.data?.map((payment, index) => (
    <div key={index}>
      {payment.bank_1 ? (
        // If bank_1 exists, show both Intermediary and Local Bank tables
        <div className="row mt-5 mb-5">
          <div className="col-md-12">
            <div className="card">
            <div className="card-header bg-gradient-info p-0 text-light  d-flex justify-content-between">
                <div >
                <h5 className="mx-3 mt-1">Transaction - {index+1}</h5>
                </div>
                <div >
                      <button
                        className="btn btn-sm btn-danger mx-3 mt-1"
                        onClick={() => handleDeleteConfirmation(payment.id)}
                      >
                        <i className="fa fa-trash"></i> Delete
                      </button>
                    </div>
              </div>
              <div className="card-body">
              <div className="table-responsive">
              <table className="table table-bordered">
                {/* Intermediary Bank Table */}
                <thead className="table-light">
                  <tr>
                    <th className="text-start" colSpan="8">
                      Intermediary Bank <span className="fw-bold">[<strong> {payment.secondary_bank_name}</strong> ]</span> Transaction Details
                    </th>
                  </tr>
                  <tr>
                    <th className="text-center">Date</th>
                    <th className="text-center">Received Amount ($)</th>
                    <th className="text-center">Deduction at Source ($)</th>
                    <th className="text-center">Exchange Rate</th>
                    <th className="text-center">Deduction at Source (INR)</th>
                    <th className="text-center">Bank Charges (INR)</th>
                    <th className="text-center">Received Amount (INR)</th>
                    <th className="text-center">TXN ID</th>
                    <th className="text-center">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">{payment.Payment_Date ? formatDate(payment.Payment_Date) : "N/A"}</td>
                    <td className="text-center"> {formatNumber(payment.Paid_Amount || "0.00")}</td>
                    <td className="text-center"> {formatNumber(payment.deduction_sour || "0.00")}</td>
                    <td className="text-center">{payment.exchange_rate || "0.00"}</td>
                    <td className="text-center">{payment.deduction_sour && payment.exchange_rate ? (payment.deduction_sour * payment.exchange_rate).toFixed(2) : "0.00"}</td>
                    <td className="text-center"> {formatNumber(payment.bank_char || "0.00")}</td>
                    <td className="text-center"> {formatNumber(payment.paid_amount_INR || "0.00")}</td>
                    <td className="text-center">{payment.Cheque_DD_TX_N_Id || "N/A"}</td>
                    <td className="text-center">{payment.Comment || "N/A"}</td>
                  </tr>
                </tbody>

                {/* Local Bank Table */}
                <thead className="table-light">
                  <tr>
                    <th className="text-start " colSpan="8">
                      Local Bank [ {payment.primary_bank_name} ] Transaction Details
                    </th>
                  </tr>
                  <tr>
                    <th className="text-center">Date</th>
                    <th className="text-center">Received Amount (INR)</th>
                    <th className="text-center">Bank Charges (INR)</th>
                    <th className="text-center">TXN ID</th>
                    <th className="text-center">Comments</th>
                  </tr>
                </thead >
                <tbody>
                  <tr>
                  <td className="text-center">{payment.Payment_Date ? formatDate(payment.bank_1_payment_date) : "N/A"}</td>
                     <td className="text-center"> {formatNumber(payment.bank_1_paid_amount_inr || "0.00")}</td>
                    <td className="text-center">{payment.bank_1_charges || "0.00"}</td>
                    <td className="text-center">{payment.bank_1_refrence || "N/A"}</td>
                    <td className="text-center">{payment.Comment || "N/A"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            </div>
          </div>
          </div>
        </div>
      ) : (
        // If bank_1 does not exist, show only the Local Bank table
        <div className="row mt-2">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header bg-gradient-info p-0 text-light  d-flex justify-content-between">
                <div className="mx-3 mt-1">
                <h5>Transaction - {index+1}</h5>
                </div>
                <div >
                      <button
                        className="btn btn-sm btn-danger mx-3 mt-1"
                        onClick={() => handleDeleteConfirmation(payment.id)}
                      >
                        <i className="fa fa-trash"></i> Delete
                      </button>
                    </div>
              </div>
              <div className="card-body">
              <div className="table-responsive">
                <table className="table table-bordered">
                  {/* Local Bank Table Only */}
                  <thead className="table-light">
                    <tr>
                      <th className="text-start " colSpan="9">
                        Local Bank [IndusInd Account in India for International and Local Transfers | XX-0120] Transaction Details
                      </th>
                    </tr>
                    <tr>
                      <th className="text-center">Date</th>
                      <th className="text-center">Received Amount (INR)</th>
                      <th className="text-center">Deduction at Source (INR)</th>
                      <th className="text-center">Exchange Rate</th>
                      <th className="text-center">Deduction at Source (INR)</th>
                      <th className="text-center">Bank Charges (INR)</th>
                      <th className="text-center">Received Amount (INR)</th>
                      <th className="text-center">TXN ID</th>
                      <th className="text-center">Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">{payment.Payment_Date || "N/A"}</td>
                      <td className="text-center">{formatNumber(payment.Paid_Amount || "0.00")}</td>
                      <td className="text-center">{payment.deduction_sour || '0.00'}</td>
                      <td className="text-center">{payment.exchange_rate || '1'}</td>
                      <td className="text-center">{payment.deduction_sour && payment.exchange_rate ? (payment.deduction_sour * payment.exchange_rate).toFixed(2) : "0.00"}</td>
                       <td className="text-center">{payment.bank_char || "0.00"}</td>
                      <td className="text-center"> {formatNumber(payment.paid_amount_INR || "0.00")}</td>
                      <td className="text-center">{payment.Cheque_DD_TX_N_Id || "N/A"}</td>
                      <td className="text-center">{payment.Comment || "N/A"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>
          </div>
        </div>
      )}
    </div>
  ))
) : (
  <></>
)}

      {loading ? <Loader /> : null}
      </div>
    </>
  );
};
