import Loader from "@Layout/Loader/loader";
import { getByTeamEmployees } from "@Networking/APIs/Employee/EmployeeApi";
import { storeTask } from "@Networking/APIs/Task/taskApi";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AssignTask = () => {
  const {
    register,
    reset,
    formState: { errors },
  } = useForm();
  const formRef = useRef(null);

  const [projectFile, setProjectFile] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getByTeamEmployees());
  }, []);

  const { ByTeamEmployee } = useSelector((state) => state.EmployeesSlice);
  const { taskStoreloading } = useSelector((state) => state.TaskSlice);


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    
    // Append form fields to FormData
    formData.append("assign_to", e.target.assign_to.value);
    formData.append("project_name", e.target.project_name.value);
    formData.append("deadline_date", e.target.deadline_date.value);
    formData.append("task_description", e.target.task_description.value);
    formData.append("remark", e.target.remark.value);
    
    // Append the file(s) directly from the input element
    if (e.target.upload_file.files.length > 0) {
      Array.from(e.target.upload_file.files).forEach((file, index) => {
        formData.append("upload_file", file); // Use "upload_file[]" if you support multiple files
      });
    }
  
    try {
      await dispatch(storeTask(formData)).unwrap();
      navigate("/AllTask");
      // reset(); // Uncomment this if you want to reset the form after submission
    } catch (error) {
    }
  };
  
  
  

  return (
    <div>
      <div className="card text-white rounded-top-2 p-3 mx-2 mb-4">
        <div className="row d-flex align-items-center">
          <div className="col-md-6 col-sm-12">
            <h3 className="page-title m-0">
              <span className="mx-2 text-primary">
                <i className="mdi mdi-file"></i>
              </span>
              ASSIGN TASK
            </h3>
          </div>
        </div>
      </div>
      <div className="container-fluid mb-4">
        <div className="card">
          <div className="card-body">
            <div>
              <div className="row p-3">
                <div className="col-md-12">
                <form onSubmit={handleSubmit} ref={formRef} className="row"
                    encType="multipart/form-data" >
                    <div className="row mt-2">
                      <div className="col-md-6 mb-2">
                        <label>Assign To</label>
                        <select
                          id="input"
                          {...register("assign_to", {
                            required: "Please select a user",
                          })}
                        >
                          <option value="" disabled selected>
                            Select Employee
                          </option>
                          {ByTeamEmployee?.employee?.length > 0 ? (
                            ByTeamEmployee.employee.map(
                              (employees) => (
                                <option key={employees.id} value={employees.id}>
                                  {employees.first_name} {""}
                                  {employees.middle_name} {""}
                                  {employees.last_name}
                                </option>
                              )
                            )
                          ) : (
                            <option disabled>No Employee available</option>
                          )}
                        </select>
                        {errors.assign_to && (
                          <div className="text-danger">
                            {errors.assign_to.message}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6">
                        <label>Upload Files</label>
                        <input
                          id="input"
                          type="file"
                          multiple
                          {...register("upload_file")}
                         
                        />
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-8">
                        <label>Project Name</label>
                        <input
                          type="text"
                          id="input"
                          {...register("project_name", {
                            required: "Please enter project name",
                          })}
                          placeholder="Project Name"
                        />
                        {errors.project_name && (
                          <div className="text-danger">
                            {errors.project_name.message}
                          </div>
                        )}
                      </div>

                      <div className="col-md-4">
                        <label>Deadline Date</label>
                        <input
                          id="input"
                          type="date"
                          {...register("deadline_date", {
                            required: "Please enter deadline date",
                          })}
                        />
                        {errors.deadline_date && (
                          <div className="text-danger">
                            {errors.deadline_date.message}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-6 mb-2">
                        <label>Task Description</label>
                        <textarea
                          {...register("task_description", {
                            required: "Please enter task description",
                          })}
                          rows="4"
                          placeholder="Task Description"
                          id="input"
                        ></textarea>
                        {errors.task_description && (
                          <div className="text-danger">
                            {errors.task_description.message}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 mb-2">
                        <label>Remarks</label>
                        <textarea
                          {...register("remark", {
                            required: "Please enter remarks",
                          })}
                          rows="4"
                          placeholder="Remarks"
                          id="input"
                        ></textarea>
                        {errors.remark && (
                          <div className="text-danger">
                            {errors.remark.message}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 mt-3 d-flex justify-content-center">
                      <button
                        type="submit"
                        className="btn btn btn-gradient-info rounded-pill w-50"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {taskStoreloading ? <Loader /> : null}
    </div>
  );
};

export default AssignTask;
