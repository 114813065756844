import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {BASE_URL,employeeCompensationStore,employeeDocumentStore,employeeJobStore,employeePersonalStore, employeeWorkStore, getEmployeUrl, reportingLeader, viewEmployee} from '@Networking/APIs/NWConfig';
 

 

// Employee Store
export const postEmployeePersonalStore = createAsyncThunk(
  "post/postEmployeePersonalStore",
  async(post) => {
      let url = BASE_URL + employeePersonalStore;

      const token = localStorage.getItem("authToken");

      try {
          const response = (await axios.post(url, post, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;

              return Promise.resolve(response);
      } catch(response) {
          return response;
      }
  }
);

export const postEmployeeJobStore = createAsyncThunk(
    "post/postEmployeeJobStore",
    async(post) => {
  
        let url = BASE_URL + employeeJobStore;
  
        const token = localStorage.getItem("authToken");
  
        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;
  
                return Promise.resolve(response);
        } catch(response) {
            return response;
        }
    }
  );

  export const postEmployeeWorkStore = createAsyncThunk(
    "post/postEmployeeWorkStore",
    async(post) => {
  
        let url = BASE_URL + employeeWorkStore;
  
        const token = localStorage.getItem("authToken");
  
        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;
  
                return Promise.resolve(response);
        } catch(response) {
            return response;
        }
    }
  );

  export const postEmployeeCompensationStore = createAsyncThunk(
    "post/postEmployeeCompensationStore",
    async(post) => {
  
        let url = BASE_URL + employeeCompensationStore;
  
        const token = localStorage.getItem("authToken");
  
        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;
  
                return Promise.resolve(response);
        } catch(response) {
            return response;
        }
    }
  );
 
 

  export const postEmployeeDocumentStore = createAsyncThunk(
    "post/postEmployeeDocumentStore",
    async(post) => {
        let url = BASE_URL + employeeDocumentStore;
  
        const token = localStorage.getItem("authToken");
  
        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  ,
                    'Content-Type': 'multipart/form-data' 
                }
            })).data;
  
                return Promise.resolve(response);
        } catch(response) {
            return response;
        }
    }
  );

  // Imploye Get Api 

  export const fetchEmployeData = createAsyncThunk(
    "get/fetchEmployeData",
    async (id) => {
      const url = `${BASE_URL}${getEmployeUrl}/${id}`;
      const token = localStorage.getItem('authToken');
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })).data;
          return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    }
  );
 

//  View Employee

export const viewEmployeData = createAsyncThunk(
    "get/viewEmployeData",
    async (id) => {
      const url = `${BASE_URL}${viewEmployee}/${id}`;
      const token = localStorage.getItem('authToken');
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })).data;
          return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    }
  );

 

// Role Data Fetched 
export const leaderRole = createAsyncThunk(
  "get/leaderRole",
  async (id) => {
    const url = `${BASE_URL}${reportingLeader}/${id}`;
    const token = localStorage.getItem('authToken');
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })).data;
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

 
 

 

 