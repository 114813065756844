import { DeleteAnnouncement, getAnnouncementList, getEditAnnouncement, postAnnouncementStore, postAnnouncementUpdate } from '@Networking/APIs/Announcement/announcementApi';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const AnnouncementSlice = createSlice({
  name: 'AnnouncementSlice',
  initialState: {
    loading: false,
    success: false,
    AnnouncementListData: [],
    AnnouncementEdit: [],
    error: null,
  },

  extraReducers: (builder) => {
    
    builder.addCase(postAnnouncementStore.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postAnnouncementStore.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
      
     
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.message)
      }
      
    });
    builder.addCase(postAnnouncementStore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Add Announcement failed.';
    });



    builder.addCase(getAnnouncementList.pending, state => {
      state.loading = true;
    });
    builder.addCase(getAnnouncementList.fulfilled, (state, action) => {
      state.AnnouncementListData = action.payload;
    });
    builder.addCase(getAnnouncementList.rejected, state => {
      state.loading = false;
    });


    //Delete Announcement
    builder.addCase(DeleteAnnouncement.pending, (state) => {
      state.loading3 = true;
      state.error = null;
    });
    builder.addCase(DeleteAnnouncement.fulfilled, (state, action) => {
      state.loading3 = false;
      state.success = true;
      state.DeleteClient = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(DeleteAnnouncement.rejected, (state, action) => {
      state.loading3 = false;
      state.error = action.error.message || "Delete failed.";
    });


        //Edit Task
        builder.addCase(getEditAnnouncement.pending, (state) => {
          state.loading = true;
        });
        builder.addCase(getEditAnnouncement.fulfilled, (state, action) => {
          state.AnnouncementEdit = action.payload;
        });
        builder.addCase(getEditAnnouncement.rejected, (state) => {
          state.loading = false;
        });


         // Update Announcement
    builder.addCase(postAnnouncementUpdate.pending, (state) => {
      state.taskLoader = true;
      state.error = null;
    });
    builder.addCase(postAnnouncementUpdate.fulfilled, (state, action) => {
      state.taskLoader = false;
      state.success = true;
      state.message = action.payload;

      if (action.payload.status == true) {
        toast.success(action.payload.message);
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(postAnnouncementUpdate.rejected, (state, action) => {
      state.taskLoader = false;
      state.error = action.error.message || "Update Announcement  failed.";
    });

  },
});

export default AnnouncementSlice.reducer;