import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {BASE_URL, categoriesGet, categories, deleteCategorie} from '@Networking/APIs/NWConfig';
import Cookies from 'js-cookie';


export const getCategory = createAsyncThunk(
    'get/getCategory',
    async () => {  // categories should be passed as an argument
  
      // Using template literals to build the URL
      let url = BASE_URL + categoriesGet;  // Corrected line
  
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );
  

  export const CategoryStore = createAsyncThunk(
    "post/CategoryStore",
    async(post) => {
        let url = BASE_URL + categories;

        const token = localStorage.getItem("authToken");

        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;

                return Promise.resolve(response);
        } catch(error) {
            return error;
        }
    }
);

export const deleteCategory = createAsyncThunk(
  'delete/deleteCategory',
  async (id) => {  
  
    let url = `${BASE_URL}${deleteCategorie}${id}`;   
 
    const token = localStorage.getItem("authToken");
    try {
      const response = (await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);
 