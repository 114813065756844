import React, { useState } from "react";
import "../../Invoice/invoicesForm.css";
import { useDispatch, useSelector } from "react-redux";
import {
  CategoryStore,
  getCategory,
} from "@Networking/APIs/Invoice/Category/CategoryGetApi";
import Loader from "@Layout/Loader/loader";
import { button } from "@Layout/Color/color";

export default function CategoryForm({ onClose }) {

  //Hooks
  const dispatch = useDispatch();

  //Redux
  const [category, setCategory] = useState("");
  const { validateError, loading1 } = useSelector(
    (state) => state.CategorySlice
  );

  const handleCategoryChange = (e) => {
    const inputValue = e.target.value;
    const validValue = inputValue.replace(/[^A-Za-z\s]/g, "");
    setCategory(validValue);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const newCategory = {
      name: category,
    };
    try {
      const res = await dispatch(CategoryStore(newCategory));
      if (res.payload.status == true) {
        onClose();
      }
      await dispatch(getCategory());
    } catch (error) {
    }
  };

  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block", backdropFilter: "blur(5px)" }}
        aria-modal="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <img
                  src="assets/logo/tslogo.png"
                  alt="Client"
                  style={{ width: "25px", height: "100%" }}
                />
                <h5 className="modal-title">Add Category</h5>
              </div>
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
                style={{ position: "absolute", right: "10px", top: "10px" }}
              >
                <span aria-hidden="true"></span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>

                <div className="row">
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="inputText"
                      id="Category"
                      name="category"
                      placeholder="Add Category"
                      value={category}
                      onChange={handleCategoryChange}
                      required
                    />

                    {validateError.name && (
                      <span className="text-danger">{validateError.name[0]}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-3 text-center">
                  <button type="submit" className={button}>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {loading1 ? <Loader /> : null}
      </div>
    </div>
  );
}
