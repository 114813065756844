import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import "../Profile/Profile.css";
import {
  fetchUserProfile,
  userProfileImage,
} from "@Networking/APIs/Profile/profileApi";
import { postEmployeeDocumentStore } from "@Networking/APIs/Employee/EmployeStoreApi";
import Loader from "@Layout/Loader/loader";
import { BASE_URL } from "@Networking/APIs/NWConfig";

function Profile() {
  const dispatch = useDispatch();

  const { ProfileData } = useSelector((state) => state.ProfileSlice);

  const [isLoading, setIsLoading] = useState(false);
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  const [profilePhotoBase64, setProfilePhotoBase64] = useState(null);

  useEffect(() => {
    setEmpId(ProfileData?.employee?.id);
  }, [ProfileData]);

  const name = ProfileData?.user?.name || "N/A";
  const profilePhoto = ProfileData?.employee?.profile_photo || "N/A";
  const employeeNumber = ProfileData?.employee?.employee_number || "N/A";
  const employeeStatus = ProfileData?.employee?.employee_status || "N/A";
  const joiningDate = ProfileData?.employee?.joining_date || "N/A";
  const probationPolicy = ProfileData?.employee?.probation_policy || "N/A";

  const firstName = ProfileData?.employee?.first_name || "N/A";
  const middleName = ProfileData?.employee?.middle_name || "N/A";
  const lastName = ProfileData?.employee?.last_name || "N/A";
  const gender = ProfileData?.employee?.gender || "N/A";
  const dateOfBirth = ProfileData?.employee?.dob || "N/A";
  const nationality = ProfileData?.employee?.nationality || "N/A";

  const country = ProfileData?.employee?.country || "N/A";
  const state = ProfileData?.employee?.state || "N/A";
  const city = ProfileData?.employee?.city || "N/A";
  const pincode = ProfileData?.employee?.pincode || "N/A";
  const address = ProfileData?.employee?.address || "N/A";

  const workLocation = ProfileData?.location?.location || "N/A";
  const reportingManager = ProfileData?.reporting_manager?.name || "N/A";
  const reportingTeamLeader = ProfileData?.reporting_teamleader?.name || "N/A";

  const bankName = ProfileData?.employee?.bank || "N/A";
  const accountName = ProfileData?.employee?.account_name || "N/A";
  const accountNumber = ProfileData?.employee?.account_number || "N/A";
  const ifscCode = ProfileData?.employee?.ifsc_code || "N/A";
  const branch = ProfileData?.employee?.branch || "N/A";

  const [selectedName, setDepartment] = useState(null);
  const [nameInput, setNameInput] = useState("");

  const [empId, setEmpId] = useState(null);

  const [documentList, setDocumentList] = useState([
    { documentName: "", documentFile: null },
  ]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedDocumentList = [...documentList];
    updatedDocumentList[index][name] = value;
    setDocumentList(updatedDocumentList);
  };

  const handleFileChange = (index, event) => {
    const file = event.target.files[0];
    const updatedDocumentList = [...documentList];
    updatedDocumentList[index].documentFile = file;
    setDocumentList(updatedDocumentList);
  };

  const handleAddRow = () => {
    setDocumentList([
      ...documentList,
      { documentName: "", documentFile: null },
    ]);
  };

  const handleRemoveRow = (index) => {
    if (index === 0) return; // Ensure the first row cannot be removed
    const updatedDocumentList = documentList.filter((_, i) => i !== index);
    setDocumentList(updatedDocumentList);
  };

  const handleView = (docFile) => {
    const anchor = document.createElement("a");
    anchor.href = docFile;
    anchor.target = "_blank";
    anchor.rel = "noopener noreferrer";
    anchor.click();
  };
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    documentList.forEach((doc, index) => {
      if (!doc.documentName) {
        newErrors[index] = newErrors[index] || {};
        newErrors[index].documentName = "Document name is required";
      }
      if (!doc.documentFile) {
        newErrors[index] = newErrors[index] || {};
        newErrors[index].documentFile = "Document file is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsModal(false);
    setIsLoading(true);

    const formData = new FormData();
    formData.append("employee_id", empId);
    documentList.forEach((doc, index) => {
      formData.append(`documents[${index}][documentName]`, doc.documentName);
      formData.append(`documents[${index}][documentFile]`, doc.documentFile);
    });

    // Log FormData for debugging
    for (let pair of formData.entries()) {
    }

    try {
      const res = await dispatch(postEmployeeDocumentStore(formData));
      if (res.payload.status === true) {
        setIsLoading(false);
        dispatch(fetchUserProfile());
      }
    } catch (error) {
      const errorMessage =
        error.response?.data ||
        "An unexpected error occurred. Please try again.";
    }
  };

  const getImageUrl = () => {
    const imageUrl = `https://developbo.techsimba.in/management/public/profile_Photos/${profilePhoto}`;
    return imageUrl;
  };

  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      handleImageUpload(file);
    }
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("profile_photo", file);

    try {
      const res = await dispatch(userProfileImage(formData));
      if (res.payload.status === true) {
        setIsLoading(false);
        dispatch(fetchUserProfile());
      }
    } catch (error) {
      const errorMessage =
        error.response?.data ||
        "An unexpected error occurred. Please try again.";
    }
  };

  const handleImageClick = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <>
      <section class=" ">
        <div class="container-fluid">
          <div class="row gy-4 gy-lg-0">
            <div class="col-12 col-lg-4 col-xl-3">
              <div class="row gy-4">
                <div class="col-12">
                  <div class="card widget-card border-light shadow-sm">
                    <div class="card-header btn-gradient-info text-white">
                      Welcome,{name}
                    </div>
                    <div class="card-body">
                      <div class="text-center">
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <a href="#" onClick={handleImageClick}>
                            <img
                              src={getImageUrl()}
                              className="img-fluid rounded-circle"
                              alt="User photo"
                              style={{
                                width: "150px",
                                height: "150px",
                                objectFit: "cover",
                              }}
                            />
                          </a>

                          <i
                            className="fa fa-pencil-alt fs-6"
                            style={{
                              position: "absolute",
                              bottom: "10px",
                              right: "10px",
                              background:
                                "linear-gradient(to right, #90caf9, #047edf)",
                              color: "white",
                              padding: "5px",
                              borderRadius: "25%",
                              cursor: "pointer",
                            }}
                            onClick={handleImageClick}
                          ></i>
                        </div>

                        <input
                          id="fileInput"
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={handleImageChange}
                        />
                      </div>
                      <h5 class="text-center mt-3 border border-3 btn-gradient-info text-light">
                        {name}
                      </h5>
                      <p class="text-center text-secondary mb-2">{}</p>

                      <ul class="list-group list-group-flush mb-0">
                        <li class="list-group-item">
                          <h6 class="mb-1">
                            <span class="bii bi-mortarboard-fill me-2"></span>
                            Employee Number
                          </h6>
                          {employeeNumber}
                        </li>

                        <li class="list-group-item">
                          <h6 class="mb-1">
                            <span class="bii bi-mortarboard-fill me-2"></span>
                            Employee Status
                          </h6>
                          {employeeStatus}
                        </li>

                        <li class="list-group-item">
                          <h6 class="mb-1">
                            <span class="bii bi-mortarboard-fill me-2"></span>
                            Joining Date
                          </h6>
                          {joiningDate}
                        </li>

                        <li class="list-group-item">
                          <h6 class="mb-1">
                            <span class="bii bi-mortarboard-fill me-2"></span>
                            Probation Policy
                          </h6>
                          {probationPolicy}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-8 col-xl-9">
              <div class="card widget-card border-light shadow-sm">
                <div class="card-body p-4">
                  <ul
                    class="nav nav-tabs modern-tabs"
                    id="profileTab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active text-dark fw-bold"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="profile-tab-pane"
                        aria-selected="true"
                      >
                        User Details
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link text-dark fw-bold"
                        id="password-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#password-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="password-tab-pane"
                        aria-selected="false"
                      >
                        Job Details
                      </button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link text-dark fw-bold"
                        id="bank-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#bank-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="bank-tab-pane"
                        aria-selected="false"
                      >
                        Bank Details
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link text-dark fw-bold"
                        id="document-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#document-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="document-tab-pane"
                        aria-selected="false"
                      >
                        Documents
                      </button>
                    </li>
                  </ul>

                  <div class="tab-content pt-4" id="profileTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="profile-tab-pane"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                      tabindex="0"
                    >
                      <div class="row gy-3 gy-xxl-4">
                        <div class="profile-container">
                          <div class="profile-card">
                            <h2 class="section-title btn-gradient-info text-white">
                              Personal Information
                            </h2>
                            <div class="info-row">
                              <div class="info-item">
                                <span>First Name:</span>{" "}
                                <span class="detail">{firstName}</span>
                              </div>
                              <div class="info-item">
                                <span>Middle Name:</span>{" "}
                                <span class="detail">{middleName}</span>
                              </div>
                            </div>
                            <div class="info-row">
                              <div class="info-item">
                                <span>Last Name:</span>{" "}
                                <span class="detail">{lastName}</span>
                              </div>
                              <div class="info-item">
                                <span>Gender:</span>{" "}
                                <span class="detail">{gender}</span>
                              </div>
                            </div>
                            <div class="info-row">
                              <div class="info-item">
                                <span>Date of Birth:</span>{" "}
                                <span class="detail">{dateOfBirth}</span>
                              </div>
                              <div class="info-item">
                                <span>Nationality:</span>{" "}
                                <span class="detail">{nationality}</span>
                              </div>
                            </div>
                          </div>

                          <div class="profile-card">
                            <h2 class="section-title btn-gradient-info text-white">
                              Current Address
                            </h2>
                            <div class="info-row">
                              <div class="info-item">
                                <span>Country:</span>{" "}
                                <span class="detail">{country}</span>
                              </div>
                              <div class="info-item">
                                <span>State:</span>{" "}
                                <span class="detail">{state}</span>
                              </div>
                            </div>
                            <div class="info-row">
                              <div class="info-item">
                                <span>City:</span>{" "}
                                <span class="detail">{city}</span>
                              </div>
                              <div class="info-item">
                                <span>Pincode:</span>{" "}
                                <span class="detail">{pincode}</span>
                              </div>
                            </div>
                            <div class="info-row">
                              <div class="info-item full-width">
                                <span>Address:</span>{" "}
                                <span class="detail">{address}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="password-tab-pane"
                      role="tabpanel"
                      aria-labelledby="password-tab"
                      tabindex="0"
                    >
                      <div class="row gy-3 gy-xxl-4">
                        <div class="profile-container">
                          <div class="profile-card">
                            <h2 class="section-title btn-gradient-info text-white">
                              Work Information
                            </h2>
                            <div class="info-row">
                              <div class="info-item">
                                <span>Work Location:</span>{" "}
                                <span class="detail">{workLocation}</span>
                              </div>
                              <div class="info-item">
                                <span>Joining Date:</span>{" "}
                                <span class="detail">{joiningDate}</span>
                              </div>
                            </div>
                            <div class="info-row">
                              <div class="info-item">
                                <span>Reporting Teamleader:</span>{" "}
                                <span class="detail">{reportingManager}</span>
                              </div>
                              <div class="info-item">
                                <span>Reporting Manager:</span>{" "}
                                <span class="detail">
                                  {reportingTeamLeader}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="bank-tab-pane"
                      role="tabpanel"
                      aria-labelledby="bank-tab"
                      tabindex="0"
                    >
                      <div class="row gy-3 gy-xxl-4">
                        <div class="profile-container">
                          <div class="profile-card">
                            <h2 class="section-title btn-gradient-info text-white">
                              Compensation Details
                            </h2>
                            <div class="info-row">
                              <div class="info-item">
                                <span>Bank Name:</span>{" "}
                                <span class="detail">{bankName}</span>
                              </div>
                              <div class="info-item">
                                <span>Account Name:</span>{" "}
                                <span class="detail">{accountName}</span>
                              </div>
                            </div>
                            <div class="info-row">
                              <div class="info-item">
                                <span>Account Number:</span>{" "}
                                <span class="detail">{accountNumber}</span>
                              </div>
                              <div class="info-item">
                                <span>IFSC Code:</span>{" "}
                                <span class="detail">{ifscCode}</span>
                              </div>
                            </div>
                            <div class="info-row">
                              <div class="info-item full-width">
                                <span>Branch:</span>{" "}
                                <span class="detail">{branch}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="document-tab-pane"
                      role="tabpanel"
                      aria-labelledby="document-tab"
                      tabindex="0"
                    >
                      <div class="row gy-3 gy-xxl-4">
                        <div class="profile-card">
                          <div class="row">
                            <div class="col-md-6">
                              <h2 class="section-title btn-gradient-info text-white">
                                Document List
                              </h2>
                            </div>
                            <div class="col-md-6 text-end">
                              <button
                                type="button"
                                className="section-title btn-gradient-success text-white"
                                onClick={() => setIsModal(true)}
                              >
                                Uploads <i className="fa-solid fa-upload"></i>
                              </button>
                            </div>
                          </div>

                          <div class="info-row">
                            {ProfileData?.documents?.map((doc) => (
                              <div class="info-item full-width" key={doc.id}>
                                <span>Document Name:</span>
                                {doc.doc_name}
                                <span class="detail">
                                  <button
                                    class="btn btn-danger"
                                    onClick={() => handleView(doc.doc_file)}
                                    title="View Document"
                                  >
                                    <i class="fa fa-file"></i>
                                  </button>
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <form onSubmit={handleSubmit}>
        {isModal ? (
          <div>
            <div
              className="modal fade show d-block"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
              <div className="modal-dialog modal-lg usrProDocMdl">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Upload Document</h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setIsModal(false)}
                    ></button>
                  </div>
                  <div className="modal-body">
                    {documentList.map((document, index) => (
                      <div className="row mb-2" key={index}>
                        <div className="col-md-4 mb-2">
                          <input
                            id="input"
                            type="text"
                            name="documentName"
                            placeholder="Enter Document Name"
                            value={document.documentName}
                            onChange={(e) => handleInputChange(index, e)}
                          />
                          {errors[index]?.documentName && (
                            <div className="text-danger">
                              {errors[index].documentName}
                            </div>
                          )}
                        </div>
                        <div className="col-md-4 mb-2">
                          <input
                            id="input"
                            type="file"
                            name="documentFile"
                            onChange={(e) => handleFileChange(index, e)}
                          />
                          {errors[index]?.documentFile && (
                            <div className="text-danger">
                              {errors[index].documentFile}
                            </div>
                          )}
                        </div>
                        <div className="col-md-4 d-flex align-items-center justify-content-around">
                          <button
                            type="button"
                            className="btn btn-primary rounded-circle"
                            onClick={handleAddRow}
                            // data-bs-toggle="tooltip"
                            // data-bs-placement="top" // Position of the tooltip (top, bottom, left, right)
                            title="Add More Documents"
                          >
                            <i className="fa-solid fa-plus"></i>
                          </button>
                          {index > 0 && (
                            <button
                              type="button"
                              className="btn btn-danger rounded-circle"
                              onClick={() => handleRemoveRow(index)}
                              title="Remove Row"
                            >
                              <i className="fa-solid fa-minus"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-gradient-success">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </form>

      {isLoading && <Loader />}
    </>
  );
}

export default Profile;
