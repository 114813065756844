import { BASE_URL, addProject, projectList } from '@Networking/APIs/NWConfig';
import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';



export const postProjectStore = createAsyncThunk(
    "post/postProjectStore",
    async(post) => {
  
        let url = BASE_URL + addProject;
  
        const token = localStorage.getItem("authToken");
  
        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;
  
                return Promise.resolve(response);
        } catch(error) {
            return Promise.reject(error);
        }
    }
  );


  export const getProjectList = createAsyncThunk(
    'get/getProjectList',
    async () => {  
      
      let url = BASE_URL + projectList;  
      const token = localStorage.getItem("authToken");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );
  