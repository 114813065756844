import React, { useEffect, useMemo, useState } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { getAllBank } from "@Networking/APIs/Invoice/Bank/bankGetApi";
import { DeleteBankStore } from "@Networking/APIs/Invoice/Bank/deleteBankApi";
import BankForm from "../Bank/bankForm";
import EditBankForm from "../Bank/editBankForm";
import {
  PagginationController,
  card,
  componentheaderStep1,
  componentheaderStep2,
  container,
  dangerButton,
  headerCard,
  lightButton,
  pagginationContainter,
  primaryButton,
  searchDiv,
  selectPage,
  successButton,
  tableDiv,
  textDiv,
  textMuted,
} from "@Layout/Color/color";

//GobalFilter
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <span>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value || undefined)}
        placeholder="Search all columns"
        className="ps-2 rounded"
        style={{ width: "200px", display: "inline-block", marginLeft: "10px" }}
      />
    </span>
  );
};

const BankTable = () => {
  //Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Redux
  const { AllBankData } = useSelector((state) => state.BankPostSlice);

  //States
  const [showAddBankForm, setShowAddBankForm] = useState(false);
  const [currentBank, setCurrentBank] = useState(null);
  const [showEditBankForm, setShowEditBankForm] = useState(false);
  const [Bank, setBank] = useState(null);
  const [bankOptions, setBankOptions] = useState("");

  //UseEffects
  useEffect(() => {
    dispatch(getAllBank(3));
  }, [dispatch]);

  const data = useMemo(() => {
    if (AllBankData && Array.isArray(AllBankData?.data)) {
      return AllBankData?.data?.map((bank) => ({
        ...bank,
        AccountType: bank.account_type,
        BankName: bank.bank_title,
        Description: DOMPurify.sanitize(bank?.bank_description),
        id: bank.id,
      }));
    }
    return [];
  }, [AllBankData]);

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "value" },
      { Header: "Bank Name", accessor: "label" },
      {
        Header: "Description",
        accessor: "bank_description", // Access plain sanitized description data
        Cell: ({ value }) => (
          <div
            className="text-wrap"
            dangerouslySetInnerHTML={{ __html: value }}
          />
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="d-flex justify-content-start">
            <button
              className={primaryButton}
              onClick={() => handleEdit(row.original)}
              title="Edit Invoice"
            >
              <i className="fa fa-pencil text-white"></i>
            </button>
            <button
              className={dangerButton}
              onClick={() => handleDeleteBank(row.original)}
              title="Delete Invoice"
            >
              <i className="fa fa-trash text-white"></i>
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
    useGlobalFilter,
    usePagination
  );

  const { globalFilter, pageIndex, pageSize } = state;

  const handleAddBank = (newBank) => {
    bankModel();
    setBankOptions([...bankOptions, newBank]);
    setBank(newBank);
  };

  const bankModel = () => {
    setShowAddBankForm(true);
  };

  const handleEdit = (i) => {
    setCurrentBank(i);
    setShowEditBankForm(true);
  };

  const handleDeleteBank = (id) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this Bank Detials?"
    );
    if (userConfirmed) {
      dispatch(DeleteBankStore(id.id));
      dispatch(getAllBank());
      setBank("");
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <h6 className="">
            <i class="fa-solid fa-list me-2"></i>Finance / Master's / Bank
            Details
          </h6>
        </div>
      </div>

      <div className="m-1">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleBack}
        >
          <i className="fa fa-arrow-left me-2"></i>
          Back
        </button>
      </div>

      <div className="card bg-gradient-info rounded-top-2 p-2 mb-4">
        <div className="row d-flex align-items-center">
          <div className="col-md-8 col-sm-6">
            <h3 className="page-title m-0  text-light">
              <span className="mx-2 text-primary">
                <i className="mdi mdi-file  text-light"></i>
              </span>
              Bank Master
            </h3>
          </div>
          <div className="col-md-2 col-sm-3">
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </div>
          <div className="col-md-2 col-sm-3 text-end">
            <button className="btn btn-success text-light" onClick={handleAddBank}>
              Add Bank
            </button>
          </div>
        </div>
      </div>

      <div className={card}>
        <div className={headerCard}>
          <h5>Bank List</h5>
        </div>
        <div className={tableDiv} style={{ height: "auto", overflowY: "auto" }}>
          <table {...getTableProps()} className="table table-hover">
            <thead
              style={{
                position: "sticky",
                top: -25,
                backgroundColor: "#f0f0f0",
              }}
            >
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th className="" {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td className="text-start" {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-center" colSpan={columns.length}>
                    No banks available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {showAddBankForm && (
          <BankForm
            bankOptions={bankOptions}
            setBankOptions={setBankOptions}
            Bank={Bank}
            setBank={setBank}
            onClose={() => setShowAddBankForm(false)}
            onAddBank={handleAddBank}
          />
        )}

        {showEditBankForm && (
          <EditBankForm
            currentBank={currentBank}
            currentClientState={Bank}
            onClose={() => setShowEditBankForm(false)}
          />
        )}

        <div className={pagginationContainter}>
          <div>
            <span className={textMuted}>
              Page <strong>{pageIndex + 1}</strong> of{" "}
              <strong>{pageOptions.length}</strong>
            </span>
          </div>
          <div className={PagginationController}>
            <button
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              className={lightButton}
              title="First Page"
            >
              <i className="bi bi-chevron-double-left"></i>
            </button>
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className={lightButton}
              title="Previous Page"
            >
              <i className="bi bi-chevron-left"></i>
            </button>

            <div className="d-flex">
              {pageOptions.map((page, index) => (
                <button
                  key={index}
                  onClick={() => gotoPage(page)}
                  className={`btn btn-sm mx-1 ${
                    pageIndex === page ? "btn-primary text-white" : "btn-light"
                  }`}
                  style={{
                    borderRadius: "50%",
                    width: "35px",
                    height: "35px",
                    padding: "5px",
                  }}
                  title={`Page ${page + 1}`}
                >
                  {page + 1}
                </button>
              ))}
            </div>

            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className={lightButton}
              title="Next Page"
            >
              <i className="bi bi-chevron-right"></i>
            </button>
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              className={lightButton}
              title="Last Page"
            >
              <i className="bi bi-chevron-double-right"></i>
            </button>
          </div>
          <div className="d-flex align-items-center">
            <label htmlFor="pageSize" className="me-2 mb-0 text-muted">
              Show:
            </label>
            <select
              id="pageSize"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
              className={selectPage}
              style={{ width: "80px" }}
            >
              {[10, 20, 30, 40, 50].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankTable;
