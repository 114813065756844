import {
  getadminDashboardData,
  getDashboardData,
  getInvoiceDashboardData,
  updateBirthdayStatus,
} from "@Networking/APIs/Dashboard/dashboardApi";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const DashboardSlice = createSlice({
  name: "DashboardSlice",
  initialState: {
    loading: false,
    loading3: false,
    DashboardData: [],
    InvoiceDashboardData: {},
    AdminDashboardData: [],
    success: false,
    error: null,
    validateError: [],
  },

  extraReducers: (builder) => {
    // Get Details
    builder.addCase(getDashboardData.pending, (state) => {
      state.error = null;
    });
    builder.addCase(getDashboardData.fulfilled, (state, action) => {
      state.success = true;
      state.DashboardData = action.payload;

    });
    builder.addCase(getDashboardData.rejected, (state, action) => {
      state.error = action.error.message || "Login failed.";
    });

    //

    // Get Details
    builder.addCase(getInvoiceDashboardData.pending, (state) => {
      state.error = null;
    });
    builder.addCase(getInvoiceDashboardData.fulfilled, (state, action) => {
      state.success = true;
      state.InvoiceDashboardData = action.payload;
    });
    builder.addCase(getInvoiceDashboardData.rejected, (state, action) => {
      state.error = action.error.message || "Login failed.";
    });

    // Admin Dashboard
     // Get Details
     builder.addCase(getadminDashboardData.pending, (state) => {
      state.error = null;
    });
    builder.addCase(getadminDashboardData.fulfilled, (state, action) => {
      state.success = true;
      state.AdminDashboardData = action.payload;
    });
    builder.addCase(getadminDashboardData.rejected, (state, action) => {
      state.error = action.error.message || "Login failed.";
    });


// Birthday Update
 builder.addCase(updateBirthdayStatus.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateBirthdayStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload;
      
     
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.message)
      }
      
    });
    builder.addCase(updateBirthdayStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Update Status Failed.';
    });

  },
});

export default DashboardSlice.reducer;
