import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  getcity,
  getcountries,
  getcountryCode,
  getstate,
} from "@Networking/APIs/Background/backgorundApi";
import { EditClientStore } from "@Networking/APIs/Invoice/Client/clientApi";
import { getClient } from "@Networking/APIs/Invoice/Client/clientGetApi";
import "../../Invoice/invoicesForm.css";
import Loader from "@Layout/Loader/loader";
import { toast } from "react-toastify";

const EditClientForm = ({ currentClient, onClose,onFormSubmit }) => {
  //Hooks
  const dispatch = useDispatch();

  //Redux
  const { country, state, city, countryCode } = useSelector(
    (state) => state.BackgroundSlice || {}
  );
  const { validateError, loading3 } = useSelector((state) => state.clientSlice);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // States
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);

  //UseEffect
  useEffect(() => {
    dispatch(getcountries());
    dispatch(getcountryCode());

    if (currentClient) {
      reset({
        id: currentClient.value,
        clientName: currentClient.first_name,
        clientLastName: currentClient.last_name,
        clientBussinessName: currentClient.business_name,
        clientPhone: currentClient.client_phone,
        clientPhoneCode: currentClient.phone_code,
        clientEmail: currentClient.email,
        clientAddress: currentClient.address,
        clientGst: currentClient.gst,
        clientPAN: currentClient.pan,
        pincode: currentClient.pincode,
        clientWebsite: currentClient.website,
      });
      setSelectedCountry(currentClient.country);
      setSelectedState(currentClient.state);
      setSelectedCity(currentClient.city);
      setSelectedCountryCode(currentClient.phone_code);
    }
  }, [currentClient, dispatch, reset]);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption ? selectedOption.label : "");
    if (selectedOption) {
      dispatch(getstate(selectedOption.value));
    }
  };

  const handleStateChange = (newValue) => {
    setSelectedState(newValue);
    if (newValue) {
      dispatch(getcity(newValue.value));
    }
  };

  const handleCityChange = (newValue) => {
    setSelectedCity(newValue);
  };

  const handleCountryCodeChange = (selectedOption) => {
    setSelectedCountryCode(selectedOption ? selectedOption.label : "");
  };

  const onSubmit = async (data) => {
    const updatedClient = {
      first_name: data.clientName,
      last_name: data.clientLastName,
      business_name: data.clientBussinessName,
      client_phone: data.clientPhone,
      phone_code: selectedCountryCode || "",
      email: data.clientEmail,
      address: data.clientAddress,
      gst: data.clientGst,
      pan: data.clientPAN,
      pincode: data.pincode,
      website: data.clientWebsite,
      country: selectedCountry || "",
      state: selectedState ? selectedState.label : "",
      city: selectedCity ? selectedCity.label : "",
      id: data.id,
    };
    try {
      const res = await dispatch(EditClientStore(updatedClient));
      if (res.payload.status == true) {
        const isSuccess = true; 
        onFormSubmit(isSuccess);
        onClose();
        toast.success(res.payload.message);
      }
      await dispatch(getClient());
    } catch (error) {
    }
  };

  return (
    <div
      className="modal fade show"
      style={{ display: "block", backdropFilter: "blur(5px)" }}
      aria-modal="true"
    >
      <div className="modal-dialog modal-lg">
        <div
          className="modal-content"
          style={{ maxHeight: "90vh", overflow: "hidden" }}
        >
          <div
            className="modal-header"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src="assets/logo/tslogo.png"
                alt="Client"
                style={{ width: "25px", height: "100%" }}
              />
              <h5 className="modal-title text-center">
                Edit Client Information
              </h5>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              style={{ position: "absolute", right: "10px", top: "10px" }}
            />
          </div>

          <div className="modal-body card-body-scrollable">
            <form onSubmit={handleSubmit(onSubmit)}>
              <h6>Customer Information</h6>
              <div className="row">
                <div className="col-md-6">
                  <label>First Name</label>
                  <input
                    type="text"
                    className={`inputText ${errors.clientName ? "is-invalid" : ""
                      }`}
                    id="clientName"
                    name="clientName"
                    placeholder="First Name"
                    {...register("clientName", { required: true })}
                  />
                  {errors.clientName && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
                <div className="col-md-6">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className={`inputText ${errors.clientLastName ? "is-invalid" : ""
                      }`}
                    id="clientLastName"
                    name="clientLastName"
                    placeholder="Last Name"
                    {...register("clientLastName", { required: true })}
                  />
                  {errors.clientLastName && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
              </div>

              <div className="col-md-12 mt-2">
                <label>Business Name</label>
                <input
                  type="text"
                  className={`inputText ${errors.clientBussinessName ? "is-invalid" : ""
                    }`}
                  id="clientBussinessName"
                  name="clientBussinessName"
                  placeholder="Enter Business Name"
                  {...register("clientBussinessName", { required: true })}
                />
                {errors.clientBussinessName && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>

              <div className="col-md-12 mt-2">
                <label>Email Address</label>
                <input
                  type="email"
                  className={"inputText"}
                  id="clientEmail"
                  name="clientEmail"
                  placeholder="Email Address"
                  {...register("clientEmail")}
                />
               
              </div>

              <div className="row mt-2">
                <div className="col-md-6">
                  <label>Country Code</label>
                  <Select
                    id="countryCode"
                    options={countryCode}
                    placeholder={selectedCountryCode || "Select Country Code"}
                    {...register("countryCode")}
                    isSearchable
                    className="select-client inputText"
                    onChange={handleCountryCodeChange}
                  />
                </div>
                <div className="col-md-6">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    className={`inputText ${errors.clientPhone ? "is-invalid" : ""
                      }`}
                    id="clientPhone"
                    name="clientPhone"
                    placeholder="Phone Number"
                    {...register("clientPhone")}
                  />
                 
                </div>
              </div>
<hr></hr>
              <h6>Billing Address</h6>
              <div className="row">
                <div className="col-md-6">
                  <label>Select Country</label>
                  <Select
                    id="clientCountry"
                    options={country}
                    placeholder={selectedCountry || "Select Country"}
                    {...register("clientCountry")}
                    isSearchable
                    className="select-client inputText"
                    onChange={handleCountryChange}
                  />
                </div>
                <div className="col-md-6">
                  <label>Select State</label>
                  <Select
                    id="clientState"
                    options={state}
                    placeholder={selectedState || "Select State"}
                    isSearchable
                    className="select-client inputText"
                    onChange={handleStateChange}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-6">
                  <label>Select City</label>
                  <Select
                    id="clientCity"
                    options={city}
                    placeholder={selectedCity || "Select City"}
                    {...register("clientCity")}
                    isSearchable
                    className="select-client inputText"
                    onChange={handleCityChange}
                  />
                </div>
                <div className="col-md-6">
                  <label>Pincode</label>
                  <input
                    type="text"
                    className={`inputText px-3 ${
                      errors.pincode ? "is-invalid" : ""
                    }`}
                    id="pincode"
                    name="pincode"
                    placeholder="Enter Pincode "
                    {...register("pincode", { 
                      pattern: {
                        value: /^[0-9]{6}$/,
                        message: "Only 6-digit numbers are allowed",
                       },
                    })}
                  />
                 
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-6">
                  <label>Client PAN</label>
                  <input
                    type="text"
                    className="inputText"
                    id="clientPAN"
                    name="clientPAN"
                    placeholder="PAN No"
                    {...register("clientPAN", { required: false })}
                  />
                </div>
                <div className="col-md-6">
                  <label>Client GST</label>
                  <input
                    type="text"
                    className={`inputText px-3 ${
                      errors.clientGst ? "is-invalid" : ""
                    }`}
                    id="clientGst"
                    name="clientGst"
                    placeholder="GST No"
                    {...register("clientGst", { 
                      pattern: {
                        value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9]{1}$/, 
                        message: "GST Number must be a valid 15-character alphanumeric string",
                      },
                    })}
                  />
                </div>
              </div>

              <div className="col-md-12 mt-2">
                <label>Website</label>
                <input
                  type="text"
                  className="inputText"
                  id="clientWebsite"
                  name="clientWebsite"
                  placeholder="Website"
                  {...register("clientWebsite", { required: false })}
                />
              </div>

              <div className="col-md-12 mt-2">
                <label>Full Address</label>
                <input
                  type="text"
                  className="inputText"
                  id="clientAddress"
                  name="clientAddress"
                  placeholder="Full Address"
                  {...register("clientAddress", { required: true })}
                />
                {errors.clientAddress && (
                  <span className="text-danger">This field is required</span>
                )}
                {/* {validateError.address && (
                  <span className="text-danger">
                    {validateError.address[0]}
                  </span>
                )} */}
              </div>

              <div className="text-center mt-3">
                <button type="submit" className="btn btn-gradient-info my-2">
                  Update Client
                </button>
              </div>
            </form>
          </div>
        </div>
        {loading3 ? <Loader /> : null}
      </div>
    </div>
  );
};

export default EditClientForm;
