import { createSlice } from '@reduxjs/toolkit';
import { getInvoiceNumber ,storeInvoice,getallinvoices, EditInvoice, getEditData, SoftCancel} from '@Networking/APIs/Invoice/invoiceApi';
import { toast } from 'react-toastify';
 
const GetInSlice = createSlice({
  name: 'GetInSlice',
  initialState: {
    loading: false,
    InvoiceNumberGet:'',
    InvoiceData:{},
    updateInvoices:{},
    cancelloading: false,
    cancelData: "",
    validateError:"",
    success: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(getInvoiceNumber.pending, (state) => {

      state.loading = true;
      state.error = null;
    });
    builder.addCase(getInvoiceNumber.fulfilled, (state, action) => {

      state.loading = false;
      state.success = true;
      state.InvoiceNumberGet = action.payload;
    });
    builder.addCase(getInvoiceNumber.rejected, (state, action) => {

      state.loading = false;
      state.error = action.error.message || 'Login failed.';
    });
    builder.addCase(storeInvoice.pending, (state) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(storeInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.InvoiceData = action.payload;
      });
      builder.addCase(storeInvoice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Login failed.';
      });
      builder.addCase(getallinvoices.pending, (state) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(getallinvoices.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.AllInvoices = action.payload;
      });
      builder.addCase(getallinvoices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Login failed.';
      });
      builder.addCase(EditInvoice.pending, (state) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(EditInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.updateInvoices = action.payload;
        if(action.payload.status == true){
          toast.success(action.payload.message)
        }
        else{
          toast.error(action.payload.message)
          state.validateError = action.payload.response.data.message;
        }
      });
      builder.addCase(EditInvoice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Login failed.';
      });
      builder.addCase(getEditData.pending, (state) => {
        state.Editloading = true;
        state.error = null; // Reset error on new request
      })
      builder.addCase(getEditData.fulfilled, (state, action) => {
        state.Editloading = false; // Set Editloading to false
        state.EditData = action.payload; // Store the fetched data
        state.success = true; // Mark success
      })
      builder.addCase(getEditData.rejected, (state, action) => {
        state.Editloading = false; // Set Editloading to false
        state.error = action.payload; // Store the error
      });
      builder.addCase(SoftCancel.pending, (state) => {
        state.cancelloading = true;
        state.error = null; // Reset error on new request
      })
      builder.addCase(SoftCancel.fulfilled, (state, action) => {
        state.cancelloading = false; // Set Editloading to false
        state.cancelData = action.payload; 
        state.success = true; // Mark success
      })
      builder.addCase(SoftCancel.rejected, (state, action) => {
        state.cancelloading = false; // Set Editloading to false
        state.error = action.payload; // Store the error
      });
  },
});

export default GetInSlice.reducer;