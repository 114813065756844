// src/api.js

import axios from 'axios';
import { BASE_URL, leaveShow, employees, leavesByEmployee } from '@Networking/APIs/NWConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchEmployeeList = createAsyncThunk(
    "get/fetchEmployeeList",
    async() => {
  
        let url = BASE_URL + employees;
        const token = localStorage.getItem("authToken");
        try {
            const response = (await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;
            return Promise.resolve(response);
        } catch(response) {
            return response;
        }
    }
  );


export const fetchLeaveList = createAsyncThunk(
  "get/fetchLeaveList",
  async() => {

      let url = BASE_URL + leaveShow;
      const token = localStorage.getItem("authToken");
      try {
          const response = (await axios.get(url, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;
          
        return Promise.resolve(response);
      } catch(response) {
          return response;
      }
  }
);

export const fetchTotalLeavesByEmployee = createAsyncThunk(
  "get/fetchTotalLeavesByEmployee",
  async(post) => {

      let url = BASE_URL + leavesByEmployee;
      const token = localStorage.getItem("authToken");
        const user_id = post.user_id;
        const month = post.month;
      try {
          const response = (await axios.get(url, {
            params: {
              user_id: user_id,
              month: month,
            },
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;
        return Promise.resolve(response);
      } catch(response) {
          return response;
      }
  }
);
