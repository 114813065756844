import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getcity,
  getcountries,
  getstate,
} from "@Networking/APIs/Background/backgorundApi";
import { debounce } from 'lodash';
import { postCompanyStore } from "@Networking/APIs/Invoice/Company/companyStoreApi";
import { getCompany } from "@Networking/APIs/Invoice/Company/companyGetApi";
import Loader from "@Layout/Loader/loader";
import { button } from "@Layout/Color/color";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CompanyForm = ({ onClose }) => {
  //Hooks
  const dispatch = useDispatch();
  const [resize, setResize] = useState(false);
  //Redux
  const { country, state, city } = useSelector(
    (state) => state.BackgroundSlice || {}
  );
  const { CompanyGet, loading } = useSelector(
    (state) => state.CompanyStoreSlice
  );

  const Data = CompanyGet?.company;

  //States
  const [selectedCountry, setSelectedCountry] = useState(
    Data?.company_country ? Data.company_country : null
  );
  const [selectedState, setSelectedState] = useState(
    Data?.company_state ? { label: Data.company_state, value: null } : null
  );
  const [selectedCity, setSelectedCity] = useState(
    Data?.company_city ? { label: Data.company_city, value: null } : null
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: Data
      ? {
          companyName: Data.company_name,
          companyEmail: Data.company_email,
          companyContact: Data.company_contact,
          companyShortName: Data.company_short_name,
          companyAddress: Data.company_address,
          companyPincode: Data.company_pincode,
          companyWebsite: Data.company_website,
          companyGst: Data.company_gst,
          termsCondition: Data.terms_condition,
          facebook: Data.facebook,
          twitter: Data.twitter,
          instagram: Data.instagram,
          linkedIn: Data.linkedIn,
        }
      : {},
  });

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption ? selectedOption.label : "");
    if (selectedOption) {
      dispatch(getstate(selectedOption.value));
    }
  };

  const handleStateChange = (newValue) => {
    setSelectedState(newValue);
    if (newValue) {
      dispatch(getcity(newValue.value));
    }
  };

  const handleCityChange = (newValue) => {
    setSelectedCity(newValue);
  };

  useEffect(() => {
    dispatch(getcountries());
    if (Data) {
    }
  }, []);
  useEffect(() => {
    const handleResize = debounce(() => {
      setResize(true);
    }, 200);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      handleResize.cancel();
    };
  }, []);

  // Custom upload adapter
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file
            .then((file) => {
              // Simulate an upload process
              const reader = new FileReader();
              reader.onload = () => {
                resolve({ default: reader.result }); // Use base64 or replace with actual upload URL
              };
              reader.onerror = reject;
              reader.readAsDataURL(file);
            })
            .catch(reject);
        });
      },
    };
  }

  // Plugin to include the custom upload adapter
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const validateForm = () => {
    const errors = {};

    if (!country) {
      errors.country = "Please select a country.";
    }

    if (!state) {
      errors.state = "Please select a state.";
    }

    if (!city) {
      errors.city = "Please select a city.";
    }

    return Object.keys(errors).length === 0;
  };

  const onSubmit = async (data) => {
    if (validateForm()) {
      const newCompany = {
        company_name: data.companyName,
        company_email: data.companyEmail,
        company_contact: data.companyContact,
        company_short_name: data.companyShortName,
        company_address: data.companyAddress,
        company_country: selectedCountry ? selectedCountry : "",
        company_state: selectedState ? selectedState.label : "",
        company_city: selectedCity ? selectedCity.label : "",
        company_pincode: data.companyPincode,
        company_website: data.companyWebsite,
        company_gst: data.companyGst,
        terms_condition: data.termsCondition,
        facebook: data.facebook,
        twitter: data.twitter,
        instagram: data.instagram,
        linkedIn: data.linkedIn,
      };
      try {
        await dispatch(postCompanyStore(newCompany));
        await dispatch(getCompany());
        if (onClose) {
          onClose();
        } else {
        }
      } catch (error) {
      }
    }
  };
  const editorStyle = {
    minHeight: '200px',  // Set a minimum height
    width: '100%',       // Set width to 100%
  };

  return (
    <div
      className="modal fade show"
      style={{ display: "block", backdropFilter: "blur(5px)" }}
      aria-modal="true"
    >
      <div className="modal-dialog modal-lg">
        <div
          className="modal-content"
          style={{ maxHeight: "90vh", overflow: "hidden" }}
        >
          <div
            className="modal-header"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "sticky",
              top: 0,
              background: "#fff",
              zIndex: 1000,
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src="assets/logo/tslogo.png"
                alt="Client"
                style={{ width: "25px", height: "100%" }}
              />
              <h5 className="modal-title text-center">Edit Company</h5>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              style={{ position: "absolute", right: "10px", top: "10px" }}
            >
              <span aria-hidden="true"></span>
            </button>
          </div>
          <div
            className="modal-body"
            style={{ overflowY: "auto", maxHeight: "calc(100% - 60px)" }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="row ">
                <div className="col-md-12">
                  <label>Company Name</label>
                  <input
                    id="company-name"
                    type="text"
                    className={`inputText ${
                      errors.companyName ? "is-invalid" : ""
                    }`}
                    placeholder="Company Name"
                    {...register("companyName", { required: true })}
                  />
                  {errors.companyName && (
                    <span className="text-danger">
                      Company Name is required
                    </span>
                  )}
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-6">
                  <label>Company Email</label>
                  <input
                    id="company-email"
                    type="email"
                    className={`inputText ${
                      errors.companyEmail ? "is-invalid" : ""
                    }`}
                    placeholder="Company Email"
                    {...register("companyEmail", {
                      required: true,
                      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i,
                    })}
                  />
                  {errors.companyEmail && (
                    <span className="text-danger">
                      Please enter a valid email
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label>Company Contact</label>
                  <input
                    id="company-contact"
                    type="text"
                    className={`inputText ${
                      errors.companyContact ? "is-invalid" : ""
                    }`}
                    placeholder="Company Contact"
                    {...register("companyContact", { required: true })}
                  />
                  {errors.companyContact && (
                    <span className="text-danger">
                      Company Contact is required
                    </span>
                  )}
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-6">
                  <label>Company Website</label>
                  <input
                    id="company-short-name"
                    type="text"
                    className={`inputText ${
                      errors.companyShortName ? "is-invalid" : ""
                    }`}
                    placeholder="Company Short Name"
                    {...register("companyShortName", { required: true })}
                  />
                  {errors.companyShortName && (
                    <span className="text-danger">
                      Company Short Name is required
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label>Company Pincode</label>  
                  <input
                    id="company-pincode"
                    type="text"
                    className={`inputText ${
                      errors.companyPincode ? "is-invalid" : ""
                    }`}
                    placeholder="Company Pincode"
                    {...register("companyPincode", { required: true })}
                  />
                  {errors.companyPincode && (
                    <span className="text-danger">
                      Company Pincode is required
                    </span>
                  )}
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-6">
                  <label>Company Country</label>
                    <Select
                      id="company-country"
                      options={country}
                      placeholder={selectedCountry || "Select Country"}
                      {...register("clientCountry")}
                      isSearchable
                      className="select-client inputText"
                      onChange={handleCountryChange}
                      value={selectedCountry}
                    />
                    {errors.companyCountry && (
                      <span className="text-danger">Country is required</span>
                    )}
                </div>
                <div className="col-md-6">
                  <label>Company State</label>
                    <Select
                      id="company-state"
                      options={state}
                      placeholder={
                        selectedState ? selectedState.label : "Select State"
                      }
                      onChange={handleStateChange}
                      isSearchable
                      className="select-client inputText"
                      value={selectedState}
                    />
                    {errors.companyState && (
                      <span className="text-danger">State is required</span>
                    )}
                </div>
              </div>

                <div className="row mt-2">
                  <div className="col-md-6">
                    <label>Company City</label>
                    <Select
                      id="company-city"
                      options={city}
                      placeholder={
                        selectedCity ? selectedCity.label : "Select City"
                      }
                      onChange={handleCityChange}
                      isSearchable
                      className="select-client inputText"
                      value={selectedCity}
                    />
                    {errors.companyCity && (
                      <span className="text-danger">City is required</span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label>Company GST</label>
                  <input
                    id="company-gst"
                    type="text"
                    className={`inputText ${
                      errors.companyGst ? "is-invalid" : ""
                    }`}
                    placeholder="Company GST"
                    {...register("companyGst", { required: true })}
                  />
                  {errors.companyGst && (
                    <span className="text-danger">Company GST is required</span>
                  )}
                </div>
                </div>

              <div className="row mt-2">
                <div className="col-md-12">
                  <label>Company Website</label>
                  <input
                    id="company-website"
                    type="text"
                    className={`inputText ${
                      errors.companyWebsite ? "is-invalid" : ""
                    }`}
                    placeholder="Company Website"
                    {...register("companyWebsite", { required: true })}
                  />
                  {errors.companyWebsite && (
                    <span className="text-danger">
                      Company Website is required
                    </span>
                  )}
                </div>
               
              </div>

              <div className="row mt-2">
                <div className="col-md-6">
                  <label>Company Facebook</label>
                  <input
                    id="facebook"
                    type="text"
                    className="inputText"
                    placeholder="Facebook Link"
                    {...register("facebook")}
                  />
                </div>
                <div className="col-md-6">
                  <label>Company Twitter </label>
                  <input
                    id="twitter"
                    type="text"
                    className="inputText"
                    placeholder="Twitter Link"
                    {...register("twitter")}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-6">
                  <label>Company Instagram</label>
                  <input
                    id="instagram"
                    type="text"
                    className="inputText"
                    placeholder="Instagram Link"
                    {...register("instagram")}
                  />
                </div>
                <div className="col-md-6">
                  <label>Company LinkedIn</label>
                  <input
                    id="linkedIn"
                    type="text"
                    className="inputText"
                    placeholder="LinkedIn Link"
                    {...register("linkedIn")}
                  />
                </div>
                </div>
                <div className="row mt-2">
                <div className="col-md-12"
                >
                  <label>Company Address</label>
                  <Controller
                    name="companyAddress"
                    control={control}
                    defaultValue="" 
                    rules={{ required: "Company Address is required" }}
                    render={({ field }) => (
                      <CKEditor
                        editor={ClassicEditor}
                        data={field.value || ""}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setValue("companyAddress", data); 
                        }}
                        config={{
                          extraPlugins: [uploadPlugin],
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                            "|",
                            "insertTable",
                            "tableColumn",
                            "tableRow",
                            "mergeTableCells",
                            "|",
                            "imageUpload",
                            "mediaEmbed",
                            "|",
                            "undo",
                            "redo",
                            "|",
                            "imageTextAlternative",
                            "imageStyle:full",
                            "imageStyle:side",
                            "imageRemove",
                          ],
                          image: {
                            toolbar: [
                              "imageStyle:full",
                              "imageStyle:side",
                              "|",
                              "imageTextAlternative",
                              "imageRemove",
                              "imageUpload",
                              "imageDelete",
                            ],
                          },
                        }}
                        style={editorStyle}
                      />
                    )}
                  />
                  {errors.companyAddress && (
                    <span className="text-danger">
                      Company Address is required
                    </span>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <button type="submit" className={button}>
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {loading ? <Loader /> : null}
    </div>
  );
};

export default CompanyForm;
